//      

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getLinkColor, getCompanyName, getIsRootSpAccount } from 'src/ducks/theme';

                                                     

const ISSUANCE_NAME = process.env.ISSUANCE_NAME ?? '';
const AUTH_NAME = process.env.SHORT_NAME ?? '';

const StyleHelmet = () => {
  const companyName = useSelector((state            ) => getCompanyName(state.themes));
  const isIssuanceAccount = useSelector((state            ) => state.themes.subscriber.issuanceAccount);
  const isRootSPAccount = useSelector((state            ) => getIsRootSpAccount(state.themes));
  const linkColor = useSelector((state            ) => getLinkColor(state.themes));

  let title = isRootSPAccount ? AUTH_NAME : isIssuanceAccount ? ISSUANCE_NAME : AUTH_NAME;

  if (!isRootSPAccount) {
    title += ` - ${companyName}`;
  }

  // :hover MUST come after :link and :visited (if they are present) in the CSS definition, in order to be effective!
  return (
    <Helmet>
      <style type="text/css">
        {`
        .motd a {
          color: ${linkColor};
        }
      `}
      </style>
      <link href="/favicon.png" rel="shortcut icon" />
      <title>{title}</title>
    </Helmet>
  );
};

export default StyleHelmet;
