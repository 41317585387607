const createThunkMiddleware = (...extraArguments) => {
  return ({ dispatch, getState }) =>
    next =>
    action => {
      if (typeof action === 'function') {
        return action(dispatch, getState, ...extraArguments);
      }

      return next(action);
    };
};

const thunk = createThunkMiddleware();
thunk.withExtraArguments = createThunkMiddleware;

export default thunk;
