//      
                                                    
                                                                            

import { getSelfUser, updateSelfUser } from 'src/api/self';
import { TYPES } from 'src/lib/userType';

// Action Types
// =============================================================================
                             
                     
                       
                  
            
  

             
               
                                  
                                                 
                                  

                
                                   
                                   
                                    

// Initial State
// =============================================================================
export const initialState                = Object.freeze({
  isLoading: false,
  lastUpdated: null,
  failed: false,
  user: {
    email: '',
    externalId: '',
    fidoTokens: [],
    firstName: '',
    grids: [],
    groups: [],
    id: '',
    lastName: '',
    locale: '',
    locked: false,
    lockedAuthenticators: [],
    lockoutExpiry: '',
    migrated: false,
    mobile: '',
    otpCreateTime: '',
    phone: '',
    registrationRequired: false,
    smartCredentials: [],
    tempAccessCode: null,
    state: 'ACTIVE',
    tokens: [],
    type: TYPES.MGMT_UI,
    userAttributeValues: [],
    userId: '',
    userAliases: [],
    verificationRequired: false,
    directoryType: ''
  }
});

// Reducers
// =============================================================================
export default (state                = initialState, action        )                => {
  switch (action.type) {
    case 'USER/FETCH_REQUEST':
    case 'USER/UPDATE_REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'USER/FETCH_FAILURE':
    case 'USER/UPDATE_FAILURE':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: true };
    case 'USER/UPDATE_SUCCESS':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: false };

    case 'USER/FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastUpdated: Date.now(),
        failed: false,
        user: {
          ...action.payload
        }
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const fetchUser = () => {
  return async (dispatch          , getState          ) => {
    if (getState().userProperties.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'USER/FETCH_REQUEST' });

    try {
      const userInfo = await getSelfUser();
      dispatch({ type: 'USER/FETCH_SUCCESS', payload: userInfo });
    } catch (e) {
      dispatch({ type: 'USER/FETCH_FAILURE' });
    }
  };
};

export const updateUser = (user      ) => {
  return async (dispatch          , getState          ) => {
    if (getState().userProperties.isLoading) {
      console.log('already fetching user info');
      return Promise.resolve();
    }

    dispatch({ type: 'USER/UPDATE_REQUEST' });

    try {
      await updateSelfUser(user);

      dispatch({ type: 'USER/UPDATE_SUCCESS' });
    } catch (e) {
      dispatch({ type: 'USER/UPDATE_FAILURE' });
    }
  };
};

// Selectors
// =============================================================================
export function getFullName(state               )         {
  const firstName = state.user.firstName || '';
  const lastName = state.user.lastName || '';
  const userId = state.user.userId || '';

  // - If only the first name exist use it.
  // - If only the last name exists, use the user ID.
  // - Otherwise, use the full name.
  return firstName ? (!lastName ? `${firstName}` : `${firstName} ${lastName}`) : userId;
}
