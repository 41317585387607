import * as React from 'react';

// Material-UI Components
// =============================================================================
import Link, { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  /**
   * @ignore
   */
  children: React.ReactNode;

  /**
   * The external link to navigate to.
   * @type {[type]}
   */
  href: string;

  /**
   * The target of external link such as _blank, _self.
   * @type {string}
   */
  target?: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-expect-error link is not typed on the Theme Palette
    color: theme.palette.text.link
  }
}));

/**
 * Link component for linking to external pages outside of Entrust IDaaS.
 */
const ExternalLink = (props: Props & LinkProps) => {
  const { href, target = '_blank', ...rest } = props;

  const classes = useStyles();

  return (
    <Link classes={{ root: classes.root }} href={href} target={target} rel="noopener noreferrer" {...rest}>
      {props.children}
    </Link>
  );
};

export default ExternalLink;
