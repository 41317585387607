//      
import UAParser from 'ua-parser-js';

// Material-UI Components
// =============================================================================
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import CloudPersonIcon from '@edc/react-ui/Icons/CloudPerson';
import ExternalIcon from '@material-ui/icons/Explicit';
import FaceIcon from '@material-ui/icons/Face';
import Google from '@edc/react-ui/Icons/Google';
import GridIcon from '@material-ui/icons/ViewComfy';
import HardwareTokenIcon from '@edc/react-ui/Icons/HardwareToken';
import KBAIcon from '@material-ui/icons/PlaylistAdd';
// TODO: finalize this icon choice
import PassthroughIcon from '@material-ui/icons/ImportExport';
import PasswordIcon from '@edc/react-ui/Icons/Password';
import SmartCard from '@edc/react-ui/Icons/SmartCard';
import TAC from '@edc/react-ui/Icons/TempAccessCode';
import TextSMS from '@material-ui/icons/Sms';
import Token from '@edc/react-ui/Icons/Token';
import TokenPush from '@edc/react-ui/Icons/TokenPush';
import TwoFA from '@edc/react-ui/Icons/TwoFA';
import SvgIcon from '@material-ui/core/SvgIcon';

// Functions
// =============================================================================
import { compareDatesFast } from 'src/lib/comparators';
import {
  AUTHENTICATOR_FACE,
  AUTHENTICATOR_PASSWORD_AND_SECONDFACTOR,
  AUTHENTICATOR_SMARTCREDENTIALPUSH,
  AUTHENTICATOR_TOKENPUSH
} from 'src/api/management-api.js';
import t from 'src/i18n';
import { store } from 'src/store';
import { getLocale } from 'src/ducks/locale';

// External Functions
import { decode } from 'html-entities';

// Flow Types
// =============================================================================
             
                              
                  
                 
                           
              
                                                   
                                                                                     
                                                         

// AuthenticatorType Flow enum based on the AuthenticatorType.java
                               
             
              
              
         
                      
         
          
           
               
                         
          
             
                      
                 
         
                               
                          
          
                  

                                                        

                                             
                             
                                          
                                        

                       
                            
                        
                          
                         
                           

export const getAuthenticatorType = (type        )         => {
  // AAAS-33564 allow changing google authenticator's name
  const googleAuthenticatorNameOverride = getGoogleAuthenticatorOverride('googleAuthenticatorName');

  switch (type) {
    case 'ENTRUST_LEGACY_TOKEN':
      return t('authType.elt');
    case 'ENTRUST_SOFT_TOKEN':
      return t('authType.est');
    case 'EXTERNAL':
      return t('authType.external');
    case 'FIDO':
      return t('authType.fido');
    case 'GOOGLE_AUTHENTICATOR':
      return googleAuthenticatorNameOverride || t('authType.google');
    case 'GRID_CARD':
    case 'GRID':
      return t('authType.grid');
    case 'HARDWARE_TOKEN':
      return t('authType.hardwaretoken');
    case 'KBA':
      return t('authType.kba');
    case 'FACE':
      return t('authType.face');
    case 'MACHINE':
      return t('authType.machine');
    case 'OATH_PHYSICAL_TOKEN':
      return t('authType.oath');
    case 'OTP':
      return t('authType.otp');
    case 'PASSTHROUGH':
      return t('authType.passthrough');
    case 'PASSWORD':
      return t('authType.password');
    case 'PASSKEY':
      return t('authType.passkey');
    case 'USER_CERTIFICATE':
      return t('authType.certificate');
    case 'SMART_CREDENTIAL':
      return t('authType.smart');
    case 'TEMP_ACCESS_CODE':
      return t('authType.tempAccessCode');

    // AAAS-12473 Authenticator types for the reports page
    case 'IDP':
      return t('authType.idp');
    case 'PASSWORD_AND_SECONDFACTOR':
      return t('ResourceRules.step.secondFactor');
    case 'SMARTCREDENTIALPUSH':
      return t('ResourceRules.step.smartCredentials');
    case 'SMART_LOGIN':
      return t('ResourceRules.step.smartLogin');
    case 'TOKEN':
      return t('ResourceRules.step.token');
    case 'TOKENPUSH':
    case 'ENTRUST_SOFT_TOKEN_PUSH':
      return t('ResourceRules.step.tokenPush');
    case 'NONE':
      return t('authType.none');
    default:
      return type;
  }
};

export const getTokenAuthenticatorAlgorithmType = (algorithm        )         => {
  switch (algorithm) {
    case 'AT':
      return t('authToken.algorithm.at');
    case 'OATH_HOTP':
      return t('authToken.algorithm.hotp');
    case 'OATH_OCRA':
      return t('authToken.algorithm.ocra');
    case 'OATH_TOTP':
      return t('authToken.algorithm.totp');
    case 'VENDOR':
      return t('authToken.algorithm.vendor');
    default:
      return null;
  }
};

export const getAuthenticatorIcon = (authenticator        )                 => {
  // AAAS-33564 allow changing google authenticator's name
  const googleAuthenticatorNameOverride = getGoogleAuthenticatorOverride('googleAuthenticatorName');
  switch (authenticator) {
    case 'OTP':
      return TextSMS;
    case 'ENTRUST_SOFT_TOKEN':
    case 'ENTRUST_SOFT_TOKEN_PUSH':
    case 'TOKENPUSH':
      return TokenPush;
    case 'GOOGLE_AUTHENTICATOR':
      return googleAuthenticatorNameOverride !== t('authType.google') ? Token : Google;
    case 'GRID_CARD':
    case 'GRID':
      return GridIcon;
    case 'KBA':
      return KBAIcon;
    case 'ENTRUST_LEGACY_TOKEN':
    case 'OATH_PHYSICAL_TOKEN':
    case 'HARDWARE_TOKEN':
    case 'TOKEN':
      return Token;
    case 'PASSWORD':
      return PasswordIcon;
    case 'PASSWORD_AND_SECONDFACTOR':
      return TwoFA;
    case 'SMART_LOGIN':
    case 'SMART_CREDENTIAL':
    case 'SMARTCREDENTIALPUSH':
      return SmartCard;
    case 'TEMP_ACCESS_CODE':
      return TAC;
    case 'PASSKEY':
    case 'FIDO':
      return HardwareTokenIcon;
    case 'USER_CERTIFICATE':
      return CardMembershipIcon;
    case 'IDP':
      return CloudPersonIcon;
    case 'EXTERNAL':
      return ExternalIcon;
    case 'FACE':
      return FaceIcon;
    case 'PASSTHROUGH':
      return PassthroughIcon;
    default:
      return null;
  }
};

export const getUserPortalPermissionByTokenType = (tokenType           ) => {
  switch (tokenType) {
    case 'ENTRUST_SOFT_TOKEN':
      return 'ENTRUSTSOFTTOKEN';
    case 'GOOGLE_AUTHENTICATOR':
      return 'GOOGLEAUTHENTICATOR';
    case 'ENTRUST_PHYSICAL_TOKEN':
    case 'OATH_PHYSICAL_TOKEN':
    case 'ENTRUST_LEGACY_TOKEN':
      return 'HARDWARETOKEN';
    default:
      return null;
  }
};

export const getGoogleAuthenticatorOverride = (type                                 ) => {
  const locale = getLocale(store.getState());
  const override = store.getState().settings.self.googleAuthenticatorOverride[locale][type];
  // Google authenticator overrides are html encoded in DB
  return decode(override);
};

export const isPushAuthentication = (
  authenticator                   ,
  secondFactorAuthenticator                          
)          => {
  const firstFactorIsPush =
    authenticator === AUTHENTICATOR_TOKENPUSH ||
    authenticator === AUTHENTICATOR_SMARTCREDENTIALPUSH ||
    authenticator === AUTHENTICATOR_FACE;

  const secondFactorIsPush =
    secondFactorAuthenticator === AUTHENTICATOR_TOKENPUSH ||
    secondFactorAuthenticator === AUTHENTICATOR_SMARTCREDENTIALPUSH ||
    secondFactorAuthenticator === AUTHENTICATOR_FACE;

  // authenticator is PASSWORD_AND_SECONDFACTOR and
  // secondfactor is TOKENPUSH, SMARTCREDENTIALPUSH or FACE
  return firstFactorIsPush || (authenticator === AUTHENTICATOR_PASSWORD_AND_SECONDFACTOR && secondFactorIsPush);
};

const userAgent = new UAParser();

export const isSafari = () => {
  return new UAParser().getBrowser().name === 'Safari';
};

function getUserAgentBrowserInfo()         {
  const name = getUserAgentInfo(userAgent.getBrowser(), 'name', 'unknown');
  const version = getUserAgentInfo(userAgent.getBrowser(), 'version', '');
  let result = name + ' ' + version;

  // silently truncate to 255 character, if required
  result = result.substring(0, 255);

  return result;
}

function getUserAgentOSInfo()         {
  const name = getUserAgentInfo(userAgent.getOS(), 'name', 'unknown');
  const version = getUserAgentInfo(userAgent.getOS(), 'version', '');
  let result = name + ' ' + version;

  // silently truncate to 255 character, if required
  result = result.substring(0, 255);

  return result;
}

function getUserAgentInfo(attrs, name, invalidString)         {
  let result = attrs[name];
  // Work around some issues in the UAParser library we are using
  if (typeof result !== 'string') result = invalidString;
  return result;
}

export const getRequestDetail = ()                => ({
  browser: getUserAgentBrowserInfo(),
  os: getUserAgentOSInfo()
});

export function passwordState(userPassword              )                                                   {
  let state = '';

  if (userPassword.present) {
    state = 'ACTIVE';

    if (userPassword.forceUpdate) {
      state = 'CHANGEREQUIRED';
    } else if (userPassword.expiryTime) {
      const expiryTime = new Date(userPassword.expiryTime);
      const now = new Date();

      if (compareDatesFast(now, expiryTime) === 1) {
        state = 'EXPIRED';
      }
    }
  }

  return state;
}

export function machineState(userMachineAuthenticator                          )                       {
  let state = 'ACTIVE';

  if (userMachineAuthenticator.expiryTime) {
    const expiryTime = new Date(userMachineAuthenticator.expiryTime);
    const now = new Date();

    if (compareDatesFast(now, expiryTime) === 1) {
      state = 'EXPIRED';
    }
  }

  return state;
}

// encodeState: 'ENCODE_START' | 'ENCODE_DONE' | 'ENCODE_ERROR',
// enrollState: 'ENROLLING' | 'ENROLLED',
// state: 'ACTIVE' | 'INACTIVE',
export function smartState(smartAuthenticator                 )         {
  const { state, enrollState, encodeState } = smartAuthenticator;

  if (enrollState === 'ENROLLING') {
    return 'ENROLLING';
  }

  // If we're done enrolling but encoding hasn't started
  if (!encodeState) {
    return 'ENROLLED';
  }

  if (encodeState !== 'ENCODE_DONE') {
    return encodeState;
  }

  return state;
}

export function kbaState(kba                             )                        {
  let state = 'ACTIVE';

  if (kba.maximumNumberOfQuestions === 0) {
    state = 'INACTIVE';
  }

  return state;
}

export function tempAccessCodeState(tempAccessCode                )                                    {
  if (tempAccessCode.expired) {
    return 'EXPIRED';
  } else if (tempAccessCode.maxUses && tempAccessCode.numUses >= tempAccessCode.maxUses) {
    return 'MAX_USES';
  } else {
    return 'ACTIVE';
  }
}

export const getSeverity = (state        )           => {
  switch (state) {
    case 'INACTIVE':
    case 'EXPIRING':
    case 'DISABLED':
    case 'REVIEW':
      return 'WARNING';
    case 'EXPIRED':
    case 'FAILED':
    case 'REVOKED':
      return 'ERROR';
    case 'ACTIVE':
    case 'COMPLETED':
    case 'PENDING':
      return 'OK';
    // TODO: Which one is correct?
    case 'CANCELED':
    case 'CANCELLED':
      return 'UNKNOWN';
    default:
      return 'INFO';
  }
};

export const getLoginFlowType = loginFlow => {
  switch (loginFlow) {
    case 'IDP_LOGIN': {
      return t('authenticationFlows.loginType.idpLogin');
    }
    case 'PASSKEY_LOGIN': {
      return t('authenticationFlows.loginType.passkeyLogin');
    }
    case 'SMART_LOGIN': {
      return t('authenticationFlows.loginType.smartLogin');
    }
    case 'USER_LOGIN': {
      return t('authenticationFlows.loginType.userLogin');
    }
    case 'USER_CERTIFICATE_LOGIN': {
      return t('authenticationFlows.loginType.userCertificateLogin');
    }
    default: {
      throw new Error(`Invalid login flow type ${loginFlow}`);
    }
  }
};
