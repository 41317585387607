//      
import { useState, Fragment } from 'react';
import ExternalLink from 'src/components/Link/ExternalLink';
import Popover from '@material-ui/core/Popover';
import { formatDate } from 'src/lib/formatters';
import { useSelector } from 'react-redux';

// Disallow list of dev environment that should not show the build info
const disallowList = ['northwelldev.us.dev.trustedauthdev.com'];

const BuildInfo = () => {
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const onClick = (e                                   ) => {
    setAnchor(e.currentTarget);
    setOpen(true);
  };

  const onClose = () => {
    setAnchor(null);
    setOpen(false);
  };

  // FOR GENERIC DEV ENVIRONMENTS ONLY
  const showVersion = process.env.SHOW_VERSION === 'true';
  const gitVersion = process.env.GIT_VERSION || '';
  const stashURL = process.env.STASH_URL || '';
  const branchName = process.env.BRANCH_NAME || '';
  const buildURL = process.env.BUILD_URL || '';
  const buildNumber = process.env.BUILD_NUMBER || '';
  const buildDate = process.env.BUILD_DATE || '';
  const branchURL = process.env.BRANCH_URL || '';
  const styleGuideURL = '/styleguide/index.html';

  const hostname = useSelector(state => state.themes.subscriber.hostname);

  if (!showVersion || disallowList.includes(hostname)) {
    return null;
  }

  return (
    <Fragment>
      <a href={window.location.href} onClick={onClick}>
        Build
      </a>

      {open && (
        <Popover open={open} anchorEl={anchor} onClose={onClose}>
          <div style={{ margin: 8 }}>
            <p>
              <span>Git Branch:&nbsp;</span>
              <ExternalLink href={branchURL + branchName}>{branchName}</ExternalLink>
            </p>
            <p>
              <span>Git Commit:&nbsp;</span>
              <ExternalLink href={stashURL}>{gitVersion}</ExternalLink>
            </p>
            <p>
              <span>Jenkins Build:&nbsp;</span>
              <ExternalLink href={buildURL}>{buildNumber}</ExternalLink>
            </p>
            <p>
              <span>Build Date:&nbsp;</span>
              <strong>{formatDate(buildDate)}</strong>
            </p>
            <p>
              <ExternalLink href={styleGuideURL}>Developer Guide</ExternalLink>
            </p>
          </div>
        </Popover>
      )}
    </Fragment>
  );
};

export default BuildInfo;
