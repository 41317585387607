//      
                                                                                                               
                                                                                     

// Helper Functions
// ===========================================================================
import { APPS, APP_LOGOS } from 'src/lib/constants';

export function joinCSV(value               )         {
  if (!value || value.length === 0) {
    return '';
  }
  return value.join(', ');
}

export function splitCSV(value        )                {
  if (!value) return [];

  value = value.trim();

  if (value === '') return [];

  return value.split(',');
}

export function getApplicationLogo(application             , logoOverride         = '') {
  const logo = logoOverride || application.logo;
  if (logo) {
    if (!logo.includes('base64')) {
      return `data:image;base64,${logo}`;
    }
    return logo;
  }
  return APP_LOGOS[application.applicationTemplateId];
}

export function getSamlAttributeArray(
  samlAttributeString         ,
  userAttributes                      ,
  auxAttributes                     
)                {
  if (!samlAttributeString) {
    return [];
  }

  const array = [];

  for (let index = 0; index < samlAttributeString.length; index++) {
    // get the next character
    const char = samlAttributeString[index];

    let attribute                                     ;
    let match               ;

    // Check if we are starting a user attribute variable
    if (char === '<') {
      // iterate through all the user attributes looking for a match
      attribute = userAttributes.find(
        userAttribute =>
          samlAttributeString.toLocaleLowerCase().indexOf(`<${userAttribute.name.toLocaleLowerCase()}>`, index) ===
          index
      );

      // found a match
      if (attribute) {
        // add the wrapping < > because the attribute name does not include them
        match = `<${attribute.name}>`;
      }
    } else if (char === '[') {
      // iterate through all the aux attributes looking for a match
      attribute = auxAttributes.find(
        auxAttribute =>
          samlAttributeString.toLocaleLowerCase().indexOf(auxAttribute.name.toLocaleLowerCase(), index) === index
      );

      // found a match
      if (attribute) {
        // no need to add the wrapping [ ] because the attribute name already includes them
        match = attribute.name;
      }
    }

    // we found a match
    if (match !== undefined) {
      // if we found a variable and we're not at the start of the string then everything before this variable is static text
      if (index !== 0) {
        // extract the static text from the string
        const text = samlAttributeString.substring(0, index);
        // add the text to the result array
        array.push(text);
        // remove the text from the saml attribute string
        samlAttributeString = samlAttributeString.substring(text.length);
      }

      // add the match to the result array
      array.push(match);
      // remove the match from the saml attribute string
      samlAttributeString = samlAttributeString.substring(match.length);

      // reset the index to the beginning (use -1 since the index++ in the for loop will increment it to 0)
      index = -1;
    }

    // no match. go to next character.
  }

  // if there is anything left over add it to the array
  if (samlAttributeString.length) {
    array.push(samlAttributeString);
  }

  return array;
}

export function getDefaultRelayStates(templateId        ) {
  const relayStates = [];
  if (templateId === APPS.ADP) {
    relayStates.push({
      name: 'Workforce Manager',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?TWFM',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'eTime',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?EETDC2',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'WFN',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?WFN',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Portal',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?PORTAL',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'TMS',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?TMS',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Vantage',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?ADPVANTAGE',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Total Source',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?TTS',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Recruiting',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?RECRUITING',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Recruiting Manager',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?RECRUITING&url=/src/isilogin2.guid',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'Recruiting Employee',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?RECRUITING&url=/srccar/isilogin2.guid',
      showInMyProfile: true
    });
    relayStates.push({
      name: 'MyADP',
      id: null,
      value: 'https://fed.adp.com/saml/fedlanding.html?REDBOX',
      showInMyProfile: true
    });
  }
  return relayStates;
}

export function getDefaultAcsUrl(templateId        ) {
  switch (templateId) {
    case APPS.ADP:
      return 'https://fed.adp.com/affwebservices/public/saml2assertionconsumer';
    case APPS.FORESTADMIN:
      return 'https://forestadmin.com/ssl/saml';
    case APPS.MIRO:
      return 'https://miro.com/sso/saml';
    case APPS.ONFIDO:
      return 'https://onfido.com/users/auth/saml/callback';
    case APPS.REVIEWINC:
      return 'https://api.nr4.me/Login/Saml2';
    default:
      return '';
  }
}

export function getDefaultAltAcsUrls(templateId        ) {
  switch (templateId) {
    case APPS.LUCIDCHART:
      return [
        {
          name: 'Alternative ACS URL',
          value: 'https://lucid.app/smal/sso/<Entrust>',
          showInMyProfile: true
        }
      ];
    case APPS.REVIEWINC:
      return [
        {
          name: 'Alternative ACS URL',
          value: 'https://my.nr4.me',
          showInMyProfile: false
        }
      ];
    default:
      return [];
  }
}

export function getDefaultSpEntityId(templateId        ) {
  switch (templateId) {
    case APPS.ADP:
      return 'https://fed.adp.com';
    case APPS.FORESTADMIN:
      return 'https://app.forestadmin.com';
    case APPS.MIRO:
      return 'https://miro.com/';
    case APPS.ONFIDO:
      return 'https://onfido.com';
    case APPS.REVIEWINC:
      return 'https://api.nr4.me';
    default:
      return '';
  }
}

export function getDefaultSamlAttributes(templateId        ) {
  switch (templateId) {
    case APPS.ADP:
      return [
        {
          name: 'PersonImmutableID',
          id: null,
          samlAttributeValue: []
        }
      ];
    case APPS.AIR:
      return [
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.AMAZON_BUSINESS:
      // This will be sorted in ascending
      return [
        {
          name: 'EmailID',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'Fullname',
          id: null,
          samlAttributeValue: ['<First Name> <Last Name>']
        }
      ];
    case APPS.AWS:
      return [
        {
          name: 'https://aws.amazon.com/SAML/Attributes/Role',
          id: null,
          samlAttributeValue: [
            'arn:aws:iam::<AWS Account Number>:saml-provider/<AWS Provider Name>,arn:aws:iam::<AWS Account Number>:role/<AWS Role Name>'
          ]
        },
        {
          name: 'https://aws.amazon.com/SAML/Attributes/RoleSessionName',
          id: null,
          samlAttributeValue: ['<User ID>']
        }
      ];
    case APPS.CONFLUENT_CLOUD:
      // This will be sorted in ascending
      return [
        {
          name: 'EmailID',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.DRUVA:
      return [
        {
          name: 'druva_auth_token',
          id: null,
          samlAttributeValue: ['replace with auth token value']
        }
      ];
    case APPS.FIFTEENFIVE:
      // This will be sorted in ascending
      return [
        {
          name: 'Email',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'UserID',
          id: null,
          samlAttributeValue: ['<User ID>']
        }
      ];
    case APPS.FORTISIEM:
      return [
        {
          name: 'Email',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.FRESHWORKS:
      return [
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
          id: null,
          samlAttributeValue: ['<Last Name>']
        }
      ];
    case APPS.HUDDLE:
      return [
        {
          name: 'emailaddress',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'givenname',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'surname',
          id: null,
          samlAttributeValue: ['<Last Name>']
        }
      ];
    case APPS.KEEPERSECURITY:
      return [
        {
          name: 'First',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'Last',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'Email',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.LEAVEWIZARD:
      // This will be sorted in ascending
      return [
        {
          name: 'UserID',
          id: null,
          samlAttributeValue: ['<User ID>']
        }
      ];
    case APPS.LUCIDCHART:
      // This will be sorted in ascending
      return [
        {
          name: 'First Name',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'Last Name',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'Email',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.MIMECAST:
      return [
        {
          name: 'Email',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'NameID',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.OFFICE365:
      return [
        {
          name: 'IDPEmail',
          id: null,
          samlAttributeValue: []
        }
      ];
    case APPS.PRODPAD:
      // This will be sorted in ascending
      return [
        {
          name: 'User.FirstName',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'User.LastName',
          id: null,
          samlAttributeValue: ['<Last Name>']
        }
      ];
    case APPS.REVIEWINC:
      // This will be sorted in ascending
      return [
        {
          name: 'FirstName',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'LastName',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'UserEmail',
          id: null,
          samlAttributeValue: ['<Email>']
        }
      ];
    case APPS.SLACK:
      // This will be sorted in ascending
      return [
        {
          name: 'first_name',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'last_name',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'User.Email',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'User.UserName',
          id: null,
          samlAttributeValue: ['<User ID>']
        }
      ];
    case APPS.SOLOINSIGHT:
      // This will be sorted in ascending
      return [
        {
          name: 'first_name',
          id: null,
          samlAttributeValue: ['<First Name>']
        },
        {
          name: 'last_name',
          id: null,
          samlAttributeValue: ['<Last Name>']
        },
        {
          name: 'email',
          id: null,
          samlAttributeValue: ['<Email>']
        },
        {
          name: 'object_id',
          id: null,
          samlAttributeValue: ['<Unique User ID>']
        }
      ];
    default:
      return [];
  }
}

export const getDefaultCertificate = (certificates                      )         => {
  // if we only have one certificate, we will use it.  Otherwise, the customer needs to pick

  if (certificates.length === 1) {
    return certificates[0].id;
  } else {
    return null;
  }
};

// Checks if a certificate is expired.
export const isExpired = (certificate                    )          => {
  const endDate = new Date(certificate.notAfter);
  const today = new Date();

  return today > endDate;
};

// Checks if a certificate is within 90 days of expiring.
export const isExpiring = (certificate                    )          => {
  const endDate = new Date(certificate.notAfter);

  const plus90Days = new Date();

  plus90Days.setDate(plus90Days.getDate() + 90);

  return !isExpired(certificate) && endDate < plus90Days;
};
