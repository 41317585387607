import { clsx } from 'clsx';

// Entrust Components
// =============================================================================
import DrawerList from 'src/components/Layout/Header/IntelliDrawer/DrawerList';
import SearchBar from 'src/components/Layout/Header/SearchBar';

// Material-UI Components
// =============================================================================\
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import type { Theme } from '@material-ui/core/styles';

type Props = {
  /**
   * Whether or not the drawer is open or closed.
   *
   * @type {boolean}
   */
  isOpen: boolean;

  /**
   * Callback function fired when the open state of the Drawer is requested to be changed.
   *
   */
  toggleDrawer: () => void;

  /**
   * If `true`, the search bar will be shown
   *
   * @type {boolean}
   */
  showSearchBar: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerPaper: {
    width: DRAWER_WIDTH
  }
}));

const IntelliDrawer = (props: Props) => {
  const { isOpen, toggleDrawer, showSearchBar } = props;

  const classes = useStyles();

  return (
    <nav
      className={clsx({
        [classes.drawer]: isOpen
      })}>
      {/* TEMPORARY DRAWER */}
      <Hidden xlUp>
        <Drawer
          anchor="left"
          variant="temporary"
          open={isOpen}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper
          }}>
          {showSearchBar && <SearchBar />}
          <DrawerList />
        </Drawer>
      </Hidden>

      {/* PERSISTENT DRAWER */}
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}
          variant="persistent"
          open={isOpen}>
          <div className={classes.drawerHeader}>
            <IconButton id={`app-drawer-close-${String(isOpen)}`} onClick={toggleDrawer}>
              {<ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          {showSearchBar && <SearchBar />}
          <DrawerList />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export const DRAWER_WIDTH = 260;

export default IntelliDrawer;
