//      
                                                                         
import { getRegistrationSettings, setRegistrationSettings } from 'src/api/settings';
import { getSelfSettings } from 'src/api/self';
import { getServiceBundles } from 'src/api/entitlements';
import { userHasRole, getPermissionsByType } from 'src/ducks/permissions';
import { ROLE_TYPE_SITE_ADMINISTRATOR } from 'src/lib/constants';

// Flow type
                                                                                                                    

// Action Types
// =============================================================================
             
               
                                           
                                                                  
                                           

                        
                                           
                                                                          
                                           

                          
                                      
                                      
     
                                     
                                                                                                  
      

                             
                          
                  
                     
                       
                                                                
                    
  

// Initial State
// =============================================================================
export const initialState = {
  failed: false,
  isLoading: false,
  lastUpdated: null,
  // TODO: move to entitlements
  bundles: {
    hasIssuance: false,
    hasIssuanceAPI: false
  },
  // TODO: update flow type with fido
  registration: {
    entrustSoftToken: 'OPTIONAL',
    fido: 'OPTIONAL',
    google: 'OPTIONAL',
    grid: 'OPTIONAL',
    hardwareToken: 'OPTIONAL',
    kba: 'OPTIONAL',
    password: 'OPTIONAL',
    registrationEnabled: false,
    registrationPeriod: 0,
    lastUpdated: null
  },
  self: {
    asyncEnabled: false,
    authenticators: [],
    sdpEnabled: false,
    userTabConfiguration: {
      defaultTab: 'APPLICATIONS',
      showApplications: true,
      showAuthenticators: true,
      showDevices: true,
      showMyActivities: true,
      showProfile: true,
      showTenants: true
    },
    googleAuthenticatorOverride: {
      da: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      de: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      en: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      es: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      fr: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      it: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      ja: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      ko: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      nb: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      nl: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      pl: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      pt: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      ru: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      sv: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      th: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      tr: {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      'zh-cn': {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      },
      'zh-tw': {
        googleAuthenticatorName: 'Google Authenticator',
        googleAuthenticatorAppleAppStoreLink: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8',
        googleAuthenticatorGooglePlayLink:
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
      }
    }
  }
};

// Reducers
// =============================================================================
export default (state                = initialState, action        ) => {
  switch (action.type) {
    case 'REGISTRATION/UPDATE_REQUEST':
    case 'SELF_SETTINGS/FETCH_REQUEST':
    case 'SETTINGS/FETCH_REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'REGISTRATION/UPDATE_FAILURE':
      return { ...state, isLoading: false, failed: true, registration: { lastUpdated: Date.now() } };

    case 'SELF_SETTINGS/FETCH_FAILURE':
      return { ...state, isLoading: false, failed: true, lastUpdated: Date.now() };

    case 'SELF_SETTINGS/FETCH_SUCCESS':
      return {
        ...state,
        lastUpdated: Date.now(),
        isLoading: false,
        failed: false,
        self: {
          ...action.payload
        }
      };

    case 'SETTINGS/FETCH_SUCCESS':
      return {
        ...state,
        lastUpdated: Date.now(),
        isLoading: false,
        failed: false,
        ...action.payload
      };

    case 'REGISTRATION/UPDATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        failed: false,
        registration: { ...action.payload, lastUpdated: Date.now() }
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const fetchSettings = () => {
  return async (dispatch          , getState          ) => {
    const store = getState();
    if (store.settings.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'SETTINGS/FETCH_REQUEST' });

    const isSiteAdmin = userHasRole(store.permissions, ROLE_TYPE_SITE_ADMINISTRATOR);
    const permissions = getPermissionsByType(store.permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SETTINGS']);

    try {
      const promises = [
        getSelfSettings(),
        isSiteAdmin ? getServiceBundles() : initialState.bundles,
        permissions.SETTINGS.actionView ? getRegistrationSettings() : initialState.registration
      ];

      const [self, bundles, registration] = await Promise.all(promises);

      dispatch({
        type: 'SETTINGS/FETCH_SUCCESS',
        payload: { self, bundles, registration }
      });
    } catch (error) {
      dispatch({ type: 'SETTINGS/FETCH_FAILURE' });
    }
  };
};

export const fetchSelfSettings = () => {
  return async (dispatch          ) => {
    dispatch({ type: 'SELF_SETTINGS/FETCH_REQUEST' });

    try {
      const payload = await getSelfSettings();
      dispatch({ type: 'SELF_SETTINGS/FETCH_SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'SELF_SETTINGS/FETCH_FAILURE' });
    }
  };
};

export const updateRegistrationSettings = (registrationSettings                      ) => {
  return async (dispatch          ) => {
    dispatch({ type: 'REGISTRATION/UPDATE_REQUEST' });

    try {
      await setRegistrationSettings(registrationSettings);
      dispatch({ type: 'REGISTRATION/UPDATE_SUCCESS', payload: registrationSettings });
    } catch (error) {
      dispatch({ type: 'REGISTRATION/UPDATE_FAILURE' });

      // Because the initial state of failed is false, any component subscribed to failed
      // will not have the correct state updated when updateRegistrationSettings is invoked.
      throw error;
    }
  };
};

// Selector
// =============================================================================
export const getUserPortalAutenticators = (state            ) => {
  return state.themes.subscriber.smartLoginEnabled
    ? state.settings.self.authenticators
    : state.settings.self.authenticators.filter(auth => auth !== 'SMARTCREDENTIALPUSH');
};

export const isFleetOnlyIssuanceTenant = (settings, themes) => {
  return (
    !themes.subscriber.authenticationAccount &&
    settings.bundles.hasFleetManagement &&
    !settings.bundles.hasIssuance &&
    !settings.bundles.hasPayToPrint &&
    !settings.bundles.hasFlashPassCounter
  );
};

export const selectAsynchronousPagination = (state            ) => {
  return state.settings.self.asyncEnabled;
};
