import * as React from 'react';

import Box from '@material-ui/core/Box';

interface Props {
  children: React.ReactNode;
}

const LayoutWrapper = ({ children }: Props) => {
  return (
    <Box display="flex" height="100%" width="100%">
      {children}
    </Box>
  );
};

export default LayoutWrapper;
