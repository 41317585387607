//      
import * as React from 'react';
import { useLocation, useHistory } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';

// Entrust Components
// =============================================================================
import FinalBasicDialog from 'src/components/FinalBasicDialog';

// Material-UI Components
// =============================================================================
import { makeStyles } from '@material-ui/core/styles';

// External Functions
// =============================================================================
import t from 'src/i18n';

// API
// =============================================================================
import { renewJWTToken } from 'src/api/users';
import {
  getSessionExpiryTime,
  getSessionStartTime,
  isSystemLoggedIn,
  updateRenewedJWTToken,
  logoutJWT,
  isLoggedIn
} from 'src/lib/jwt';

const useStyles = makeStyles({
  root: {
    zIndex: 1500
  }
});

const MAX_TIME_REMAINING_IN_SESSION = 120000;

const MIN_SESSION_LIFETIME = 60;

const IdleDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [totalSeconds, setTotalSeconds] = React.useState        (MIN_SESSION_LIFETIME);

  const classes = useStyles();

  const location = useLocation();

  const history = useHistory();

  const isBulkAdd = location.pathname.startsWith('bulkoperations/add');

  // Timeout will call the onIdle function
  const timeout = getSessionExpiryTime() - getSessionStartTime();

  const thirdSessionLength = Math.floor(timeout / 3);

  // Prompt the user at most 120s before expiry otherwise use 1/3 of the timeout session
  const promptBeforeIdle =
    thirdSessionLength > MAX_TIME_REMAINING_IN_SESSION ? MAX_TIME_REMAINING_IN_SESSION : thirdSessionLength;

  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor((totalSeconds % 3600) % 60);

  const message =
    minutes > 0 ? t('common.session.expiry_minutes', minutes, seconds) : t('common.session.expiry_seconds', seconds);

  // Idle Functions
  const onIdle = () => {
    // On Bulk Import page the sessions should be renewed
    // Used by issuance when importing enrollments
    if (isBulkAdd) {
      onClose();
    } else {
      logoutJWT(true);
    }
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { activate, isPrompted, isIdle } = useIdleTimer({
    crossTab: true,
    onIdle,
    onPrompt,
    promptBeforeIdle,
    syncTimers: 200,
    timeout
  });

  const onClose = async () => {
    try {
      // Renew session here
      const renewedJWT = await renewJWTToken();
      updateRenewedJWTToken(renewedJWT);
      setOpen(false);
      activate();
    } catch (error) {
      logoutJWT(true);
      console.log(error);
    }
  };

  React.useEffect(() => {
    // Renew the session if the user is active and there is only 15 seconds left before expiry
    const renew = async () => {
      const userIsActive = !isPrompted() && !isIdle();

      if (totalSeconds < 15 && userIsActive) {
        await onClose();
      }

      if (totalSeconds <= 0) {
        // If we're not authenticated to a system application then log the user out
        if (!isSystemLoggedIn()) {
          logoutJWT(true);
          history.push('/logout');
        } else {
          // Otherwise let the AuthProvider handle the redirect
          logoutJWT(true);
        }
      }
    };

    if (isLoggedIn()) {
      renew();
    }
  }, [totalSeconds]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTotalSeconds(Math.round((getSessionExpiryTime() - Date.now()) / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <FinalBasicDialog
      id="app-idle-dialog"
      dialogProps={{ className: classes.root }}
      onClose={onClose}
      primaryButtonLabel={t('button.continue')}
      primaryOnly
      title={t('button.warning')}>
      <p>{message}</p>
    </FinalBasicDialog>
  );
};

export default IdleDialog;
