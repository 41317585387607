//      
import * as React from 'react';
import { useSelector } from 'react-redux';

// Entrust Components
// =============================================================================
import Loading from 'src/components/Loading';

// Redux Functions
// ===========================================================================
import { fetchActiveEntitlements } from 'src/ducks/activeEntitlements';
import { fetchSettings } from 'src/ducks/settings';
import { fetchBundleFeatures } from 'src/ducks/bundleFeatures';
import { useActions } from 'src/lib/hooks';

// Flow type
// ===========================================================================
                                       

              
     
            
     
                      
  

const SettingsProvider = (props       ) => {
  const settings = useSelector((state       ) => state.settings);

  const bundleFeatures = useSelector((state       ) => state.bundleFeatures);

  const activeEntitlements = useSelector((state       ) => state.activeEntitlements);

  const actions = useActions({ fetchSettings, fetchBundleFeatures, fetchActiveEntitlements });

  React.useEffect(() => {
    const getSettings = async () => {
      if (settings.lastUpdated === null) {
        try {
          await actions.fetchSettings();
        } catch (error) {
          // Do nothing
        }
      }
    };

    const getBundleFeatures = async () => {
      if (bundleFeatures.lastUpdated === null) {
        try {
          await actions.fetchBundleFeatures();
        } catch (error) {
          // Do nothing
        }
      }
    };

    const getActiveEntitlements = async () => {
      if (activeEntitlements.lastUpdated === null) {
        try {
          await actions.fetchActiveEntitlements();
        } catch (error) {
          // Do nothing
        }
      }
    };

    getSettings();
    getBundleFeatures();
    getActiveEntitlements();
  }, []);

  // Display the loading component the first time the data is fetched
  // Otherwise the settings page will remount on form submit causing unnecessary browser refresh.
  if (
    (settings.isLoading && settings.registration.lastUpdated === null) ||
    bundleFeatures.lastUpdated === null ||
    activeEntitlements.lastUpdated === null
  ) {
    return <Loading />;
  }

  return props.children;
};

export default SettingsProvider;
