export default {smartlogin: {authMobileChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/AuthMobileChallenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authorizeByAgentUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/authorizeByAgent'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileChallengeUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/mobileChallenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }}}