import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  /**
   * @ignore
   */
  children: React.ReactNode;

  /**
   * The internal route to navigate to.
   *
   * @type {string}
   */
  to: string;
}

const useStyles = makeStyles(theme => ({
  link: {
    // @ts-expect-error link is not typed on the Theme Palette
    color: theme.palette.text.link
  }
}));

/**
 * Link component for linking to internal pages inside of Entrust IDaaS.
 */
const InternalLink = (props: Props) => {
  const { to, ...rest } = props;

  const classes = useStyles();

  return (
    <Link className={classes.link} to={to} {...rest}>
      {props.children}
    </Link>
  );
};

export default InternalLink;
