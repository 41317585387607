import { Field } from 'react-final-form';

// Entrust Components
// =============================================================================
import ExternalLink from 'src/components/Link/ExternalLink';
import FinalCheckbox from 'src/components/final-form/Checkbox';

// Material-UI Components
// =============================================================================
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Helpers
// ===========================================================================
import { equals } from 'src/lib/finalFormValidation';
import t, { ts } from 'src/i18n';
import { DOC_LINKS } from 'src/lib/constants';

interface Props {
  /**
   * Whether or not this is an authentication account.
   *
   * @type {boolean}
   */
  isAuthenticationAccount?: boolean;

  /**
   * The name of the final form element.
   *
   * @type {string}
   */
  name: string;
}

/**
 * Use the LegalTerms component to display the Entrust Legal Terms.
 */
const LegalTerms = (props: Props) => {
  const { isAuthenticationAccount = true, name } = props;

  const tos = (
    <ExternalLink
      id="app-tenant-signup-idx-termsLink"
      href={isAuthenticationAccount ? DOC_LINKS.TOS_URL : DOC_LINKS.TOS_ISSUANCE_URL}>
      {t('common.footer.termsOfService')}
    </ExternalLink>
  );

  const mspTos = (
    <ExternalLink id="app-tenant-signup-idx-msp-termsLink" href={DOC_LINKS.MSP_TOS_URL}>
      {t('legal.terms')}
    </ExternalLink>
  );

  return (
    <FormControlLabel
      control={
        <Field
          id="app-usrOnBoard-idx-agree"
          name={name}
          component={FinalCheckbox}
          type="checkbox"
          color="primary"
          validate={equals(true)}
        />
      }
      label={
        isAuthenticationAccount
          ? ts('legal.legalCheckBoxTextIDaaS', 'link', String(process.env.SHORT_NAME), tos, mspTos)
          : ts('legal.legalCheckBoxTextIIDaaS', 'link', String(process.env.ISSUANCE_NAME), tos)
      }
    />
  );
};

export default LegalTerms;
