import Formsy from 'formsy-react-2';
import { isEmpty, isNumber, parseNumber } from './smart';
import {
  VALID_CLIENT_SECRET,
  VALID_DN_REGEX,
  VALID_HOST_OR_IP_REGEX,
  VALID_HOSTNAME_OR_IP,
  VALID_HTTPS_URI_REGEX,
  VALID_IP_REGEX,
  VALID_LOCALHOST_URI,
  VALID_NATIVE_URL,
  VALID_UPN_REGEX,
  VALID_URL_REGEX,
  VALID_UUID_REGEX,
  BLOCK_HTML
} from './regex';
import { validEmail } from 'src/lib/finalFormValidation';
import { isValidNumber } from 'libphonenumber-js';
import { splitCSV } from './application';

Formsy.addValidationRule('isUnique', (values, value, array) => {
  return !value || !array.find(arrayValue => String(value).toLowerCase().trim() === arrayValue.toLowerCase().trim());
});

Formsy.addValidationRule('isValidMinMaxRestriction', (values, value, limits) => {
  if (isEmpty(value) || values['variableType'] !== 'STRING') {
    return true;
  }
  if (!isNumber(value)) {
    return false;
  }
  value = parseNumber(value);
  return value >= limits.min && value <= limits.max;
});

Formsy.addValidationRule('characterRestrictionAreValid', (values, value, restrictionFields) => {
  let digits = values[restrictionFields.digits];
  let lower = values[restrictionFields.lower];
  let upper = values[restrictionFields.upper];
  let special = values[restrictionFields.special];
  return !(digits === 'NOT_ALLOWED' && lower === 'NOT_ALLOWED' && upper === 'NOT_ALLOWED' && special === 'NOT_ALLOWED');
});

function validHostOrIp(value) {
  return VALID_HOST_OR_IP_REGEX.test(value);
}

Formsy.addValidationRule('isValidHostOrIp', (values, value) => {
  return isEmpty(value) || validHostOrIp(value);
});

Formsy.addValidationRule('isValidHostNameOrIp', (values, value) => {
  return VALID_HOSTNAME_OR_IP.test(value);
});

Formsy.addValidationRule('isValidHostOrIpList', (values, value) => {
  if (isEmpty(value)) {
    return true;
  }

  const list = Array.isArray(value) ? value : splitCSV(value);
  for (let i = 0; i < list.length; i++) {
    if (!validHostOrIp(list[i].trim())) {
      return false;
    }
  }

  return true;
});

Formsy.addValidationRule('isValidIp', (values, value) => {
  return isEmpty(value) || VALID_IP_REGEX.test(value);
});

Formsy.addValidationRule('isValidDN', (values, value) => {
  return isEmpty(value) || VALID_DN_REGEX.test(value);
});

Formsy.addValidationRule('isValidUPN', (values, value) => {
  return isEmpty(value) || VALID_UPN_REGEX.test(value);
});

Formsy.addValidationRule('isValidUUID', (values, value) => {
  return isEmpty(value) || VALID_UUID_REGEX.test(value);
});

Formsy.addValidationRule('isValidOidcUri', (values, value) => {
  return isEmpty(value) || VALID_HTTPS_URI_REGEX.test(value) || VALID_LOCALHOST_URI.test(value);
});

// Validates native (e.g., OIDC) URL.
Formsy.addValidationRule('isValidNativeUri', (values, value) => {
  return isEmpty(value) || VALID_NATIVE_URL.test(value);
});

// Validates SAML Assertion Consumer Service URL and SLO URL.
Formsy.addValidationRule('validUrl', (values, value) => {
  return isEmpty(value) || VALID_URL_REGEX.test(value);
});

// Validates domain name without the http part.
Formsy.addValidationRule('validDomain', (values, value) => {
  return /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi.test(value);
});

Formsy.addValidationRule('isValidDirectoryUserName', (values, value) => {
  if (isEmpty(value)) {
    return true;
  }

  return VALID_DN_REGEX.test(value) || VALID_UPN_REGEX.test(value);
});

Formsy.addValidationRule('isValidObjectClass', (values, value) => {
  // an undefined or empty value is allowed.  Let the form declare if it is required or not.
  // We don't allow a value with spaces (which are allowed by isEmpty() because that is not a valid value
  if (value === undefined || value === null || value === '') {
    return true;
  }

  const list = Array.isArray(value) ? value : splitCSV(value);
  // must have at least one object class name
  if (list.length === 0) return false;
  for (let i = 0; i < list.length; i++) {
    // each object class name must start with an alpha and contain only alphanumeric or -
    if (!/^[a-zA-Z][a-zA-Z0-9-]*$/.test(list[i].trim())) {
      return false;
    }
  }

  return true;
});

Formsy.addValidationRule('noSpaces', (values, value) => {
  let spacesFound = /\s/.test(value);
  return !spacesFound;
});

Formsy.addValidationRule('isMinLength', (values, value, minLength) => {
  return isEmpty(minLength) || String(value).trim().length >= minLength;
});

Formsy.addValidationRule('isMaxLength', (values, value, maxLength) => {
  return isEmpty(maxLength) || String(value).trim().length <= maxLength;
});

Formsy.addValidationRule('isMinLengthUserCode', (values, value, minLength) => {
  return isEmpty(minLength) || String(value).replace(/-/g, '').trim().length >= minLength;
});

Formsy.addValidationRule('isMaxLengthUserCode', (values, value, maxLength) => {
  return isEmpty(maxLength) || String(value).replace(/-/g, '').trim().length <= maxLength;
});

Formsy.addValidationRule('lengthIsBetween', (values, value, limits) => {
  // don't use isEmpty because that allows a string that is all spaces
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (!isNumber(limits.min) || !isNumber(limits.max)) {
    return false;
  }
  const length = String(value).trim().length;
  return length >= limits.min && length <= limits.max;
});

Formsy.addValidationRule('isLessThan', (values, value, max) => {
  if (isEmpty(value) || isEmpty(max)) {
    return true;
  }
  if (!isNumber(value) || !isNumber(max)) {
    return false;
  }
  return parseNumber(value) <= parseNumber(max);
});

Formsy.addValidationRule('isLessThanField', (values, value, field) => {
  let max = values[field];
  if (isEmpty(value) || isEmpty(max)) {
    return true;
  }
  if (!isNumber(value) || !isNumber(max)) {
    return false;
  }
  return parseNumber(value) <= parseNumber(max);
});

Formsy.addValidationRule('isGreaterThan', (values, value, min) => {
  if (isEmpty(value) || isEmpty(min)) {
    return true;
  }
  if (!isNumber(value) || !isNumber(min)) {
    return false;
  }
  return parseNumber(value) >= parseNumber(min);
});

Formsy.addValidationRule('isGreaterThanField', (values, value, field) => {
  let min = values[field];
  if (isEmpty(value) || isEmpty(min)) {
    return true;
  }
  if (!isNumber(value) || !isNumber(min)) {
    return false;
  }
  return parseNumber(value) >= parseNumber(min);
});

Formsy.addValidationRule('isBetween', (values, value, limits) => {
  if (isEmpty(value)) {
    return true;
  }
  if (!isNumber(value) || !isNumber(limits.min) || !isNumber(limits.max)) {
    return false;
  }
  value = parseNumber(value);
  return value >= limits.min && value <= limits.max;
});

Formsy.addValidationRule('errorLabel', (values, value, message) => {
  return message === null;
});

/**
 * Makes sure that the value is not just white space.  Do NOT trim the value before running the Regular Expression.
 */
Formsy.addValidationRule('isEmpty', (values, value) => {
  return !/^[\s]+$/.test(value);
});

Formsy.addValidationRule('preventInvalidMaximumHistory', values => {
  return !(
    parseInt(values.maximumLocationHistory) === 0 &&
    (values.locationHistoryIpCheckRequired || values.velocityCheckRequired)
  );
});

Formsy.addValidationRule('notNull', (values, value) => {
  return value !== null;
});

Formsy.addValidationRule('isValidRadiusPort', (values, value) => {
  if (isEmpty(value)) return true;
  if (!isNumber(value)) return false;
  const port = parseNumber(value);
  return port <= 65535 && port > 1024 && port !== 8443;
});

Formsy.addValidationRule('isValidPhoneNumber', (values, value) => {
  return isEmpty(value) || isValidNumber(value);
});

Formsy.addValidationRule('isBetweenArraySize', (values, value, limits) => {
  if (isEmpty(value)) {
    return true;
  }
  if (!isNumber(limits.min) || !isNumber(limits.max)) {
    return false;
  }
  if (!Array.isArray(limits.array)) {
    return false;
  }
  const currentSize = limits.array.length;
  return currentSize >= limits.min && currentSize < limits.max;
});

Formsy.addValidationRule('isNotEqualTo', (values, value, secondValue) => {
  if (isEmpty(value) || isEmpty(secondValue)) {
    return true;
  }
  return value.toLowerCase() !== secondValue.toLowerCase();
});

Formsy.addValidationRule('oidcGrantTypeNoneImplicit', (values, value, secondValue) => {
  if (!Array.isArray(values.oidcAppScopes)) {
    return true;
  }

  if (!value.some(type => type.name === 'none') && !value.some(type => type.name === 'implicit')) {
    return true;
  }

  // If the OIDC Grant Types include "none" or "implicit", then the OIDC Scopes must include "openid"
  return value.some(type => type.name === 'none' || type.name === 'implicit') && secondValue;
});

Formsy.addValidationRule('oidcGrantTypeRefreshToken', (values, value) => {
  if (!Array.isArray(values.oidcAppGrantTypes)) {
    return true;
  }

  if (!value.some(grantType => grantType.name === 'refresh_token')) {
    return true;
  }

  // If the OIDC Grant Types include "refresh_token", then the OIDC Grant Types must include "authorization_code" (not triggered for "device_code")
  return (
    value.some(type => type.name === 'refresh_token') &&
    (value.some(type => type.name === 'authorization_code') ||
      value.some(type => type.name === 'device_code') ||
      value.some(type => type.name === 'jwt_idaas'))
  );
});

Formsy.addValidationRule('oidcTokenAlg', (values, value) => {
  if (!Array.isArray(values.oidcAppGrantTypes)) {
    return true;
  }

  // If the OIDC ID Token Signing Algorithm is "none", then the OIDC Grant Type cannot include "implicit"
  return !(value === 'none' && values.oidcAppGrantTypes.findIndex(type => type.name === 'implicit') > -1);
});

Formsy.addValidationRule('isNonEmptyArray', (values, value) => {
  return Array.isArray(value) && value.length > 0;
});

Formsy.addValidationRule('isValidSecret', (values, value) => {
  return VALID_CLIENT_SECRET.test(value);
});

Formsy.addValidationRule('blockHTML', (values, value) => {
  return BLOCK_HTML.test(value);
});

// The valuesToCheck argument should be an array of values.  This validation checks to ensure that at least one value
// is not empty.  This rule can be used when a form requires that at least one of a set of fields must have a value.
Formsy.addValidationRule('needOne', (values, value, valuesToCheck) => {
  const foundOne = valuesToCheck.find(v => {
    return !isEmpty(v);
  });

  return !!foundOne;
});

// The refresh token timeout value should not be greater than refresh token limit. This validation is to check
// these two values and make sure refresh token timeout is less than or equal to refresh token limit.
Formsy.addValidationRule('tokenTimeOutRule', values => {
  if (
    !isNumber(values.refreshTokenLimit) ||
    !isNumber(values.refreshTokenTimeout) ||
    values.refreshTokenLimit === '0'
  ) {
    return true;
  }
  const tokenLimit = parseNumber(values.refreshTokenLimit);
  const tokenTimeout = parseNumber(values.refreshTokenTimeout);
  return tokenTimeout <= tokenLimit;
});

Formsy.addValidationRule('matchesRegex', (values, value, regex) => {
  return isEmpty(value) || regex.test(value);
});

Formsy.addValidationRule('validEmailAllowWhitespace', (values, value) => {
  return validEmail(value, true);
});
