//      

// Source - https://blog.logrocket.com/4-different-techniques-for-copying-objects-in-javascript-511e422ceb1e

export default function deep   (value   )    {
  if (typeof value !== 'object' || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return deepArray(value);
  }

  return deepObject(value);
}

function deepObject           (source   )    {
  const result = {};

  Object.keys(source).forEach(key => {
    const value = source[key];

    result[key] = deep(value);
  }, {});

  return result;
}

function deepArray               (collection   )    {
  return collection.map(value => {
    return deep(value);
  });
}
