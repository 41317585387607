//      
import deep from 'src/lib/deep';

// Flow Types
// =============================================================================
                                                    
                                                         

                                                                                          

                                                                                  

                                        

export const initialState              = [];

// Reducers
// =============================================================================
export default (state              = initialState, action        ) => {
  const newState = deep(state);
  let lastElement;
  switch (action.type) {
    case 'OPEN_BANNER':
      newState.push(action.payload);
      return newState;
    case 'CLOSE_BANNER':
      // Set the open property of the last Banner and move it to the begining of Array
      lastElement = newState.pop();
      lastElement.open = false;
      return [lastElement, ...newState];
    default:
      return state;
  }
};

// Actions
// =============================================================================

/**
 * Add a message to be added in the Idaas Banner
 * @param {String} message The message to be displayed in the Banner
 * @param {String} severity The severity of the message
 * @param {String} referenceLink The URL to which learn more button should link to
 */
export const openBanner = (message        , severity        , referenceLink         ) => {
  return (dispatch          , getState          ) => {
    const messageExists = getState().banner.find(bannerItem => bannerItem.message === message);

    if (messageExists) {
      return null;
    }

    const payload = { message, severity, referenceLink, open: true };
    dispatch({ type: 'OPEN_BANNER', payload });
  };
};

/**
 * Close the current Banner being displayed by removing it from the banner array.
 */
export const closeBanner = () => {
  return (dispatch          ) => {
    dispatch({ type: 'CLOSE_BANNER' });
  };
};

// Selectors
// ========================================================================

/**
 * Get the last message in the banner array.  If there are no more messages then null is returned.
 * @param  {Object} banner The root Redux banner object
 * @return {String}        The next message or null if there are no more messages.
 */
export const getNextBannerMessage = (banner             )          => {
  if (banner.length > 0) {
    return banner[banner.length - 1].message;
  }
  return null;
};

/**
 * Get the severity of last message in the banner array.  If there are no more messages then null is returned.
 * @param  {Object} banner The root Redux banner object
 * @return {String}        The next message or null if there are no more messages.
 */
export const getNextBannerSeverity = (banner             )            => {
  if (banner.length > 0) {
    return banner[banner.length - 1].severity;
  }
  return null;
};

/**
 * Get the reference URL from the last message in the banner array.  If there are no more messages then null is returned.
 * @param  {Object} banner The root Redux banner object
 * @return {String}        The next message or null if there are no more messages.
 */
export const getNextBannerReferenceLink = (banner             )          => {
  if (banner.length > 0) {
    return banner[banner.length - 1].referenceLink;
  }
  return null;
};

/**
 * Check whether the banner has been dismissed by the user or not
 * @param  {Object} banner The root Redux banner object
 * @return {boolean} returns whether the banner should be open or closed
 */
export const checkNextBannerOpen = (banner             )          => {
  if (banner.length > 0) {
    return banner[banner.length - 1].open;
  }
  return false;
};
