//      

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material-UI Components
// =============================================================================
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// External Functions
// ===========================================================================
import t from 'src/i18n';
import { filterByBundleFeatureName } from 'src/lib/entitlements';
import { getHighestRankedBundle } from 'src/ducks/bundleFeatures';
import { getIsRootSpAccount } from 'src/ducks/theme';
import { ROLE_TYPE_SITE_ADMINISTRATOR, ROLE_TYPE_ACCOUNT_MANAGER } from 'src/lib/constants';
import { userHasRole, userHasPermissionsForRoleType, getRoleByType } from 'src/ducks/permissions';
import { useDebounce } from 'src/lib/hooks';

// Material-UI Icons
// =============================================================================
import SearchIcon from '@material-ui/icons/Search';

                                                     

const useStyles = makeStyles(theme => ({
  inputLabel: {
    color: 'white'
  },
  input: {
    '& .MuiFilledInput-input': {
      padding: '10px 12px 10px'
    },
    borderRadius: theme.spacing(0.5)
  },
  textField: {
    backgroundColor: '#FFF',
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(1, 0)
  },
  autocomplete: {
    marginRight: theme.spacing(1),
    margin: theme.spacing(1, 1, 0, 1)
  },
  customPopper: {
    width: '350px !important',
    zIndex: 1500
  }
}));

const SearchBar = () => {
  const [miniSearch, setMiniSearch] = React.useState(null);
  const [result, setResult] = React.useState([]);

  const permissions = useSelector((state            ) => state.permissions);

  const bundleFeatures = useSelector((state            ) => getHighestRankedBundle(state));

  const smartLoginEnabled = useSelector((state            ) => state.themes.subscriber.smartLoginEnabled);

  const currentAdminRole = useSelector((state            ) =>
    getRoleByType(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR)
  );

  const isSPAdmin = useSelector((state            ) => userHasRole(state.permissions, ROLE_TYPE_ACCOUNT_MANAGER));

  const isRootSPAccount = useSelector((state            ) => getIsRootSpAccount(state.themes));

  const isSp = useSelector((state            ) => state.themes.subscriber.isSp);

  const history = useHistory();

  const classes = useStyles();

  const SP_MENU_ITEMS = [
    t('tasks.title'),
    t('ServiceProviderMenu.tenants'),
    t('ServiceProviderMenu.otpProviders'),
    t('ServiceProviderMenu.clientCredential'),
    t('ServiceProviderMenu.managementPlatform'),
    t('ServiceProviderMenu.pkiaasCredentials'),
    t('ServiceProviderMenu.pushCredentials'),
    t('ServiceProviderMenu.rateLimiting'),
    t('SettingsMenu.policies'),
    t('RateLimiting.headerNames.tenantPolicies'),
    t('breadCrumbs.tenantsTab')
  ];

  React.useEffect(() => {
    import('minisearch').then(({ default: MiniSearch }) => {
      setMiniSearch(
        new MiniSearch({
          fields: ['name', 'description'], // fields to index for full-text search
          storeFields: ['name', 'description', 'url'],
          searchOptions: {
            fuzzy: 0.2,
            prefix: true,
            fields: ['name', 'description'],
            combineWith: 'OR'
          }
        })
      );
    });
  }, []);

  React.useEffect(() => {
    if (!miniSearch) {
      return;
    }

    (async () => {
      const menuIndex = await import('src/assets/menu.json');
      const filteredMenu = menuIndex.default.filter(menu => {
        const hasPermission = userHasPermissionsForRoleType(
          permissions,
          ROLE_TYPE_SITE_ADMINISTRATOR,
          menu.permissions
        );

        const hasBundleFeature =
          menu.requiredBundleFeatures.length === 0 ||
          menu.requiredBundleFeatures.every(
            featureName => filterByBundleFeatureName(featureName, bundleFeatures)?.enabled
          );

        const isPermitted = hasPermission && hasBundleFeature;

        if (
          [
            t('resourceMenu.certificateAuth'),
            t('breadCrumbs.smartCredDef'),
            t('breadCrumbs.dcCertsDef'),
            t('authType.smart')
          ].includes(menu.name)
        ) {
          return smartLoginEnabled && isPermitted;
        }

        if ([t('breadCrumbs.groupPolicies'), t('breadCrumbs.resourceRules')].includes(menu.name)) {
          return currentAdminRole?.groupManagement === 'ALL' && isPermitted;
        }

        if ([t('breadCrumbs.applications')].includes(menu.name)) {
          return bundleFeatures?.bundle !== 'CONSUMER' && isPermitted;
        }

        // sp menu items
        if (SP_MENU_ITEMS.includes(menu.name)) {
          let permit = true;

          if (menu.requireRootSp) {
            permit = isRootSPAccount;
          }

          if (menu.requireSpAdmin) {
            permit = isSPAdmin && permit;
          }

          return isSp && permit && isPermitted;
        }

        return isPermitted;
      });

      if (!filteredMenu) {
        return;
      }

      miniSearch.removeAll();
      miniSearch.addAll(filteredMenu);
    })();
  }, [miniSearch, permissions, bundleFeatures, smartLoginEnabled, currentAdminRole]);

  const handleChange = (_, value) => {
    if (!value?.url) {
      return;
    }

    history.push(value.url);
  };

  const handleInputChange = useDebounce((_, value        ) => {
    if (!value?.trim()) {
      setResult([]);
      return;
    }

    const result = miniSearch?.search(value) || [];
    setResult(result);
  }, 200);

  return (
    <Autocomplete
      id={'search_menu'}
      freeSolo={true}
      className={classes.autocomplete}
      options={result}
      filterOptions={result => result}
      getOptionLabel={option => option.name || ''}
      getOptionSelected={(option, value) => option.path === value.path}
      renderOption={option => (
        <span>
          {option.name}
          <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            {option.description}
          </Typography>
        </span>
      )}
      PopperComponent={props => <Popper {...props} className={classes.customPopper} />}
      color="secondary"
      renderInput={params => {
        return (
          <TextField
            {...params}
            className={classes.textField}
            placeholder="Search..."
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                maxLength: 30,
                onInput: e => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, '');
                }
              },
              classes: {
                root: classes.input
              },
              startAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        );
      }}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export default SearchBar;
