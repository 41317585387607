import 'src/lib/machineSecret.js';

// Helper Functions
// =============================================================================
import { clearMachineNonce, clearSequenceNonce, setMachineNonce, setSequenceNonce } from 'src/ducks/authentication';
import { store } from 'src/store';

window.machineSecret.setStorageTypeExclusions('cookie');
window.machineSecret.setStorageTypeExclusions('flash');

export const setNonces = machineAuthenticator => {
  if (machineAuthenticator?.machineNonce) {
    try {
      window.localStorage.setItem('machineNonce', machineAuthenticator.machineNonce);
    } catch (error) {
      store.dispatch(setMachineNonce(machineAuthenticator.machineNonce));
    }
  } else {
    try {
      window.localStorage.removeItem('machineNonce');
    } catch (error) {
      store.dispatch(clearMachineNonce());
    }
  }

  if (machineAuthenticator?.sequenceNonce) {
    try {
      window.localStorage.setItem('sequenceNonce', machineAuthenticator.sequenceNonce);
    } catch (error) {
      store.dispatch(setSequenceNonce(machineAuthenticator.sequenceNonce));
    }
  } else {
    try {
      window.localStorage.removeItem('sequenceNonce');
    } catch (error) {
      store.dispatch(clearSequenceNonce());
    }
  }
};

export const getMachineNonce = () => {
  try {
    return window.localStorage.getItem('machineNonce');
  } catch (error) {
    return store.getState().authentication.machineNonce;
  }
};

export const getSequenceNonce = () => {
  try {
    return window.localStorage.getItem('sequenceNonce');
  } catch (error) {
    return store.getState().authentication.sequenceNonce;
  }
};

export const removeNonces = () => {
  try {
    window.localStorage.removeItem('machineNonce');
    window.localStorage.removeItem('sequenceNonce');
  } catch (error) {
    store.dispatch(clearMachineNonce());
    store.dispatch(clearSequenceNonce());
  }
};

export const getMachineAuthenticator = userMachineSettings => {
  let machineAuthenticator = {};
  machineAuthenticator.machineNonce = getMachineNonce();
  machineAuthenticator.sequenceNonce = getSequenceNonce();

  // we gather the fingerprint if we have the user machine settings and a fingerprint is required
  if (userMachineSettings) {
    machineSecret.setAttributeExclusions(userMachineSettings.attributeExclusions);
    if (userMachineSettings.deviceFingerprintRequired) {
      machineAuthenticator.fingerprint = machineSecret.getDeviceFingerprint();
    }
  } else {
    // during userQuery we don't have the userMachineSettings
    machineAuthenticator.fingerprint = machineSecret.getDeviceFingerprint();
  }

  return machineAuthenticator;
};

export const getMachineRegistration = (machineLabel, userMachineSettings) => {
  let machineAuthenticatorRegistration = {};
  machineAuthenticatorRegistration.label = machineLabel;

  // we gather the fingerprint if we have the user machine settings and a fingerprint is required
  if (userMachineSettings) {
    machineSecret.setAttributeExclusions(userMachineSettings.attributeExclusions);
    if (userMachineSettings.deviceFingerprintRequired) {
      machineAuthenticatorRegistration.fingerprint = machineSecret.getDeviceFingerprint();
      console.log('fingerprint: ', machineAuthenticatorRegistration.fingerprint);
    }
  }

  return machineAuthenticatorRegistration;
};

// Some authentication API calls might throw an exception (e.g., invalid response) that might contain a
// machine authenticator with a new sequence nonce. If so, we want to save it
export const saveMachineAuthenticatorIfPresent = err => {
  if (err.response && err.response.parameters && Array.isArray(err.response.parameters)) {
    // if a machine authenticator is available, it's the last one
    const idx = err.response.parameters.length - 1;
    try {
      const machineAuthenticator = err.response.parameters[idx] && JSON.parse(err.response.parameters[idx]);
      if (machineAuthenticator.machineNonce) {
        setNonces(machineAuthenticator);
      }
    } catch (error) {
      // Not a machine authenticator. Ignore error.
    }
  }
};
