//      
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Entrust Components
// =============================================================================
import Banner from 'src/components/Banner';
import FixedAppBarMenu from 'src/components/Layout/Header/FixedAppBarMenu';
import IntelliDrawer, { DRAWER_WIDTH } from 'src/components/Layout/Header/IntelliDrawer';

// Material-UI Components
// =============================================================================
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Redux / Utility functions
// =============================================================================
import { getIsPortalRoute } from 'src/ducks/router';
import { getIsUserLoading } from 'src/ducks';
import { isIE11 } from 'src/lib/browser';
import { openBanner } from 'src/ducks/banner';
import { useActions } from 'src/lib/hooks';
import t from 'src/i18n';
import { ROLE_TYPE_SITE_ADMINISTRATOR } from 'src/lib/constants';
import { userHasRole } from 'src/ducks/permissions';

// Flow Types
// =============================================================================
                                                     

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
  }
}));

const Header = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const classes = useStyles();

  const location = useLocation();

  const isPortalRoute = getIsPortalRoute(location);

  const userIsLoading = useSelector((state            ) => getIsUserLoading(state));

  const isAuthenticationAccount = useSelector(state => state.themes.subscriber.authenticationAccount);

  const isSiteAdmin = useSelector(state => userHasRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR));

  const { openBanner: showBanner } = useActions({ openBanner });

  const theme = useTheme();

  // Keep the drawer open for screens bigger than lg
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    if (isIE11()) {
      showBanner(t('banner.ie11Warning'), 'WARNING');
    }
  }, []);

  React.useEffect(() => {
    // Close the drawer if it is open and we are navigating to a new route and we are using the temporary drawer
    if (openDrawer && matches) {
      toggleDrawer();
    }
  }, [location.pathname]);

  const toggleDrawer = () => {
    setOpenDrawer(value => !value);
  };

  const iconElementRight = isPortalRoute ? <FixedAppBarMenu /> : null;

  let showDrawer = !userIsLoading;

  // Only show the drawer when in the Admin / User Portals
  if (!isPortalRoute) {
    showDrawer = false;
  }

  const appBarClass = isPortalRoute && showDrawer && openDrawer ? classes.appBar : '';

  const showSearchBar = isPortalRoute && isAuthenticationAccount && isSiteAdmin;

  return (
    <>
      <AppBar position="fixed" className={appBarClass}>
        <Banner />
        <Toolbar>
          {showDrawer && !openDrawer && (
            <IconButton id="app-drawer-open" color="inherit" onClick={toggleDrawer}>
              <MenuIcon htmlColor="inherit" />
            </IconButton>
          )}

          <Box flexGrow={1} />
          {iconElementRight}
        </Toolbar>
      </AppBar>
      {showDrawer && <IntelliDrawer toggleDrawer={toggleDrawer} isOpen={openDrawer} showSearchBar={showSearchBar} />}
    </>
  );
};

export default Header;
