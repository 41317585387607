import UAParser from 'ua-parser-js';

const parser = new UAParser();

export const isIOS = (): boolean => {
  return parser.getOS().name === 'iOS';
};

export const isIE11 = (): boolean => {
  // @ts-expect-error ie 11 has ActiveXObject
  return !window.ActiveXObject && 'ActiveXObject' in window;
};

export const isLegacyEdge = (): boolean => {
  return /Edge\/(1[6-8])/.test(navigator.userAgent) && !/Edg\//.test(navigator.userAgent);
};
