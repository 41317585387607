//      
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Redux / Router Functions
// ===========================================================================
import { selectIsRenewSpUser, selectSessionIsExpired, selectSessionIsInvalid } from 'src/ducks/errors';
import { getSessionExpiryTime, isLoggedIn, isSystemLoggedIn } from 'src/lib/jwt';

              
                      
  

const AuthProvider = ({ children }       ) => {
  const history = useHistory();

  const location = useLocation();

  const isStepAuth = useSelector((state            ) => state.authentication.isStepAuth);

  const isSessionExpired = useSelector(state => selectSessionIsExpired(state.errors));
  const isSessionInvalid = useSelector(state => selectSessionIsInvalid(state.errors));
  const isRenewSpUser = useSelector(state => selectIsRenewSpUser(state.errors));

  const loggedIn = isLoggedIn() && isSystemLoggedIn();

  if (isSessionExpired) {
    history.replace('/logout');
  }

  // AAAS-31485 !loggedIn is needed to prevent a race conditon between auth flow mounting prior to the jwt being cleared
  if (isRenewSpUser && !loggedIn) {
    history.replace({ pathname: '/', search: '?action=sp' });
    return null;
  }
  if (!loggedIn || isSessionInvalid) {
    // when step up authentication is triggered, the authentication state in redux will temporarily be logged out,
    // but we still want to stay on the same page without redirecting in order to complete step up
    if (isStepAuth && Date.now() < getSessionExpiryTime()) {
      return children;
    }
    history.replace({
      pathname: '/',
      search: `?redirect=${location.pathname}`
    });
    return null;
  }

  return children;
};

export default AuthProvider;
