//      
import { getActiveEntitlement } from 'src/api/entitlements';
import { getPermissionsByType } from 'src/ducks/permissions';
import { ROLE_TYPE_SITE_ADMINISTRATOR } from 'src/lib/constants';

// Flow type
                                                    
                                                                                   

// Action Types
// =============================================================================
             
                              
                                          
                                          
                                                                       

                                                          
                  
                   
                      
  

// Initial State
// =============================================================================
export const initialState = {
  failed: false,
  isLoading: false,
  lastUpdated: null,
  additionalFeatures: null,
  allotment: 0,
  contractMode: 'TRIAL',
  endDate: new Date(),
  entitlementId: '',
  overageType: 'NO',
  quantity: 0,
  smsVoice: null,
  startDate: new Date(),
  usageType: 'USERS'
};

// Reducers;
// =============================================================================
export default (state                         = initialState, action        ) => {
  switch (action.type) {
    case 'ENTITLEMENTS/FETCH_REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'ENTITLEMENTS/FETCH_FAILURE':
      return { ...state, isLoading: false, failed: true, lastUpdated: Date.now() };

    case 'ENTITLEMENTS/FETCH_SUCCESS':
      return {
        ...state,
        lastUpdated: Date.now(),
        isLoading: false,
        failed: false,
        ...action.payload
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const fetchActiveEntitlements = () => {
  return async (dispatch          , getState          ) => {
    try {
      const store = getState();

      if (store.activeEntitlements.isLoading) {
        return Promise.resolve();
      }

      dispatch({ type: 'ENTITLEMENTS/FETCH_REQUEST' });

      const permissions = getPermissionsByType(store.permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['ENTITLEMENTS']);

      const payload = permissions.ENTITLEMENTS.actionView ? await getActiveEntitlement() : null;

      dispatch({ type: 'ENTITLEMENTS/FETCH_SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'ENTITLEMENTS/FETCH_FAILURE' });
    }
  };
};
