export default {credentialdesignmanagement: {getAllCredentialDesignsList: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/api/web/issuance/v2/credential-designs/all-credential-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },generateBarcode: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/barcode'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readCredentialDesignTemplates: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-design-templates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateCredentialDesignTemplate: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-design-templates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createCredentialDesignTemplate: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-design-templates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readAllCredentialTemplateNames: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-design-templates/thumbnail'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readCredentialDesignTemplate: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/credential-design-templates/{CredentialDesignTemplateId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteCredentialDesignTemplate: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["CredentialDesignTemplateId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/credential-design-templates/{CredentialDesignTemplateId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDefaultDesignName: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-designs/defaultName'
        + ''
      );

      const headers = {
        'Accept': 'text/plain',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMagneticStripeFieldIds: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/credential-designs/magneticStripeFieldIds/{credentialDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCredentialDesignsSummary: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-designs/summary'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOrUpdateCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/credential-designs/updateOrCreate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/credential-designs/{credentialDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["credentialDesignId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/credential-designs/{credentialDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },transformImage: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["transformType","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/image/transform/type/{transformType}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readImageTransformations: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/image/transform/types'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printCredentialDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printers/{printerId}/print-design`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCredentialDesignsList: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/credential-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},alexa: {alexaAccessTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization","X-AUTHORIZATION","grant_type","code","refresh_token"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/alexa/access-token'
        + '?' + ["grant_type","code","refresh_token"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': '*/*',
        ...["Authorization","X-AUTHORIZATION"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readPrintersAlexa: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/alexa/readPrinters'
        + '?' + ["fields","withStatus"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },alexaRegisterUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/alexa/register'
        + '?' + ["code","scope","state","error_description"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'text/html',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },registerPrinterAlexa: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/alexa/registration'
        + '?' + ["action","printerId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },alexaRequestUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/alexa/request'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},enrollment: {updateEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },insertEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAutoSequenceValue: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollment/autoSequence/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },compressImageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/compressImage'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEnrollmentCountUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollment/count/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readCredential: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollment/credential/{credentialDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteEnrollmentByIDs: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/delete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAllEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/delete/all'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },tenantHasEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/enrollmentsExist'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },generateCredential: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/generateCredential'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readCredentials: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/list'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },loadNewEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollment/newEnroll/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCredentialsSummary: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/summary'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImageThumbnailUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollment/thumbnailImage'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'text/plain';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollment/{enrollmentTokenJson}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },exportEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentExport/export'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEnrollmentExportView: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentExport/export/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },bulkIssueMFP: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/mfp/issue/bulk'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },bulkRevokeMFP: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/mfp/revoke/bulk'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printCredentials: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/multiPrints'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printCredential: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/print'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printAllCredentials: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/print/all'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readMobileFlashPassClaimDataUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentIssuance/readMobileFlashPassClaimData/{enrollmentId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },restartPrintJob: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printJobId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentIssuance/restartPrintJob/{printJobId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeMobileFlashPass: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/revokeMobileFlashPass'
        + ''
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendMobileFlashPassEmailUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["enrollmentId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentIssuance/sendEmail/{enrollmentId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendMobileFlashPassEmails: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentIssuance/sendEmails'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentSearch/list/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },searchEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/enrollmentSearch/search'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSearchPageView: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/enrollmentSearch/view/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},externaldatabase: {readAllExternalDatabases: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/externaldatabase/'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateExternalDatabase: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/externaldatabase/'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createExternalDatabase: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/externaldatabase/'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getColumns: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/externaldatabase/columns/{databaseId}/{tableName}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTables: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/externaldatabase/tables/{databaseId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testConnection: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/externaldatabase/testconnection'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readExternalDatabase: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/externaldatabase/{databaseId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteExternalDatabase: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["databaseId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/externaldatabase/{databaseId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},fieldconnection: {readFieldConnections: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/fieldConnection/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFieldConnections: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["workflowId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/fieldConnection/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},workflowdesign: {createGroupWithEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGroupWithEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGroupWithEnrollment: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },groupsPaged: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/groupspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addUpdateVariableGraphics: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/variableGraphic'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readVariableGraphicByFieldId: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/variableGraphic/byField/{fieldId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readVariableGraphicById: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/variableGraphic/byId/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readWorkflowDesigns: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/workflowDesign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateWorkflowDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/workflowDesign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createWorkflowDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/workflowDesign'
        + ''
      );

      const headers = {
        'Accept': 'text/plain',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readWorkflowDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/workflowDesign/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteWorkflowDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["workflowId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/workflowDesign/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEnrollmentDesignTextFields: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/workflowDesignFields/{workflowId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getWorkflowDesigns: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v3/workflowDesign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllWorkflowDesignSummaries: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v3/workflowDesign/all-workflowDesign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},mobileflashpassdesignmanagement: {readMobileFlashPassDesigns: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs'
        + '?' + ["fields"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateMobileFlashPassDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createMobileFlashPassDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileFlashPassDesignCredits: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs/credits'
        + '?' + ["expiry"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },reduceMobileFlashPassCredits: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["credits","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs/credits'
        + '?' + ["credits"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileFlashPassDesignDefaultFields: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs/default-fields'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendMobileFlashPassDesignSampleUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["designId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/mobile-flash-pass-designs/send/{designId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileFlashPassDesignSummary: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/mobile-flash-pass-designs/summary'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readMobileFlashPassDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/mobile-flash-pass-designs/{mobileFlashPassDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteMobileFlashPassDesign: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["mobileFlashPassDesignId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/mobile-flash-pass-designs/{mobileFlashPassDesignId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},printerdashboardapi: {checkFirmware: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/checkFirmware/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["query","accountId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrinterInfo: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/info/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },runCleaningCard: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/runCleaningCard/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrinterSettings: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/settings/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPrinterSettings: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["deviceId","printerType","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/settings/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrinterStatus: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/status/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFirmware: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/printerdashboard/updateFirmware/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["printerType","accountId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},issuancesettings: {getMobileFlashPassSettings: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/settings/mobile-flash-pass'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateMobileFlashPassSettings: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/settings/mobile-flash-pass'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},smartcard: {updateSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/smartCard'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/smartCard'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },formatSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["cardType","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/smartCard/format/{cardType}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readSmartCards: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/smartCard/list'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["cardType","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/smartCard/test/{cardType}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/smartCard/{id}/{cardType}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSmartCard: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","cardType","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v1/smartCard/{id}/{cardType}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},printersearch: {updateFleetManagementOptIn: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v1/update/fleetManagementOptIn'
        + ''
      );

      const headers = {
        'Accept': 'text/plain',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },allTenantsPrinters: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/printers/allTenantsPrinters'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },filterPrinters: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/printers/filterList'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },filterPrintersByAccountId: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["accountId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/printers/filterPrintersByAccountId/{accountId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAccountPrintersSummary: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/printers/printerSummary/{accountId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePtpFlagOnBundleChange: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tenantId","bundle","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/printers/{tenantId}/updatePtpFlagOnBundleChange/{bundle}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRibbonBundle: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/issuance/v2/ribbon/{ribbonId}/bundle`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantList: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/tenantlist'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTier1ParentCompanyName: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/issuance/v2/tier1ParentCompanyName'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }}}