export default {internal: {getPublicSubscriberInformationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/account/public'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDatabaseProxyAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/database-proxy/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDatabaseProxyAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/database-proxy/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectorySyncAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/directory/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDirectorySyncAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/directory/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getIdgAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/idg/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateIdgAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/idg/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPasswordAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/password/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePasswordAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/password/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRadiusAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/radius/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateRadiusAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/agents/radius/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listIdgApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/idg'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createIdgApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/idg'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getIdgApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/idg/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateIdgApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/idg/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeIdgApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/idg/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOidcAADCAProviderTenantAppIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/aadcaprovidertenantappid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAccessTokenSigningAlgorithmsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/accesstokensigningalgorithms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listApplicationTypesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/applicationtypes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listClaimFormatsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/claimformats'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listCodeChallengeMethodsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/codechallengemethods'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listGrantTypesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/granttypes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOidcIdTokenRequestValidationDisabledUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/idtokenrequestvalidationdisabled'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listIdTokenSigningAlgorithmsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/idtokensigningalgorithms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOidcMSEAMProviderTenantAppIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/mseamprovidertenantappid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listTokenEndpointAuthMethodsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/tokenendpointauthmethods'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listUserCodeCharacterSetsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/usercodecharactersets'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listUseridClaimsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/useridclaims'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listUserinfoSigningAlgorithmsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/oidc/userinfosigningalgorithms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listRadiusApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/radius'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createRadiusApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/radius'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRadiusApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/radius/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateRadiusApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/radius/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeRadiusApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/radius/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSamlApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSamlApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listDataEncryptionMethodsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml/dataencryptionmethods'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },generateSecretUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml/generatesecret'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listKeyEncryptionMethodsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml/keyencryptionmethods'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listNameIdFormatsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml/nameidformats'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSignatureAlgorithmsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/saml/signaturealgorithms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSamlApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSamlApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSamlApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSIEMApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/siem'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSIEMApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/applications/siem'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSIEMApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/siem/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSIEMApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/siem/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSIEMApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/applications/siem/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyDirectoryAttrMappingUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/attributemappings/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDirectoryAttributeMappingUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/attributemappings/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },gatewayAuthenticateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/authentication/gateways/authenticate/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },gatewayChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["agentType","id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/authentication/gateways/authenticate/{agentType}/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },spClientAuthenticateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/authentication/serviceprovider/authenticate/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },spClientChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/authentication/serviceprovider/authenticate/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userApplicationAuthenticationQueryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["applicationId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/authentication/users/applications/{applicationId}/validate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllAssignEntrustSTBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/entrustsofttokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initAssignEntrustSTBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/entrustsofttokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAssignEntrustSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/entrustsofttokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startAssignEntrustSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/entrustsofttokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignEntrustSTBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/entrustsofttokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAssignEntrustSTBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/entrustsofttokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllAssignGoogleSTBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/googlesofttokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initAssignGoogleSTBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/googlesofttokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAssignGoogleSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/googlesofttokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startAssignGoogleSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/googlesofttokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignGoogleSTBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/googlesofttokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAssignGoogleSTBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/googlesofttokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllAssignGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initAssignGridsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAssignGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/grids/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startAssignGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/grids/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAssignGridsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllAssignPasswordBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initAssignPasswordBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/assign/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAssignPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/passwords/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startAssignPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/passwords/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignPasswordBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/passwords/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAssignPasswordBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/assign/passwords/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllDeleteUserBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/delete/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initDeleteUsersBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/delete/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelDeleteUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/delete/users/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startDeleteUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/delete/users/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDeleteUserBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/delete/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDeleteUsersBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/delete/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportGridBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/grids/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/grids/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportGridsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportGroupsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportGroupsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/groups/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/groups/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportGroupsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportGroupsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/hardwaretokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportTokensBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/hardwaretokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/hardwaretokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/hardwaretokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/hardwaretokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportTokensBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/hardwaretokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllIdentityGuardBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/identityguard'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initIdentityGuardBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/identityguard'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelIdentityGuardBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/identityguard/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startIdentityGuardBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/identityguard/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getIdentityGuardBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/identityguard/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteIdentityGuardBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/identityguard/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportUserGroupsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/usergroups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportUserGroupsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/usergroups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportUserGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/usergroups/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportUserGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/usergroups/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportUserGroupsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/usergroups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportUserGroupsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/usergroups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportUsersBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportUsersBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/import/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/users/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/users/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportUsersBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportUsersBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/import/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllResetPasswordBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/reset/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initResetPasswordsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/bulkoperations/reset/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelResetPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/reset/passwords/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startResetPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/reset/passwords/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResetPasswordBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/reset/passwords/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteResetPasswordBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/reset/passwords/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsResetPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/bulkoperations/reset/passwords/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeCertificateUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/certificates/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createCertificateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["type","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/certificates/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/certificates/{type}/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyDirectoryUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDirectoryUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyDirectoryAttrMappingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directories/{id}/attributemappings`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyGroupFiltersUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directories/{id}/groupfilters`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifySearchBasesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directories/{id}/searchbases`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyDirectorySyncUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directorysyncs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDirectorySyncUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directorysyncs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },forceCrawlUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/directorysyncs/{id}/forcecrawl`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSubscriberActiveEntitlementUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/entitlements/active'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listGatewaysUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/gateways'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGatewayUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/gateways/create'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGatewayInstanceUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/createinstance/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },registerGatewayUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/register/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },registerCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/gateways/registercomplete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGatewayUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/update/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGatewayDownloadUrlUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/gateways/url'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGatewayDownloadUrlsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/gateways/urls'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGatewayUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGatewayUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },processGatewayCSRResponseUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGatewayCSRUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGatewayCSRUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listGatewayInstancesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}/instances`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGatewaySSLCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/gateways/{id}/sslcert/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyGroupFilterUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/groupfilters/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGroupFilterUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/groupfilters/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGroupCompatibilityUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSCAProxyDownloadUrlUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/mscaproxy/url'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },activeApplicationsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/activeapplicationscount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },activeUsersUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/activeuserscount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByOutcomeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/authenticationoutcomecount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsReportUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/authentications'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },failedAuthenticationsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/failedauthenticationcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },smsSentUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/reports/smscount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSiteRoleUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSiteRoleUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listPermissionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/roles/permissions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSiteRoleUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/roles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSCDefnPIVAppletConfigsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/scdefnpivappletconfigs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSCDefnPIVAppletConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefnpivappletconfigs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSCDefnUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/scdefns'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSCDefnUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefns/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSCDefnUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefns/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSCDefnVariableUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/scdefnvariables'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSCDefnVariableUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefnvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSCDefnVariableUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefnvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSCDefnVariableUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/scdefnvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifySearchBaseUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["searchbaseid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/searchbases/{searchbaseid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSearchBaseUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["searchbaseid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/searchbases/{searchbaseid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSPManagementPlatformUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/managementplatform'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSPManagementPlatformUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/managementplatform'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSPManagementPlatformUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/managementplatform'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSPManagementPlatformUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/managementplatform/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },promoteSubscriberToServiceProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/promote'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSPRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSPClientCredentialUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/spclientcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSPClientCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/spclientcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSPClientCredentialUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/spclientcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSPClientCredentialUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/spclientcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getServiceProviderAccountsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/subscribers'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSubscriberWithoutReCaptchaUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/serviceprovider/subscribers'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },lockServiceProviderAccountUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/subscribers/lock/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockServiceProviderAccountUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/subscribers/unlock/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },serviceProviderAccountResetResourceRulesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/subscribers/{id}/resetresourcerules`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },serviceProviderAdminAccountsUnlockUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/serviceprovider/subscribers/{id}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDeviceAttributesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/devices/{platform}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDeviceAttributesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["platform","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/devices/{platform}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEntrustLegacyTokenAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/entrustlegacytoken'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEntrustLegacyTokenAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/entrustlegacytoken'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGridAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/grid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGridAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/grid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getHardwareOathAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/hardwareoath'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateHardwareOathAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/hardwareoath'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getKbaSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateKbaSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listKbaQuestionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba/questions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateKbaQuestionUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba/questions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addKbaQuestionUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba/questions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteKbaQuestionUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/kba/questions/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listKbaWordMapsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/kba/wordmaps'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateKbaWordSynonymUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["locale","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/kba/wordmaps/{locale}/wordsynonyms`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addKbaWordSynonymUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["locale","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/kba/wordmaps/{locale}/wordsynonyms`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteKbaWordSynonymUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["locale","uuid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/settings/kba/wordmaps/{locale}/wordsynonyms/{uuid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMachineAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/machine'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateMachineAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/machine'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRbaSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/rba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateRbaSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/rba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSmartCredentialSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/smartcredential'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSmartCredentialSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/smartcredential'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTempAccessCodeSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/tempaccesscode'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateTempAccessCodeSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/settings/tempaccesscode'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSubscriberUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/subscribers'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetThemeUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/subscribers/resettheme'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSubscriberUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/subscribers/update'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },applicationsForCurrentUserUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/api/v1/user/applications'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserApplicationByIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/user/applications/{appId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/users/{userid}/applications`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserSPRoleAssociationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/users/{userid}/serviceprovider/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserSPRoleAssociationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/api/v1/users/{userid}/serviceprovider/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },registerAdConnectorUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adconnector/register'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listADConnectorDirectoriesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adconnectordirectories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createADConnectorDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adconnectordirectories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getADConnectorDirectoryUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/adconnectordirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["details"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateADConnectorDirectoryUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/adconnectordirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteADConnectorDirectoryUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/adconnectordirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },heartbeatUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adconnectorheartbeat'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getManagementAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/agents/management/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateManagementAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/agents/management/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSiemAgentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/agents/siem/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSiemAgentUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/agents/siem/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAdminApiSharedSecretUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/adminapi/regenerate/self'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAuxAttributesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/auxattributes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listOidcApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOidcApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listClaimsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc/claims'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOidcClaimUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc/claims'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteOidcClaimUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc/claims'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },generateOidcClientSecretUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc/generateclientsecret'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listScopesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/oidc/scopes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOidcApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOidcApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeOidcApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserPortalApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/portal/user'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUserPortalApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/portal/user'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSamlApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSamlApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },generateSecretUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml/generatesecret'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },parseSamlMetadataUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml/metadata'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testRegexUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml/regextest'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },validateSamlAttributeWithRegexUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["attributeValue","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/saml/validatesamlattributewithregex'
        + '?' + ["attributeValue"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSamlApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSamlApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSamlApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSiemSyslogApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/siem/syslog'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSiemSyslogApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/siem/syslog'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetSiemSyslogApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/siem/syslog/reset'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSiemSyslogApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/siem/syslog/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSiemSyslogApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/siem/syslog/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSiemSyslogApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/siem/syslog/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeApplicationUserOAuthTokensUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/useroauthtokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listArchivedAuditsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/archivedaudits'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getArchivedAuditForDownloadUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/archivedaudits/{uuid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByApplicationAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/async/reports/authenticationapplicationcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByApplicationAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/reports/authenticationapplicationcount/{id}/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByApplicationAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/reports/authenticationapplicationcount/{id}/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByAuthenticatorAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/async/reports/authenticationauthenticatorcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByAuthenticatorAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/reports/authenticationauthenticatorcount/{id}/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByAuthenticatorAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/reports/authenticationauthenticatorcount/{id}/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedTokenPageAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/async/tokenspaged/assigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedTokenPageAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/async/tokenspaged/unassigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedTokenPageAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/tokenspaged/{id}/assigned/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedTokenPageAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/tokenspaged/{id}/assigned/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedTokenPageAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/tokenspaged/{id}/unassigned/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedTokenPageAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/async/tokenspaged/{id}/unassigned/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listIdentityProvidersForVerificationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/identityproviders/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAuthenticationInformationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/information'
        + '?' + ["applicationId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },magicLinkAuthenticateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["magicToken","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/magic'
        + '?' + ["magicToken"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },otpVoiceResponseUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/authentication/otp/voice/{responseId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/xml',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSubscriberPublicLoginUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/subscribers/public'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },loginToApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["applicationId","Authorization","orgId","relayStateId","acsUrlId","requestTime"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/authentication/users/applications/{applicationId}/login`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["orgId","relayStateId","acsUrlId","requestTime"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },loginToOidcApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["applicationId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/authentication/users/applications/{applicationId}/oidclogin`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },validateUnauthenticatedUserPasswordUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/users/password/validate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },passwordResetQueryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/users/passwordreset'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },passwordResetChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["authenticator","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/authentication/users/passwordreset/authenticate/{authenticator}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },passwordResetAuthenticateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["authenticator","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/authentication/users/passwordreset/authenticate/{authenticator}/complete`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },passwordResetCompleteUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/users/passwordreset/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewAuthenticationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/users/renew'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userAuthenticatorScopesUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/authentication/users/scopes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAzureDirectoriesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/azureDirectories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createAzureDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/azureDirectories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAzureChinaConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/azureDirectories/chinaconfig'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAzureGlobalConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/azureDirectories/globalconfig'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAzureUsGovConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/azureDirectories/usgovconfig'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAzureDirectoryUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAzureDirectoryUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAzureDirectoryUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },reauthAzureDirectoryUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}/accessToken`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },forceSyncAzureDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}/forceSync`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAzureDirectorySyncStatusInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}/sync`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },syncAzureDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/azureDirectories/{id}/sync`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsAssignEntrustSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/entrustsofttokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsAssignGoogleSTBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/googlesofttokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsAssignGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/grids/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsAssignPasswordBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/passwords/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllAssignTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/assign/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initAssignTokensBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/assign/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignTokensBulkSampleDataUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/assign/tokens/bulksample'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAssignTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/tokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startAssignTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/tokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAssignTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteAssignTokensBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsAssignTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/assign/tokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllCreateTenantBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/create/tenant'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelCreateTenantBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/create/tenant/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllDeleteEnrollmentsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDeleteEnrollmentsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/enrollments/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteEnrollmentsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/enrollments/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllDeleteGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initDeleteGridsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelDeleteGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/grids/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startDeleteGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/grids/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDeleteGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDeleteGridsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/grids/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllDeleteGroupBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initDeleteGroupsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelDeleteGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/groups/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startDeleteGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/groups/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDeleteGroupBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDeleteGroupsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/groups/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllDeleteTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initDeleteTokensBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/delete/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelDeleteTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/tokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startDeleteTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/tokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDeleteTokensBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDeleteTokensBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/tokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/delete/users/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportEnrollmentsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/import/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportEnrollmentsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/import/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportEnrollmentsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/enrollments/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportEnrollmentsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/enrollments/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportEnrollmentsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/enrollments/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportEnrollmentsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/enrollments/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportEnrollmentsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/enrollments/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/grids/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/groups/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportTokensBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/hardwaretokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsIdentityGuardBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/identityguard/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllImportSmartCardsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/import/smartcards'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initImportSmartCardsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/import/smartcards'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelImportSmartCardsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/smartcards/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startImportSmartCardsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/smartcards/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getImportSmartCardsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/smartcards/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteImportSmartCardsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/smartcards/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportUserGroupsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/usergroups/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsImportUsersBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/import/users/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllIssueMFPBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/mfp/issue'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelIssueMFPBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/issue/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteIssueMFPBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/issue/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsIssueMFPBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/issue/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllRevokeMFPBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/mfp/revoke'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelRevokeMFPBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/revoke/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteRevokeMFPBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/revoke/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsRevokeMFPBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/mfp/revoke/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllPrintEnrollmentsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/print/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelPrintEnrollmentsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/print/enrollments/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePrintEnrollmentsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/print/enrollments/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsDeleteEnrollmentsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/print/enrollments/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllProvisionScimBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/provision/scim'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelProvisionScimBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/provision/scim/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },syncOnDemandBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/provision/scim/sync/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteScimProvisioningBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/provision/scim/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsProvisionScimBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/provision/scim/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllResetTokenBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/reset/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initResetTokenBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/reset/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelResetTokenBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/reset/tokens/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startResetTokenBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/reset/tokens/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResetTokenBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/reset/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteResetTokenBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/reset/tokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsResetTokenBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/reset/tokens/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllSetGridBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/grid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initSetGridBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/grid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelSetGridBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/grid/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startSetGridBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/grid/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSetGridBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/grid/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSetGridBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/grid/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsSetGridBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/grid/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllSetRegistrationBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initSetRegistrationBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelSetRegistrationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/registration/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startSetRegistrationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/registration/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSetRegistrationBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/registration/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSetRegistrationBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/registration/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsSetRegistrationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/registration/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllSetVerificationBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initSetVerificationBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/set/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelSetVerificationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/verification/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startSetVerificationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/verification/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSetVerificationBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/verification/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSetVerificationBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/verification/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsSetVerificationBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/set/verification/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAllUnassignedGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/unassigned/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },initUnassignedGridsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/bulkoperations/unassigned/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelUnassignedGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/unassigned/grids/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startUnassignedGridsBulkOperationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/unassigned/grids/start/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUnassignedGridsBulkOperationDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/unassigned/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUnassignedGridsBulkOperationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/unassigned/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewCredentialsUnassignedGridsBulkOperationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/bulkoperations/unassigned/grids/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listCAsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createEDCCAUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/edc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEDCCAUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/edc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEDCCAUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/edc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },edcCaCertInfoUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/edc/{id}/cacerts`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEDCCAPIVContentSignerCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/edc/{id}/pivcontentsigner/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createExternalPKIaaSCAUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/external-pkiaas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createMSCAUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/ms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSCAUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/ms/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateMSCAUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/ms/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },msCaCertInfoUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/ms/{id}/cacerts`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSCAPIVContentSignerCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/ms/{id}/pivcontentsigner/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSCATestUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/ms/{id}/test`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createPKIaaSCAUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/pkiaas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePKIaaSCAConfigurationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/config/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmbeddedPKIaaSCASupportedUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/pkiaas/supported/embedded'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getExternalPKIaaSCASupportedUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/pkiaas/supported/external'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSCAUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePKIaaSCAUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSCACertInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}/cacerts/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createPKIaaSCAPIVContentSignerUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}/pivcontentsigner`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSCAPIVContentSignerCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}/pivcontentsigner/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSCATestUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/pkiaas/{id}/test`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listTrustedCAsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/trusted'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTrustedCAUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/trusted'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },refreshTrustedCAsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/cas/trusted/refresh'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTrustedCAUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/trusted/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateTrustedCAUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/trusted/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeTrustedCAUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/trusted/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeCAUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/cas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },validateSSLCertificateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/certificate/validate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCertificatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/certificates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },exportCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/certificates/{id}/cert/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },processCertificateCSRResponseUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/certificates/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createCertificateCSRUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/certificates/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteCertificateCSRUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/certificates/{id}/csr`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendCustomerSupportEmailUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/customersupport/email'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDCCertificatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/dccerts'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },enrollDomainControllerCertUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/dccerts/enroll'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },enrollDomainControllerCertReqUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/dccerts/enrollreq'
        + ''
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDCCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/delete`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDCCertificateDetailsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/details`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },exportDCCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/export/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },holdDCCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/hold`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeDCCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/revoke`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unholdDCCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/dccerts/{id}/unhold`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDesktopClientByUserUuidUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/desktops/users/{userUuid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDesktopClientByIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/desktops/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteDesktopClientUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/desktops/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createDigitalIdConfigCertTemplateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigcerttemplates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDigitalIdConfigCertTemplateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigcerttemplates/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDigitalIdConfigCertTemplateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigcerttemplates/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeDigitalIdConfigCertTemplateUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigcerttemplates/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDigitalIdConfigsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigs'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createEDCDigitalIdConfigUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigs/edc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEDCDigitalIdConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/edc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEDCDigitalIdConfigUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/edc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createMSDigitalIdConfigUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigs/ms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSDigitalIdConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/ms/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateMSDigitalIdConfigUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/ms/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createPKIaaSDigitalIdConfigUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigs/pkiaas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSDigitalIdConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/pkiaas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePKIaaSDigitalIdConfigUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/pkiaas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeDigitalIdConfigUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createDigitalIdConfigSubjectAltNameUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigsubjectaltnames'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDigitalIdConfigSubjectAltNameUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigsubjectaltnames/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDigitalIdConfigSubjectAltNameUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigsubjectaltnames/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeDigitalIdConfigSubjectAltNameUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigsubjectaltnames/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listEDCDigitalIdConfigTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigtemplates/edc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEDCDigitalIdConfigTemplateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigtemplates/edc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listMSDigitalIdConfigTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigtemplates/ms'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMSDigitalIdConfigTemplateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigtemplates/ms/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listPKIaaSDigitalIdConfigTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigtemplates/pkiaas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSDigitalIdConfigTemplateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigtemplates/pkiaas/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createDigitalIdConfigVariableUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/digitalidconfigvariables'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDigitalIdConfigVariableUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateDigitalIdConfigVariableUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeDigitalIdConfigVariableUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/digitalidconfigvariables/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectorySSLCertificatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/certificates`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },exportDirectoryServerSSLCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/sslcert/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/test`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listDirectoryCommonsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/directorycommons'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSubscriberEntitlementBundledFeaturesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/entitlement/bundle/features'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEntitlementBundlesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/entitlement/bundles/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getServiceBundleUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/entitlements/bundle/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getServiceBundlesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/entitlements/bundles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },upgradegatewayUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/gateways/upgrade/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getLatestGatewayVersionUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/gateways/version'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listGatewaySSLCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/gateways/{id}/certificate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listHighAvailabilityGroupsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/highavailabilitygroups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createHighAvailabilityGroupUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/highavailabilitygroups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getHighAvailabilityGroupUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/highavailabilitygroups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateHighAvailabilityGroupUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/highavailabilitygroups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteHighAvailabilityGroupUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/highavailabilitygroups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addHighAvailabilityItemUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/highavailabilitygroups/{id}/item`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },idpAuthFlowCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/idp/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },idpAuthFlowBeginUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["idpId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/idp/start'
        + '?' + ["idpId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },idpAuthFlowServiceProviderBeginUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/idp/start/sp'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getVerificationMessageUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/idp/verification/message'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },idpAuthVerificationFlowBeginUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["idpId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/idp/verification/start'
        + '?' + ["idpId"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getIpListsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/iplists'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createIpListUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/iplists'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getIpListUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/iplists/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateIpListUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/iplists/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteIpListUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","force","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/iplists/{id}/{force}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },emailServerAuthorizeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauth/emailserver/authorize'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },emailServerRedirectUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauth/emailserver/redirect'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },scimRedirectGetUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauth/scim/redirect'
        + '?' + ["params"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },scimRedirectPostUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauth/scim/redirect'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOAuthRoleUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauthroles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOAuthRoleUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/oauthroles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOAuthRoleUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/oauthroles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeOAuthRoleUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/oauthroles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOrganizationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/organizations'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPassthroughDefinitionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createPassthroughDefinitionUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePassthroughDefinitionUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/passthrough/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPassthroughDefinitionByIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/passthrough/{passthroughId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePassthroughDefinitionByIdUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["passthroughId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/passthrough/{passthroughId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/pkiaas'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createReportUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSystemAlertsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/alerts'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },applicationCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/applicationcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/authenticationapplicationcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticationsByAuthenticatorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/authenticationauthenticatorcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authenticatorTypeReportUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/authenticatortypecount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectoryCertificateCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/reports/directorycert/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },directoryCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/directorycount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },gatewayCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/gatewaycount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },highAvailabilityGroupCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/highavailabilitygroupcount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userCountUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reports/usercount'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteReportUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/reports/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelReportUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/reports/{id}/cancel`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },renewReportCredentialsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/reports/{id}/renewcredentials`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },reportsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/reportspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resourceRuleConfigUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/resourcecontexts/config'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listResourceServerApisUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/resourceserverapis'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createResourceServerApiUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/resourceserverapis'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeResourceServerAPIUserOAuthTokensUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverapis/useroauthtokens/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResourceServerApiUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverapis/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateResourceServerApiUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverapis/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeResourceServerApiUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverapis/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateResourceServerApiClientCredentialsGrantUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverapis/{id}/clientcredentialsgrant`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listResourceServerScopesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/resourceserverscopes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createResourceServerScopeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/resourceserverscopes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResourceServerScopeUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverscopes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateResourceServerScopeUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverscopes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeResourceServerScopeUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/resourceserverscopes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listScimProvisionersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scimprovisioners'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createScimProvisionerUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scimprovisioners'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },fetchOidcConfigurationForSCIMUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scimprovisioners/oauth/configuration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getScimProvisionerOAuthUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/oauth/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateScimProvisionerOAuthUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/oauth/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },authorizeScimProvisionerOAuthUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/oauth/{id}/authorize`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getScimProvisionerOAuthInCacheUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/oauth/{id}/{key}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testScimProvisionerConnectionUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scimprovisioners/testconnection'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getScimProvisionerUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateScimProvisionerUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteScimProvisionerUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scimprovisioners/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfCachedActiveSyncDevicesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/activesyncdevices'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfActiveSyncDevicesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/activesyncdevices'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfActiveSyncDevicesUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/activesyncdevices'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeSelfActiveSyncDevicesUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["deviceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/activesyncdevices/{deviceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfAuthenticationsReportUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/authentications'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfDesktopClientsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/desktops'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfDesktopClientByIdUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/desktops/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfDesktopClientUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/desktops/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfStartRegisterFaceUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["registrationFlow","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/face'
        + '?' + ["registrationFlow"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetFaceUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/face/{faceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfStartRenewFaceUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["faceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/face/{faceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfDeleteFaceUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["faceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/face/{faceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfSendFaceActivationEmailUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["faceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/face/{faceid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetFacesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/faces'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignSelfGridUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/grids/assign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },gridSelfChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/grids/challenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSelfGridUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/grids/create'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deliverSelfAssignedGridByEmailUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/grids/{gridid}/email`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfSingleGridExportUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/grids/{gridid}/export`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfGridUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfGridUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/grids/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeSelfGridStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/grids/{id}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetKnowledgeBasedAuthenticatorUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfUpdateKnowledgeBasedAuthenticatorUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfAddKnowledgeBasedAuthenticatorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfDeleteKnowledgeBasedAuthenticatorUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/kba'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetKbaQuestionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/kba/questions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfListMachineAuthenticatorsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/machineauthenticators'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfCreateMachineAuthenticatorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/machineauthenticators'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfDeleteMachineAuthenticatorUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/machineauthenticators/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userPasswordUnlockUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/passwordunlock'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfUserRegistrationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setSelfUserRegistrationCompleteUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/registration/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/settings'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfUserMachineSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/settings/machine'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetUserPasswordSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/settings/password'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSelfSmartCredentialUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/smartcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfSmartCredentialDefinitionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/smartcredentials/definitions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfSmartCredentialUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfSmartCredentialUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },activateSelfSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}/activate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeSelfSmartCredentialStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignSelfSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}/unassign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unblockSelfSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/smartcredentials/{id}/unblock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSelfTokensUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/tokens'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignSelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/tokens/assign'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfTokenUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },completeActivateSelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startActivateSelfTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifySelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/assigned`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeSelfTokenStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },completeRegistrationSelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/registration`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetSelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/reset`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockSelfTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{tokenid}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSelfTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["type","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/self/tokens/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfUserFavoriteApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/user/application/favorite'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfUserUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfUserUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfExtraAttributeUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/extraattributes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSelfContactExtraAttributeChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/extraattributes/challenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSelfContactExtraAttributeCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/extraattributes/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSelfOptionalAttributeUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/optionalattributes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfContactOptionalAttributeChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/optionalattributes/challenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfContactOptionalAttributeCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/optionalattributes/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetUserPassthroughUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendSelfPasswordExpiryNotificationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/password/notify'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },validateSelfUserPasswordUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/password/validate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfGetUserPasswordUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },selfSetUserPasswordUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/passwords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfSystemContactAttributeChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/systemattributes/challenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSelfSystemContactAttributeCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/users/systemattributes/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSelfUserVerificationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/self/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateTenantHostnameUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/serviceprovider/hostname/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOTPProvidersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/otpproviders'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setOTPProvidersUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/otpproviders'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPKIaaSCredentialsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/pkiaascredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPKIaaSCredentialsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/pkiaascredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPlayIntegrityCredentialsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/playintegritycredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPlayIntegrityCredentialsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/playintegritycredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePlayIntegrityCredentialsUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/playintegritycredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listPushCredentialsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/pushcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPushCredentialsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/pushcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listRateLimitingPoliciesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateRateLimitingPolicyUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createRateLimitingPolicyUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },refreshRateLimitingPoliciesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/policies/refresh'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRateLimitingPolicyUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/serviceprovider/ratelimiting/policies/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteRateLimitingPolicyUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/serviceprovider/ratelimiting/policies/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listRateLimitingTenantPoliciesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/tenants/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setRateLimitingTenantPolicyUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/tenants/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteRateLimitingTenantPolicyUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceprovider/ratelimiting/tenants/policies'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },serviceProviderAccountsReportUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/serviceprovider/reports/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },serviceProviderAccountResetMailServerUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/serviceprovider/subscribers/{id}/resetmailserver`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAuthenticatorProvisioningSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/authenticatorprovisioning'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAuthenticatorProvisioningSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/authenticatorprovisioning'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getBlacklistedPasswordsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/blacklistedpasswords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateBlacklistedPasswordsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/blacklistedpasswords'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCorsSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/cors'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateCorsSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/cors'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailServerSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/emailserver'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEmailServerSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/emailserver'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },fetchOidcConfigurationForEmailServerUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/emailserver/oauth/configuration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testCustomEmailServerConnectionUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/emailserver/testconnection'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailServerSettingsInCacheUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/emailserver/{key}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFaceSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/face'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setFaceSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/face'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFIDOSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/fido'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFIDOSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/fido'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGroupAttributeSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/groupattribute'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setGroupAttributeSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/groupattribute'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listGroupPoliciesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGroupPolicyUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAdministratorRoleSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/administratorrole'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGridAuthenticatorSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/gridauthenticator'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMagicLinkSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/magiclink'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPassthroughAuthenticatorSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },reorderGroupPoliciesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/priority'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRegistrationSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeGroupPolicyStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/grouppolicy/state/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/tenant'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getVerificationSettingsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/grouppolicy/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGroupPolicyUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/grouppolicy/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGroupPolicyUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/grouppolicy/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGroupPolicyUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/grouppolicy/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getHttpConnectorPlaceholdersUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/httpconnectors/placeholders'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResultItemsWithIdUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/httpconnectors/resultitems'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testHttpConnectorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/httpconnectors/test'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMagicLinkSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/magiclink'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setMagicLinkSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/magiclink'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileSDKSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/mobilesdk'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setMobileSDKSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/mobilesdk'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteMobileSDKSettingsUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/mobilesdk'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getNotificationSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/notification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateNotificationSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/notification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPassthroughSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPassthroughSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/passthrough'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPasswordAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/password'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePasswordAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/password'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRegistrationSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setRegistrationSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/registration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listRiskEnginesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/riskengine'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createRiskEngineUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/riskengine'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRiskEngineDefnsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/riskengine/definitions'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRiskEngineDynamicRiskFactorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/riskengine/riskfactors'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeRiskEngineStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/riskengine/state/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getRiskEngineUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/riskengine/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateRiskEngineUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/riskengine/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteRiskEngineUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/riskengine/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSDPSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/sdp'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSDPSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/sdp'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserCodeUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/sdp/devicecode'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testSdpConnectionUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/sdp/testconnection'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/tenant'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setTenantSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/tenant'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listTransactionItemsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/items'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateTransactionItemUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/items'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addTransactionItemUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/items'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTransactionItemUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/transaction/items/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listTransactionRulesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/rules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateTransactionRuleUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/rules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addTransactionRuleUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/rules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTransactionRuleUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/settings/transaction/rules/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTransactionRuleTestUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/transaction/ruletest'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserNotificationSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/user/notification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUserNotificationSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/user/notification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserCertAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/usercertauthenticator'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setUserCertAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/usercertauthenticator'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserPortalSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/userportal'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setUserPortalSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/userportal'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getVerificationSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setVerificationSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/verification'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSmartCardUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcards/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifySmartCardUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcards/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSmartCardUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcards/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSmartCardsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/smartcardspaged/unassigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sslAuthFlowCompleteUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/ssl/complete'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sslAuthFlowBeginUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/ssl/start'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSubscriberUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/subscribers/get'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        ...["Authorization"].reduce((acc, key) => ({ ...acc, [key]: parameters[key] }), {})
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSanitizedHTMLUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/subscribers/sanitizehtml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listScheduledTasksUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/tasks/scheduled'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createScheduledTaskUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/tasks/scheduled'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getScheduledTaskUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tasks/scheduled/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateScheduledTaskUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tasks/scheduled/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteScheduledTaskUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tasks/scheduled/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/templates/email'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEmailTemplateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/templates/email'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailTemplatePreviewUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/templates/email/preview'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetEmailVariablesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/templates/email/reset/variables/{locale}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetEmailTemplateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["type","locale","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/templates/email/reset/{type}/{locale}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEmailVariablesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/templates/email/variables'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailVariablesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/templates/email/variables/{locale}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailTemplateVariablesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/templates/email/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEmailTemplateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/templates/email/{type}/{locale}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPreferredOTPProviderUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/otpprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setPreferredOTPProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/otpprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSpIdentityProviderInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/spidentityproviderinfo`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSoftTokenManualActivateContentUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/user/{useruuid}/token/{tokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserOAuthTokenSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/useroauthtokens/settings'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserResourceServerApiScopesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/resourceserverapiscopes`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserOAuthTokensUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/useroauthtokens`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeUserOAuthTokensUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/useroauthtokens`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeUserOAuthTokenUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","oauthusertokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/useroauthtokens/{oauthusertokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFaceByHookUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/webhook/face'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedGridsPageAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/async/gridspaged/assigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedGridsPageAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/async/gridspaged/unassigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedGridsPageAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/gridspaged/{id}/assigned/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedGridsPageAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/gridspaged/{id}/assigned/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedGridsPageAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/gridspaged/{id}/unassigned/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedGridsPageAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/gridspaged/{id}/unassigned/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },auditEventPageAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/async/reports/auditeventspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },auditEventPageAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/reports/auditeventspaged/{id}/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },auditEventPageAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/async/reports/auditeventspaged/{id}/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOnPremDirectoryUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/directories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOnPremDirectoryUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/directories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },usersPagedAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/async/userspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },usersPagedAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/userspaged/{id}/result`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },usersPagedAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/userspaged/{id}/status`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }}}