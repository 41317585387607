//      

/**
 * This file is pulled from the node-ldapjs project
 *
 * https://github.com/joyent/node-ldapjs/blob/ad451edc18d7768c3ddee1a1dd472d2bbafdae5e/lib/dn.js
 *
 * Rev. ad451edc
 *
 * It's been modified to use ES6 classes and Flow Types.
 *
 */

///--- Helpers

function invalidDN(name        ) {
  const e = new Error();
  e.name = 'InvalidDistinguishedNameError';
  e.message = name;
  return e;
}

function isAlphaNumeric(c        )          {
  const re = /[A-Za-z0-9]/;
  return re.test(c);
}

function isWhitespace(c        )          {
  const re = /\s/;
  return re.test(c);
}

///--- API

class RDN {
  attrs = {};
  spLead        ;
  spTrail        ;

  set = function rdnSet(name        , value        , opts                               ) {
    const lname = name.toLowerCase();

    this.attrs[lname] = {
      value: value,
      name: name
    };

    if (opts) {
      Object.keys(opts).forEach((key        ) => {
        if (key !== 'value') {
          this.attrs[lname][key] = opts[key];
        }
      });
    }
  };
}

// Thank you OpenJDK!
export default function parse(name        )     {
  let cur = 0;
  const len = name.length;

  function parseRdn()      {
    const rdn = new RDN();
    let order = 0;

    rdn.spLead = trim();

    while (cur < len) {
      const opts = {
        order
      };

      const attr = parseAttrType();

      trim();

      if (cur >= len || name[cur++] !== '=') {
        throw invalidDN(name);
      }

      trim();

      // Parameters about RDN value are set in 'opts' by parseAttrValue
      const value = parseAttrValue(opts);

      rdn.set(attr, value, opts);

      rdn.spTrail = trim();

      if (cur >= len || name[cur] !== '+') break;

      ++cur;

      ++order;
    }

    return rdn;
  }

  function trim()         {
    let count = 0;

    while (cur < len && isWhitespace(name[cur])) {
      ++cur;
      count++;
    }

    return count;
  }

  function parseAttrType()         {
    const beg = cur;

    while (cur < len) {
      const c = name[cur];

      if (isAlphaNumeric(c) || c == '.' || c == '-' || c == ' ') {
        ++cur;
      } else {
        break;
      }
    }
    // Back out any trailing spaces.
    while (cur > beg && name[cur - 1] == ' ') --cur;

    if (beg === cur) {
      throw invalidDN(name);
    }

    return name.slice(beg, cur);
  }

  function parseAttrValue(opts                                                       )         {
    if (cur < len && name[cur] == '#') {
      opts.binary = true;
      return parseBinaryAttrValue();
    } else if (cur < len && name[cur] == '"') {
      opts.quoted = true;
      return parseQuotedAttrValue();
    } else {
      return parseStringAttrValue();
    }
  }

  function parseBinaryAttrValue()         {
    const beg = cur++;

    while (cur < len && isAlphaNumeric(name[cur])) ++cur;

    return name.slice(beg, cur);
  }

  function parseQuotedAttrValue()         {
    let str = '';

    ++cur; // Consume the first quote

    while (cur < len && name[cur] != '"') {
      if (name[cur] === '\\') {
        cur++;
      }
      str += name[cur++];
    }

    if (cur++ >= len) {
      // no closing quote
      throw invalidDN(name);
    }

    return str;
  }

  function parseStringAttrValue()         {
    const beg = cur;
    let str = '';
    let esc = -1;

    while (cur < len && !atTerminator()) {
      if (name[cur] === '\\') {
        // Consume the backslash and mark its place just in case it's escaping
        // whitespace which needs to be preserved.
        esc = cur++;
      }

      if (cur === len) {
        // backslash followed by nothing
        throw invalidDN(name);
      }
      str += name[cur++];
    }

    // Trim off (unescaped) trailing whitespace and rewind cursor to the end of
    // the AttrValue to record whitespace length.
    for (; cur > beg; cur--) {
      if (!isWhitespace(name[cur - 1]) || esc === cur - 1) {
        break;
      }
    }
    return str.slice(0, cur - beg);
  }

  function atTerminator()          {
    return cur < len && (name[cur] === ',' || name[cur] === ';' || name[cur] === '+');
  }

  const rdns = [];

  // Short-circuit for empty DNs
  if (len === 0) {
    return new DN(rdns);
  }

  rdns.push(parseRdn());

  while (cur < len) {
    if (name[cur] === ',' || name[cur] === ';') {
      ++cur;
      rdns.push(parseRdn());
    } else {
      throw invalidDN(name);
    }
  }

  return new DN(rdns);
}

class DN {
  rdns            ;
  _format        ;

  constructor(rdns            ) {
    this.rdns = rdns ? rdns.slice() : [];
    this._format = {};
  }

  // length = (): number => this.rdns.length;
}
