//      
import * as React from 'react';
import { useSelector } from 'react-redux';

// Entrust Components
// =============================================================================
import ApplicationLoading from 'src/components/ApplicationLoading';
import LockedAccount from 'src/components/App/States/LockedAccount';
import OfflinePage from 'src/components/App/States/Offline';
import SimpleWrapper from 'src/components/Layout/SimpleWrapper';

// Material-UI Components
// =============================================================================
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

// Redux / Router Functions
// ===========================================================================
import { accountIsLoading as getAccountIsLoading, isOffline as getIsOffline } from 'src/ducks';
import {
  accountIsLocked as getAccountIsLocked,
  hostnameCannotBeFound as getHostnameCannotBeFound
} from 'src/ducks/errors';
import { fetchTheme } from 'src/ducks/theme';
import { redirectToRootAccount } from 'src/lib/smart';
import { useActions } from 'src/lib/hooks';

// Flow Types
// ===========================================================================
                                       

              
                      
  

const AccountProvider = ({ children }       ) => {
  const accountIsLoading = useSelector((state       ) => getAccountIsLoading(state));
  const accountIsLocked = useSelector((state       ) => getAccountIsLocked(state.errors));
  const hostnameCannotBeFound = useSelector((state       ) => getHostnameCannotBeFound(state.errors));
  const isOffline = useSelector((state       ) => getIsOffline(state));
  const theme = useSelector((state       ) => state.themes);

  const actions = useActions({ fetchTheme });

  React.useEffect(() => {
    const fetchPublicInfo = async () => {
      if (hostnameCannotBeFound) {
        return redirectToRootAccount();
      }

      if (theme.lastUpdated === null) {
        await actions.fetchTheme();
      }
    };

    fetchPublicInfo();
  }, [theme.lastUpdated]);

  if (accountIsLoading) {
    return (
      <SimpleWrapper>
        <ApplicationLoading />
      </SimpleWrapper>
    );
  }

  if (isOffline) {
    return (
      <MuiThemeProvider>
        <SimpleWrapper>
          <OfflinePage />
        </SimpleWrapper>
      </MuiThemeProvider>
    );
  }

  if (accountIsLocked) {
    return (
      <MuiThemeProvider>
        <SimpleWrapper>
          <LockedAccount />
        </SimpleWrapper>
      </MuiThemeProvider>
    );
  }

  return children;
};

export default AccountProvider;
