const override = '';

const hostname = override || window.location.hostname;
const protocol = window.location.protocol;
const url = hostname.startsWith('http') ? hostname : `${protocol}//${hostname}`;

export default {
  title: 'Entrust',
  description: '',
  hostname: url,
  apiWebManagementUrl: url,
  apiSamlIdpUrl: url + '/api/saml',
  apiOidcOpUrl: url + '/api/oidc',
  apiSmartCredentialUrl: 'https://localhost:8088/api/mobile'
};
