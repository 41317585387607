import Thunk from './middlewares/thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createTransform, persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import rootReducer, { initialDucksState } from './ducks';

let composeEnhancers = null;

// Allow the Redux DevTools Extension in local dev environments and in AWS dev environments.
if (process.env.SHOW_VERSION === 'true' || process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const DuckTransform = createTransform(
  inboundState => {
    const { isLoading, lastUpdated, failed, ...rest } = inboundState;
    return { ...rest };
  },
  null,
  {
    whitelist: ['themes', 'onboarding']
  }
);

const persistConfig = {
  key: 'identity', // the key for to persist the store under
  storage, // the storage adapter
  version: -1,
  whitelist: ['themes', 'onboarding', 'banner', 'authentication'], // only persist the redux states that are needed for the page to load
  debug: process.env.NODE_ENV === 'development',
  transforms: [DuckTransform],
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialDucksState, composeEnhancers(applyMiddleware(Thunk)));

export const persistor = persistStore(store);

export default store;
