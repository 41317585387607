// @flow
import { getSelfRoles } from 'src/api/roles';
import type { GetState, Dispatch } from 'src/ducks';
import type { Permission as FlowPermission, Role } from 'src/api/swagger/api-web-administration-flowtype.js';

import { createSelectorCreator, defaultMemoize } from 'reselect';

// Flow Types
// =============================================================================
export type Permission = {
  actionAdd: boolean;
  actionView: boolean;
  actionEdit: boolean;
  actionRemove: boolean;
};

type ValueOf<T> = T[keyof T];

// "SITE_ADMINISTRATOR" | "ACCOUNT_MANAGER"
type RoleType = ValueOf<Pick<Role, 'roleType'>>;

// "ALL" | "SUBSCRIBERS" | "USERS" ... etc.
export type EntityType = ValueOf<Pick<FlowPermission, 'entityType'>>;

// {
//    ALL: {
//        actionAdd: boolean;
//        actionView: boolean;
//        actionEdit: boolean;
//        actionRemove: boolean;
//    },
//    ... etc.
// }
type RolePermission = {
  [name in EntityType]: Permission;
};

// Override the API Role permissions value so it's a map instead of an array
type UIRole = Omit<Role, 'permissions'> & {
  permissions: RolePermission;
};

type Roles = {
  [role in RoleType]: UIRole;
};

// Action Types
// =============================================================================
export type PermissionState = {
  isLoading: boolean;
  lastUpdated?: number;
  failed: boolean;
  roles: Roles;
};

type Action =
  | { type: 'PERMISSIONS/REQUEST' }
  | { type: 'PERMISSIONS/SUCCESS'; payload: Roles }
  | { type: 'PERMISSIONS/FAILURE' };

// Initial State
// =============================================================================
export const initialState = Object.freeze({
  isLoading: false,
  lastUpdated: null,
  failed: false,
  roles: {}
}) as PermissionState;

// Reducers
// =============================================================================
export default (state: PermissionState = initialState, action: Action): PermissionState => {
  switch (action.type) {
    case 'PERMISSIONS/REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'PERMISSIONS/FAILURE':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: true };

    case 'PERMISSIONS/SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastUpdated: Date.now(),
        failed: false,
        roles: {
          ...action.payload
        }
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const fetchPermissions = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (getState().permissions.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'PERMISSIONS/REQUEST' });

    try {
      const selfRoles = await getSelfRoles();
      const permissions = getAllPermissionsFromUserRole(selfRoles);
      dispatch({ type: 'PERMISSIONS/SUCCESS', payload: permissions });
    } catch {
      dispatch({ type: 'PERMISSIONS/FAILURE' });
    }
  };
};

// Private Selectors - Not exported
// =============================================================================

/**
 * Formats the getSelfRoles REST API response into a more suitable data structure.
 * @param  {Array} userRoles The getSelfRoles API response
 * @return {Object}          The formatted data.
 */
function getAllPermissionsFromUserRole(userRoles: Role[]): Roles {
  const permissionsList = {} as Roles;

  if (!Array.isArray(userRoles)) {
    return permissionsList;
  }

  // Loop over all the users roles
  userRoles.forEach(userRole => {
    const { allRoles, id, name, description, superAdministrator, groupManagement, groupIds, roleType } = userRole;
    const rolePermissions = {} as RolePermission;

    // Loop over all the permissions in the current role
    userRole.permissions.forEach(permission => {
      const { actionType, entityType } = permission;

      // Set default entity permissions
      let entityPermissions: Permission = {
        actionView: false,
        actionAdd: false,
        actionEdit: false,
        actionRemove: false
      };

      // Check if this entityType already exists in the permissions list
      if (entityType in rolePermissions) {
        entityPermissions = rolePermissions[entityType];
      }

      // Update the entity permissions based on the action type
      switch (actionType) {
        case 'ALL':
          entityPermissions = {
            actionView: true,
            actionAdd: true,
            actionEdit: true,
            actionRemove: true
          };
          break;
        case 'VIEW':
          entityPermissions.actionView = true;
          break;
        case 'ADD':
          entityPermissions.actionAdd = true;
          entityPermissions.actionView = true;
          break;
        case 'EDIT':
          entityPermissions.actionEdit = true;
          entityPermissions.actionView = true;
          break;
        case 'REMOVE':
          entityPermissions.actionRemove = true;
          entityPermissions.actionView = true;
          break;
      }

      // Add the entity permissions to the permissions list
      rolePermissions[entityType] = entityPermissions;
    });

    const roleObject: UIRole = {
      allRoles,
      id,
      name,
      description,
      superAdministrator,
      groupManagement,
      groupIds,
      permissions: rolePermissions,
      roleType
    };

    permissionsList[roleType] = { ...roleObject };
  });

  return permissionsList;
}

// Redux Selectors - For computing derived data
// =============================================================================

/**
 * Determine whether or not the user is a Super Administrator for the given role type.
 * @param  {Object}  state    The root Redux Permissions object
 * @param  {String}  roleType The role type to check. ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @return {Boolean}          True if the user is a Super Administrator for the given role type. False otherwise.
 */
export function isSuperAdmin(state: PermissionState, roleType: RoleType): boolean {
  return userHasRole(state, roleType) && state.roles[roleType].superAdministrator;
}

/**
 * Determine if the user is a regular user (ie. has no roles) or if the user is some sort of administrator (ie. has at
 * least 1 role)
 */
export function getIsAdmin(state: PermissionState) {
  return Object.keys(state.roles).length > 0;
}

/**
 * Determine whether or not the user has the specified role assigned to them.
 * @param  {Object} state    The root Redux Permissions object
 * @param  {String} roleType The role type to check. ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @return {Boolean}         True if the user has the role type assigned to them. False otherwise.
 */
export function userHasRole(state: PermissionState, roleType: RoleType): boolean {
  return roleType in state.roles;
}

/**
 * Determine whether or not the user is the specified role.
 *
 * @param {*} state    The root Redux Permissions object
 * @param {*} roleType The role type the role ID belongs to. ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @param {*} id       The ID of the role we want to check.
 * @return {Boolean}   True if the user is the provided role. False otherwise.
 */
export function isRole(state: PermissionState, roleType: RoleType, id: string): boolean {
  return userHasRole(state, roleType) && state.roles[roleType].id === id;
}

/**
 * Determine whether or not the user has at least one of the specified permissions for a role.
 * @param  {Object} state       The root Redux Permissions object
 * @param  {String} roleType    The role type the permissions belong to.
 *                              ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @param  {Array} permissions  An array of permissions to to check.
 * @return {Boolean}            True if the user has at least one of the permissions specified from the permissions
 *                              array. False otherwise.
 */
export function userHasPermissionsForRoleType(
  state: PermissionState,
  roleType: RoleType,
  permissions: string[]
): boolean {
  // Make sure the user has the role type
  if (!userHasRole(state, roleType)) {
    return false;
  }

  const rolePermissions = state.roles[roleType].permissions;

  // If the user has the ALL permission then the user has permissions for everything
  if (rolePermissions.ALL) {
    return true;
  }

  return permissions.some(permission => permission in rolePermissions);
}

/**
 * Create a permission selector that will only recompute the result if the input parameters have changed.
 *
 * The first parameter is the state which is an object. This is the permissions object on the Root Redux state.
 * The second parameter is the roleType which is a string.
 * The third parameter is the entityTypes which is an array. This is the entity types to get permissions for. Need to
 *   compare the contents of the array to determine if the selector should recompute.
 */
const createPermissionSelector = createSelectorCreator(defaultMemoize, (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }
  return a === b;
});

/**
 * Get the permissions for a given entityType
 * @param  {Object} state       The root Redux Permissions object
 * @param  {String} roleType    The role type the permissions belong to.
 *                              ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @param  {Array} entityTypes  The entity types to get permissions for.
 *                              ie. USERS, ROLES, GROUPS, etc.
 * @return {Object}             An object containing the permissions for the given entity types.
 *                              Object are in the form:
 *                               {
 *                                 USERS: {
 *                                   actionView: true | false,
 *                                   actionAdd: true | false,
 *                                   actionEdit: true | false,
 *                                   actionRemove: true | false
 *                                 },
 *                                 GROUPS: {
 *                                   actionView...
 *                                 }
 *                               }
 */
export const getPermissionsByType = createPermissionSelector(
  [
    (state: PermissionState) => state,
    (state: PermissionState, roleType: RoleType) => roleType,
    (state: PermissionState, roleType: RoleType, entityTypes: EntityType[]) => entityTypes
  ],
  (state: PermissionState, roleType: RoleType, entityTypes: EntityType[]): RolePermission => {
    const falsePermissions = {
      actionView: false,
      actionAdd: false,
      actionEdit: false,
      actionRemove: false
    };

    if (!entityTypes || !state || !roleType || !Array.isArray(entityTypes)) {
      throw new Error('getPermissionsByType - missing or invalid parameter');
    }

    // The return object.
    const permissions = {} as RolePermission;

    // Initialize the return object with all false permissions for each entityType passed in.
    entityTypes.forEach(entityType => {
      permissions[entityType] = falsePermissions;
    });

    // Make sure the user has the role we are checking for.
    if (!userHasRole(state, roleType)) {
      return permissions;
    }

    // Get the user's permissions for the role.
    const rolePermissions = state.roles[roleType].permissions;

    // If the user has the ALL permission then the user has permissions for everything
    if (rolePermissions.ALL) {
      entityTypes.forEach(entityType => {
        permissions[entityType] = rolePermissions.ALL;
      });
      return permissions;
    }

    // Check if the user has entityType permissions
    entityTypes.forEach(entityType => {
      if (entityType in rolePermissions) {
        permissions[entityType] = rolePermissions[entityType];
      }
    });

    return permissions;
  }
);

/**
 * Get the role of the current administrator by type
 * @param  {Object} state       The root Redux Permissions object
 * @param  {String} roleType    The role type of the role to return
 *                              ie. ROLE_TYPE_ACCOUNT_MANAGER or ROLE_TYPE_SITE_ADMINISTRATOR
 * @return {Object}             An object containing the role of the current administrator.
 */
export function getRoleByType(state: PermissionState, roleType: RoleType): UIRole | null {
  // Make sure the user has the role we are checking for.
  if (!userHasRole(state, roleType)) {
    return null;
  }

  // Get the user's role.
  return state.roles[roleType];
}
