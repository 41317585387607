import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

// Entrust Components
// =============================================================================
import AuthProvider from 'src/components/App/Providers/AuthProvider';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Layout/PageTitle';
import Redirector from 'src/components/App/Redirector';
import SettingsProvider from 'src/components/App/Providers/SettingsProvider';
import UserProvider, { SoftUserProvider } from 'src/components/App/Providers/UserProvider';

// Material-UI Components
// =============================================================================
import Box from '@material-ui/core/Box';

// External Functions
// =============================================================================
import { filterByBundleFeatureName } from 'src/lib/entitlements';
import { getHighestRankedBundle } from 'src/ducks/bundleFeatures';
import { isFleetOnlyIssuanceTenant } from 'src/ducks/settings';
import t from 'src/i18n';

// Unprotected Routes
// =============================================================================
const LoginPage = React.lazy(() => import('src/routes/entry'));

const SamlAuthentication = React.lazy(() => import('src/routes/saml'));

const SamlReauthentication = React.lazy(() => import('src/routes/saml/reauthentication'));

const OidcAuthentication = React.lazy(() => import('src/routes/oidc'));

const OidcUserCodePage = React.lazy(() => import('src/routes/oidc/UserCodePage'));

const ErrorPage = React.lazy(() => import('src/routes/oidc/ErrorPage'));

const UserCodeStatus = React.lazy(() => import('src/routes/oidc/UserCodeStatusPage'));

const IDPComplete = React.lazy(() => import('src/routes/idp'));

const Logout = React.lazy(() => import('src/routes/entry/Logout'));

const PasswordReset = React.lazy(() => import('src/routes/entry/passwordreset'));

// User Portal Routes
// =============================================================================
const MyActivity = React.lazy(() => import('src/routes/user/activity'));

const MyProfile = React.lazy(() => import('src/routes/user/profile'));

// Subscriber Portal Routes
// =============================================================================
const EditPortalApplication = React.lazy(
  () => import('src/routes/subscriber/resources/applications/components/EditPortalApplication')
);
const CertificateList = React.lazy(
  () => import('src/routes/subscriber/resources/applications/components/Generic/CertificateList')
);

const OidcCustomClaims = React.lazy(
  () => import('src/routes/subscriber/resources/applications/components/OIDC/OidcCustomClaims')
);

const AddResourceRule = React.lazy(() => import('src/routes/subscriber/resources/resource-rules/AddResourceRule'));

const EditResourceRule = React.lazy(() => import('src/routes/subscriber/resources/resource-rules/EditResourceRule'));

const Dashboard = React.lazy(() => import('src/routes/subscriber/dashboard'));

const AddUser = React.lazy(() => import('src/routes/subscriber/members/adduser'));

const Applications = React.lazy(() => import('src/routes/subscriber/resources/applications'));

const AddEditApplication = React.lazy(
  () => import('src/routes/subscriber/resources/applications/components/AddEditApplication')
);

const AttributesList = React.lazy(() => import('src/routes/subscriber/members/attributes'));

const AuthenticationFlow = React.lazy(() => import('src/routes/subscriber/resources/authentication-flows'));

const AddEditAuthenticationFlow = React.lazy(
  () => import('src/routes/subscriber/resources/authentication-flows/AddEditAuthenticationFlow')
);

const BulkOperationsAdd = React.lazy(() => import('src/routes/subscriber/bulkimport/add'));

const BulkOperationsList = React.lazy(() => import('src/routes/subscriber/bulkimport'));

const CertificateAuthorities = React.lazy(() => import('src/routes/subscriber/resources/authorities'));

const CertificateAuthoritiesAddEditEDC = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/AddEditEntrustCA')
);

const CertificateAuthoritiesAddEditMS = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/AddEditMicrosoftCA')
);

const CertificateAuthoritiesAddEditPKIaaS = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/AddEditPKIaaSCA')
);
const CertificateAuthoritiesAddEditPKIaaSExternal = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/AddEditPKIaaSCAExternal')
);

const DCCertificates = React.lazy(() => import('src/routes/subscriber/resources/domaincontrollercerts'));

const Details = React.lazy(() => import('src/routes/subscriber/members/user'));

const DeviceFingerprintAttributes = React.lazy(() => import('src/routes/subscriber/settings/devices'));

const DigitalIdConfigDetailsEDC = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/EditEntrustDigitalId')
);
const DigitalIdConfigDetailsMS = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/EditMicrosoftDigitalId')
);
const DigitalIdConfigDetailsPKIaaS = React.lazy(
  () => import('src/routes/subscriber/resources/authorities/EditPKIaaSDigitalId')
);
const DirectoryAdd = React.lazy(() => import('src/routes/subscriber/resources/directories/AddDirectory'));

const DirectoryEdit = React.lazy(() => import('src/routes/subscriber/resources/directories/EditDirectory'));

const DirectoryList = React.lazy(() => import('src/routes/subscriber/resources/directories'));

const CustomEmailContainer = React.lazy(() => import('src/routes/subscriber/customization/customEmail'));

const Gateways = React.lazy(() => import('src/routes/subscriber/resources/gateways'));

const GroupsList = React.lazy(() => import('src/routes/subscriber/members/groups'));

const Organizations = React.lazy(() => import('src/routes/subscriber/members/organizations'));

const AddEditOrganization = React.lazy(() => import('src/routes/subscriber/members/organizations/AddEditOrganization'));

const HardwareTokens = React.lazy(() => import('src/routes/subscriber/resources/tokens'));

const SmartCards = React.lazy(() => import('src/routes/subscriber/resources/smartcards'));

const GridCards = React.lazy(() => import('src/routes/subscriber/resources/grids'));

const KBA = React.lazy(() => import('src/routes/subscriber/settings/kba'));

const RBA = React.lazy(() => import('src/routes/subscriber/settings/rba'));

const Transactions = React.lazy(() => import('src/routes/subscriber/settings/rba/Transactions'));

const SoftTokenSDK = React.lazy(() => import('src/routes/subscriber/settings/softTokenSDK'));

const UserNotification = React.lazy(() => import('src/routes/subscriber/settings/UserNotification'));

const UserPortalSettings = React.lazy(() => import('src/routes/subscriber/settings/userportal'));

const Reports = React.lazy(() => import('src/routes/subscriber/reports'));

const ScheduledTasks = React.lazy(() => import('src/routes/subscriber/scheduledTasks'));

const ResourceRulesList = React.lazy(() => import('src/routes/subscriber/resources/resource-rules'));

const Printers = React.lazy(() => import('src/routes/trucredential/printers'));

const RolesList = React.lazy(() => import('src/routes/subscriber/members/roles'));

const SCCerts = React.lazy(() => import('src/routes/subscriber/members/user/containers/SCCerts'));

const SettingsAuthenticator = React.lazy(() => import('src/routes/subscriber/settings/authenticators'));

const SettingsGeneral = React.lazy(() => import('src/routes/subscriber/settings/GeneralSettings'));

const CORS = React.lazy(() => import('src/routes/subscriber/settings/configurations/CrossOriginResourceSharing'));

const PasswordBlocklist = React.lazy(() => import('src/routes/subscriber/settings/configurations/PasswordBlocklist'));

const SettingsMobileFlashPass = React.lazy(
  () => import('src/routes/subscriber/settings/configurations/MobileFlashPassSettings')
);
const SettingsNotification = React.lazy(() => import('src/routes/subscriber/settings/configurations/Notification'));

const Webhooks = React.lazy(() => import('src/routes/subscriber/settings/configurations/Webhooks'));

const SettingsRiskEngines = React.lazy(() => import('src/routes/subscriber/settings/risk-engine'));

const AddEditRiskEngines = React.lazy(() => import('src/routes/subscriber/settings/risk-engine/AddEditRiskEngine'));
const AddEditRiskConnectorBasedEngines = React.lazy(
  () => import('src/routes/subscriber/settings/risk-engine/AddEditConnectorBasedRiskEngine.jsx')
);

const RegistrationSettings = React.lazy(() => import('src/routes/subscriber/settings/registration'));

const CustomEmailServer = React.lazy(() => import('src/routes/subscriber/settings/configurations/EmailServer'));

const ActiveSyncAccess = React.lazy(() => import('src/routes/subscriber/settings/configurations/ActiveSyncAccess'));

const GroupPolicySettings = React.lazy(() => import('src/routes/subscriber/settings/groupPolicies'));

const AddEditGroupPolicy = React.lazy(() => import('src/routes/subscriber/settings/groupPolicies/AddEditGroupPolicy'));

const IPLists = React.lazy(() => import('src/routes/subscriber/settings/ip-lists'));

const SmartCredentials = React.lazy(() => import('src/routes/subscriber/resources/smartcredentials'));

const SmartCredentialsAddEdit = React.lazy(
  () => import('src/routes/subscriber/resources/smartcredentials/AddEditScDefn')
);
const Theme = React.lazy(() => import('src/routes/subscriber/customization/theme'));

const Users = React.lazy(() => import('src/routes/subscriber/members'));

const ResourceServers = React.lazy(() => import('src/routes/subscriber/resources/authorization'));

const AddEditResourceServer = React.lazy(
  () => import('src/routes/subscriber/resources/authorization/AddEditResourceServer')
);

const EditClientCredentialsGrant = React.lazy(
  () => import('src/routes/subscriber/resources/authorization/EditClientCredentialsGrant')
);

const IdentityProviders = React.lazy(() => import('src/routes/subscriber/resources/identityproviders'));

const AddEditOidcIdentityProvider = React.lazy(
  () => import('src/routes/subscriber/resources/identityproviders/AddEditOidcIdentityProvider')
);

const AddEditSamlIdentityProvider = React.lazy(
  () => import('src/routes/subscriber/resources/identityproviders/AddEditSamlIdentityProvider')
);

const Provisioners = React.lazy(() => import('src/routes/subscriber/resources/provisioners'));

const AddEditProvisioner = React.lazy(() => import('src/routes/subscriber/resources/provisioners/AddEditProvisioner'));

const AddEditProvisionerAuth = React.lazy(
  () => import('src/routes/subscriber/resources/provisioners/AddEditProvisionerAuth')
);

const Passthrough = React.lazy(() => import('src/routes/subscriber/settings/passthrough'));

const AddEditPassthroughDefinition = React.lazy(
  () => import('src/routes/subscriber/settings/passthrough/AddEditPassthroughDefinition')
);

// Service Provider Routes
// =============================================================================
const ClientCredential = React.lazy(() => import('src/routes/serviceprovider/clientcredential'));

const SupportAndResources = React.lazy(() => import('src/routes/serviceprovider/supportandresources'));

const ManagementPlatform = React.lazy(() => import('src/routes/serviceprovider/managementplatform'));

const OtpProviders = React.lazy(() => import('src/routes/serviceprovider/otpproviders'));

const PKIaaSCredentials = React.lazy(() => import('src/routes/serviceprovider/pkiaascredentials'));

const Tenants = React.lazy(() => import('src/routes/serviceprovider/tenants'));

const PushCredentials = React.lazy(() => import('src/routes/serviceprovider/pushcredentials'));

const RateLimiting = React.lazy(() => import('src/routes/serviceprovider/ratelimiting'));

const AddEditRateLimitingPolicy = React.lazy(
  () => import('src/routes/serviceprovider/ratelimiting/AddEditRateLimitingPolicy')
);

const PlayIntegrityCredentials = React.lazy(() => import('src/routes/serviceprovider/playintegritycredentials'));

// Issuance routes
// ==============================================================================
const CreateCard = React.lazy(() => import('src/routes/trucredential/smartcarddesigner/CreateCard'));

const CredentialDesigner = React.lazy(() => import('src/routes/trucredential/credentialdesigner'));

const AddDesigner = React.lazy(
  () => import('src/routes/trucredential/credentialdesigner/components/AddCredentialDesigner')
);
const CreateDesign = React.lazy(
  () => import('src/routes/trucredential/credentialdesigner/components/CreateCredentialDesign')
);
const Credentials = React.lazy(() => import('src/routes/trucredential/credentials'));

const CreateEnrollment = React.lazy(() => import('src/routes/trucredential/credentials/enrollment'));

const MobileFlashPassDesigns = React.lazy(() => import('src/routes/trucredential/mobileflashpassdesigns'));

const AddEditMobileFlashPassDesign = React.lazy(
  () => import('src/routes/trucredential/mobileflashpassdesigns/AddEditMobileFlashPassDesign')
);
const PrintQueue = React.lazy(() => import('src/routes/trucredential/printqueue'));

const SearchEnrollment = React.lazy(() => import('src/routes/trucredential/credentials/SearchEnrollment'));

const EnrollmentDesignsList = React.lazy(() => import('src/routes/trucredential/enrollmentdesigner'));

const EnrollmentDesigner = React.lazy(() => import('src/routes/trucredential/enrollmentdesigner/EnrollmentDesigner'));

const ExternalDatabases = React.lazy(() => import('src/routes/trucredential/externaldatabases'));

const FieldConnections = React.lazy(() => import('src/routes/trucredential/fieldconnections'));

const SmartCardDesigner = React.lazy(() => import('src/routes/trucredential/smartcarddesigner'));

const FaceAccount = React.lazy(() => import('src/routes/subscriber/settings/FaceAccount'));

// Printer Dashboard top-level route
// ==============================================================================

const PrinterDashboard = React.lazy(() => import('src/routes/printerdashboard/PrinterDashboard'));

const RedirectToSignUp = () => {
  window.location.href = 'https://in.entrust.com/IDaaS/';
  return null;
};

const Routes = props => {
  const { isAuthenticationAccount, isIssuanceAccount, smartLoginEnabled } = props;

  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" exact>
          <LoginPage />
        </Route>

        {/* Unprotected Routes */}
        <Route path="/logout">
          <Logout />
        </Route>

        <Route path="/signup">
          <RedirectToSignUp />
        </Route>

        {/* Need to keep this old route because Entrust Marketing pages link to it */}
        <Route path="/identity/user/useronboard">
          <RedirectToSignUp />
        </Route>

        {isAuthenticationAccount && <Route path="/saml" component={SemiProtectedRoutes} />}
        {isAuthenticationAccount && <Route path="/oidc/usercode" component={OidcUserCodePage} />}
        {isAuthenticationAccount && <Route path="/oidc/usercodestatus" component={UserCodeStatus} />}
        {isAuthenticationAccount && <Route path="/oidc" component={SemiProtectedRoutes} />}
        {isAuthenticationAccount && <Route path="/idp/complete" component={IDPComplete} />}
        <Route path="/usercodestatus" component={UserCodeStatus} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/reset/:userId?" component={PasswordReset} />
        <ProtectedRoutes
          isAuthenticationAccount={isAuthenticationAccount}
          isIssuanceAccount={isIssuanceAccount}
          smartLoginEnabled={smartLoginEnabled}
        />
      </Switch>
    </React.Suspense>
  );
};

const SemiProtectedRoutes = () => (
  <Switch>
    <SoftUserProvider>
      <Route path="/saml/authentication">
        <SamlAuthentication />
      </Route>
      <Route path="/saml/reauthentication">
        <SamlReauthentication />
      </Route>
      <Route path="/oidc/authentication">
        <OidcAuthentication />
      </Route>
    </SoftUserProvider>
  </Switch>
);

const ProtectedRoutes = props => {
  const { isAuthenticationAccount, isIssuanceAccount, smartLoginEnabled } = props;

  const bundleFeatures = useSelector(state => getHighestRankedBundle(state));
  const isFleetOnlyTenant = useSelector(state => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const showMyActivity = useSelector(state => state.settings.self?.userTabConfiguration?.showMyActivities);
  const tier = useSelector(state => state.themes.subscriber.tier);
  const hasFleetManagement = useSelector(state => state.settings.bundles.hasFleetManagement || false);
  const isDirectTenant = useSelector(state => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector(state => state.settings.bundles.directTenantLocked);

  const isResourceServerEnabled =
    bundleFeatures && filterByBundleFeatureName('oAuthV2ApiProtection', bundleFeatures)?.enabled;

  const idpProxyEnabled = bundleFeatures && filterByBundleFeatureName('idpProxy', bundleFeatures)?.enabled;

  const isExternalRiskEngineEnabled =
    bundleFeatures && filterByBundleFeatureName('externalRiskEngine', bundleFeatures)?.enabled;

  const isScimProvisioningEnabled =
    bundleFeatures && filterByBundleFeatureName('scimProvisioning', bundleFeatures)?.enabled;

  // Passthrough is supported using Auth API applications, so using Auth API feature
  const passthroughEnabled = bundleFeatures && filterByBundleFeatureName('authApi', bundleFeatures)?.enabled;

  return (
    <AuthProvider>
      <UserProvider>
        <SettingsProvider>
          <Switch>
            <Route path="/redirect">
              <Redirector />
            </Route>
            <Route
              path="/myprofile/devices"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.myProfile')} {...routeProps}>
                  <MyProfile selectedTab={3} isDirectUrl />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/myprofile"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.myProfile')} {...routeProps}>
                  <MyProfile />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/password"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.myProfile')} {...routeProps}>
                  <MyProfile selectedTab={2} />
                </AuthorizedRouteWrapper>
              )}
            />

            {isAuthenticationAccount && showMyActivity && (
              <Route
                path="/myreports"
                render={routeProps => (
                  <AuthorizedRouteWrapper title={t('breadCrumbs.myActivity')} {...routeProps}>
                    <MyActivity />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {/* Subscriber Portal Routes */}
            <Route
              path="/users"
              exact
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.userList')} {...routeProps}>
                  <Users />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/user/add"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  title={t('breadCrumbs.userAdd')}
                  crumb={t('breadCrumbs.userList')}
                  crumbRoute={'/users'}
                  {...routeProps}>
                  <AddUser />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/user/sc/certs/:scId"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  title={t('breadCrumbs.userDetails')}
                  crumb={t('breadCrumbs.userList')}
                  crumbRoute={'/users'}
                  {...routeProps}>
                  <SCCerts />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/user/:userId/:tab"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  title={t('breadCrumbs.userDetails')}
                  crumb={t('breadCrumbs.userList')}
                  crumbRoute={'/users'}
                  {...routeProps}>
                  <Details />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/user/:userId"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  title={t('breadCrumbs.userDetails')}
                  crumb={t('breadCrumbs.userList')}
                  crumbRoute={'/users'}
                  {...routeProps}>
                  <Details />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/userattributes"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.attributeList')} {...routeProps}>
                  <AttributesList />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/groups"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.groupList')} {...routeProps}>
                  <GroupsList />
                </AuthorizedRouteWrapper>
              )}
            />

            {isAuthenticationAccount && (
              <Route
                path="/organizations"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper title={t('breadCrumbs.organizations')} {...routeProps}>
                    <Organizations />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            <Route
              path="/organizations/add/:tab"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.organizations')}
                  crumbRoute="/organizations">
                  <AddEditOrganization />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/organizations/:id/:tab"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.organizations')}
                  crumbRoute="/organizations">
                  <AddEditOrganization />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/roles"
              render={routeProps => (
                <AuthorizedRouteWrapper title={t('breadCrumbs.roles')} {...routeProps}>
                  <RolesList />
                </AuthorizedRouteWrapper>
              )}
            />

            {!isFleetOnlyTenant && (
              <Route
                path="/rules"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.resourceRules')}
                    crumb={t('breadCrumbs.resourceRuleList')}>
                    <ResourceRulesList />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            <Route
              path="/rules/add/:applicationId"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.resourceRulesAdd')}
                  crumb={t('breadCrumbs.resourceRuleList')}
                  crumbRoute="/rules">
                  <AddResourceRule />
                </AuthorizedRouteWrapper>
              )}
            />

            {!isFleetOnlyTenant && (
              <Route
                path="/rules/edit/:resourceRuleId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.resourceRulesEdit')}
                    crumb={t('breadCrumbs.resourceRuleList')}
                    crumbRoute="/rules">
                    <EditResourceRule />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/rules/clone/:resourceRuleId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.resourceRulesAdd')}
                    crumb={t('breadCrumbs.resourceRuleList')}
                    crumbRoute="/rules">
                    <EditResourceRule isClone />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && isResourceServerEnabled && (
              <Route
                path="/authorization"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.resourceServer.route')}>
                    <ResourceServers />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/authorization/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.resourceServer.add')}
                    crumb={t('breadCrumbs.resourceServer.route')}
                    crumbRoute="/authorization">
                    <AddEditResourceServer />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/authorization/edit/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.resourceServer.edit')}
                    crumb={t('breadCrumbs.resourceServer.route')}
                    crumbRoute="/authorization">
                    <AddEditResourceServer />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/authorization/:id/ccg"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('clientCredentialsGrant.title')}
                    crumb={t('breadCrumbs.resourceServer.route')}
                    crumbRoute="/authorization">
                    <EditClientCredentialsGrant />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/applications"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.applications')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <Applications />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {!isFleetOnlyTenant && (
              <Route
                path="/applications/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.applicationsAdd')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <AddEditApplication isAdd />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/applications/certificates/SAML"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.signingCert')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <CertificateList type="SAML" />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/applications/certificates/OIDC"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.signingCert')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <CertificateList type="OIDC" />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {!isFleetOnlyTenant && (
              <Route
                path="/applications/aaas/:applicationId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.applicationsEdit')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <EditPortalApplication />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/applications/:type/:applicationId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.applicationsEdit')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <AddEditApplication />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/applications/oidcCustomClaims"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.oidcCustomClaims')}
                    crumb={t('breadCrumbs.applicationList')}
                    crumbRoute="/applications">
                    <OidcCustomClaims />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isScimProvisioningEnabled && (
              <Route
                path="/provisioners"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.provisioners')}
                    crumb={t('breadCrumbs.provisioners')}
                    crumbRoute="/provisioners">
                    <Provisioners />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isScimProvisioningEnabled && (
              <Route
                path="/provisioners/oauth/:provisionerId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.provisionerEdit')}
                    crumb={t('breadCrumbs.provisioners')}
                    crumbRoute="/provisioners">
                    <AddEditProvisionerAuth />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isScimProvisioningEnabled && (
              <Route
                path="/provisioners/:type/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.provisionersAdd')}
                    crumb={t('breadCrumbs.provisioners')}
                    crumbRoute="/provisioners">
                    <AddEditProvisioner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isScimProvisioningEnabled && (
              <Route
                path="/provisioners/:provisionerId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.provisionerEdit')}
                    crumb={t('breadCrumbs.provisioners')}
                    crumbRoute="/provisioners">
                    <AddEditProvisioner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && idpProxyEnabled && (
              <Route
                path="/identityproviders"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.identityproviders')}
                    crumb={t('breadCrumbs.identityproviderList')}
                    crumbRoute="/identityproviders">
                    <IdentityProviders />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && idpProxyEnabled && (
              <Route
                path="/identityproviders/oidc/:type/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.identityprovidersAdd')}
                    crumb={t('breadCrumbs.identityproviderList')}
                    crumbRoute="/identityproviders">
                    <AddEditOidcIdentityProvider />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && idpProxyEnabled && (
              <Route
                path="/identityproviders/oidc/:identityProviderId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.identityprovidersEdit')}
                    crumb={t('breadCrumbs.identityproviderList')}
                    crumbRoute="/identityproviders">
                    <AddEditOidcIdentityProvider />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && idpProxyEnabled && (
              <Route
                path="/identityproviders/saml/:type/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.identityprovidersAdd')}
                    crumb={t('breadCrumbs.identityproviderList')}
                    crumbRoute="/identityproviders">
                    <AddEditSamlIdentityProvider />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && idpProxyEnabled && (
              <Route
                path="/identityproviders/saml/:identityProviderId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.identityprovidersEdit')}
                    crumb={t('breadCrumbs.identityproviderList')}
                    crumbRoute="/identityproviders">
                    <AddEditSamlIdentityProvider />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isIssuanceAccount && (
              <Route
                path="/printers"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={
                      hasFleetManagement && tier === 1
                        ? t('breadCrumbs.fleetManagementPrinters')
                        : t('breadCrumbs.printers')
                    }
                    crumb={
                      hasFleetManagement && tier === 1
                        ? t('breadCrumbs.fleetManagementPrintersList')
                        : t('breadCrumbs.printerList')
                    }>
                    <Printers />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && (
              <Route
                path="/printers/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={
                      hasFleetManagement && tier === 1
                        ? t('breadCrumbs.fleetManagementPrinters')
                        : t('breadCrumbs.printers')
                    }
                    crumb={
                      hasFleetManagement && tier === 1
                        ? t('breadCrumbs.fleetManagementPrintersList')
                        : t('breadCrumbs.printerList')
                    }
                    crumbRoute="/printers">
                    <Printers />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isFleetOnlyTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentials"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.credentials')}
                    crumbRoute="/credentials">
                    <Credentials />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            <Route
              path="/authenticationflow"
              exact
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.authenticationFlows')}
                  crumbRoute="/authenticationflow">
                  <AuthenticationFlow />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/authenticationflow/add"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.authenticationFlowsAdd')}
                  crumb={t('breadCrumbs.authenticationFlows')}
                  crumbRoute="/authenticationflow">
                  <AddEditAuthenticationFlow />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/authenticationflow/clone/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.authenticationFlowsClone')}
                  crumb={t('breadCrumbs.authenticationFlows')}
                  crumbRoute="/authenticationflow">
                  <AddEditAuthenticationFlow isClone />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/authenticationflow/edit/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('breadCrumbs.authenticationFlowsEdit')}
                  crumb={t('breadCrumbs.authenticationFlows')}
                  crumbRoute="/authenticationflow">
                  <AddEditAuthenticationFlow />
                </AuthorizedRouteWrapper>
              )}
            />

            {isIssuanceAccount && !isFleetOnlyTenant && (
              <Route
                path="/smartcard"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.smartCardProfiles')}
                    crumb={t('breadCrumbs.smartCardProfiles')}
                    crumbRoute="/smartcard">
                    <SmartCardDesigner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && (
              <Route
                path="/smartcard/create/:cardType"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.createSmartCard')}
                    crumb={t('breadCrumbs.createSmartCard')}
                    crumbRoute="/smartcard">
                    <CreateCard />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && (
              <Route
                path="/smartcard/edit/:id/:cardType"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.createSmartCard')}
                    crumb={t('breadCrumbs.createSmartCard')}
                    crumbRoute="/smartcard">
                    <CreateCard />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && <Route path="/printerdashboard" render={() => <PrinterDashboard />} />}
            {isAuthenticationAccount && (
              <Route
                path="/directories"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.directories')}
                    crumb={t('breadCrumbs.directoriesList')}
                    crumbRoute="/directories">
                    <DirectoryList />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/directories/:type/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.directoriesAdd')}
                    crumb={t('breadCrumbs.directoriesList')}
                    crumbRoute="/directories">
                    <DirectoryAdd />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/directories/:type/:directoryId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.directoriesEdit')}
                    crumb={t('breadCrumbs.directoriesList')}
                    crumbRoute="/directories">
                    <DirectoryEdit />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/gateways"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.gateways')}
                    crumb={t('breadCrumbs.gatewayList')}
                    crumbRoute="/gateways">
                    <Gateways />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            <Route
              path="/webhooks"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('webhooks.pageTitle')}>
                  <Webhooks />
                </AuthorizedRouteWrapper>
              )}
            />

            {isAuthenticationAccount && (
              <Route
                path="/tokens"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.hardwareTokens')}>
                    <HardwareTokens />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/smartcards"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.smartCardList')}>
                    <SmartCards />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/grids"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.gridCards')}>
                    <GridCards />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuth')}
                    crumb={t('breadCrumbs.certAuthList')}>
                    <CertificateAuthorities />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/edc/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthEntrustAdd')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditEDC />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/ms/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthMicrosoftAdd')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditMS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/pkiaas/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthPKIaaSAdd')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditPKIaaS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/pkiaasexternal/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthPKIaaSAdd')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditPKIaaSExternal />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/edc/edit/:authorityId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthEntrustEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditEDC />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/ms/edit/:authorityId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthMicrosoftEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditMS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/pkiaas/edit/:authorityId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthPKIaaSEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditPKIaaS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/pkiaasexternal/edit/:authorityId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuthPKIaaSEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthoritiesAddEditPKIaaSExternal />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/cas/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.certAuth')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <CertificateAuthorities />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/digitalId/edc/:digitalIdConfigId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.digitalIdEdcEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <DigitalIdConfigDetailsEDC />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/digitalId/ms/:digitalIdConfigId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.digitalIdMsEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <DigitalIdConfigDetailsMS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/digitalId/pkiaas/:digitalIdConfigId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.digitalIdPKIaaSEdit')}
                    crumb={t('breadCrumbs.certAuthList')}
                    crumbRoute="/cas">
                    <DigitalIdConfigDetailsPKIaaS />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/sc"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.smartCredDef')}
                    crumb={t('breadCrumbs.smartCredList')}>
                    <SmartCredentials />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/sc/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.smartCredAdd')}
                    crumb={t('breadCrumbs.smartCredList')}
                    crumbRoute="/sc">
                    <SmartCredentialsAddEdit />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/sc/:scId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.smartCredEdit')}
                    crumb={t('breadCrumbs.smartCredList')}
                    crumbRoute="/sc">
                    <SmartCredentialsAddEdit />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && smartLoginEnabled && (
              <Route
                path="/dccerts"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.dcCertsDef')}
                    crumb={t('breadCrumbs.dcCertsList')}
                    crumbRoute="/dccerts">
                    <DCCertificates />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/settings/general"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.general')}>
                  <SettingsGeneral />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/registration/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.registration')}>
                  <RegistrationSettings />
                </AuthorizedRouteWrapper>
              )}
            />

            <Route
              path="/settings/registration"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.registration')}>
                  <RegistrationSettings />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/authenticators/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.authenticators')}>
                  <SettingsAuthenticator />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/authenticators"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.authenticators')}>
                  <SettingsAuthenticator />
                </AuthorizedRouteWrapper>
              )}
            />
            {isAuthenticationAccount && (
              <Route
                path="/settings/grouppolicies"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.groupPolicies')}>
                    <GroupPolicySettings />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/grouppolicies/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.groupPolicies')}
                    crumbRoute="/settings/grouppolicies">
                    <AddEditGroupPolicy />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/grouppolicies/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.groupPolicies')}
                    crumbRoute="/settings/grouppolicies">
                    <AddEditGroupPolicy />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/settings/email"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.email')}>
                    <CustomEmailServer />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/settings/devices"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.deviceFingerPrintAtt')}>
                    <DeviceFingerprintAttributes />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/settings/kba/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.kbaAuth')}>
                    <KBA />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/kba"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.kbaAuth')}>
                    <KBA />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {!isAuthenticationAccount && !isFleetOnlyTenant && !isDirectTenant && (
              <Route
                path="/settings/mobileflashpass"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.mobileFlashPassSettings')}>
                    <SettingsMobileFlashPass />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/settings/notification"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.notification')}>
                  <SettingsNotification />
                </AuthorizedRouteWrapper>
              )}
            />
            {isAuthenticationAccount && (
              <Route
                path="/settings/rba/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.riskAuth')}>
                    <RBA />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/rba"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.riskAuth')}>
                    <RBA />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/softtokensdk/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.softTokenSDK')}>
                    <SoftTokenSDK />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && (
              <Route
                path="/settings/softTokenSDK"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.softTokenSDK')}>
                    <SoftTokenSDK />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/settings/userportal/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('SettingsMenu.userPortal')}>
                  <UserPortalSettings />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/userportal"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('SettingsMenu.userPortal')}>
                  <UserPortalSettings />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/UserNotification"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.userNotification')}>
                  <UserNotification />
                </AuthorizedRouteWrapper>
              )}
            />
            {isAuthenticationAccount && (
              <Route
                path="/settings/transactions"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.transaction')}>
                    <Transactions />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/settings/cors"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.cors')}>
                  <CORS />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/settings/blocklist"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.blocklist')}>
                  <PasswordBlocklist />
                </AuthorizedRouteWrapper>
              )}
            />
            {isAuthenticationAccount && (
              <Route
                path="/settings/activesyncaccess"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.sdp')}>
                    <ActiveSyncAccess />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isExternalRiskEngineEnabled && (
              <Route
                path="/settings/riskengines/connector-based risk engines/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.riskEngines.add')}
                    crumbRoute="/settings/riskengines"
                    crumb={t('breadCrumbs.riskEngines.title')}>
                    <AddEditRiskConnectorBasedEngines />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isExternalRiskEngineEnabled && (
              <Route
                path="/settings/riskengines/:engine/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.riskEngines.add')}
                    crumbRoute="/settings/riskengines"
                    crumb={t('breadCrumbs.riskEngines.title')}>
                    <AddEditRiskEngines />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isExternalRiskEngineEnabled && (
              <Route
                path="/settings/riskengines/:engine/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.riskEngines.edit')}
                    crumbRoute="/settings/riskengines"
                    crumb={t('breadCrumbs.riskEngines.title')}>
                    <AddEditRiskEngines />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isExternalRiskEngineEnabled && (
              <Route
                path="/settings/riskenginescberes/:id"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.riskEngines.edit')}
                    crumbRoute="/settings/riskengines"
                    crumb={t('breadCrumbs.riskEngines.title')}>
                    <AddEditRiskConnectorBasedEngines />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && isExternalRiskEngineEnabled && (
              <Route
                path="/settings/riskengines"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.riskEngines.title')}>
                    <SettingsRiskEngines />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && passthroughEnabled && (
              <Route
                path="/settings/passthrough"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.passthrough.title')}>
                    <Passthrough />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && passthroughEnabled && (
              <Route
                path="/settings/passthrough/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.passthrough.add')}
                    crumb={t('breadCrumbs.passthrough.title')}
                    crumbRoute="/settings/passthrough">
                    <AddEditPassthroughDefinition />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isAuthenticationAccount && passthroughEnabled && (
              <Route
                path="/settings/passthrough/:passthroughId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.passthrough.edit')}
                    crumb={t('breadCrumbs.passthrough.title')}
                    crumbRoute="/settings/passthrough">
                    <AddEditPassthroughDefinition />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {!isFleetOnlyTenant && (
              <Route
                path="/settings/iplists"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.ipLists')}>
                    <IPLists />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {isAuthenticationAccount && (
              <Route
                path="/settings/onfido"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.onfidoAccount')}>
                    <FaceAccount />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}

            {(!isDirectTenant || (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/theme"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.theme')}>
                    <Theme />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/emailTemplates"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.emailTemplates')}>
                  <CustomEmailContainer />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/dashboard"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.dashboard')}>
                  <Dashboard />
                </AuthorizedRouteWrapper>
              )}
            />
            {!isFleetOnlyTenant && (
              <Route
                path="/bulkoperations"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.bulkOperations')}
                    crumb={t('breadCrumbs.bulkOperationList')}>
                    <BulkOperationsList />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {!isFleetOnlyTenant && (
              <Route
                path="/bulkoperations/add"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.bulkOperationsAdd')}
                    crumb={t('breadCrumbs.bulkOperationList')}
                    crumbRoute="/bulkoperations">
                    <BulkOperationsAdd />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {!isFleetOnlyTenant && (
              <Route
                path="/bulkoperations/add/:action/:type"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.bulkOperationsAdd')}
                    crumb={t('breadCrumbs.bulkOperationList')}
                    crumbRoute="/bulkoperations">
                    <BulkOperationsAdd />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            <Route
              path="/reports"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.reports')}>
                  <Reports />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/scheduledTasks"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('tasks.title')}>
                  <ScheduledTasks />
                </AuthorizedRouteWrapper>
              )}
            />

            {/* Service Provider Routes */}
            <Route
              path="/serviceprovider/tenants"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.tenants')}>
                  <Tenants />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/supportandresources"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.supportResources')}>
                  <SupportAndResources />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/otpproviders"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('ServiceProviderMenu.otpProviders')}>
                  <OtpProviders />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/spclientcredentials"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.serviceProviderClientList')}>
                  <ClientCredential />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/ManagementPlatform"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.managementPlatform')}>
                  <ManagementPlatform />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/PKIaaSCredentials"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.pkiaasCredentials')}>
                  <PKIaaSCredentials />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/PlayIntegrityCredentials"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('breadCrumbs.playIntegrityCredentials')}>
                  <PlayIntegrityCredentials />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/PushCredentials"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('ServiceProviderMenu.pushCredentials')}>
                  <PushCredentials />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/ratelimiting/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('ServiceProviderMenu.rateLimiting')}>
                  <RateLimiting />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/serviceprovider/RateLimiting"
              render={routeProps => (
                <AuthorizedRouteWrapper {...routeProps} title={t('ServiceProviderMenu.rateLimiting')}>
                  <RateLimiting />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/ratelimiting/add"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('RateLimiting.breadCrumbs.addPolicy')}
                  crumb={t('ServiceProviderMenu.rateLimiting')}
                  crumbRoute="/serviceprovider/ratelimiting">
                  <AddEditRateLimitingPolicy />
                </AuthorizedRouteWrapper>
              )}
            />
            <Route
              path="/ratelimiting/edit/:id"
              render={routeProps => (
                <AuthorizedRouteWrapper
                  {...routeProps}
                  title={t('RateLimiting.breadCrumbs.editPolicy')}
                  crumb={t('ServiceProviderMenu.rateLimiting')}
                  crumbRoute="/serviceprovider/ratelimiting">
                  <AddEditRateLimitingPolicy />
                </AuthorizedRouteWrapper>
              )}
            />

            {/* Designer Routes - Issuance */}
            {((isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentialdesigns"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('CredentialDesigns.title')}>
                    <CredentialDesigner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentialdesigns/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('AddCredentialDesigns.pageTitle')}
                    crumb={t('CredentialDesigns.title')}
                    crumbRoute="/credentialdesigns">
                    <AddDesigner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentialdesigns/create"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('DesignerScreen.pageTitle')}
                    crumb={t('CredentialDesigns.title')}
                    crumbRoute="/credentialdesigns">
                    <CreateDesign />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentialdesigns/create/:designId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('DesignerScreen.pageTitle')}
                    crumb={t('CredentialDesigns.title')}
                    crumbRoute="/credentialdesigns">
                    <CreateDesign />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentialdesigns/edit/:designId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('DesignerScreen.pageTitle')}
                    crumb={t('CredentialDesigns.title')}
                    crumbRoute="/credentialdesigns">
                    <CreateDesign />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentials/enrollment/create/:workflowId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('Enrollments.create')}
                    crumb={t('breadCrumbs.credentials')}
                    crumbRoute="/credentials">
                    <CreateEnrollment />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentials/enrollment/search/:workflowId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('Enrollments.search')}
                    crumb={t('breadCrumbs.credentials')}
                    crumbRoute="/credentials">
                    <SearchEnrollment />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/credentials/enrollment/edit/:workflowId/:enrollmentId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('Enrollments.edit')}
                    crumb={t('breadCrumbs.credentials')}
                    crumbRoute="/credentials">
                    <CreateEnrollment />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/enrollmentdesigns"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('EnrollmentDesigns.title')}>
                    <EnrollmentDesignsList />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/enrollmentdesign/create"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('EnrollmentDesigns.title')}
                    crumbRoute="/enrollmentdesigns">
                    <EnrollmentDesigner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {((isIssuanceAccount && !isDirectTenant) ||
              (isIssuanceAccount && isDirectTenant && !isDirectTenantLocked)) && (
              <Route
                path="/enrollmentdesign/edit/:workflowId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('EnrollmentDesigns.designTitle')}
                    crumb={t('EnrollmentDesigns.title')}
                    crumbRoute="/enrollmentdesigns">
                    <EnrollmentDesigner />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && (
              <Route
                path="/externaldatabases"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('breadCrumbs.externalDatabases')}
                    crumb={t('breadCrumbs.externalDatabases')}
                    crumbRoute="/externaldatabases">
                    <ExternalDatabases />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && (
              <Route
                path="/fieldconnections/:workflowId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('FieldConnection.pageTitle')}
                    crumb={t('EnrollmentDesigns.title')}
                    crumbRoute="/enrollmentdesigns">
                    <FieldConnections />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {/* Mobile Flash Pass Designer Routes - Issuance */}
            {isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant && (
              <Route
                path="/mobileflashpassdesigns"
                exact
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('MobileFlashPassDesigns.title')}
                    crumbRoute="/mobileflashpassdesigns">
                    <MobileFlashPassDesigns />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant && (
              <Route
                path="/mobileflashpassdesigns/add"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('MobileFlashPassDesigns.add.pageTitle')}
                    crumb={t('MobileFlashPassDesigns.title')}
                    crumbRoute="/mobileflashpassdesigns">
                    <AddEditMobileFlashPassDesign />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && !isDirectTenant && (
              <Route
                path="/mobileflashpassdesigns/edit/:designId"
                render={routeProps => (
                  <AuthorizedRouteWrapper
                    {...routeProps}
                    title={t('MobileFlashPassDesigns.edit.pageTitle')}
                    crumb={t('MobileFlashPassDesigns.title')}
                    crumbRoute="/mobileflashpassdesigns">
                    <AddEditMobileFlashPassDesign />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {isIssuanceAccount && !isFleetOnlyTenant && (
              <Route
                path="/printqueue"
                render={routeProps => (
                  <AuthorizedRouteWrapper {...routeProps} title={t('PrintJobs.title')}>
                    <PrintQueue />
                  </AuthorizedRouteWrapper>
                )}
              />
            )}
            {/* No matching route. Redirect to My Profile page */}
            <Route path="/" render={() => <Redirect to="/myprofile" />} />
          </Switch>
        </SettingsProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export const AuthorizedRouteWrapper = ({ match: { path }, title, children, crumb, crumbRoute, ...rest }) => {
  crumb = crumb || title;
  crumbRoute = crumbRoute || path;

  return (
    // This overrides the justify column center on the body so that pages aren't rendered in the middle like the login screen
    <Box marginBottom="auto">
      <PageTitle title={title} crumb={crumb} crumbRoute={crumbRoute} />
      {React.cloneElement(children, { ...rest })}
    </Box>
  );
};

export default Routes;
