//      
import { parsePhoneNumber, isSupportedCountry } from 'libphonenumber-js';

/**
 * This file contains common validation methods for react-final-form
 *
 * https://github.com/final-form/react-final-form
 *
 * If the validation rule you are adding is specific to a particular form then
 * add the validation rule to that file, do NOT add it here.
 *
 * All form validation rules defined here MUST have unit tests.
 */

import { isEmpty } from 'src/lib/smart';
import {
  BLOCK_HTML,
  MATCH_REPLACE_CHARS,
  NO_DUPLICATE_CHARS,
  INVALID_DIRECTORY_GROUP_FILTER,
  VALID_CIDR_IP,
  VALID_CIDR_IPV6,
  VALID_CLIENT_SECRET,
  VALID_DOMAIN,
  VALID_HOSTNAME,
  VALID_HOSTNAME_OR_IP,
  VALID_HTTPS_URI_REGEX,
  VALID_IP,
  VALID_LOCALHOST,
  VALID_LOCALHOST_URI,
  VALID_NATIVE_URL,
  VALID_SCHEME,
  VALID_URI_WITH_WILDCARD_PORT_REGEX,
  VALID_URL_REGEX,
  VALID_LOCALHOST_URI_WITH_WILDCARD,
  VALID_VERSION,
  validateRegex
} from './regex';
import parse from 'src/lib/dn';
import t, { keyLookup } from 'src/i18n';

/**
 * Utility function to compose multiple validations together.
 *
 * @param  {...any} validators
 */
export const composeValidators =
  (...validators                                                                 ) =>
  (value      , allValues        , meta        ) =>
    validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined);

/**
 * Utility function for determining if a value is null or undefined.
 *
 * @param {*} value
 */
export const isNullOrUndefined = (value      )          => value === null || value === undefined;

/**
 *  Validates that the input has a value.
 *  TODO: Textfields always pass in strings.  Check other components.
 *
 * @export
 * @param {?any} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function required(value      )          {
  return isEmpty(value) ? t('errors.isRequired') : undefined;
}

/**
 * Same as the required validation but allows you to pass in whether or not the field is required.
 * This covers scenarios where a field might be required on an Add operation but not on Edit.
 *
 * @export
 * @param {boolean} req Whether or not the field is required.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function isRequired(req         )                           {
  return function (value         )          {
    return req ? required(value) : undefined;
  };
}

/**
 * Validate that the input does not match any existing values.  Performs a case-insensitive search.
 *
 * @export
 * @param {Array<string>} existingValues An array containing the existing values to check against.
 * @returns {(value: string) => ?string} The react-final-form validate function.
 */
export function isUnique(existingValues               )                             {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    value = value.toLocaleLowerCase().trim();

    // Check if value matches any of the existing values.
    const exists = existingValues.find(ev => ev.toLocaleLowerCase().trim() === value) !== undefined;

    return exists ? t('errors.isUnique') : undefined;
  };
}

/**
 * TODO: Getting values by index doesn't work. If you delete an element in the middle of the list, all the elements
 * after the deleted element keep the same name/index so this validation function fails.
 *
 * Validate that the input does not match any existing values.  This function accepts a path to the form values object
 * for looking up the current values.
 *
 * This function expects a value to be passed in.  It should be used in conjunction with the required function.
 *
 * @export
 * @param {string} name The path to the form values
 * @returns {(value: string) => ?string} The react-final-form validate function.
 */
export function isUniqueByRef(name        ) {
  return function (value        , values        )          {
    // name should be in the form of a.b[x].c
    const start = name.indexOf('[');
    const end = name.indexOf(']');

    // Extract the parent from the name.
    // If name is a.b[x].c then:
    //    - parentKey is "a.b"
    //    - child is "c".
    const parentKey = name.substring(0, start);
    const child = name.substring(end + 2);

    // Get the array from the current form values (this is the array we want to check for uniqueness)
    const parent = keyLookup(values, parentKey);

    // parent is not an array. Likely developer error so throw an exception.
    if (!Array.isArray(parent)) {
      throw new Error('invalid parent key ' + parentKey);
    }

    // Iterate over the array and get the child value that we want to verify is unique
    const existingValues = parent.map(item => keyLookup(item, child)).filter(item => item !== null);

    // If index and lastIndex of a value are different, then it is not unique in the array.
    return existingValues.indexOf(value) === existingValues.lastIndexOf(value) ? undefined : t('errors.isUnique');
  };
}

/**
 * Validates that the length of an input is less than a certain length.
 *
 * @export
 * @param {number} maxLength The maximum length of the input value.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function maxLength(maxLength        )                              {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    // Get the length of the value.
    const length = value.trim().length;

    return length > maxLength ? t('errors.isMaxLength', maxLength) : undefined;
  };
}

/**
 * Validates that the length of the user code input is less than a certain length excluding '-' .
 *
 * @export
 * @param {number} maxLength The maximum length of the input value.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function userCodeMaxLength(maxLength        )                              {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    // Get the length of the value.
    const length = value.replace(/-/g, '').trim().length;

    return length > maxLength ? t('errors.isMaxLengthUserCode', maxLength) : undefined;
  };
}

/**
 * Validates that the form value is before a given date.
 *
 * @export
 * @param {Date} endDate The end date to validate against
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export function isBefore(endDate      )                              {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    return new Date(value) > endDate ? t('errors.isBefore', endDate.toDateString()) : undefined;
  };
}

/**
 * Validates that the form value is after a given date.
 *
 * @export
 * @param {Date} startDate The start date to validate against
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export function isAfter(startDate      )                              {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    return new Date(value) < startDate ? t('errors.isAfter', startDate.toDateString()) : undefined;
  };
}

/**
 * Validates that the length of an input is greater than a certain length.
 *
 * If you're passing 1 to this function then you're using this validation incorrectly.
 * Use "required" instead.
 *
 * @export
 * @param {number} minLength The minimum length of the input value.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function minLength(minLength        )                           {
  return function (value         )          {
    // Check if the value is null or undefined
    if (!value) {
      return undefined;
    }

    // Get the length of the value.
    const length = String(value).trim().length;

    return length < minLength ? t('errors.isMinLength', minLength) : undefined;
  };
}

/**
 * Validates that the input value is a number between the supplied min and max values.
 *
 * Note that this validation function expects that you are parsing the value as a Number.
 *
 * @export
 * @param {Array<number>} limits The array containing the min and max values [min, max].
 * @returns {(value: number) => ?string} The react-final-form validate function.
 */
export function isBetween(limits               )                                       {
  return function (value                  )          {
    const [min, max] = limits;

    // Check if the value is null or undefined
    if (isEmpty(value)) {
      return undefined;
    }

    return Number.isNaN(value) || value < min || value > max
      ? t('errors.isBetween', min.toLocaleString(), max.toLocaleString())
      : undefined;
  };
}

/**
 * Validates that the input is a valid hostname or IPv4 Address.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidHostnameOrIP(value         )          {
  if (!value) {
    return undefined;
  }

  return !VALID_HOSTNAME_OR_IP.test(value) ? t('errors.isValidHostnameOrIP') : undefined;
}

/**
 * Validates that the input is a valid hostname or IP address.
 * This function also allows leading wildcard hostnames.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidDNSOrWildCard(value         )          {
  if (!value) {
    return;
  }

  // if the value starts with a wildcard, replace it with a non-wildcard to match the regex
  value = value.replace(/^\*\./, 'a.');

  return isValidHostname(value) ? t('errors.validDNSOrWildCard') : undefined;
}

/**
 * Validates that the input is a valid IPv4 Address.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidIP(value         )          {
  if (!value) {
    return undefined;
  }

  return !VALID_IP.test(value) ? t('errors.validIp') : undefined;
}

/**
 * Validates that the input is a valid hostname.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidHostname(value         )          {
  if (!value) {
    return undefined;
  }

  return !VALID_HOSTNAME.test(value) ? t('errors.isValidHostname') : undefined;
}

/**
 * Validates that the input is a valid group filter.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidDirectoryGroupFilter(value         )          {
  if (!value) {
    return undefined;
  }

  return INVALID_DIRECTORY_GROUP_FILTER.test(value) ? t('errors.invalidDirectoryGroupFilter') : undefined;
}
/**
 * Validates that the input is a valid IPv4 or CIDR Address.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidCidrIp(value         )          {
  return !VALID_CIDR_IP.test(value) ? t('errors.isValidCidrIp') : undefined;
}

/**
 * Validates that the input is a valid IPv4, IPv6 or CIDR Address.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidIpv4Ipv6Cidr(value         )          {
  return !(VALID_CIDR_IP.test(value) || VALID_CIDR_IPV6.test(value))
    ? t('errors.isValidIpv4Ipv6Cidr', value)
    : undefined;
}

/**
 * Validates that the input is a valid domain name.  This is used to validate the domain value
 * when creating tenant accounts.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidDomainName(value         )          {
  return !VALID_DOMAIN.test(value) ? t('errors.isValidDomainName') : undefined;
}

/**
 * Validates that the input is a valid origin, both http and https
 * Port number are optional and wildcard(*) is allowed.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidOrigin(value         )          {
  return !VALID_URI_WITH_WILDCARD_PORT_REGEX.test(value) && !VALID_LOCALHOST_URI_WITH_WILDCARD.test(value)
    ? t('errors.isValidOrigin')
    : undefined;
}

/**
 * Validates that the input is a valid hostname, IP or localhost.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidHost(value         )          {
  return !VALID_HOSTNAME_OR_IP.test(value) && !VALID_LOCALHOST.test(value)
    ? t('Applications.validationMessages.isValidHostNameOrIp')
    : undefined;
}

/**
 * Validates that the input is a valid redirect URL: http, https and localhost
 * Port number and paths must be explicitly defined
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidRedirect(value         )          {
  return !VALID_LOCALHOST_URI.test(value) && !VALID_URL_REGEX.test(value) ? t('errors.isValidRedirect') : undefined;
}

/**
 * Validates that the input is a valid version.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isVersion(value         )          {
  return !VALID_VERSION.test(value) ? t('dfp.validationMessages.errorMsgChangeThresholdVersion') : undefined;
}

/**
 * Validates that the input values matches the supplied value. The value must be a string.
 * This validation function performs a "loose" equality check that is case-insensitive and
 * the values are trimmed of whitespace.
 *
 * @export
 * @param {string} expected The value that the input should match.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function equalsValue(expected        )                           {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    return value.trim().toLocaleLowerCase() !== expected.trim().toLocaleLowerCase()
      ? t('errors.equals', expected)
      : undefined;
  };
}

/**
 * Validates the the input values matches the supplied value. Unlike the equalsValue validation, this validation
 * performs a strict equality check of the input value against the expected value.
 *
 * @export
 * @param {(string | boolean | number)} expected  The value that the input should match.
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function equals(expected                           )                           {
  return function (value         )          {
    return value !== expected ? t('errors.equals', String(expected)) : undefined;
  };
}

/**
 * A helper function that wraps the parse DN validation function.  This is a utility function that returns a boolean.
 * If you want to validate a DN in a form then use the isValidDN function above.
 * This validation accepts empty values as valid.  If you need that to not be the case then use this validation function
 * in conjunction with the "required" validation function.
 *
 * @export
 * @param {string} defaultCountry The default phone number country.
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export function isValidPhoneNumber(defaultCountry         = '')                           {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    try {
      const isValidCountry = isSupportedCountry(defaultCountry);

      if (!isValidCountry) {
        defaultCountry = '';
      }

      // https://github.com/catamphetamine/libphonenumber-js#parsephonenumberfromstringstring-defaultcountry
      const phoneNumber = parsePhoneNumber(value, defaultCountry);

      if (!phoneNumber.isValid()) {
        return t('errors.isValidPhoneNumber');
      }

      return undefined;
    } catch (error) {
      return t('errors.isValidPhoneNumber');
    }
  };
}

/**
 * Validates that the input value is a valid Distinguished Name. If no value is passed in then the DN validation will be
 * skipped and the value will be considered valid.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidDN(value         )          {
  if (!value) {
    return undefined;
  }

  return !validDN(value) ? t('Directories.errors.isValidDN') : undefined;
}

/**
 * A helper function that wraps the parse DN validation function.  This is a utility function that returns a boolean.
 * If you want to validate a DN in a form then use the isValidDN function above.
 *
 * @export
 * @param {string} value The value being validated
 * @returns {boolean} Whether or not the value is a valid DN.
 */
export function validDN(value        )          {
  try {
    parse(value);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Validates that the input value contains no spaces.
 *
 * @export
 * @param {string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function noSpaces(value         )          {
  if (!value) {
    return undefined;
  }

  return /\s/.test(value) ? t('errors.noSpaces') : undefined;
}

/**
 * Validates that the input value matches the Regular Expression.
 *
 * @export
 * @param {string} regex The Regular Expression to match the form value to.
 * @param {string} error The error message to display.
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export function matchesRegex(regex        , error        )                           {
  return function (value         )          {
    if (!value) {
      return undefined;
    }

    return !regex.test(value) ? error : undefined;
  };
}

/**
 * Validates that the input value is a valid email address. If no value is passed in then the email address validation
 * will be skipped and the value will be considered valid.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidEmail(value         )          {
  if (!value) {
    return undefined;
  }
  return !validEmail(value) ? t('errors.isValidEmail') : undefined;
}

/**
 * A helper function the does the actual email address validation.  This is a utility function that returns a boolean.
 * If you want to validate an email in a form then use the isValidEmail function above.
 *
 * @param {string} email The value being validated.
 * @param {boolean} allowSurroundingWhitespace True if we should ignore leading and trailing whitespace. Defaults to false.
 * @returns {boolean} Whether or not the value is a valid email address.
 */
export function validEmail(email        , allowSurroundingWhitespace          = false)          {
  // These comments use the following terms from RFC2822:
  // local-part, domain, domain-literal and dot-atom.
  // Does the address contain a local-part followed an @ followed by a domain?
  // Note the use of lastIndexOf to find the last @ in the address
  // since a valid email address may have a quoted @ in the local-part.
  // Does the domain name have at least two parts, i.e. at least one dot,
  // after the @? If not, is it a domain-literal?
  // This will accept some invalid email addresses
  // BUT it doesn't reject valid ones.
  if (allowSurroundingWhitespace) {
    email = email.trim();
  }

  const atSym = email.lastIndexOf('@');

  if (atSym < 1) {
    return false;
  } // no local-part

  if (atSym === email.length - 1) {
    return false;
  } // no domain

  if (atSym > 64) {
    return false;
  } // there may only be 64 octets in the local-part

  if (email.length - atSym > 255) {
    return false;
  } // there may only be 255 octets in the domain

  if (email.startsWith('.')) {
    return false;
  } // emails can't start with "."

  if (email.includes('..')) {
    return false;
  } // emails can't contain ".."

  if (email.startsWith(' ')) {
    return false;
  } // emails can't start with a white-space

  if (email.startsWith('@')) {
    return false;
  } // emails can't start with ampersand

  const localPart = email.substring(0, atSym);

  const escapedLocalParts = localPart.split('"');

  if (escapedLocalParts.length === 1) {
    if (escapedLocalParts[0].includes(' ')) {
      return false;
    } // The local-part can't contain a space without any wrapping quotes

    if (escapedLocalParts.find(x => x.includes('@'))) {
      return false;
    } // The local-part can't contain an unescaped "@"
  }

  // Is the domain plausible?
  const lastDot = email.lastIndexOf('.');

  // Check if it is a dot-atom such as example.com
  if (lastDot > atSym + 1 && lastDot < email.length) {
    return true;
  }

  // Check if could be a domain-literal.
  if (email.charAt(atSym + 1) === '[' && email.charAt(email.length - 1) === ']') {
    return true;
  }

  return false;
}

/**
 * Validates that the input value is a valid https URI. If no value is passed in then the URI validation
 * will be skipped and the value will be considered valid.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const isValidHttpsUri = (value         )          => {
  if (!value) {
    return undefined;
  }
  return !VALID_HTTPS_URI_REGEX.test(value) ? t('errors.isValidHttpsUri') : undefined;
};

/**
 * Validates that the input is a valid URI, supports both http and https
 * No port and wildcard allowed
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidUri(value         )          {
  return !VALID_URL_REGEX.test(value) ? t('email.form.isValidUri') : undefined;
}

/**
 * Validates that the input is a valid URI, supports both http and https
 * No port and wildcard allowed. If no value is passed in then the URI validation
 * will be skipped and the value will be considered valid.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidUriAcceptEmpty(value         )          {
  if (!value) {
    return undefined;
  }
  return !VALID_URL_REGEX.test(value) ? t('email.form.isValidUri') : undefined;
}

/**
 * Validates that the input value is a valid https or localhost URI. If no value is passed in then the URI validation
 * will be skipped and the value will be considered valid.
 *
 * @export
 * @param {?string} value the form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const isValidOidcUri = (value         )          => {
  if (!value) {
    return undefined;
  }

  return !VALID_HTTPS_URI_REGEX.test(value) && !VALID_LOCALHOST_URI.test(value)
    ? t('errors.isValidHttpsUri')
    : undefined;
};

/**
 * Validates that the input value is a valid URI allowing for custom url schemes.
 *
 * @export
 * @param {?string} value the form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const isValidNativeUri = (value         )          => {
  return !VALID_NATIVE_URL.test(value) ? t('errors.isValidNativeUri') : undefined;
};

/**
 * Validates that the input is a valid URI, supports http, https and localhost.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidHttpHttpsUri(value         )          {
  return !VALID_URL_REGEX.test(value) && !VALID_LOCALHOST_URI.test(value) ? t('email.form.isValidUri') : undefined;
}

/**
 * Validates that the input is a valid export name containing only alphanumeric, underscore, and hyphen characters.
 *
 * @param {string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const isValidExportName = (value         )          => {
  if (!value) return undefined;

  return VALID_CLIENT_SECRET.test(value) ? undefined : t('errors.invalidExportName');
};

/**
 * A helper function to validate if a name is a valid SAM account name i.e. DOMAIN\username
 *
 * @param {string} name The value being validated.
 * @returns {boolean} Whether or not the value is a valid email address.
 */
export function validSamAccountName(name        )          {
  const backSlashSym = name.lastIndexOf('\\');

  if (backSlashSym <= 0 || backSlashSym == name.length - 1) {
    return false;
  }

  const domainPart = name.substring(0, backSlashSym);
  const usernamePart = name.substring(backSlashSym + 1);

  return VALID_DOMAIN.test(domainPart) && isValidNetBiosUserName(usernamePart);
}

/**
 * A helper function to validate if a name is a valid NETBIOD user name i.e. usernames used by windows.
 *
 * @param {string} value The value being validated.
 * @returns {boolean} Whether or not the value is a valid email address.
 */
export function isValidNetBiosUserName(value        )          {
  // NETBIOS validation
  // http://www.rlmueller.net/CharactersEscaped.htm
  const netbiosUserNameForbiddenChars = '\\/:*?";|=,+<>[]@ ';
  const forbiddenChars = netbiosUserNameForbiddenChars.split('');

  for (let i = 0; i < forbiddenChars.length; i++) {
    if (value.indexOf(forbiddenChars[i]) > -1) {
      return false;
    }
  }

  return true;
}

/**
 * Validates that the input is greater than the min.
 *
 * @param {number} value The value being validated.
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export function isGreaterThan(min        )                                       {
  return function (value                  )          {
    // Check if the value is null or undefined
    if (isEmpty(value)) {
      return undefined;
    }

    return isNaN(value) || value <= min ? t('errors.isGreaterThan', min.toLocaleString()) : undefined;
  };
}

/**
 * Validates that the input value does not contain duplicate characters
 *
 * @param {?string} value The value being validated.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const noDuplicateCharacters = (value         ) => {
  // Check if the value is null or undefined
  if (isEmpty(value)) {
    return undefined;
  }

  return NO_DUPLICATE_CHARS.test(value) ? undefined : t('errors.noDuplicateCharacters');
};

/**
 * Validates that the input value not contain replaceable characters e.g. B, G, I, L, O, S, U, Z
 * @param {?string} value The value being validated
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const noReplaceCharacters = (value         ) => {
  // Check if the value is null or undefined
  if (isEmpty(value)) {
    return undefined;
  }

  return !MATCH_REPLACE_CHARS.test(value) ? undefined : t('auth.validationMessages.noReplaceCharacters');
};

export const noHTML = (value         )          => {
  if (isEmpty(value)) {
    return undefined;
  }

  return !BLOCK_HTML.test(value) ? t('authFIDO.error.htmlInName') : undefined;
};

/**
 *  Validates that the input value is a valid scheme.
 *
 * @param {?string} value The value being validated
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const isValidScheme = (value         ) => {
  // Check if the value is null or undefined
  if (isEmpty(value)) {
    return undefined;
  }

  return VALID_SCHEME.test(value) ? undefined : t('auth.validationMessages.validScheme');
};

/**
 * Validates the input string for identical values after splitting with a given delimiter.
 *
 * @export
 * @param {string} delimiter A delimiter to be used for splitting the input string.
 * @param {string} value The value being validated
 * @returns {(value: ?any) => ?string} The react-final-form validate function.
 */
export function hasDuplicateSplitString(delimiter         )                              {
  return (value         )          => {
    if (!value) {
      return undefined;
    }

    const duplicates = value.split(delimiter).filter(x => x !== '');
    // Check to see if index and last index of an entry is the same. If they are the entry is unique.
    const checkDuplicates = duplicates.some(item => {
      return duplicates.indexOf(item) !== duplicates.lastIndexOf(item);
    });

    return checkDuplicates ? t('errors.duplicate') : undefined;
  };
}

/**
 * Validates that the input is less than the max.
 *
 * @param {number} max The maximum value.
 * @returns {(value: ?string) => ?string} The react-final-form validate function.
 */
export const isLessThan = (max        )                                         => {
  return function (value                  )          {
    // Check if the value is null or undefined
    if (isEmpty(value)) {
      return undefined;
    }

    return isNaN(value) || value >= max ? t('authSmart.error_variableValidNumberMax') : undefined;
  };
};

/**
 * Validates that the all character restrictions cannot be Not Allowed.
 *
 * @export
 * @param {Object} restrictionFields The restrictions object.
 * @returns {(value: ?string, values: Object) => ?string} The react-final-form validate function.
 */
export const characterRestrictionAreValid = (
  restrictionFields        
)                                                => {
  return function (value         , values        )          {
    const digits = values[restrictionFields.digits];
    const lower = values[restrictionFields.lower];
    const upper = values[restrictionFields.upper];
    const special = values[restrictionFields.special];
    return digits === 'NOT_ALLOWED' && lower === 'NOT_ALLOWED' && upper === 'NOT_ALLOWED' && special === 'NOT_ALLOWED'
      ? t('SmartCredentials.validationMessages.characterRestrictionAreValid')
      : undefined;
  };
};

/**
 * Validates that the value is less than the amount of REQUIRED characters.
 *
 * @export
 * @param {Object} restrictionFields The restrictions object.
 * @returns {(value: ?number, values: Object) => ?string} The react-final-form validate function.
 */
export const isValidMaxRestrictionLength = (
  restrictionFields        
)                                                => {
  return function (value         , values        )          {
    let length = 0;
    length += values[restrictionFields.digits] === 'REQUIRED' ? 1 : 0;
    length += values[restrictionFields.lower] === 'REQUIRED' ? 1 : 0;
    length += values[restrictionFields.upper] === 'REQUIRED' ? 1 : 0;
    length += values[restrictionFields.special] === 'REQUIRED' ? 1 : 0;
    return value && value < length ? t('SmartCredentials.validationMessages.isValidMaxRestrictionLength') : undefined;
  };
};

/**
 *  Validates that if variable is not Modifiable, either Generate or Default Value must be set.
 *
 * @export
 * @param {boolean} value The form value.
 * @param {Object} values The form values object.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const modifiableIsValid = (value         , values        )          => {
  return !(value || values.defaultValue || values.generate)
    ? t('SmartCredentials.validationMessages.modifiableIsValid')
    : undefined;
};

/**
 *  Validates that the variable must be Displayable if it is Modifiable.
 *
 * @export
 * @param {boolean} value The form value.
 * @param {Object} values The form values object.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export const modifiableAndDisplayable = (value         , values        )          => {
  return value && !values.displayable ? t('SmartCredentials.validationMessages.modifiableAndDisplayable') : undefined;
};

export const validateRegexFinalForm = (value        )          => {
  return validateRegex(value) ? undefined : t('SAMLAttributes.testForm.regexFormat');
};

/**
 * Validates that the input url has valid placeholders.
 *
 * @export
 * @param {?string} value The form value.
 * @returns {?string} The error message if the value fails validation or undefined otherwise.
 */
export function isValidPlaceholder(value         )          {
  if (!value) {
    return undefined;
  }

  const placeholderRegex = /\{\{(USER|REQ|AUTH|ENV)\.([a-zA-Z0-9_]+)\}\}/g;

  const placeholders = value.match(placeholderRegex);

  if (!placeholders) {
    const invalidPlaceholder = /\{\{.*?\}\}/.test(value);
    return invalidPlaceholder ? t('httpconnector.form.inValidPlaceholder') : undefined;
  }

  for (const placeholder of placeholders) {
    const [, key, field] = placeholder.match(/\{\{(USER|REQ|AUTH|ENV)\.([a-zA-Z0-9_]+)\}\}/);
    if (key === 'ENV' && field !== 'clientIp') {
      return t('httpconnector.form.inValidENVPlaceholder');
    }
  }

  return undefined;
}
