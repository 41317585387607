//      
import { EXCEPTION_NOT_LOGGED_IN, EXCEPTION_EXPIRED_SESSION } from 'src/lib/api-error-messages';

// Flow types
                                                    

// Action Types
// =============================================================================
                                   
                     
                                   
                              
                                    
                     
                              
                         
                               
                    
                         
                               
                      
                          
                  
  

                                      
                                   
                              
                                    
                      
                              
                               
                    
                     
                   
                         
                               
                      
                          
  

             
                                                             
                                                        
                                                          
                                          
                                            
                                             
                           
                                   
                                    
                                                  
                                                   
                                                                                    
                                             
                                               
                                                           
                                                      
                                                  
                                                    
                                                       
                                                                

// Initial State
// =============================================================================
export const initialState                      = {
  expiryTime: 0,
  idpAuthenticationFailed: false,
  isAuthenticatedJwt: false,
  isSystemAuthenticatedJwt: false,
  jwt: null,
  machineNonce: null,
  organizationId: '',
  sequenceNonce: null,
  startTime: 0,
  updateProfile: false,
  updateProfileTimeout: 0,
  isStepAuth: false,
  usedSmartLogin: false,
  verifier: ''
};

// Reducers
// =============================================================================
export default (state                      = initialState, action        )                      => {
  switch (action.type) {
    case 'UPDATE_AUTHFLOW': {
      return { ...state, ...action.payload };
    }
    case 'SET_MACHINE_NONCE':
      return { ...state, machineNonce: action.payload };
    case 'SET_SEQUENCE_NONCE':
      return { ...state, sequenceNonce: action.payload };
    case 'CLEAR_TOKEN': {
      return { ...state, jwt: null, isAuthenticatedJwt: false, isSystemAuthenticatedJwt: false };
    }
    case 'CLEAR_MACHINE_NONCE':
      return { ...state, machineNonce: null };
    case 'CLEAR_SEQUENCE_NONCE':
      return { ...state, sequenceNonce: null };
    case 'CLEAR_VERIFIER':
      return { ...state, verifier: '' };
    case 'SET_TOKEN': {
      return { ...state, jwt: action.payload, isAuthenticatedJwt: false };
    }
    case 'SET_AUTHENTICATED_TOKEN': {
      return { ...state, jwt: action.payload, isAuthenticatedJwt: true };
    }
    case 'RENEW_TOKEN': {
      return { ...state, jwt: action.payload };
    }
    case 'UPDATE_TOKEN': {
      return { ...state, jwt: action.payload };
    }
    case 'SET_SYSTEM_AUTHENTICATED': {
      return { ...state, isSystemAuthenticatedJwt: action.payload };
    }
    case 'SET_SESSION_TIME': {
      return { ...state, startTime: action.payload.startTime, expiryTime: action.payload.expiryTime };
    }
    case 'SET_UPDATE_PROFILE_TIMEOUT': {
      return { ...state, updateProfileTimeout: action.payload };
    }
    case 'SET_USED_SMART_LOGIN': {
      return { ...state, usedSmartLogin: action.payload };
    }
    case 'SET_STEP_UP_AUTH': {
      return { ...state, isStepAuth: action.payload };
    }
    case 'SET_ORGANIZATION_ID': {
      return { ...state, organizationId: action.payload };
    }
    case 'REMOVE_ORGANIZATION_ID': {
      return { ...state, organizationId: '' };
    }
    case 'SET_IDP_AUTHENTICATION_FAILED': {
      return { ...state, idpAuthenticationFailed: action.payload };
    }
    default:
      return state;
  }
};

// Actions
// =============================================================================

export const setStepUpAuth = (enabled         ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_STEP_UP_AUTH', payload: enabled });
  };
};

export const clearJWT = () => {
  return (dispatch          ) => {
    dispatch({ type: 'CLEAR_TOKEN' });
  };
};

export const setToken = (token        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_TOKEN', payload: token });
  };
};

export const setAuthenticatedToken = (token        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_AUTHENTICATED_TOKEN', payload: token });
  };
};

export const renewToken = ({ token, time, expires }        ) => {
  return (dispatch          , getState          ) => {
    const jwt = getState().authentication.jwt;

    dispatch({
      type: 'RENEW_TOKEN',
      payload: { ...jwt, token, time, expires }
    });
  };
};

export const updateToken = (token        ) => {
  return (dispatch          , getState          ) => {
    const jwt = getState().authentication.jwt;

    dispatch({ type: 'UPDATE_TOKEN', payload: { ...jwt, token } });
  };
};

export const setSystemAuthenticated = (isSystemAuthenticated         ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_SYSTEM_AUTHENTICATED', payload: isSystemAuthenticated });
  };
};

export const setMachineNonce = (machineNonce        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_MACHINE_NONCE', payload: machineNonce });
  };
};

export const clearMachineNonce = () => {
  return (dispatch          ) => {
    dispatch({ type: 'CLEAR_MACHINE_NONCE' });
  };
};

export const setSequenceNonce = (sequenceNonce        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_SEQUENCE_NONCE', payload: sequenceNonce });
  };
};

export const clearSequenceNonce = () => {
  return (dispatch          ) => {
    dispatch({ type: 'CLEAR_SEQUENCE_NONCE' });
  };
};

export const setIdleSession = (startTime        , expiryTime        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_SESSION_TIME', payload: { startTime, expiryTime } });
  };
};

export const setUpdateProfileTimeout = (updateProfileTimeout        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_UPDATE_PROFILE_TIMEOUT', payload: updateProfileTimeout });
  };
};
export const setUsedSmartLogin = (smartLoginUsed         ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_USED_SMART_LOGIN', payload: smartLoginUsed });
  };
};

export const setOrganizationId = (organizationId        ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_ORGANIZATION_ID', payload: organizationId });
  };
};

export const deleteOrganizationId = () => {
  return (dispatch          ) => {
    dispatch({ type: 'REMOVE_ORGANIZATION_ID' });
  };
};

export const setIdpAuthenticationFailed = (success         ) => {
  return (dispatch          ) => {
    dispatch({ type: 'SET_IDP_AUTHENTICATION_FAILED', payload: success });
  };
};

// Selector
// =============================================================================
export const selectUserId = (state                     ) => {
  const jwt = state.jwt;
  return jwt ? jwt.userId : '';
};

export const getIsLoggedIn = (state                     ) => {
  const isAuthenticatedJwt = state.isAuthenticatedJwt;

  const jwt = state.jwt;

  const expiryTime = state.expiryTime;

  try {
    if (!isAuthenticatedJwt || !jwt) {
      throw new EXCEPTION_NOT_LOGGED_IN();
    }

    // Check if it's still valid.
    if (expiryTime < Date.now()) {
      throw new EXCEPTION_EXPIRED_SESSION();
    }

    return true;
  } catch (error) {
    return false;
  }
};
