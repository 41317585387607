export default {adminauth: {authenticateAdminApiUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adminapi/authenticate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},applications: {listApplicationInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applicationinfo'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAdminApiApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/adminapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createAdminApiApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/adminapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAdminApiSharedSecretUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/regenerate/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAdminApiApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAdminApiApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeAdminApiApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listApplicationTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/templates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},enrollmentoperations: {updateEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/enrollment/api'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/enrollment/api'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/enrollment/api'
        + ''
      );

      const headers = {
        'Accept': '*/*',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readEnrollments: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/enrollment/api/readEnrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEnrollmentsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteEnrollmentsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/enrollments'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readEnrollmentsDesignNames: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v2/enrollments/enrollmentDesigns'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readEnrollmentsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["enrollmentDesignName","offset","limit","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/enrollments/read/{enrollmentDesignName}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["offset","limit"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createEnrollmentsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["enrollmentDesignName","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/enrollments/{enrollmentDesignName}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readEnrollmentV2: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/enrollments/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},mobileflashpassapi: {getIssueMFPBulkOperationDetails: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/mobileFlashPass'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },issueMobileFlashPassBulkOperation: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/mobileFlashPass'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelMobileFlashPassBulkOperation: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadMFPBulkOperationLogs: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/download/logs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFlashPassDownloadURI: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/downloadUri/{walletType}/{enrollmentId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadAppleMobileFlashPass: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/downloads/apple/{enrollmentId}/{claimToken}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/vnd.apple.pkpass',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadGoogleMobileFlashPass: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/downloads/google/{enrollmentId}/{claimToken}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getMobileFlashPassRequestDetails: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/mobileFlashPass/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },issueMobileFlashPassBulkOperationV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/mobileFlashPass'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadMFPLogsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/mobileFlashPass/download/logs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/octet-stream',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},printapi: {getPrintEnrollmentsBulkOperationDetails: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/print'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printEnrollmentsBulkOperation: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/print'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelPrintEnrollmentsBulkOperation: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/print/cancel/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadPrintEnrollmentsBulkOperationLogs: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/print/download/logs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },singleEnrollmentPrint: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/print/single'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrintRequestDetails: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/print/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printEnrollmentsBulkOperationV2: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/print'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },downloadPrintEnrollmentsLogsV2: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/print/download/logs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},basicprint: {getPrintCounts: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/printcounts'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrintStatus: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/print/{printStatusId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelPrint: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printStatusId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/print/{printStatusId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePrint: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printStatusId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/print/{printStatusId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PATCH',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },print: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}/print`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},printers: {readPrinters: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/printers'
        + '?' + ["fields","withStatus"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createPrinter: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/printers'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },printersSummary: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/printers/summary'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readPrinter: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },replacePrinter: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePrinter: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readPrinterPreferences: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}/preferences`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },replacePrinterPreferences: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}/preferences`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePrinterWithTenantId: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","tenantId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/printers/{printerId}/{tenantId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrinters: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v2/printers'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readPrinterByDeviceId: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/printers/{deviceId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},printqueue: {getPrintJobs: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/prints'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPaginatedPrintJobs: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/prints/paginated'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPrintJob: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/prints/{printJobId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deletePrintJob: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printJobId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/prints/{printJobId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },cancelAllActivePrintJobs: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["printerId","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/prints/{printerId}/cancelAllActivePrints`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},roles: {listSiteRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSiteRoleUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/roles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserSiteRoleAssociationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserSiteRoleAssociationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserSiteRoleAssociationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},tenants: {getSpIdentityProviderUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/spidentityprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setSpIdentityProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/spidentityprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCreateTenantAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/tenants/{id}/createresult`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCreateTenantAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/tenants/{id}/createstatus`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTenantUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenants'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEntitlementUsageInfoUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenants/entitlements/usage'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeTenantUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },lockTenantUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}/lock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockTenantUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantEntitlementsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setTenantEntitlementUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tenantid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantEntitlementUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTenantEntitlementUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tenantid","type","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenantspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTenantAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v5/async/tenants'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},webhooks: {getWebhooksUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/webhooks'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createWebhookUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/webhooks'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testWebhookUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/test/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readWebhookUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateWebhookUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteWebhookUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }}}