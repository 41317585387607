// Import redux components
import Provider from 'react-redux/es/components/Provider';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'src/store';

// Import the router
import { HashRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

// import the application
import App from 'src/components/App/app';
import ApplicationLoading from 'src/components/ApplicationLoading';
import MessageBox from 'src/components/MessageBox';

const AppLoader = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={<ApplicationLoading />} persistor={persistor}>
          <HashRouter>
            <App />

            <MessageBox />
          </HashRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default AppLoader;
