// Material-UI Components
// =============================================================================
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloudOffIcon from '@material-ui/icons/CloudOff';

// External Functions
// =============================================================================
import t from 'src/i18n';

const useStyles = makeStyles({
  icon: {
    fontSize: 100,
    margin: 24,
    width: 72,
    height: 72
  }
});

const Offline = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" margin={1}>
      <CloudOffIcon className={classes.icon} htmlColor={grey[900]} />
      <div>
        <h1>{t('internalErrors.offline')}</h1>
        <p>{t('internalErrors.offlineRefresh')}</p>
      </div>
    </Box>
  );
};

export default Offline;
