//      
                                                    

// Action Types
// =============================================================================
                                                                                          

                                          

                                                                                      

// Initial State
// =============================================================================
export const initialState               = [];

// Reducers
// =============================================================================
export default (state               = initialState, action        )               => {
  state.pop;
  switch (action.type) {
    case 'MESSAGES/ADD':
      return state.concat(action.payload);
    case 'MESSAGES/CLOSE':
      return state.slice(1);
    default:
      return state;
  }
};

// Actions
// =============================================================================

/**
 * Add a message to be displayed in the snackbar
 * @param {String} message Adds a message to the list of messages to be displayed. The message must already be
 *                         translated, neither this Duck nor the MessageBox component will perform any message
 *                         translations.
 */
export const addMessage = (message        ) => {
  return (dispatch          , getState          ) => {
    const payload = { isError: false, message };

    // Prevent adding duplicate messages in the list
    if (getState().messages.some(x => x.message === payload.message)) {
      return null;
    }
    dispatch({ type: 'MESSAGES/ADD', payload });
  };
};

/**
 * Add a message to be displayed in the snackbar
 * @param {String} message Adds a message to the list of messages to be displayed. The message must already be
 *                         translated, neither this Duck nor the MessageBox component will perform any message
 *                         translations.
 *
 * @param {String} action Adds an action label to the MessageBox component that will be displayed alongside the
 *                        message. The action label must be already translated.
 *
 * @param {String} value  The value provided alongside the action label that will be used a paramter for the onActionClick
 *                        callback in the MessageBox component
 */
export const addMessageAction = (message        , action        , redirectUrl        ) => {
  return (dispatch          , getState          ) => {
    const payload = { isError: false, message, action, redirectUrl };

    // Remove adding duplicate messages in the list
    if (getState().messages.some(x => x.message === payload.message)) {
      return null;
    }
    dispatch({ type: 'MESSAGES/ADD', payload });
  };
};

/**
 * Add an API message to be displayed in the snackbar
 * @param {String} message Adds an API error message to the list of messages to be displayed. The message must
 *                         already be translated, neither this Duck nor the MessageBox component will perform
 *                         any message translations.
 */
export const addErrorMessage = (message        ) => {
  return (dispatch          , getState          ) => {
    const payload = { isError: true, message };

    // Remove adding duplicate messages in the list
    if (getState().messages.some(x => x.message === payload.message)) {
      return null;
    }
    dispatch({ type: 'MESSAGES/ADD', payload });
  };
};

/**
 * Close the current message being displayed by removing it from the message list.
 * @return {[type]} [description]
 */
export const closeMessage = () => {
  return { type: 'MESSAGES/CLOSE' };
};

// Selectors
// =============================================================================

/**
 * Get the next message in the messages list.  If there are no more messages then null is returned.
 * @param  {Object} messages The root Redux Messages object
 * @return {String}          The next message or null if there are no more messages.
 */
export const getNextMessage = (messages              )          => {
  if (messages.length > 0) {
    return messages[0].message;
  }
  return null;
};

/**
 * Get the next message action in the messages list.  If there are no more messages then null is returned.
 * @param  {Object} messages The root Redux Messages object
 * @return {String}       The next message or null if there are no more messages.
 */
export const getNextMessageAction = (messages              )          => {
  if (messages.length > 0) {
    return messages[0].action;
  }
  return null;
};

/**
 * Get the next message action value in the messages list.  If there are no more messages then null is returned.
 * @param  {Object} messages The root Redux Messages object
 * @return {String}       The next message or null if there are no more messages.
 */
export const getNextMessageRedirectURL = (messages              )          => {
  if (messages.length > 0) {
    return messages[0].redirectUrl;
  }
  return null;
};

/**
 * Determine if the next message in the messages list is an error.  If there are no more messages then false is
 * returned.
 * @param  {Object}  messages The root Redux Messages object
 * @return {Boolean}       True if the next message is an error. False otherwise.
 */
export const isNextMessageAnError = (messages              )          => {
  if (messages.length > 0) {
    return messages[0].isError;
  }
  return false;
};
