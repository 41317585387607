//      
import { getJWTToken } from 'src/api/management-api';
import api from 'src/api/swagger/api-web-administration.swagger';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import apiIssuanceInternal from 'src/api/swagger/api-web-issuanceinternal.swagger';
import config from 'src/../config';
import errorHandler from 'src/api/errorHandler';

             
                    
                     
              
                   
              
             
                                                            
                                                                                               

export function getTenantEntitlement(tenantid        , type                          )                       {
  const jwtToken = getJWTToken();

  return api.tenants
    .getTenantEntitlementUsingGET({
      parameters: {
        Authorization: jwtToken,
        tenantid,
        type
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getTenantList()                                {
  const jwtToken = getJWTToken();

  return apiIssuanceInternal.printersearch
    .getTenantList({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getTier1ParentCompanyName()                  {
  const jwtToken = getJWTToken();

  return apiIssuanceInternal.printersearch
    .getTier1ParentCompanyName({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getTenantEntitlements(tenantid        )                              {
  const jwtToken = getJWTToken();

  return api.tenants
    .getTenantEntitlementsUsingGET({
      parameters: {
        Authorization: jwtToken,
        tenantid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getTenantsPaged = (searchParms             )                       => {
  const jwtToken = getJWTToken();

  return api.tenants
    .getTenantsPageUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function createTenant(parms                   )                              {
  const jwtToken = getJWTToken();

  return api.tenants
    .createTenantUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const startCreateTenant = (parms                   )                                   => {
  const jwtToken = getJWTToken();

  return api.tenants
    .createTenantAsyncUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const cancelCreateTenantOperation = (id        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .cancelCreateTenantBulkOperationUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getCreateTenantOperations = ()                                                   => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getAllCreateTenantBulkOperationDetailsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getCreateTenantStatus = (id        )                                   => {
  const jwtToken = getJWTToken();

  return api.tenants
    .getCreateTenantAsyncStatusUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getCreateTenantResults = (id        )                              => {
  const jwtToken = getJWTToken();

  return api.tenants
    .getCreateTenantAsyncResultUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function deleteTenant(id        )                {
  const jwtToken = getJWTToken();

  return api.tenants
    .removeTenantUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function lockTenant(id        )                {
  const jwtToken = getJWTToken();

  return api.tenants
    .lockTenantUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unlockTenant(id        )                {
  const jwtToken = getJWTToken();

  return api.tenants
    .unlockTenantUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function setTenantEntitlement(tenantid        , parms                  )                {
  const jwtToken = getJWTToken();

  return api.tenants
    .setTenantEntitlementUsingPUT({
      parameters: {
        Authorization: jwtToken,
        parms,
        tenantid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}
