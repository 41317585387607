//      
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Error from 'src/components/App/States/Error';
import t from 'src/i18n';

              
                       
            
                   
    
             
                    
   
  

              
               
  

class ErrorBoundary extends React.Component               {
  state = {
    error: null
  };

  componentDidCatch(error       ) {
    this.setState({ error });
  }

  componentDidUpdate(prevProps       ) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ error: null });
    }
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div id="app-error-boundary" className={classes.wrapper}>
          <Error message={t('internalErrors.applicationError')} />
          <details>
            <pre>{error.stack}</pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

const style = {
  wrapper: {
    flex: '1 1 auto'
  }
};

export default withRouter(withStyles(style)(ErrorBoundary));
