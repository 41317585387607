//      
import * as React from 'react';

// Material-UI Components
// =============================================================================
import { Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Helper Functions & Constants
// ===========================================================================
import { isBetween, isNullOrUndefined } from 'src/lib/finalFormValidation';
import { isEmpty } from 'src/lib/smart';
import t from 'src/i18n';

// Flow Types
// ===========================================================================
                                                         
                                                                  

                                                       

              
     
                                                        
    
                   
     
                       

     
            
     
            
                
    

     
                                                                 
    
                   
     
             

     
                                                                
    
                   
     
              

     
                                                                
    
                   
     
              

     
                                              
     
            
                      
                 

              
                         
            
  

// ===========================================================================
// Component
// ===========================================================================
const styles = {
  unit: {
    padding: '0 8px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

/**
 * A duration component that uses the Material-UI Textfield component with Final Form.  This component allows the user to
 * change the unit of time being used when entering a value.
 *
 * **Note:** It is assumed that the form value is in **ms**.
 */
class Duration extends React.Component               {
  static defaultProps = {
    unit: 'ms',
    validate: []
  };

  constructor(props       ) {
    super(props);

    const { value } = props.input;

    const ms = toMs(value, props.unit);

    const unit = getUnit(ms);

    // console.log('Setting default unit to', unit);

    this.state = {
      anchorEl: null,
      unit
    };
  }

  // A function that takes the value from the form and formats the value to give to the input.
  // final form -> UI
  format = (value                  )         => {
    if (isEmpty(value)) {
      return '';
    }

    if (String(value).endsWith('.') || String(value).endsWith('.0')) {
      // console.log('formatting', value, 'ends with "." displaying as is.');
      return String(value);
    }

    const inputValue = Number(value);

    if (Number.isNaN(inputValue)) {
      return String(value);
    }

    const ms = toMs(inputValue, this.props.unit);

    const convertedValue = getValue(ms, this.state.unit);

    // console.log('formattting', value, this.props.unit, 'as', convertedValue, this.state.unit);

    return String(convertedValue);
  };

  // A function that takes the value from the input and converts the value into the value you want stored.
  // UI -> Final Form
  parse = (value        )                  => {
    if (value === '') {
      return '';
    }

    if (value.endsWith('.') || value.endsWith('.0')) {
      // console.log('parsing', value, 'ends with "." saving as is.');
      return value;
    }

    const convertedValue = Number(value);

    if (Number.isNaN(convertedValue)) {
      return value;
    }

    const ms = toMs(convertedValue, this.state.unit);

    const parsedValue = getValue(ms, this.props.unit);

    // console.log('parsing', value, this.state.unit, 'as', parsedValue, this.props.unit);

    return parsedValue;
  };

  // Converts the units before passing them to the isBetween validation function.
  durationBetween = (limits               ) => {
    return (value         ) => {
      const { allowedValue } = this.props;

      // console.log('validating', value);

      const usingAllowedValue = isNullOrUndefined(allowedValue) === false;

      // Allow empty values
      if (isEmpty(value)) {
        return undefined;
      }

      // Check if the current field value equals the "allowedValue"
      if (usingAllowedValue && value === allowedValue) {
        return undefined;
      }

      // $FlowFixMe
      const ms = toMs(value, this.props.unit);

      const convertedValue = getValue(ms, this.state.unit);

      // Validate the value is between the limits
      const errorMessage = isBetween(limits)(convertedValue);

      // Override the error message if the value is invalid and the "allowedValue" prop is being used
      if (errorMessage !== undefined && usingAllowedValue) {
        const [min, max] = limits;

        return t(
          'errors.isBetweenWithException',
          min.toLocaleString(),
          max.toLocaleString(),
          allowedValue.toLocaleString()
        );
      }

      return errorMessage;
    };
  };

  onUnitButtonClick = (event                                   ) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  onUnitChanged = (unit      ) => {
    this.setState({ anchorEl: null, unit });
  };

  onBlur = (e, input) => {
    const num = Number(e.target.value);

    if (Number.isNaN(num)) {
      return;
    }

    const parsed = this.parse(num.toString());

    input.onChange(parsed);
  };

  units                                                           = {
    ms: {
      full: t('duration.millisecond.full'),
      short: t('duration.millisecond.short'),
      value: 'ms'
    },
    sec: {
      full: t('duration.second.full'),
      short: t('duration.second.short'),
      value: 'sec'
    },
    min: {
      full: t('duration.minute.full'),
      short: t('duration.minute.short'),
      value: 'min'
    },
    hr: {
      full: t('duration.hour.full'),
      short: t('duration.hour.short'),
      value: 'hr'
    },
    day: {
      full: t('duration.day.full'),
      short: t('duration.day.short'),
      value: 'day'
    }
  };

  render() {
    const { allowedValue, classes, helperText, id, input, meta, min, max, validate, ...rest } = this.props;
    const { anchorEl, unit } = this.state;

    const convertedMin = getValue(min, unit);
    const convertedMax = getValue(max, unit);

    return (
      <Field
        name={input.name}
        format={this.format}
        parse={this.parse}
        validate={this.durationBetween([convertedMin, convertedMax])}
        {...rest}>
        {({ input: { name, onChange, value, ...restInput }, meta, ...rest }                  ) => {
          const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

          return (
            <TextField
              {...rest}
              id={id}
              name={name}
              helperText={showError ? meta.error || meta.submitError : helperText}
              error={showError}
              inputProps={restInput}
              onBlur={e => this.onBlur(e, input)}
              onChange={onChange}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      id={`${id}-unit`}
                      className={classes.unit}
                      onClick={this.onUnitButtonClick}
                      variant="body2">
                      {this.units[unit].short}
                    </Typography>
                    <Menu id={`${id}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.onCloseMenu}>
                      {Object.values(this.units).map((unit                                              ) => (
                        <MenuItem
                          id={`${id}-unit-${unit.value}`}
                          key={unit.value}
                          onClick={() => this.onUnitChanged(unit.value)}>
                          {unit.full}
                        </MenuItem>
                      ))}
                    </Menu>
                  </InputAdornment>
                )
              }}
            />
          );
        }}
      </Field>
    );
  }
}

const s = 1000;
const m = s * 60;
const h = m * 60;
const d = h * 24;

function getUnit(ms        )       {
  if ((ms / d) % 1 === 0) {
    return 'day';
  }
  if ((ms / h) % 1 === 0) {
    return 'hr';
  }
  if ((ms / m) % 1 === 0) {
    return 'min';
  }
  if ((ms / s) % 1 === 0) {
    return 'sec';
  }

  return 'ms';
}

export function getValue(ms        , unit      )         {
  let value = 0;
  switch (unit) {
    case 'day':
      value = ms / d;
      break;
    case 'hr':
      value = ms / h;
      break;
    case 'min':
      value = ms / m;
      break;
    case 'sec':
      value = ms / s;
      break;
    default:
      value = ms;
  }

  return value;
}

export function toMs(n        , unit      )         {
  switch (unit) {
    case 'day':
      return n * d;
    case 'hr':
      return n * h;
    case 'min':
      return n * m;
    case 'sec':
      return n * s;
    case 'ms':
    default:
      return n;
  }
}

export default withStyles(styles)(Duration);
