//      
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Entrust Components
// =============================================================================
import Loading from 'src/components/Loading';

// Redux / Router Functions
// ===========================================================================
import { fetchPermissions } from 'src/ducks/permissions';
import { fetchUser } from 'src/ducks/userInfo';
import { isLoggedIn, isSystemLoggedIn } from 'src/lib/jwt';
import { getIsUserLoading } from 'src/ducks';
import { getIsOidcRoute, getIsSamlRoute } from 'src/ducks/router';

// Flow
                                       
                                                             
                                                        

              
     
            
     
                       

     
            
     
                               

     
            
     
                        

     
                    
     
                       

     
            
     
            
                                      
    

     
            
     
                       

     
            
     
                       

     
            
     
             
                    
    

                               
                         
                                
                    
               
  

class UserProviderClass extends React.Component        {
  static defaultProps = {
    hideLoading: false
  };

  componentDidMount() {
    const { soft, loggedIn } = this.props;
    if ((soft && loggedIn) || !soft) {
      this.props.fetchUser();
      this.props.fetchPermissions();
    }
  }

  componentDidUpdate() {
    const { history, isSamlRoute, isOidcRoute, location, loggedIn, userProperties, permissions } = this.props;

    if (loggedIn && !isSamlRoute && !isOidcRoute) {
      if (userProperties.lastUpdated === null) {
        this.props.fetchUser();
      }

      if (permissions.lastUpdated === null) {
        this.props.fetchPermissions();
      }

      // If the user is authenticated to the user portal and required registration or verification then
      // redirect back to the authentication flow so that the necessary step is performed
      if (userProperties.user.registrationRequired || userProperties.user.verificationRequired) {
        history.replace({
          pathname: '/',
          search: `?redirect=${location.pathname}`
        });
      }
    }
  }

  render() {
    const { hideLoading, loggedIn, soft, userIsLoading } = this.props;

    if ((soft && loggedIn && userIsLoading) || (!soft && userIsLoading)) {
      if (hideLoading) {
        return null;
      }
      return <Loading />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state       , props       ) => ({
  loggedIn: isLoggedIn() && isSystemLoggedIn(),
  isSamlRoute: getIsSamlRoute(props.location),
  isOidcRoute: getIsOidcRoute(props.location),
  permissions: state.permissions,
  userIsLoading: getIsUserLoading(state),
  userProperties: state.userProperties
});

const UserProvider = connect(mapStateToProps, { fetchUser, fetchPermissions })(UserProviderClass);

export default withRouter(UserProvider);

// For when the user is logged but needs to authenticate with a different authenticator due to resource rule
// restrictions for example.
export const SoftUserProvider = (props                          ) => <UserProvider soft {...props} />;
