//      

// External Functions
// =============================================================================
import { differenceInDays, differenceInHours } from 'date-fns';
import t from 'src/i18n';
import { sortByNumber } from 'src/lib/smart';

// Flow Types
// =============================================================================
                                                                                   

                                                           

// Thresholds for Entitlement Notifications for Trial and Production accounts. We show a Warning / Error
// expiration notification if the number of days left is below these values
export const TRIAL_WARNING_DAYS = 8;
export const TRIAL_ERROR_DAYS = 4;
export const PRODUCTION_WARNING_DAYS = 60;
export const PRODUCTION_ERROR_DAYS = 14;

export function getNotifications(accountType             , entitlement                   , isSp         ) {
  const notifications = [];
  const { contractMode } = entitlement;
  // For SMS/Voice we use 'PRODUCTION' threshold since they are always allocated one year
  let warnThreshold =
    contractMode === 'TRIAL' && 'SMSVOICE' !== accountType ? TRIAL_WARNING_DAYS : PRODUCTION_WARNING_DAYS;
  let errorThreshold =
    contractMode === 'TRIAL' && 'SMSVOICE' !== accountType ? TRIAL_ERROR_DAYS : PRODUCTION_ERROR_DAYS;

  switch (accountType) {
    case 'AUTH':
      {
        const { allotment, consumed, quantity, endDate } = entitlement;

        const count = entitlement.overageType === 'UNLIMITED' ? Number.POSITIVE_INFINITY : quantity;

        // Expiration notice:
        const expirationNotice = getExpirationNotice(count, endDate, warnThreshold, errorThreshold);
        if (expirationNotice) {
          notifications.push(expirationNotice);
        }

        // Users consumed notice:
        //   Show error if less than 10% of an accounts entitlements are left
        //   Show warning if less than 30% are left
        //   Show good otherwise
        const usersLeft = isSp ? allotment - consumed : quantity - consumed;
        const percentageLeft = usersLeft / quantity;

        const level =
          entitlement.overageType === 'UNLIMITED'
            ? 'OK'
            : percentageLeft < 0.1
              ? 'ERROR'
              : percentageLeft < 0.3
                ? 'WARNING'
                : 'OK';
        const caption =
          entitlement.overageType === 'UNLIMITED'
            ? null
            : usersLeft >= 0
              ? t('Dashboard.captions.entitlementsRemaining', usersLeft.toLocaleString())
              : t('Dashboard.captions.entitlementsExceeded', Math.abs(usersLeft).toLocaleString());

        notifications.push({
          type: 'USAGE',
          caption: caption,
          captionLevel: level,
          quantity: count
        });
      }
      break;
    case 'ISSUANCE':
      {
        const { consumed, quantity, endDate } = entitlement.issuance;
        const count = contractMode === 'TRIAL' ? quantity : t('Tenants.add.billingTypeSelect.payPeruse');

        // Expiration notice:
        const expirationNotice = getExpirationNotice(count, endDate, warnThreshold, errorThreshold);
        if (expirationNotice) {
          notifications.push(expirationNotice);
        }

        // Transactions consumed notice (TRIAL):
        //   Show error if we consumed more than 90% of an accounts entitlements
        //   Show warning we consumed more than 50% of an accounts entitlements
        //   Show good otherwise (and for PRODUCTION)

        const level = contractMode === 'TRIAL' ? (consumed > 90 ? 'ERROR' : consumed > 50 ? 'WARNING' : 'OK') : 'OK';
        const caption =
          contractMode === 'TRIAL'
            ? t('Dashboard.captions.transactionsConsumedPercent', consumed.toLocaleString())
            : t('Dashboard.captions.transactionsConsumed', consumed.toLocaleString());

        notifications.push({
          type: 'USAGE',
          caption: caption,
          captionLevel: level,
          quantity: count
        });
      }
      break;
    case 'SMSVOICE':
      {
        const { allotment, consumed, quantity, endDate, renewalQuantity } = entitlement.smsVoice;

        const count = entitlement.overageType === 'UNLIMITED' ? Number.POSITIVE_INFINITY : quantity;

        // SMS/Voice Expiration notice: takes precedence over USERS expiration (we allow tenants to go over limit)
        if (entitlement.smsVoice) {
          // we show an expiration notification when the current active entitlement is near expiration
          // but there's not a renewal in place
          const smsVoiceExpirationNotice = getExpirationNotice(count, endDate, warnThreshold, errorThreshold);
          if (smsVoiceExpirationNotice && renewalQuantity === 0) {
            notifications.push(smsVoiceExpirationNotice);
          }
          // SMS/Voice consumed notice
          const smsVoiceUsageNotice = getSmsVoiceConsumedNotice(allotment, consumed, isSp, quantity);
          if (smsVoiceUsageNotice) {
            notifications.push(smsVoiceUsageNotice);
          }
        }
      }
      break;
    default:
      throw new Error(`Invalid AccountType ${accountType}`);
  }

  // if there are 2 notifications with different level (e.g., one warning and one error), then put first error.
  return notifications.sort((n1, n2) => (getNotificationPriority(n1) > getNotificationPriority(n2) ? -1 : 1));
}

const getNotificationPriority = notification => {
  if (!notification || notification === undefined) return -1;
  if (notification.captionLevel === 'OK') return 0;
  if (notification.captionLevel === 'WARNING') return 1;
  return 2; // it's an ERROR level notification
};

// expiration notice is only returned if the entitlements is close to expiration (less than 2 months)
function getExpirationNotice(count, endDateISO, warnThreshold, errorThreshold) {
  let notice;

  const today = new Date();
  const endDate = new Date(endDateISO);
  const daysLeft = differenceInDays(endDate, today);

  // Expiration notice:
  //   Show an error if there is less than errorThreshold days or if the contract has expired
  //   Show a warning if there is less than warnThreshold days until the contract expires
  if (daysLeft < warnThreshold) {
    const hoursLeft = differenceInHours(endDate, today);

    const level = daysLeft > errorThreshold ? 'WARNING' : 'ERROR';
    const caption =
      daysLeft > 0
        ? t('Dashboard.captions.daysLeft', daysLeft)
        : hoursLeft > 0
          ? t('Dashboard.captions.hoursLeft', hoursLeft)
          : t('Dashboard.captions.contractExpired');
    notice = {
      type: 'EXPIRATION',
      caption: caption,
      captionLevel: level,
      quantity: count
    };
  }

  return notice;
}

export function getPreferredNotifications(accountType             , entitlement                   , isSp         ) {
  if (accountType === 'ISSUANCE') {
    return getNotifications(accountType, entitlement, isSp);
  }

  // it's an AUTHENTICATION account type: return the most critical notification
  // A Pay-per-use tenant has overageType set to YES and a quantity of 1. If the quantity is greater than 1, then
  // even with overateType = YES, we treat the user in the UI as Pre-paid. That means that it will receive
  // usage notifications and that it will see the quantity, the consumed amount, and the remaining credits
  const smsVoiceNotifications =
    entitlement.smsVoice && (entitlement.smsVoice.overageType !== 'YES' || entitlement.smsVoice.quantity > 1)
      ? getNotifications('SMSVOICE', entitlement, isSp)
      : null;
  const usersNotifications = getNotifications('AUTH', entitlement, isSp);

  const smsVoiceNotificationLevel = getNotificationLevel(smsVoiceNotifications);
  const usersNotificationLevel = getNotificationLevel(usersNotifications);

  // we support USERS and SMSVOICE). If there's a tie, we display the USERS notification
  return usersNotificationLevel > smsVoiceNotificationLevel ? usersNotifications : smsVoiceNotifications;
}

function getNotificationLevel(notification) {
  if (!notification || notification.length == 0) return -1;
  if (notification[0].captionLevel === 'OK') return 0;
  if (notification[0].captionLevel === 'WARNING') return 1;
  return 2; // it's an ERROR level notification
}

// consumed notice is only returned if the entitlements has less than 30%
function getSmsVoiceConsumedNotice(allotment, consumed, isSp, quantity) {
  let notice;

  // consumed notice:
  //   Show error if less than 10% of an accounts entitlements are left
  //   Show warning if less than 30% are left
  let left = isSp ? allotment - consumed : quantity - consumed;
  left = Math.max(left, 0); // Root SP can set quantity to zero after some credits are consumed
  const percentageLeft = left / quantity;
  if (percentageLeft < 0.3) {
    const level = percentageLeft < 0.1 ? 'ERROR' : percentageLeft < 0.3 ? 'WARNING' : 'OK';
    const caption = t('Dashboard.captions.entitlementsRemainingSmsVoice', left.toLocaleString());
    notice = {
      type: 'USAGE',
      caption: caption,
      captionLevel: level,
      quantity: quantity
    };
  }

  return notice;
}

export const sortBundleByRank = (a                , b                ) => {
  return sortByNumber('rank')(a, b);
};

export const filterByBundleName = (name        , bundleFeatures                       )                 => {
  return bundleFeatures.find(b => b.bundle === name);
};

export const filterByBundleFeatureName = (name        , bundleFeatures                 ) => {
  return bundleFeatures?.features.find(feature => feature.name === name);
};

/**
  This function determines if a warning message to be displayed when an SP changes its tenant bundle type.
  The warning message will indicate that some features may be unavailabe for the tenant with bundle type changed.
 */
export const isBundleLowered = (newBundle        , oldBundle                ) => {
  switch (oldBundle?.bundleType) {
    case 'PREMIUM':
      return newBundle === 'STANDARD' || newBundle === 'PLUS' || newBundle === 'CONSUMER';
    case 'PLUS':
      return newBundle === 'STANDARD' || newBundle === 'CONSUMER';
    case 'STANDARD':
      return newBundle === 'CONSUMER';
    case 'CONSUMER':
      return newBundle === 'STANDARD';
    default:
      return false;
  }
};

/**
 * Method to determine if the issuance account has a specific add on enabled.
 *
 * @param {string} addOn  The issuance addOn entitlement string
 * @param {string} type   The issuance addOn type
 * @returns boolean
 */
export const hasIssuanceAddOn = (addOn        , type        ) => {
  if (!addOn) {
    return false;
  }

  try {
    const issuanceAddOn = JSON.parse(addOn);
    // existing implementations use addOn string like - {name: printercert, value: true}
    // we will need to accomodate for this JSON structure
    if (
      issuanceAddOn &&
      Object.hasOwn(issuanceAddOn, 'name') &&
      Object.hasOwn(issuanceAddOn, 'value') &&
      issuanceAddOn.name == type
    ) {
      // there's a possibility addOn value comes back as a string
      // so we must parse it into a boolean value
      return JSON.parse(issuanceAddOn.value);
    }

    // accomodate for newer issuance addOn JSON structure - {printerCert: true}
    if (issuanceAddOn && Object.hasOwn(issuanceAddOn, type)) {
      return JSON.parse(issuanceAddOn[type]);
    }

    return false;
  } catch (error) {
    return false;
  }
};
