//      

import { getSubscriberEntitlementBundle } from 'src/api/subscribers';
                                                    
import { getIsRootSpAccount } from 'src/ducks/theme';
import { filterByBundleName, sortBundleByRank } from 'src/lib/entitlements';

// Action Types
// =============================================================================
                              
                 
                   
         
                         
                       
                         
                   
                
                   
                                    
                                             
                             
                                
                                     
                               
                      
                                           
                     
                     
                                              
                    
                         
                 
                  
                                       
                          
                              
                                    
                     
                  
                            
                          
                                        
                     
                     
     
              
  

                                   
                     
                       
                  
                                       
  

             
                                       
                                                                       
                                        

// Initial State
// =============================================================================
export const initialState                      = Object.freeze({
  isLoading: false,
  lastUpdated: null,
  failed: false,
  bundleFeatures: [
    {
      bundle: 'STANDARD',
      features: [
        {
          name: 'userSelfService',
          enabled: false,
          quantity: null
        },
        {
          name: 'vpnRemoteAccess',
          enabled: false,
          quantity: null
        },
        {
          name: 'ssoPortal',
          enabled: false,
          quantity: null
        },
        {
          name: 'adSync',
          enabled: false,
          quantity: null
        },
        {
          name: 'office365',
          enabled: false,
          quantity: null
        },
        {
          name: 'additionalSamlApplications',
          enabled: false,
          quantity: null
        },
        {
          name: 'mobileSoftTokenAndPushNotifications',
          enabled: false,
          quantity: null
        },
        {
          name: 'basicAuthenticators',
          enabled: false,
          quantity: null
        },
        {
          name: 'nativeDeviceBiometrics',
          enabled: false,
          quantity: null
        },
        {
          name: 'emailWebAndTelephoneSupport',
          enabled: false,
          quantity: null
        },
        {
          name: 'riskBasedPolicyEngine',
          enabled: false,
          quantity: null
        },
        {
          name: 'desktopLogin',
          enabled: false,
          quantity: null
        },
        {
          name: 'onPremisesApplicationIntegrations',
          enabled: false,
          quantity: null
        },
        {
          name: 'azureAdSync',
          enabled: false,
          quantity: null
        },
        {
          name: 'openIdConnectApplicationsIntegration',
          enabled: false,
          quantity: null
        },
        {
          name: 'azureAdCac',
          enabled: false,
          quantity: null
        },
        {
          name: 'siemIntegration',
          enabled: false,
          quantity: null
        },
        {
          name: 'authApi',
          enabled: false,
          quantity: null
        },
        {
          name: 'adminApi',
          enabled: false,
          quantity: null
        },
        {
          name: 'crossPlatformFacialBiometrics',
          enabled: false,
          quantity: null
        },
        {
          name: 'ldapDatabaseSync',
          enabled: false,
          quantity: null
        },
        {
          name: 'oAuthV2ApiProtection',
          enabled: false,
          quantity: null
        },
        {
          name: 'mobileSmartCredentialLogin',
          enabled: false,
          quantity: null
        },
        {
          name: 'idpProxy',
          enabled: false,
          quantity: null
        },
        {
          name: 'externalRiskEngine',
          enabled: false,
          quantity: null
        },
        {
          name: 'scimProvisioning',
          enabled: true,
          quantity: null
        },
        {
          name: 'userCertificateAuthentication',
          enabled: false,
          quantity: null
        }
      ],
      rank: 1005
    }
  ]
});

// Reducers
// =============================================================================
export default (state                      = initialState, action        )                      => {
  switch (action.type) {
    case 'BUNDLE_FEATURES/REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'BUNDLE_FEATURES/FAILURE':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: true };

    case 'BUNDLE_FEATURES/SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastUpdated: Date.now(),
        failed: false,
        bundleFeatures: [...action.payload]
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const fetchBundleFeatures = () => {
  return async (dispatch          , getState          ) => {
    if (getState().bundleFeatures.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'BUNDLE_FEATURES/REQUEST' });

    try {
      const bundleFeatures                        = await getSubscriberEntitlementBundle();
      dispatch({ type: 'BUNDLE_FEATURES/SUCCESS', payload: bundleFeatures });
    } catch (e) {
      dispatch({ type: 'BUNDLE_FEATURES/FAILURE' });
    }
  };
};

// Selectors
// =============================================================================
export const getHighestRankedBundle = (state       ) => {
  const isRootSp = getIsRootSpAccount(state.themes);
  const bundleFeatures = state.bundleFeatures.bundleFeatures;
  const isSp = state.themes.subscriber.isSp;
  const isAuthenticationAccount = state.themes.subscriber.authenticationAccount;
  const tier = state.themes.subscriber.tier;

  // Return PREMIUM bundle which is the highest bundle for Root SP Auth Account
  if (isRootSp) {
    return filterByBundleName('PREMIUM', bundleFeatures);
  }

  // If there's only one bundle return the first one
  if (bundleFeatures.length === 1) {
    return bundleFeatures[0];
  }

  // Return PLUS bundle which is the highest bundle for MSP Auth Account
  if (isAuthenticationAccount && isSp && tier === 1) {
    return filterByBundleName('PLUS', bundleFeatures);
  }

  // Sort bundles by rank.
  const sorted = bundleFeatures.sort(sortBundleByRank);
  // return the last element which is the highest ranked bundle
  return sorted[sorted.length - 1];
};
