import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  /**
   * React children elements
   * @ignore
   */
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  scrollbar: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflow: 'auto'
  },
  toolbar: theme.mixins.toolbar
}));

const ScrollBar = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.scrollbar}>
      <div className={classes.toolbar} />
      {children}
    </div>
  );
};

export default ScrollBar;
