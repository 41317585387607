// Material-UI Components
// =============================================================================
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SadIcon from '@material-ui/icons/SentimentDissatisfied';

// External Functions
// =============================================================================
import t from 'src/i18n';

interface Props {
  message: string;
}

const useStyles = makeStyles({
  icon: {
    fontSize: 100,
    margin: 24,
    width: 72,
    height: 72
  }
});

const Error = (props: Props) => {
  const { message = '' } = props;

  const classes = useStyles();

  const retry = () => location.reload();

  const error = message || t('internalErrors.loadingError');

  return (
    <Box display="flex" justifyContent="center" alignItems="center" margin={1}>
      <SadIcon className={classes.icon} htmlColor={grey[900]} />
      <div>
        <h1>{error}</h1>
        <p>{t('internalErrors.offlineRefresh')}</p>
        <Button id="app-error-reload" color="primary" onClick={retry} variant="outlined">
          {t('button.refresh')}
        </Button>
      </div>
    </Box>
  );
};

export default Error;
