import { FinalDialog, FinalDialogProps } from '@edc/react-ui/FinalDialog';
import t from 'src/i18n';

const FinalBasicDialog = (props: FinalDialogProps) => {
  return (
    <FinalDialog
      {...props}
      i18n={{
        cancel: t('button.cancel'),
        next: t('button.next'),
        previous: t('button.previous')
      }}
    />
  );
};

export default FinalBasicDialog;
