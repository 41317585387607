//      
import { useSelector } from 'react-redux';

// Material-UI Components
// =============================================================================
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Entrust Components
// =============================================================================
import FooterLogo from 'src/components/Branding/FooterLogo';
import BuildInfo from 'src/components/Layout/Footer/BuildInfo';
import ExternalLink from 'src/components/Link/ExternalLink';

// External Functions
// =============================================================================
import { getIsLoggedIn } from 'src/ducks/authentication';
import { DOC_LINKS } from 'src/lib/constants';
import t from 'src/i18n';

                                                     

const CURRENT_YEAR = new Date().getFullYear();

const useStyles = makeStyles(theme => ({
  link: {
    marginRight: theme.spacing(1.5)
  }
}));

const Footer = () => {
  const classes = useStyles();

  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);

  const isLoggedIn = useSelector((state            ) => getIsLoggedIn(state.authentication));

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      margin={1}
      paddingTop={1}
      borderTop="1px solid #E2E1DD">
      <div>
        {/* This should not be translated */}
        <Typography variant="body2">{`© ${CURRENT_YEAR} Entrust Corporation. All rights reserved.`}</Typography>

        {/* Footer Links */}
        <Box display="flex" flexWrap="wrap">
          <ExternalLink
            href={isAuthenticationAccount ? DOC_LINKS.TOS_URL : DOC_LINKS.TOS_ISSUANCE_URL}
            className={classes.link}
            variant="body2">
            {t('common.footer.termsOfService')}
          </ExternalLink>

          <ExternalLink href={DOC_LINKS.PP_URL} className={classes.link} target="_blank" variant="body2">
            {t('common.footer.privacyStatement')}
          </ExternalLink>

          {isLoggedIn && (
            <ExternalLink href={DOC_LINKS.OSS_URL} className={classes.link} target="_blank" variant="body2">
              {t('common.footer.openSourceCopyright')}
            </ExternalLink>
          )}

          <BuildInfo />
        </Box>
      </div>

      <FooterLogo />
    </Box>
  );
};

export default Footer;
