import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import InternalLink from 'src/components/Link/InternalLink';
import t from 'src/i18n';

type Props = {
  title: string;
  crumb: string;
  crumbRoute: string;
};

const PageTitle = ({ title, crumb, crumbRoute }: Props) => (
  <Box py={1}>
    <Breadcrumbs>
      <InternalLink to="/redirect">{t('breadCrumbs.home')}</InternalLink>
      {crumb !== title && <InternalLink to={crumbRoute}>{crumb}</InternalLink>}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  </Box>
);

export default PageTitle;
