import * as React from 'react';
import namespaceIndex from 'src/i18n/namespaceIndex.json';

const languages = [
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'pl',
  'pt',
  'ru',
  'sv',
  'th',
  'tr',
  'zh-cn',
  'zh-tw'
] as const;

const ADMIN_LOCALES = ['en', 'es', 'fr', 'ja', 'pt'];

const ADMIN_PORTAL_FILES = [
  'bulkImport',
  'customization',
  'dashboard',
  'members',
  'resource',
  'security',
  'serviceProvider',
  'settings',
  'subscriberPortal',
  'issuancePortal'
];

type Language = (typeof languages)[number];

type JSONValue = string | number | boolean | null | JSONObject | JSONArray;
type JSONObject = { [key: string]: JSONValue };
type JSONArray = JSONValue[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (...args: any[]) => void;

class EventEmitter {
  private events: { [event: string]: Listener[] } = {};

  on = (event: string, listener: Listener): void => {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit = (event: string, ...args: any[]): void => {
    const listeners = this.events[event];
    if (listeners) {
      listeners.forEach(listener => listener(...args));
    }
  };

  off = (event: string, listenerToRemove: Listener): void => {
    const listeners = this.events[event];
    if (listeners) {
      this.events[event] = listeners.filter(listener => listener !== listenerToRemove);
    }
  };

  once = (event: string, listener: Listener): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onceWrapper = (...args: any[]) => {
      listener(...args);
      this.off(event, onceWrapper);
    };
    this.on(event, onceWrapper);
  };
}

const StyledText = ({
  style,
  children
}: {
  style: 'bold' | 'italic' | 'strike' | 'code' | 'link' | 'none';
  children: React.ReactNode;
}) => {
  switch (style) {
    case 'bold':
      return <b>{children}</b>;
    case 'italic':
      return <i>{children}</i>;
    case 'strike':
      return <s>{children}</s>;
    case 'code':
      return <code>{children}</code>;
    case 'link':
      return <>{children}</>;
    case 'none':
    default:
      return <span>{children}</span>;
  }
};

class I18N extends EventEmitter {
  private tMapStore: Record<Language, JSONObject> = {} as Record<Language, JSONObject>;
  private loadingNamespaces: Set<string> = new Set();
  private previousLanguage: Language = 'en';
  private currentLanguage: Language = 'en';

  private languageLoader = (lang: Language, filename: string): Promise<JSONObject> =>
    import(`src/i18n/locales/${lang}/${filename}.json`);

  private _changeLanguage = async (lang: Language, ...eagerNameSpaces: Array<string>) => {
    const targetLang = languages.includes(lang) ? lang : 'en';
    if (!this.tMapStore[targetLang]) {
      this.tMapStore[targetLang] = {};
    }
    this.previousLanguage = this.currentLanguage;
    this.currentLanguage = targetLang;
    document.documentElement.lang = targetLang;
    await Promise.all([
      this._loadNamespace(targetLang, 'restApiErrors'),
      ...eagerNameSpaces.map(ns => this._loadNamespace(targetLang, ns))
    ]);
    this.emit('languageChange', targetLang);
  };

  private _loadNamespace = (lang: Language, ns: string) => {
    if (this.tMapStore[lang]?.[ns]) {
      return;
    }

    const filename = namespaceIndex[ns];

    // Ignore admin portal files for non-admin locales
    if (!ADMIN_LOCALES.includes(lang) && ADMIN_PORTAL_FILES.includes(filename)) {
      return;
    }

    const cacheKey = `${lang}-${filename}`;

    if (this.loadingNamespaces.has(cacheKey)) {
      return;
    }

    this.loadingNamespaces.add(cacheKey);

    this.languageLoader(lang, filename)
      .then(nsMap => {
        this.tMapStore[lang] = { ...this.tMapStore[lang], ...nsMap };
        this.emit('languageChange', lang + '-' + ns);
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.loadingNamespaces.delete(cacheKey);
      });

    return;
  };

  private _keyLookup = (path: string): [void | string | null, boolean] => {
    const ns = path.split('.')[0];

    // return error if the index need to be refreshed
    if (!(ns in namespaceIndex)) {
      return [`KEY_NOT_FOUND__${path}__NAMESPACE_INDEX_MISSING`, false];
    }

    // if current language ns is not loaded, load it
    if (!this.tMapStore[this.currentLanguage] || !(ns in this.tMapStore[this.currentLanguage])) {
      this._loadNamespace(this.currentLanguage, ns);
      return [keyLookup(this.tMapStore[this.previousLanguage], path), true];
    }

    return [keyLookup(this.tMapStore[this.currentLanguage], path), false];
  };

  /**
   * Pull a language string out of the translation map based on a key.
   *
   * @returns String the language string corresponding to the input key, or a 'key not found' if the key does not exist
   */
  t = (path: string, ...params: Array<string | number>): string => {
    const [message, loading] = this._keyLookup(path);
    if (!message) {
      if (!loading) {
        console.error('KEY_NOT_FOUND__', path);
      }
      return loading ? '' : 'KEY_NOT_FOUND__' + path;
    }
    return params.length ? replaceValues(message, params) : message;
  };

  /**
   * Pull a language string out of the translation map based on a key.
   *
   * @returns String the language string corresponding to the input key, or null if the key does not exist
   */
  tb = (path: string, ...params: Array<string | number>): string | null => {
    const [message] = this._keyLookup(path);
    if (!message) {
      return null;
    }
    return params.length ? replaceValues(message, params) : message;
  };

  /**
   * Just like the t function, except parameters can be styled.
   * @param {*} path The i18n key to look up
   * @param {*} style The style to apply to each parameter.
   * @param {*} params The parameters to insert into the string
   */
  ts = (
    path: string,
    style: 'bold' | 'italic' | 'strike' | 'link' | 'none',
    ...params: Array<string | React.JSX.Element>
  ): React.JSX.Element => {
    const [message, loading] = this._keyLookup(path);
    if (!message) {
      return loading ? <span></span> : <span>KEY_NOT_FOUND__{path}</span>;
    }

    if (!params.length) {
      return <span>{message}</span>;
    }

    return (
      <span>
        {message.split('{}').map((msg, index) => (
          <React.Fragment key={`${msg}-${index}`}>
            {msg}
            <StyledText style={style}>{params.shift()}</StyledText>
          </React.Fragment>
        ))}
      </span>
    );
  };

  changeLanguage = (lang: Language, ...eagerNameSpaces: Array<string>) => {
    this._changeLanguage(lang, ...eagerNameSpaces).catch(err => {
      console.error(err);
    });
  };
}

export const i18n = new I18N();

export const ts = i18n.ts;
export const tb = i18n.tb;

export default i18n.t;

export const testOnlyLoadAllNamespaces = (lang: Language) => {
  i18n.changeLanguage(lang, ...Object.keys(namespaceIndex));
};

function replaceValues(message: string, args: Array<string | number>): string {
  return message.replace(/\{\}/g, () => String(args.shift()));
}

/* ==========================================
 * Perform a deep lookup of a key in an object. Also supports array lookups.
 *
 * path: 'x.y.0.z'
 * object: {x: {y: [{z: 'a'}]}}
 * returns: 'a'
 *
 *  return: the value if path was found in object. Otherwise, returns defaultValue or null.
 *
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 * ========================================== */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keyLookup = (obj: any, path: string, defaultValue?: string): void | string | null =>
  path.split('.').reduce((a, c) => (a && a[c] !== null && a[c] !== undefined ? a[c] : defaultValue || null), obj);
