//      
import { EXCEPTION_EXPIRED_SESSION, EXCEPTION_NOT_LOGGED_IN } from 'src/lib/api-error-messages';
import { ADMIN_PORTAL_APPLICATION_ID, USER_PORTAL_APPLICATION_ID } from 'src/lib/constants';
import { store } from 'src/store';
import { logout } from 'src/ducks';
import {
  clearJWT,
  renewToken,
  setAuthenticatedToken,
  setIdleSession,
  setSystemAuthenticated,
  setToken,
  setUpdateProfileTimeout,
  updateToken
} from 'src/ducks/authentication';

/*
 * Functions related to storing, retrieving and deleting the JWT.
 * Also, helper functions to determine if a user is logged in.
 */

/*
Authentication is based on a challenge/response:
- First obtain a challenge (such as SMS OTP or token challenge).
  This returns an initial, but "unauthenticated" JWT.
  This JWT only grants access to a limited set of pages and REST APIs
  (e.g. the page to enter a response to the challenge)

- Second, answer the challenge.
  If successful, this returns an "authenticated" JWT, which
  then grants access to the rest of the app and the REST APIs
  (based on the user's permissions and roles).

To differentiate whether we have an unauthenticated or authenticated
JWT, an additional attribute/flag must be stored locally (along with
the JWT).
*/

// By default this is an unauthenticated JWT (i.e. A JWT received as part of a challenge request)
export const setUnauthenticatedJWT = (jwt        ) => {
  store.dispatch(setToken(jwt));
  console.log('Set JWT');
  setSessionTimes(jwt);
};

export const setAuthenticatedJWT = (jwt        ) => {
  // Session time must be set first before the jwt otherwise expiry time becomes now
  // and the isLoggedIn check fails even though the jwt is authenticated
  setSessionTimes(jwt);
  store.dispatch(setAuthenticatedToken(jwt));
  console.log('Set authenticated JWT');
};

// Calculate the expiry time based on the local clock, this
// will adjust for any clock skew between client and server.
// Must be called whenever the JWT token is updated
// (i.e. on login and renew).
const setSessionTimes = jwt => {
  const now = Date.now();
  const sessionLifetime = jwt.expires - jwt.time;
  const sessionExpiryTime = sessionLifetime + now;

  store.dispatch(setIdleSession(now, sessionExpiryTime));
};

export const getSessionStartTime = ()         => {
  return store.getState().authentication.startTime;
};

// Should always call this function instead of using jwt.expires,
// since this function accounts for clock skew between client
// and server
export const getSessionExpiryTime = ()         => {
  return store.getState().authentication.expiryTime;
};

export const getJWT = ()         => {
  if (getSessionExpiryTime() > Date.now()) {
    return store.getState().authentication.jwt;
  }

  return null;
};

export const deleteJWT = () => {
  store.dispatch(clearJWT());
  console.log('Deleted JWT');
};

export const logoutJWT = (clearRedux          = false) => {
  // Delete the JWT and stop all activity related timers
  deleteJWT();

  // Clear Redux store
  if (clearRedux) {
    store.dispatch(logout());
  }
};

/*
 * The authorization token attribute, in the JWT, which is used
 * in the REST API calls
 */
export const getTokenAttribute = ()                => {
  const jwt = store.getState().authentication.jwt;
  return jwt ? jwt.token : null;
};

/**
 * Use selectUserId instead. getUserId does not update react component if the user id changes.
 * @deprecated
 *
 */
export const getUserId = ()         => {
  const jwt = getJWT();
  return jwt ? jwt.userId : '';
};

// TODO: Can this be deprecated?
export const getUserIdExpiredOverride = ()                => {
  const jwt = store.getState().authentication.jwt;
  return jwt ? jwt.userId : '';
};
/*
 * Determine if the user has a valid authenticated JWT.
 * Return the JWT if it's valid.
 * Throw an exception otherwise.
 * TODO: Remove in favor of isLoggedIn()
 */
export const checkLoggedIn = () => {
  // Check if the authenticated flag is set first
  const authn = store.getState().authentication.isAuthenticatedJwt;

  // when refreshing page before step up auth is finished, make the user still logged into the user portal
  if (!authn && !store.getState().authentication.isStepAuth) {
    throw new EXCEPTION_NOT_LOGGED_IN();
  }

  // Check if there's an unexpired JWT
  const jwt = checkHaveJWT();

  return jwt;
};

export const isLoggedIn = ()          => {
  try {
    checkLoggedIn();
    return true;
  } catch (error) {
    return false;
  }
};

export const hasJWT = ()          => {
  const hasJWT = getJWT() !== null;
  const isAuthenticated = store.getState().authentication.isAuthenticatedJwt;
  return hasJWT && isAuthenticated;
};

/*
 * Determine if the user has an unexpired JWT.
 * Note, this could be an authenticated or an
 * unauthenticated JWT.
 * Return the JWT if it's valid.
 * Throw an exception otherwise.
 */
export const checkHaveJWT = () => {
  const jwt = getJWT();

  if (!jwt) {
    throw new EXCEPTION_NOT_LOGGED_IN();
  }

  // Check if it's still valid.
  if (getSessionExpiryTime() < Date.now()) {
    deleteJWT();
    throw new EXCEPTION_EXPIRED_SESSION();
  }

  return jwt;
};

export const updateJWTToken = (token        ) => {
  store.dispatch(updateToken(token));
  console.log('Updated JWT');
};

// Update only the attributes that are related to the JWT token itself
// (the token, and related timestamps).  Don't update the other fields
// in the JWT response since they contain additional information
// which should not be updated when it's only the token that's being
// renewed.  (For example, the authenticated JWT JSON response contains the user's
// name, etc., which should not be updated on a renew.)
export const updateRenewedJWTToken = (renewedJWT        ) => {
  if (getSessionExpiryTime() > Date.now()) {
    store.dispatch(renewToken(renewedJWT));
    // recalculate new session times
    setSessionTimes(renewedJWT);
    console.log('Updated JWT');
  }
};

/*
 * Determine if the user has authenticated to a system application.
 */
export const isSystemLoggedIn = ()          => {
  return store.getState().authentication.isSystemAuthenticatedJwt;
};

/*
 * Check if the user has authenticated to a system apps: if so,
 * store a flag so that we don't need to call to the back-end again.
 */
export const setLoggedIntoSystemApps = (applicationId        ) => {
  if (applicationId === USER_PORTAL_APPLICATION_ID || applicationId === ADMIN_PORTAL_APPLICATION_ID) {
    store.dispatch(setSystemAuthenticated(true));
  }
};

/*
 * Check if the user has not authenticated to a system apps: if so,
 * remove the flag so that we call the back-end again.
 */
export const clearLoggedIntoSystemApps = (applicationId        ) => {
  if (applicationId !== USER_PORTAL_APPLICATION_ID && applicationId !== ADMIN_PORTAL_APPLICATION_ID) {
    store.dispatch(setSystemAuthenticated(false));
  }
};

export const setUpdateProfileJWT = (jwt        ) => {
  store.dispatch(setUpdateProfileTimeout(jwt.stepUpAuthExpiry));
  console.log('Updated JWT');
};

export const clearUpdateProfile = () => {
  store.dispatch(setUpdateProfileTimeout(0));
};
