//      

// Helper Functions
// ===========================================================================
import t from 'src/i18n';
                                                                                             

const fidoSupported = ()          => {
  return !!window.PublicKeyCredential && !!navigator.credentials;
};

export const FIDO_SUPPORTED = fidoSupported();

export function base64Decode(value        )             {
  // convert base64url to base64
  value = value.replace(/-/g, '+').replace(/_/g, '/');
  // decode base64
  return Uint8Array.from(atob(value), c => c.charCodeAt(0));
}

export function base64Encode(value            )         {
  value = new Uint8Array(value);

  let str = '';
  const len = value.byteLength;

  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(value[i]);
  }
  return window.btoa(str);
}

export const fidoRegister = async (
  challenge            ,
  rp                  ,
  user                                                   ,
  excludeCredentials                                           ,
  timeout        ,
  abortController    ,
  fidoSettings
) => {
  const pubKeyCredParams = [
    {
      type: 'public-key',
      alg: -7
    }
  ];

  const publicKey = {
    challenge,
    pubKeyCredParams,
    rp,
    user,
    excludeCredentials,
    authenticatorSelection: {
      userVerification: fidoSettings.registrationUserVerification.toLowerCase(),
      residentKey: fidoSettings.registrationRequireResidentKey.toLowerCase(),
      ...(fidoSettings.registrationAuthenticatorAttachment !== 'EITHER' && {
        authenticatorAttachment:
          fidoSettings.registrationAuthenticatorAttachment === 'PLATFORM' ? 'platform' : 'cross-platform'
      })
    },
    timeout
  };

  //$FlowFixMe
  const response = navigator.credentials.create({ publicKey, signal: abortController.signal });
  return response;
};

export const fidoAuthenticate = (fidoChallenge               )                                         => {
  const abortController = FIDO_SUPPORTED ? new AbortController() : null;

  const authenticate               = async () => {
    if (!FIDO_SUPPORTED) {
      // The caller should have checked this before calling authenticate.
      throw new Error('FIDO not supported by this browser.');
    }

    const publicKey = {
      challenge: base64Decode(fidoChallenge.challenge),
      timeout: fidoChallenge.timeout * 1000
    };

    //$FlowFixMe
    const response             = await navigator.credentials.get({ publicKey });

    const fidoResponse = {
      credentialId: response.id,
      authenticatorData: base64Encode(response.response.authenticatorData),
      signature: base64Encode(response.response.signature),
      clientDataJSON: base64Encode(response.response.clientDataJSON),
      userHandle: base64Encode(response.response.userHandle)
    };

    return fidoResponse;
  };

  return [authenticate, abortController];
};

export const getFIDOError = (errorCode)         => {
  switch (errorCode) {
    case 'NotAllowedError':
      return t('authFIDO.error.timeout');
    case 'InvalidStateError':
      return t('authFIDO.error.device_not_registered');
    case 'error':
      return t('authFIDO.error.error');
    case 'AbortError':
      return '';
    default:
      console.log('Error code not defined.');
      return '';
  }
};
