//      
import t from 'src/i18n';
                                                                            

export const TYPES = {
  MGMT_UI: 'MGMT_UI',
  LDAP_AD: 'LDAP_AD',
  EXTERNAL: 'EXTERNAL'
};

export const userType = (type                                    ) =>
  ({
    EXTERNAL: t('Users.types.EXTERNAL'),
    MGMT_UI: t('Users.types.MGMT_UI'),
    LDAP_AD: t('Users.types.LDAP_AD')
  })[type];

/**
 * Return whether or not a user is read-only.
 * Read-only users are:
 *   - the current admin
 *   - directory synced users
 *   - externally synced users
 *
 * @param {*} user - The User
 * @param {*} currentUserId - The UserID of the user that is currently logged in.
 */
export const isReadOnly = (user      , currentUserId        )          => {
  const isADUser = user.type === 'LDAP_AD';
  const isExternalUser = user.type === 'EXTERNAL';

  return currentUserId === user.userId || isADUser || isExternalUser;
};
