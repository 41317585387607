//      
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI Components
// =============================================================================
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// External Functions
// =============================================================================
import { getIsAdmin, getPermissionsByType } from 'src/ducks/permissions';
import { ROLE_TYPE_SITE_ADMINISTRATOR } from 'src/lib/constants';

const Redirector = () => {
  const history = useHistory();

  const location = useLocation();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const redirect = new URLSearchParams(location.search).get('redirect');

  const isAdmin = useSelector(state => getIsAdmin(state.permissions));
  const permissions = useSelector(state =>
    getPermissionsByType(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
      'CREDENTIALDESIGNS',
      'ENROLLMENTDESIGNS',
      'CREDENTIALS',
      'ENROLLMENTS',
      'REPORTS',
      'SETTINGS',
      'SMARTCARDS',
      'USERS'
    ])
  );
  const isAuthenticationAccount = useSelector(state => state.themes.subscriber.authenticationAccount);

  React.useEffect(() => {
    // If we were redirected from a page to be authenticated then send us back there.
    if (redirect) {
      return history.replace(redirect);
    }

    // For Issuance Account on mobile devices only credentials page is compatible.
    if (!isAuthenticationAccount && isMobile) {
      return history.replace('/credentials');
    }

    // If the user is not an administrator redirect them to the My Profile page
    if (!isAdmin) {
      return history.replace('/myprofile');
    }

    // If the user has REPORTS permission then redirect them to the Dashboard
    if (permissions.REPORTS.actionView) {
      return history.replace('/dashboard');
    }

    // If the user has CREDENTIALDESIGNS ADD OR EDIT then redirect to credential designs
    if (permissions.CREDENTIALDESIGNS.actionAdd || permissions.CREDENTIALDESIGNS.actionEdit) {
      return history.replace('/credentialdesigns');
    }

    // If the user has ENROLLMENTDESIGNS ADD OR EDIT then redirect to ENROLLMENTDESIGNS
    if (permissions.ENROLLMENTDESIGNS.actionAdd || permissions.ENROLLMENTDESIGNS.actionEdit) {
      return history.replace('/enrollmentdesigns');
    }
    // If the user has SMARTCARD ADD OR EDIT then redirect to SMARTCARD page
    if (permissions.SMARTCARDS.actionAdd || permissions.SMARTCARDS.actionEdit) {
      return history.replace('/smartcard');
    }

    // If the user manage credentials or enroll them then redirect to credentials
    if (permissions.CREDENTIALS.actionAdd || permissions.CREDENTIALS.actionEdit || permissions.ENROLLMENTS.actionView) {
      return history.replace('/credentials');
    }

    // If the user has SETTINGS ADD OR EDIT then redirect to external databases.
    if (permissions.SETTINGS.actionAdd || permissions.SETTINGS.actionEdit) {
      return history.replace('/externaldatabases');
    }

    // If the user has USERS permission then redirect them to the Users List
    if (permissions.USERS.actionView) {
      return history.replace('/users');
    }

    return history.replace('/myprofile');
  }, [redirect]);

  return null;
};

export default Redirector;
