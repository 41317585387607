//      

// Action Types
// =============================================================================
                          
                       
                                                                                   
                
  

             
                                                   
                                                       
                                                          
                                                        
                                                  
                            

// Initial State
// =============================================================================
export const initialState             = Object.freeze({
  lastUpdated: null,
  response: {
    errorCode: '',
    errorMessage: '',
    parameters: []
  },
  status: 200
});

// Reducers
// =============================================================================
export default (state             = initialState, action        )             => {
  switch (action.type) {
    case 'ACCOUNT_LOCKED':
    case 'HOSTNAME_NOT_FOUND':
    case 'EXPIRED_SESSION_TOKEN':
    case 'INVALID_WEB_SESSION':
    case 'RENEW_SP_USER':
      return { ...state, lastUpdated: Date.now(), ...action.payload };
    case 'CLEAR_ERROR':
      return initialState;
    default:
      return state;
  }
};

// Actions
// =============================================================================

/**
 * Save API error message to redux
 * @param {String} error Adds an API error message to Redux in order to compute
 *                       accountIsLocked, hostnameCannotBeFound, and sessionIsExpired
 */
export const saveError = (error            )         => {
  let type = error.response.errorCode || error.response.code;
  type = type.toUpperCase();

  return { type, payload: error };
};

export const clearError = ()         => {
  const type = 'CLEAR_ERROR';

  return { type };
};

// Selectors
// =============================================================================

/**
 * Determine whether or not the last error was account_locked.
 *
 * @param  {Object}  state    The root Redux Error Messages object
 * @return {Boolean}          True if the account is locked. False otherwise.
 */
export const accountIsLocked = (state            )          => {
  if (state.lastUpdated === null) {
    return false;
  }

  const errorCode = state.response.errorCode.toUpperCase();

  return errorCode === 'ACCOUNT_LOCKED' && state.status === 403;
};

/**
 * Determine whether or not the last error was hostname_not_found.
 *
 * @param  {Object}  state    The root Redux Error Messages object
 * @return {Boolean}          True if the hostname cannot be found. False otherwise.
 */
export const hostnameCannotBeFound = (state            )          => {
  if (state.lastUpdated === null) {
    return false;
  }

  const errorCode = state.response.errorCode.toUpperCase();

  return errorCode === 'HOSTNAME_NOT_FOUND' && state.status === 404;
};

/**
 * Determine whether or not the last error was expired_session_token.
 *
 * @param  {Object} state The root Redux Error Messages object
 * @return {Boolean}      True if the session token has expired. False otherwise.
 */
export const selectSessionIsExpired = (state            )          => {
  if (state.lastUpdated === null) {
    return false;
  }

  const errorCode = state.response.errorCode.toUpperCase();

  return errorCode === 'EXPIRED_SESSION_TOKEN' && state.status === 401;
};

/**
 * Determine whether or not the last error was invalid_web_session. A session is invalid when the browser session cookie
 * does not match what the server was expecting.
 *
 * @param  {Object} state The root Redux Error Messages object
 * @return {Boolean}      True if the web session is invalid. False otherwise.
 */
export const selectSessionIsInvalid = (state            )          => {
  if (state.lastUpdated === null) {
    return false;
  }

  const errorCode = state.response.errorCode.toUpperCase();

  return errorCode === 'INVALID_WEB_SESSION' && state.status === 401;
};

export const selectIsRenewSpUser = (state            ) => {
  if (state.lastUpdated === null) {
    return false;
  }

  const errorCode = state.response.errorCode.toUpperCase();

  return errorCode === 'RENEW_SP_USER' && state.status === 403;
};
