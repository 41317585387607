//      
import MuiCheckbox from '@material-ui/core/Checkbox';
                                                         

/**
 * A wrapper for using the Material-UI [Checkbox](https://material-ui.com/api/checkbox/) component with Final Form.
 */
const Checkbox = ({ input: { checked, name, onChange, ...restInput }, meta, ...rest }                  ) => (
  <MuiCheckbox {...rest} name={name} inputProps={restInput} onChange={onChange} checked={checked} />
);

export default Checkbox;
