//      
import { getTenantEntitlement } from 'src/api/tenants';
import { MAX_ENTITLEMENT_QUANTITY } from 'src/lib/constants';
import { getIsRootSpAccount } from 'src/ducks/theme';

                                                                                      
                                                           

// Action Types
// =============================================================================

                                
                     
                       
                  
                           
  

             
                                   
                                                         
                                    

// Initial State
// =============================================================================
export const initialState = Object.freeze({
  isLoading: false,
  lastUpdated: null,
  failed: false,
  entitlement: null
});

// Reducers
// =============================================================================
export default (state                   = initialState, action        )                   => {
  switch (action.type) {
    case 'ENTITLEMENT/REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'ENTITLEMENT/FAILURE':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: true };

    case 'ENTITLEMENT/SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastUpdated: Date.now(),
        failed: false,
        entitlement: {
          ...action.payload
        }
      };

    default:
      return state;
  }
};

// Actions
// =============================================================================

export const fetchTenantEntitlement = () => {
  return async (dispatch          , getState          ) => {
    if (getState().tenantEntitlement.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'ENTITLEMENT/REQUEST' });

    const id = getState().themes.subscriber.id;

    try {
      const entitlement              = await getTenantEntitlement(id, 'USERS');
      dispatch({ type: 'ENTITLEMENT/SUCCESS', payload: entitlement });
    } catch (e) {
      dispatch({ type: 'ENTITLEMENT/FAILURE' });
    }
  };
};

// Selectors
// =============================================================================

/**
 * Get the maximum number of entitlements that can be allocated from this account.
 *
 * @param  {State} state     The root Redux object
 * @return {number}          The number of entitlements that can be allocated.
 */
export const getMaxQuantity = (state       )         => {
  const isRoot = getIsRootSpAccount(state.themes);

  const { entitlement } = state.tenantEntitlement;

  if (!entitlement) {
    return 0;
  }

  return isRoot ? MAX_ENTITLEMENT_QUANTITY : entitlement.remaining;
};

/**
 * Get the maximum number of SMS/Voice entitlements that can be allocated from this
 * SP account.
 *
 * @param  {State} state     The root Redux object
 * @return {number}          The number of entitlements that can be allocated.
 */
export const getMaxQuantitySmsVoice = (state       )         => {
  const isRoot = getIsRootSpAccount(state.themes);

  if (isRoot) return MAX_ENTITLEMENT_QUANTITY;

  const { entitlement } = state.tenantEntitlement;

  if (!entitlement || !entitlement.smsVoice) {
    return 0;
  }

  return Math.max(entitlement.smsVoice.allotment - entitlement.smsVoice.consumed, 0);
};
