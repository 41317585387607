//      
                                          

// Action Types
// =============================================================================
                               
               
                
                 
                                                                     
                
                
                            
                           
  

                                                                      

                                      
                          
                   
  

// Initial State
// =============================================================================
export const initialState                         = Object.freeze({
  wizard: {
    vpn: false,
    saas: false,
    other: false,
    syncUser: null,
    fido: false,
    grid: false,
    entrustSoftToken: false,
    authenticatorApp: false
  },
  complete: false
});

// Reducers
// =============================================================================

export default (state                         = initialState, action        )                         => {
  switch (action.type) {
    case 'ONBOARDING_UPDATE':
      return { ...state, wizard: { ...action.payload }, complete: true };
    default:
      return state;
  }
};

// Actions
// =============================================================================
export const updateOnboarding = (payload                 ) => {
  return (dispatch          ) => {
    dispatch({ type: 'ONBOARDING_UPDATE', payload });
  };
};
