import { lookupAPIError } from 'src/lib/api-error-messages';
import { saveMachineAuthenticatorIfPresent } from 'src/lib/machine';
import { addErrorMessage } from 'src/ducks/messages';
import { saveError } from 'src/ducks/errors';
import { store } from 'src/store';
import { keyLookup } from 'src/i18n';

export default error => {
  if (keyLookup(error, 'response.errorCode') || keyLookup(error, 'response.code')) {
    store.dispatch(saveError(error));
  }

  // Get the REST API error message to display
  const apiErrorMessage = lookupAPIError(error);

  if (apiErrorMessage) {
    store.dispatch(addErrorMessage(apiErrorMessage));
  }

  // Some authentication API calls might throw an exception (e.g., invalid response) that might contain a
  // machine authenticator with a new sequence nonce. If so, we want to save it
  saveMachineAuthenticatorIfPresent(error);

  // Rethrow the error
  throw error;
};
