//      
import config from 'src/../config';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import apiIssuance from 'src/api/swagger/api-web-issuanceinternal.swagger';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

                                                                                                   

export const getActiveEntitlement = ()                             => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSubscriberActiveEntitlementUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getServiceBundles = ()                          => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getServiceBundlesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getServiceBundle = (type        )                          => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getServiceBundleUsingGET({
      parameters: {
        Authorization: jwtToken,
        type
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const updateFleetManagementOtpIn = () => {
  const jwtToken = getJWTToken();

  return apiIssuance.printersearch
    .updateFleetManagementOptIn({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
