//      
import { createTheme } from '@material-ui/core/styles'; // v1.x
import { getSubscriberPublic, updateSubscriber, resetAccountTheme } from 'src/api/subscribers';
import { grey } from '@material-ui/core/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createSelector } from 'reselect';

                                                    
                                                                                       
                                                   
                                                      

const defaultMuiTheme = getMuiTheme();

// Flow Type
                    
                    
                       
   
  

// Action Types
// =============================================================================
                          
                     
                       
                  
                           
                      
                         
                               
                                   
  

             
                                                                 
                                   
                                                                   
                                   
                                    
                                                                    
                                    
                                   
                                                                   
                                   
                                                     
                                   
                                                 

// Initial State
// =============================================================================
export const initialState             = Object.freeze({
  isLoading: false,
  lastUpdated: null,
  failed: false,
  authLocale: null,
  renderingLocale: null,
  isLockStatusPolling: false,
  emailLastSent: null,
  subscriber: {
    authenticationAccount: true,
    baseUrl: '',
    color: '#72A241',
    defaultLocale: 'en',
    primaryColor1: defaultMuiTheme.palette.primary1Color,
    primaryColor2: defaultMuiTheme.palette.primary2Color,
    primaryColor3: defaultMuiTheme.palette.primary3Color,
    accentColor1: defaultMuiTheme.palette.accent1Color,
    accentColor2: defaultMuiTheme.palette.accent2Color,
    accentColor3: defaultMuiTheme.palette.accent3Color,
    companyCountry: '',
    companyName: '',
    companyState: '',
    contractMode: 'TRIAL',
    countryCode: '',
    domain: null,
    enableIGMigration: false,
    hostname: '',
    id: '',
    igUrl: null,
    isSp: false,
    issuanceAccount: false,
    legalAcknowledged: false,
    logo: '',
    motd: {},
    parentHostname: '',
    salesForceId: '',
    smartLoginEnabled: false,
    subscriberRegion: 'us-east-1',
    tier: 0,
    terminated: false,
    fleetManagementOptIn: false
  }
});

// Reducers
// =============================================================================
export default (state             = initialState, action        )             => {
  switch (action.type) {
    case 'ACCOUNT_SET_LOCK_STATUS_POLLING':
      return { ...state, isLockStatusPolling: action.payload };

    case 'THEME_FETCH_REQUEST':
    case 'THEME_UPSERT_REQUEST':
    case 'THEME_RESET_REQUEST':
      return { ...state, isLoading: true, failed: false };

    case 'THEME_FETCH_FAILURE':
    case 'THEME_UPSERT_FAILURE':
    case 'THEME_RESET_FAILURE':
      return { ...state, isLoading: false, lastUpdated: Date.now(), failed: true };

    case 'THEME_FETCH_SUCCESS':
    case 'THEME_UPSERT_SUCCESS':
    case 'THEME_RESET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastUpdated: Date.now(),
        failed: false,
        subscriber: {
          ...state.subscriber,
          ...action.payload
        }
      };

    case 'RENDERING_LOCALE_CHANGE':
      return { ...state, renderingLocale: action.payload };

    case 'LOCALE_CHANGE_LOCALE':
      return { ...state, authLocale: action.payload };

    case 'LOCALE_RESET_LOCALE':
      return { ...state, authLocale: null };

    case 'USER_UPDATE_LAST_EMAIL_SENT_DATE':
      return { ...state, emailLastSent: Date.now() };

    default:
      return state;
  }
};

// Actions
// =============================================================================
export const updateEmailLastSentDate = () => {
  return { type: 'USER_UPDATE_LAST_EMAIL_SENT_DATE' };
};

export const setAccountLockStatusPolling = (polling         ) => {
  return { type: 'ACCOUNT_SET_LOCK_STATUS_POLLING', payload: polling };
};

export const fetchTheme = () => {
  return async (dispatch          , getState          ) => {
    if (getState().themes.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'THEME_FETCH_REQUEST' });

    try {
      const subscriberInfo = await getSubscriberPublic();
      dispatch({ type: 'THEME_FETCH_SUCCESS', payload: subscriberInfo });
    } catch (e) {
      dispatch({ type: 'THEME_FETCH_FAILURE' });
    }
  };
};

export const resetTheme = () => {
  return async (dispatch          , getState          ) => {
    if (getState().themes.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'THEME_RESET_REQUEST' });

    try {
      const subscriberInfo                        = await resetAccountTheme();
      dispatch({ type: 'THEME_RESET_SUCCESS', payload: subscriberInfo });
    } catch (e) {
      dispatch({ type: 'THEME_RESET_FAILURE' });
    }
  };
};

export const upsertTheme = (subscriber                       ) => {
  return async (dispatch          , getState          ) => {
    if (getState().themes.isLoading) {
      return Promise.resolve();
    }

    dispatch({ type: 'THEME_UPSERT_REQUEST' });

    try {
      const subscriberInfo = await updateSubscriber(subscriber);
      dispatch({ type: 'THEME_UPSERT_SUCCESS', payload: subscriberInfo });
    } catch (e) {
      dispatch({ type: 'THEME_UPSERT_FAILURE' });
    }
  };
};

// Change the auth locale.
// Doesn't persist changes, should only be used to change the language of the Auth Flow
export const changeLocale = (language        ) => {
  return { type: 'LOCALE_CHANGE_LOCALE', payload: language };
};

// Trigger a rerendering of the app with the new locale
export const changeRenderingLocale = (language        ) => {
  return { type: 'RENDERING_LOCALE_CHANGE', payload: language };
};

// Redux Selectors - For computing derived data
// =============================================================================

export const getPalette = createSelector([(state            ) => state], (state            ) => {
  if (state.lastUpdated === null) {
    return defaultMuiTheme;
  }

  const theme = state.subscriber;

  return getMuiTheme({
    palette: {
      primary1Color: theme.primaryColor1,
      // Use primaryColor1 as the MUI primary2Color
      primary2Color: theme.primaryColor1,
      accent1Color: theme.accentColor1,
      // Override the default accent2Color with grey300
      accent2Color: grey[300]
    },
    // Other theme overrides.
    textField: {
      // Override the default TextField label font color. Default rgba(0, 0, 0, 0.3)
      floatingLabelColor: 'rgba(0, 0, 0, 0.5)'
    },
    datePicker: {
      headerColor: theme.primaryColor1
    },
    timePicker: {
      headerColor: theme.primaryColor1
    }
  });
});

export const getTheme = createSelector(
  [(state            ) => state.subscriber],
  (theme                       )        => {
    return createTheme({
      palette: {
        primary: { main: theme.primaryColor1 },
        secondary: { main: theme.accentColor1 },
        text: {
          link: theme.primaryColor2
        }
      },
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: 12
          }
        }
      }
    });
  }
);

export function getLinkColor(state            )         {
  if (state.lastUpdated === null) {
    return defaultMuiTheme.palette.primary2Color;
  }

  return state.subscriber.primaryColor2;
}

export function getCompanyName(state            )         {
  if (state.lastUpdated === null) {
    return '';
  }
  return state.subscriber.companyName;
}

/**
 * Get the companyCounty of the subscriber. Fall back to the region since it can be null on some accounts.
 * @param {*} state The root Redux Themes object
 * @return {string} The 2 letter country code
 */
export function getCountryCode(state            )         {
  const { companyCountry } = state.subscriber;
  const country = companyCountry || getRegion(state);
  return country === 'UK' ? 'GB' : country;
}

/**
 * Get the 2 letter country code from the subscriber hostname.
 * @param {*} state The root Redux Themes object
 * @return {string} The 2 letter country code
 */
export function getRegion(state            )         {
  const { hostname } = state.subscriber;
  const splitHostname = hostname.split('.');

  if (splitHostname.length === 1) {
    return 'US';
  }

  // For vanity URL in production fall back to company country
  if (
    ![
      'US',
      'IE',
      'DE',
      'AF',
      'AU',
      'BR',
      'CA',
      'CN',
      'DK',
      'ES',
      'FR',
      'IN',
      'JP',
      'KR',
      'MX',
      'SE',
      'SG',
      'UK'
    ].includes(splitHostname[1].toUpperCase())
  ) {
    return state.subscriber.companyCountry || 'US';
  }

  return splitHostname[1].toUpperCase();
}

export const getIsRootSpAccount = (state            )          => {
  return state.subscriber.isSp && state.subscriber.parentHostname === null;
};
