//      
import config from 'src/../config';
import apiAuth from 'src/api/swagger/api-web-authentication.swagger';
import apiSmartLogin from 'src/api/swagger/api-smartlogin.swagger';
import api from 'src/api/swagger/api-web-internal.swagger';
import { checkHaveJWT, getTokenAttribute } from 'src/lib/jwt';
import { isPushAuthentication } from 'src/lib/authenticatorType';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

import { isIOS } from 'src/lib/browser';

             
                        
                          
                             
                                  
                                
                   
                                                         

             
                                      
                                    
                                   
                                 
                                
                             
                                   
                                 
                    
                              
                                 
                          
                                                   

                                                                                                  

const isRetryableError = (error       , abortSignal             ) => {
  // Retry on connection lost
  if (error instanceof TypeError && error.message.includes('Load failed')) {
    return true;
  }

  // Retry on client side timeout
  if (abortSignal.aborted) {
    return true;
  }

  return false;
};

export function authenticateChallenge(
  authenticator        ,
  token,
  userAuthenticateParms                            ,
  // 100 * 3s = 5m should be enough if iOS change the behavior
  maxRetries          = 100
)                                 {
  const jwtToken = token || getTokenAttribute();

  const shouldRetry = isIOS() && isPushAuthentication(authenticator, userAuthenticateParms?.secondFactorAuthenticator);

  const abortSignal = shouldRetry ? AbortSignal.timeout(3000) : undefined;

  return apiAuth.authentication
    .userAuthenticateUsingPOST({
      ...(shouldRetry && { signal: abortSignal }),
      parameters: {
        userAuthenticateParms,
        authenticator,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(error => {
      // With push notification, we are running into a situation where switching
      // back from the mobile app to the SP application causes the first request to
      // fail with a connection lost error on iOS mobile devices.
      if (shouldRetry && isRetryableError(error, abortSignal) && maxRetries > 0) {
        return authenticateChallenge(authenticator, token, userAuthenticateParms, maxRetries - 1);
      }
      return errorHandler(error);
    });
}

export function cancelChallenge(
  authenticator        ,
  userAuthenticateParms                            
)                                 {
  checkHaveJWT();
  const jwtToken = getTokenAttribute();

  return apiAuth.authentication
    .userAuthenticateUsingPOST({
      parameters: {
        authenticator,
        userAuthenticateParms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getChallenge = (
  authenticator         ,
  userChallengeParms                                                                                  
)                                 => {
  return apiAuth.authentication
    .userChallengeUsingPOST({
      parameters: {
        authenticator,
        userChallengeParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

//
// Smart Login API
//

export function getMobileChallenge()                            {
  return apiSmartLogin.smartlogin
    .getMobileChallengeUsingGET({
      baseUrl: config.apiSmartCredentialUrl
    })
    .catch(error => {
      // Ignore error and continue with normal authentication flow (fallback) if Smart Login is unavailable
      throw error;
    });
}

export function authMobileChallenge(parms                  )                            {
  return apiSmartLogin.smartlogin
    .authMobileChallengeUsingPOST({
      parameters: {
        MobileAuthParams: parms
      },
      baseUrl: config.apiSmartCredentialUrl
    })
    .catch(errorHandler);
}

export const queryAuthenticators = (
  userQueryParms                                 
)                                         => {
  return apiAuth.authentication
    .userAuthenticatorQueryUsingPOST({
      parameters: {
        userQueryParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(error => {
      errorHandler(error);
    });
};

//
// Password Reset API
//

export function passwordResetQuery(userId        )                                      {
  const authToken = getTokenAttribute();

  return api.internal
    .passwordResetQueryUsingPOST({
      parameters: {
        passwordResetQueryParms: { authToken, userId }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function passwordUnlockStatus()                                    {
  const authToken = getJWTToken();
  return api.internal
    .userPasswordUnlockUsingPOST({
      parameters: {
        Authorization: authToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function passwordResetChallenge(
  authenticator        ,
  parms                                                                
)                                          {
  const authToken = getTokenAttribute();

  return api.internal
    .passwordResetChallengeUsingPOST({
      parameters: {
        authenticator,
        passwordResetChallengeParms: { ...parms, authToken }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function passwordResetAuthenticate(
  authenticator        ,
  parms                                                                   
)                                             {
  const authToken = getTokenAttribute();

  return api.internal
    .passwordResetAuthenticateUsingPOST({
      parameters: {
        authenticator,
        passwordResetAuthenticateParms: { ...parms, authToken }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function passwordResetComplete(userPassword                   )                                         {
  const authToken = getTokenAttribute();

  return api.internal
    .passwordResetCompleteUsingPUT({
      parameters: {
        Authorization: authToken,
        userPassword
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function validateUnauthenticatedUserPassword(
  parms                             
)                                          {
  const authToken = getTokenAttribute();

  return api.internal
    .validateUnauthenticatedUserPasswordUsingPOST({
      parameters: {
        Authorization: authToken,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getUserAuthenticatorScopes = (
  userScopesParms                                  
)                                          => {
  const authToken = getTokenAttribute();

  return api.internal
    .userAuthenticatorScopesUsingPOST({
      parameters: {
        Authorization: authToken,
        userScopesParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const magicLinkAuthenticate = (magicToken        )                                 => {
  return api.internal
    .magicLinkAuthenticateUsingPOST({
      parameters: {
        magicToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
