//      
import * as React from 'react';

              
                       
                
  

// A simple Flex Box container that centers its content vertically and horizontally.
const SimpleWrapper = (props       ) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        flex: '1 0 auto',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.style
      }}>
      {props.children}
    </div>
  );
};

export default SimpleWrapper;
