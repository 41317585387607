//      
import config from 'src/../config';
import apiAdmin from 'src/api/swagger/api-web-administration.swagger';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import { setNonces } from 'src/lib/machine';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

// Flow Types

             
                
                 
                               
                                
                   
                    
                 
       
                 
            
             
                              
           
              
              
                  
                       
                              
                                 
           
                
                 
        
                   
               
                  
       
                        
               
                           
            
               
                    
                              
                                 
                  
           
                                                            

             
                
                       
                   
                
            
                                             
                       
                                      
                          
                                                      

export function renewJWTToken()                            {
  const jwtToken = getJWTToken();

  // Let the caller decide how to handle the error, as
  // opposed to automatically using the default errorHandler
  // in a catch.
  return apiInternal.internal
    .renewAuthenticationUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// TODO: Define UserApplicationAuthenticationQueryParameters and UserApplicationAuthenticationQueryResponse
export async function validateApplicationAccess(
  applicationId        ,
  maxAge        ,
  requestTime        ,
  machineAuthenticator
)                                                      {
  const jwtToken = getJWTToken();

  const authQueryResult = await apiInternal.internal
    .userApplicationAuthenticationQueryUsingPOST({
      parameters: {
        applicationId: applicationId,
        userQueryParms: { machineAuthenticator, maxAge, requestTime },
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);

  if (authQueryResult) {
    setNonces(authQueryResult.machineAuthenticator);

    return authQueryResult;
  }
}

export function getUserLocationHistory(userid        )                               {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .listUserLocationsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserLocationHistory(userid        , userLocationUuids               )                {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .deleteUserLocationsUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid,
        userLocationUuids
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserExpectedLocations(userid        )                                 {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .listUserExpectedLocationsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function setUserExpectedLocations(userid        , expectedLocations                         )                {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .setUserExpectedLocationsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        expectedLocations
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserRbaSettings(userid        )                           {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .getUserRbaSettingsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function setUserRbaSettings(userid        , settings                 )                {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .updateUserRbaSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function ipLocationFromIpAddress(ipLocation            )                      {
  const jwtToken = getJWTToken();

  return apiAdmin.userrbasettings
    .ipLocationFromIpAddressUsingPOST({
      parameters: {
        Authorization: jwtToken,
        ipLocation
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unassignGrid(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .unassignGridUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function assignGridbyUserId(
  gridserialnumber        ,
  userId        ,
  emailAttributeName        
)                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .assignGridByIdUsingPUT({
      parameters: {
        Authorization: jwtToken,
        gridserialnumber,
        assignParms: { userId, emailParms: { emailAttributeName } }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function assignGridBySerial(serialNumber        , id        , emailAttributeName        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .assignGridBySerialNumberUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        assignParms: { serialNumber, emailParms: { emailAttributeName } }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getGridExport = (gridid        )                      => {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .getSingleGridExportUsingGET({
      parameters: {
        Authorization: jwtToken,
        gridid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const sendGridEmail = (gridid        , emailAttributeName        )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .deliverAssignedGridByEmailUsingPOST({
      parameters: {
        Authorization: jwtToken,
        gridid,
        emailParms: {
          emailAttributeName
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getUnassignedGridsPage = (searchParms             )                     => {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .unassignedGridsPageUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getGridProperties()                          {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .getGridPropertiesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getAssignedGridsPage = (searchParms             )                     => {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .assignedGridsPageUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function createUnassignedGrids(numberOfGrids        , groups                )                       {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .createUnassignedGridsUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms: { numberOfGrids, groups }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyUnassignedGrid(gridid        , groups               )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .modifyUnassignedGridUsingPUT({
      parameters: {
        Authorization: jwtToken,
        gridid,
        parms: { groups }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createGridCardAuthenticator(userid        , emailAttributeName        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .createGridUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userid,
        createParms: { emailParms: { emailAttributeName } }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteGridCard(gridid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .deleteGridUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        gridid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getGridCard(gridid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .getGridUsingGET({
      parameters: {
        Authorization: jwtToken,
        gridid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function changeGridCardState(gridid        , state        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.grids
    .changeGridStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        gridid,
        parms: { state }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createSmartCredentialAuthenticator(toCreate                      )                           {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .createSmartCredentialUsingPOST({
      parameters: {
        Authorization: jwtToken,
        toCreate
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSmartCredentialAuthenticator(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .deleteSmartCredentialUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const activateSmartCredentialAuthenticator = (
  id        ,
  parms                              
)                                         => {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .activateSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getSmartCredential(id        , revocationInfo          = false)                           {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .getSmartCredentialUsingGET({
      parameters: {
        Authorization: jwtToken,
        id,
        revocationInfo
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSmartCredential(id        , toUpdate                      )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .updateSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        toUpdate
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function changeSmartCredentialState(id        , state        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .changeSmartCredentialStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms: { state }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unassignSmartCredential(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .unassignSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unblockSmartCredential(
  id        ,
  parms                             
)                                          {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .unblockSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function revokeCertificate(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .revokeCertificateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function holdCertificate(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .holdCertificateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unholdCertificate(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .unholdCertificateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function exportCertificate(id        , format        )                             {
  const jwtToken = getJWTToken();

  return apiAdmin.smartcredentials
    .exportCertificateUsingGET({
      parameters: {
        Authorization: jwtToken,
        id,
        format
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function assignTokenbyUserId(tokenid        , userId        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .assignTokenByIdUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        assignParms: { userId }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function assignTokenBySerial(serialNumber        , id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .assignTokenBySerialNumberUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        assignParms: { serialNumber }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unassignToken(tokenid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .unassignTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const setTokenLabel = (tokenid        , label        )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .modifyAssignedTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { label }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function deleteUserPassword(userid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .deleteUserPasswordUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserPassword(userid        )                        {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .getUserPasswordUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function setUserPassword(userid        , userPassword                   )                        {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .setUserPasswordUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        userPassword
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getMachineAuthenticators(userid        )                                           {
  const jwtToken = getJWTToken();

  return apiAdmin.machineauth
    .listMachineAuthenticatorsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteMachineAuthenticator(userid        , id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.machineauth
    .deleteMachineAuthenticatorUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getAllAttributes()                                {
  const jwtToken = getJWTToken();

  return apiAdmin.userattributes
    .listUserAttributesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createAttribute(userAttribute               )                         {
  const jwtToken = getJWTToken();

  return apiAdmin.userattributes
    .createUserAttributeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttribute
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyAttribute(id        , userAttribute               )                         {
  const jwtToken = getJWTToken();

  return apiAdmin.userattributes
    .modifyUserAttributeUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        userAttribute
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteAttribute(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.userattributes
    .deleteUserAttributeUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyUserAuthGroups(userid        , groupIds        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.groups
    .modifyUserAuthorizationGroupAssociationsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        groupIds
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyUserOAuthRoles(userid        , oauthRoleIds        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.oauthroles
    .modifyUserOAuthRoleAssociationsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        oauthRoleIds
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getUsersPage = (searchParms             )                     => {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .usersPagedUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getUserByUserID(userId        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .userByUseridUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms: { userId }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUser(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .userUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateUser(id        , user           )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .updateUserUsingPUT({
      parameters: {
        id,
        user,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateUserState(id        , state        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .updateUserStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        user: { state }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unlockUser(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .unlockUserUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createUser(user           )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .createUserUsingPOST({
      parameters: {
        Authorization: jwtToken,
        user
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUser(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .deleteUserUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// "ENTRUST_PHYSICAL_TOKEN", "ENTRUST_SOFT_TOKEN", "GOOGLE_AUTHENTICATOR", "OATH_PHYSICAL_TOKEN", "ENTRUST_LEGACY_TOKEN"
export function createToken(userid        , type        , parms                  )                 {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .createTokenUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userid,
        type,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function startActivateToken(tokenid        , activateParms               )                          {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .startActivateTokenUsingPOST({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        activateParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function completeActivateToken(tokenid        , registrationCode        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .completeActivateTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { registrationCode }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getToken(tokenid        )                 {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .getTokenUsingGET({
      parameters: {
        Authorization: jwtToken,
        tokenid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getTokenActivationContent = (tokenid        , useruuid        )                                    => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSoftTokenManualActivateContentUsingGET({
      parameters: {
        Authorization: jwtToken,
        useruuid,
        tokenid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function modifyToken(tokenid        , groups               )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .modifyTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { groups }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteToken(tokenid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .deleteTokenUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        tokenid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unlockToken(tokenid        , unlockChallenge        )                        {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .unlockTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { unlockChallenge }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function changeTokenState(tokenid        , state        )                 {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .changeTokenStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { state }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function syncToken(tokenid        , response        )                 {
  const jwtToken = getJWTToken();

  return apiAdmin.tokens
    .resetTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { response }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserKba(userid        )                                       {
  const jwtToken = getJWTToken();

  return apiAdmin.kba
    .getUserKbaUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserKba(userid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.kba
    .deleteUserKbaUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserKbaQuestion(userid        , id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.kba
    .deleteUserKbaQuestionUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserKbaChallenges(userid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.kba
    .deleteUserKbaChallengesUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createTempAccessCode(userid        )                          {
  const jwtToken = getJWTToken();

  return apiAdmin.tempaccesscodes
    .createTempAccessCodeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteTempAccessCode(userid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.tempaccesscodes
    .deleteTempAccessCodeUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteFIDOToken(fidoid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.fidotokens
    .deleteFIDOTokenUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        fidoid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateFIDOToken(fidoid        , parms                )                {
  const jwtToken = getJWTToken();

  return apiAdmin.fidotokens
    .updateFIDOTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        fidoid,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function validateUserPassword(
  userid        ,
  parms                             
)                                          {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .validateUserPasswordUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userid,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function syncUser(syncUserParms               )                    {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .syncUserUsingPOST({
      parameters: {
        Authorization: jwtToken,
        syncUserParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unsyncUser(unsyncUserParms                 )                {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .unsyncUserUsingPOST({
      parameters: {
        Authorization: jwtToken,
        unsyncUserParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getCachedActiveSyncDevices(userid        )                              {
  const jwtToken = getJWTToken();

  return apiAdmin.devices
    .getCachedActiveSyncDevicesUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getActiveSyncDevices(userid        )                              {
  const jwtToken = getJWTToken();

  return apiAdmin.devices
    .getActiveSyncDevicesUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateActiveSynDevice(userid        , parms          )                              {
  const jwtToken = getJWTToken();

  return apiAdmin.devices
    .updateActiveSyncDevicesUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function removeActiveSyncDevice(userid        , deviceid        )                              {
  const jwtToken = getJWTToken();

  return apiAdmin.devices
    .removeActiveSyncDeviceUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userid,
        deviceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getDesktopClientByUserUuid(userUuid        )                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getDesktopClientByUserUuidUsingGET({
      parameters: {
        Authorization: jwtToken,
        userUuid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getDesktopClientById(id        )                         {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getDesktopClientByIdUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteDesktopClientById(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteDesktopClientUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserResourceServerApiScopes(userid        )                                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getUserResourceServerApiScopesUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserPasswordSettings(userid        )                                {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .getUserPasswordSettingsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const sendPasswordExpiryNotification = (userid        )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.passwords
    .sendPasswordExpiryNotificationUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getSmartCard(id        )                     {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .getSmartCardUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifySmartCard(id        , groups               )                {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .modifySmartCardUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms: { groups }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSmartCard(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSmartCardUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const createMagicLink = (userid        , email        )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.users
    .createMagicLinkUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userid,
        MagicLinkCreateParms: { email, autoSend: true, redirectUrl: '' }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getUserFaceSettings = (userid        )                            => {
  const jwtToken = getJWTToken();

  return apiAdmin.face
    .getUserFaceSettingsUsingGET({
      parameters: {
        Authorization: jwtToken,
        userid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const usersPagedAsync = (searchParms             )                                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .usersPagedAsyncUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const usersPagedAsyncStatus = (id        )                                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .usersPagedAsyncStatusUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const usersPagedAsyncResult = (id        )                     => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .usersPagedAsyncResultUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const assignedGridsPageAsync = (searchParms             )                                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .assignedGridsPageAsyncUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const assignedGridsPageAsyncStatus = (id        )                                => {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .assignedGridsPageAsyncStatusUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const assignedGridsPageAsyncResult = (id        )                     => {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .assignedGridsPageAsyncResultUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const unassignedGridsPageAsync = (searchParms             )                                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .unassignedGridsPageAsyncUsingPOST({
      parameters: {
        Authorization: jwtToken,
        searchParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const unassignedGridsPageAsyncStatus = (id        )                                => {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .unassignedGridsPageAsyncStatusUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const unassignedGridsPageAsyncResult = (id        )                     => {
  const jwtToken = getJWTToken();
  return apiInternal.internal
    .unassignedGridsPageAsyncResultUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
