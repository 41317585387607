import * as React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIconProps } from '@material-ui/core';

// =============================================================================
// Linear Progress loading component
// =============================================================================

const Loading = () => (
  <Box padding={10}>
    <LinearProgress variant="indeterminate" />
  </Box>
);

export default Loading;

// =============================================================================
// Circular Progress loading component
// =============================================================================

export const CircularLoading = () => (
  <Box margin={2} textAlign="center">
    <CircularProgress />
  </Box>
);

// =============================================================================
// Circular Progress loading component spinning around an icon
// =============================================================================

interface LoadingSpinnerIconProps {
  Icon: React.ComponentType<SvgIconProps>;
}

const useStyles = makeStyles(theme => ({
  fabProgress: {
    position: 'absolute',
    top: theme.spacing(-1),
    left: theme.spacing(-1)
  }
}));

export const LoadingSpinnerIcon = ({ Icon }: LoadingSpinnerIconProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" margin={1}>
      <Box margin={1} position="relative" fontSize="3rem">
        <Icon color="primary" fontSize="inherit" />
        <CircularProgress className={classes.fabProgress} size="4rem" thickness={3} />
      </Box>
    </Box>
  );
};
