import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material-UI Components
// =============================================================================
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

// External Functions
// =============================================================================
import {
  closeMessage,
  getNextMessage,
  getNextMessageAction,
  isNextMessageAnError,
  getNextMessageRedirectURL
} from 'src/ducks/messages';
import { useActions } from 'src/lib/hooks';

import { State } from 'src/ducks';

const useClasses = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
    verticalAlign: 'middle'
  }
}));

const MessageBox = () => {
  const classes = useClasses();

  const history = useHistory();

  const action = useSelector((state: State) => getNextMessageAction(state.messages));
  const isError = useSelector((state: State) => isNextMessageAnError(state.messages));
  const open = useSelector((state: State) => state.messages.length > 0);
  const message = useSelector((state: State) => getNextMessage(state.messages));
  const value = useSelector((state: State) => getNextMessageRedirectURL(state.messages));

  const { closeMessage: hideMessage } = useActions({ closeMessage });

  const onActionClick = () => {
    history.push(value);
  };

  const icon = open ? (
    isError ? (
      <CloseIcon className={classes.icon} htmlColor={red[500]} />
    ) : (
      <CheckIcon className={classes.icon} htmlColor={green[500]} />
    )
  ) : null;

  const messageNode = (
    <div>
      {icon}
      {message}
    </div>
  );

  const snackbarAction = action ? (
    <Button key={action} color="secondary" onClick={onActionClick} size="small">
      {action}
    </Button>
  ) : undefined;

  return (
    <Snackbar
      id="comp-ErrorMessageBox"
      key={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={10000}
      onClose={hideMessage}>
      <SnackbarContent action={snackbarAction} message={messageNode} />
    </Snackbar>
  );
};

export default MessageBox;
