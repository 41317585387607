//      

import config from 'src/../config';
import apiAdmin from 'src/api/swagger/api-web-administration.swagger';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import apiIssuance from 'src/api/swagger/api-web-issuanceinternal.swagger';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

// Flow Types
             
                  
                                 
                              
                           
                         
                                                         
             
                                    
                      
               
                           
                       
                  
                      
              
                                          
                            
                            
                 
                      
                                    
              
              
                               
                    
                       
                         
                   
                                  
                        
                   
           
            
              
                       
                     
              
                          
                         
                 
                     
                       
          
             
                                                   

                                                                                                 

// Get General Settings
// =============================================================================
export function getGeneralSettings()                           {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getGeneralSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set General Settings
// =============================================================================
export function setGeneralSettings(settings                 )                {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .updateGeneralSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get OTP Settings
// =============================================================================
export function getOTPAuthenticatorSettings()                                    {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getOTPAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set OTP Settings
// =====================================================================  ========
export function setOTPAuthenticatorSettings(settings                          )                {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .updateOTPAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Preferred OTP Provider
// =============================================================================
export const getPreferredOTPProvider = (id        )                  => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getPreferredOTPProviderUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Set Preferred OTP Provider
// =============================================================================
export const setPreferredOTPProvider = (id        , settings                  )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setPreferredOTPProviderUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get SP Identity Provider Info
// =============================================================================
export const getSpIdentityProviderInfo = (id        )                  => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSpIdentityProviderInfoUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get SP Identity Provider
// =============================================================================
export const getSpIdentityProvider = (id        )                  => {
  const jwtToken = getJWTToken();

  return apiAdmin.tenants
    .getSpIdentityProviderUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Set SP Identity Provider
// =============================================================================
export const setSpIdentityProvider = (id        , spIdentityProviderParms                         )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.tenants
    .setSpIdentityProviderUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        spIdentityProviderParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get Temp. Access Code Settings
// =============================================================================
export function getTempAccessCodeSettings()                                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getTempAccessCodeSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Temp. Access Code Settings
// =============================================================================
export const setTempAccessCodeSettings = (settings                        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateTempAccessCodeSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get Grid Settings
// =============================================================================
export function getGridAuthenticatorSettings()                                     {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getGridAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Grid Settings
// =============================================================================
export function setGridAuthenticatorSettings(settings                           )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateGridAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Entrust ST Settings
// =============================================================================
export function getEntrustSTAuthenticatorSettings()                                          {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getEntrustSTAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Entrust ST Settings
// =============================================================================
export function setEntrustSTAuthenticatorSettings(settings                                )                {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .updateEntrustSTAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Google Authenticator Settings
// =============================================================================
export function getGoogleAuthenticatorSettings()                                       {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getGoogleAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Google Authenticator Settings
// =============================================================================
export function setGoogleAuthenticatorSettings(settings                             )                {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .updateGoogleAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Password Settings
// =============================================================================
export function getPasswordAuthenticatorSettings()                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getPasswordAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Password Settings
// =============================================================================
export function updatePasswordAuthenticatorSettings(settings                  )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updatePasswordAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Machine Authenticator Settings
// =============================================================================
// TODO: Missing Annotations
export function getMachineAuthenticatorSettings()                                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getMachineAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Machine Authenticator Settings
// =============================================================================
export function updateMachineAuthenticatorSettings(
  settings                              
)                                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateMachineAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Hardware OATH Settings
// =============================================================================
// TODO: Missing Annotations
export function getHardwareOathAuthenticatorSettings()                                             {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getHardwareOathAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Hardware OATH Settings
// =============================================================================
export function setHardwareOathAuthenticatorSettings(settings                                   )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateHardwareOathAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Entrust Legacy Token Settings
// =============================================================================
export function getEntrustLegacyTokenAuthenticatorSettings()                                                   {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getEntrustLegacyTokenAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Entrust Legacy Token Settings
// =============================================================================
export function setEntrustLegacyTokenAuthenticatorSettings(
  settings                                         
)                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateEntrustLegacyTokenAuthenticatorSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Device Attributes
// =============================================================================
export function getDeviceAttributes(platform        )                                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getDeviceAttributesUsingGET({
      parameters: {
        Authorization: jwtToken,
        platform
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Device Attributes
// =============================================================================
export function updateDeviceAttributes(
  platform        ,
  attributes                        
)                                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateDeviceAttributesUsingPUT({
      parameters: {
        Authorization: jwtToken,
        platform,
        attributes
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Smart Credential Settings
// =============================================================================
// TODO: Missing annotations
export function getSmartCredentialSettings()                                   {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSmartCredentialSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Smart Credential Settings
// =============================================================================
export function updateSmartCredentialSettings(settings                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSmartCredentialSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}
// Get Mobile SDK Settings
// =============================================================================
export function getMobileSDKSettings()                             {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getMobileSDKSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Mobile SDK Settings
// =============================================================================
export function setMobileSDKSettings(settings                   )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setMobileSDKSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Delete Mobile SDK Settings
// =============================================================================
export function deleteMobileSDKSettings()                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteMobileSDKSettingsUsingDELETE({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get FIDO Settings
// =============================================================================
export function getFIDOSettings()                                     {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getFIDOSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update FIDO Settings
// =============================================================================
export function updateFIDOSettings(settings                           )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateFIDOSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get RBA Settings
// =============================================================================
// TODO: Missing annotations
export function getRbaSettings()                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getRbaSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set RBA Settings
// =============================================================================
export function updateRbaSettings(settings             )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateRbaSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get KBA Settings
// =============================================================================
export function getKbaSettings()                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getKbaSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set KBA Settings
// =============================================================================
export function updateKbaSettings(settings             )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateKbaSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get KBA Questions
// =============================================================================
export function getKbaQuestions()                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .listKbaQuestionsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Add KBA Question
// =============================================================================
export function addKbaQuestion(question          )                    {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .addKbaQuestionUsingPOST({
      parameters: {
        question,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Delete KBA Question
// =============================================================================
export function deleteKbaQuestion(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteKbaQuestionUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update KBA Question
// =============================================================================
export function updateKbaQuestion(question          )                    {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateKbaQuestionUsingPUT({
      parameters: {
        question,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get KBA Word Maps
// =============================================================================
export function getKbaWordMaps()                          {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .listKbaWordMapsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Add a KBA WordSynonym
// =============================================================================
export function addKbaWordSynonym(locale        , wordSynonym             )                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .addKbaWordSynonymUsingPOST({
      parameters: {
        locale,
        wordSynonym,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Delete a KBA WordSynonym
// =============================================================================
export function deleteKbaWordSynonym(locale        , uuid        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteKbaWordSynonymUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        locale,
        uuid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update a KBA WordSynonym
// =============================================================================
export function updateKbaWordSynonym(locale        , wordSynonym             )                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateKbaWordSynonymUsingPUT({
      parameters: {
        locale,
        wordSynonym,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Password Reset Settings
// =============================================================================
export function getPasswordResetSettings()                                 {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getPasswordResetSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Set Password Reset Settings
// =============================================================================
export function updatePasswordResetSettings(settings                       )                {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .updatePasswordResetSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Password Blocklist
// =============================================================================
export function getPasswordBlocklist()                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getBlacklistedPasswordsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Add/Remove Password Blocklist Values from list.
// =============================================================================
export function updatePasswordBlocklist(
  passwordBlocklist                            
)                                      {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateBlacklistedPasswordsUsingPUT({
      parameters: {
        blacklistedPasswordList: passwordBlocklist,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Registration Settings
// =============================================================================
export function getRegistrationSettings()                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getRegistrationSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Registration Settings
// =============================================================================
export function setRegistrationSettings(settings                      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setRegistrationSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Verification Settings
// =============================================================================
export function getVerificationSettings()                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getVerificationSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Verification Settings
// =============================================================================
export function setVerificationSettings(settings                      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setVerificationSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Tenant Settings
// =============================================================================
export const getTenantSettings = ()                          => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getTenantSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Update Tenant Settings
// =============================================================================
export const setTenantSettings = (settings                )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setTenantSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get User Portal Settings
// =============================================================================
export function getUserPortalSettings()                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getUserPortalSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update User Portal Settings
// =============================================================================
export function setUserPortalSettings(settings                    )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setUserPortalSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getEmailSettings()                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getEmailServerSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateEmailServerSettings(settings                     )                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateEmailServerSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function testEmailServerConnection(emailServerSettings                     )                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .testCustomEmailServerConnectionUsingPOST({
      parameters: {
        emailServerSettings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function authorizeEmailServer(emailServerSettings                     )                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .emailServerAuthorizeUsingPOST({
      parameters: {
        emailServerSettings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getOauthSettingsInCache(key        )                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getEmailServerSettingsInCacheUsingGET({
      parameters: {
        key,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getOauthConfiguration(parms                        )                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .fetchOidcConfigurationForEmailServerUsingPOST({
      parameters: {
        parms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getOAuthUsercode = ()                  => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getUserCodeUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getSecureDeviceProvisioningSettings()                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSDPSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSecureDeviceProvisioningSettings(settings             )                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSDPSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function testActiveSyncAccessConnection(sdpConnectionParms                    )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .testSdpConnectionUsingPOST({
      parameters: {
        sdpConnectionParms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Notification Settings
// =============================================================================
export function getNotificationSettings()                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getNotificationSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Notification Settings
// =============================================================================
export function updateNotificationSettings(settings                      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateNotificationSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get User Notification Settings
// =============================================================================
export const getUserNotificationSettings = ()                                    => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getUserNotificationSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Update User Notification Settings
export const updateUserNotificationSettings = (settings                          )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateUserNotificationSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get Mobile Flash Pass Settings (Issuance)
// =============================================================================
export function getMobileFlashPassSettings()                                   {
  const jwtToken = getJWTToken();

  return apiIssuance.issuancesettings
    .getMobileFlashPassSettings({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Update Mobile Flash Pass Settings (Issuance)
// =============================================================================
export function updateMobileFlashPassSettings(settings                         )                {
  const jwtToken = getJWTToken();

  return apiIssuance.issuancesettings
    .updateMobileFlashPassSettings({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// CORS Settings
// =============================================================================
export const getCorsSettings = ()                        => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getCorsSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const updateCorsSettings = (settings              )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateCorsSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Authenticator Provisioning Settings
// =============================================================================
export function getAuthenticatorProvisioningSettings()                                             {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getAuthenticatorProvisioningSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const updateAuthenticatorProvisioningSettings = (settings                                   )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateAuthenticatorProvisioningSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get IP Lists
// =============================================================================
export function getIPLists()                         {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getIpListsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Add IP List
// =============================================================================
export function addIPList(ipList             )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createIpListUsingPOST({
      parameters: {
        Authorization: jwtToken,
        ipList
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Delete IP List
// =============================================================================
export const deleteIPList = (force         , id        )                                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteIpListUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        force,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Update IP List
// =============================================================================
export function updateIPList(id        , ipList             )                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateIpListUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        ipList
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Get Magic Link Settings
// =============================================================================
export const getMagicLinkSettings = ()                             => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getMagicLinkSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Set Magic Link Settings
// =============================================================================
export const setMagicLinkSettings = (settings                   )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setMagicLinkSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Set User Certificate Authenticator Settings
// =============================================================================
export const setUserCertificateAuthenticatorSettings = (settings                               )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setUserCertAuthenticatorSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get User Certificate Authenticator Settings
// =============================================================================
export const getUserCertificateAuthenticatorSettings = ()                                         => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getUserCertAuthenticatorSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get/Set FACE Settings
// =============================================================================
export const getFaceSettings = ()                        => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getFaceSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const setFaceSettings = (settings              )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setFaceSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const getFaceAccountSettings = ()                               => {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .getFaceAccountSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const setFaceAccountSettings = (settings                     )                => {
  const jwtToken = getJWTToken();

  return apiAdmin.settings
    .setFaceAccountSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get/Set PASSTHROUGH Settings
// =============================================================================
export const getPassthroughSettings = ()                                           => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getPassthroughSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const setPassthroughSettings = (settings                                 )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setPassthroughSettingsUsingPUT({
      parameters: {
        settings,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Get Group Attribute Settings
// =============================================================================
export const getGroupAttributeSettings = ()                          => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getGroupAttributeSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// Set Group Attribute Settings
// =============================================================================
export const setGroupAttributeSettings = (settings                     )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setGroupAttributeSettingsUsingPUT({
      parameters: {
        Authorization: jwtToken,
        settings
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
