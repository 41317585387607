// Material-UI Components
// =============================================================================
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LockedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import t from 'src/i18n';

const useStyles = makeStyles({
  icon: {
    fontSize: 100,
    margin: 16,
    width: 64,
    height: 64
  }
});

const LockedAccount = () => {
  const classes = useStyles();

  return (
    <Box alignItems="center" justifyContent="center" flex="1 1 auto" display="flex">
      <Container maxWidth="md">
        <Box display="flex" alignItems="center">
          <LockedIcon className={classes.icon} />
          <Typography variant="h5">{t('restApiErrors.account_locked')}</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default LockedAccount;
