//      
import { store } from 'src/store';

import { da, de, es, fr, it, ja, ko, nb, nl, pl, pt, ru, sv, th, tr, zhCN, zhTW } from 'date-fns/locale';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  format,
  formatDistance,
  formatDuration,
  intervalToDuration
} from 'date-fns';

// External Functions
// =============================================================================
import { toMs } from 'src/components/final-form/Duration';
import { getLocale } from 'src/ducks/locale';
import { isEmpty } from 'src/lib/smart';

// Flow Types
// =============================================================================
                                                               

const DATE_FORMAT = 'dd MMM yyyy HH:mm:ss';

// Remove spaces and colons from the filename
const FILE_DATE_FORMAT = 'yyyyMMddHHmmss';

const DATE_REGEX = /^([+-])(\d+)([YMDhmsw])$/;

// Get the date-fns locale
const getDateLocale = () => {
  // Get the locale from Redux using the getLocale selector
  const locale = getLocale(store.getState());

  switch (locale) {
    case 'da':
      return da;
    case 'de':
      return de;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'ja':
      return ja;
    case 'ko':
      return ko;
    case 'nb':
      return nb;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'sv':
      return sv;
    case 'th':
      return th;
    case 'tr':
      return tr;
    case 'zh-cn':
      return zhCN;
    case 'zh-tw':
      return zhTW;
    default:
      'en';
  }
};

export const formatDate = (dateString        , notSet         = '', dateFormat         = DATE_FORMAT) => {
  if (isEmpty(dateString)) {
    return notSet;
  }

  const locale = getDateLocale();

  return format(formatDateToZulu(dateString), dateFormat, { locale });
};

export const formatRelativeDate = (dateString        , notSet         = '') => {
  if (isEmpty(dateString)) {
    return notSet;
  }

  const locale = getDateLocale();

  return formatDistance(formatDateToZulu(dateString), new Date(), { addSuffix: true, locale });
};

export const formatFileDate = (date      ) => {
  return format(date, FILE_DATE_FORMAT);
};

/**
 * Helper function to convert durations into human readable format.
 *
 * @param {number} duration - The duration value in number
 * @param {string} unit - The unit measurement in ms, sec, min, hr, day
 */
export const formatRelativeDuration = (duration        , unit      ) => {
  return formatDuration(intervalToDuration({ start: 0, end: toMs(duration, unit) }));
};

/**
 * Strip off the date timezone offset and append Zulu.
 * Some of the date string returned from the API use the old java.util.date instead LocaleDateTime
 * return in Safari and IE unable to parse the +0000 timezone portion of the string.
 * */
export const formatDateToZulu = (date        )       => {
  // Check if the date string is in Zulu time
  return date.endsWith('Z') ? new Date(date) : new Date(date.split('+').shift().concat('Z'));
};

/**
 * Helper function to get dates using a simple syntax instead of manually manipulating a date object.
 *
 * Syntax is as follows: "[modifier][number][unit]"
 *
 * modifier: must be either + or -
 * number: the number to add or subtract from today's date
 * unit: the units to increment or decrement by. This value follows the ISO-8601 standard for tokens.
 * Must be one of the following:
 *    Y: year
 *    M: month
 *    D: day
 *    h: hours
 *    m: minutes
 *    s: seconds
 *    w: weeks
 *
 * Examples:
 *
 *   "+1D" will return tomorrow's date
 *   "-1D" will return yesterday's date
 *   "+3M" will return you the date 3 month's from now
 *
 * @export
 * @param {string} value
 * @returns {Date}
 */
export const getDate = (value        )       => {
  const result = DATE_REGEX.exec(value);

  if (result === null) {
    throw new Error('Invalid format supplied to getDate ' + value);
  }

  let [, modifier, number, unit] = result;

  let date = new Date();

  if (modifier === '-') {
    number = Number(number) * -1;
  } else {
    number = Number(number);
  }

  switch (unit) {
    case 'Y':
      date = addYears(date, number);
      break;
    case 'M':
      date = addMonths(date, number);
      break;
    case 'D':
      date = addDays(date, number);
      break;
    case 'h':
      date = addHours(date, number);
      break;
    case 'm':
      date = addMinutes(date, number);
      break;
    case 's':
      date = addSeconds(date, number);
      break;
    case 'w':
      date = addWeeks(date, number);
      break;
  }

  return date;
};
