import { useSelector } from 'react-redux';

// Material-UI Components
// =============================================================================
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';

// Entrust Components
// =============================================================================
import ExternalLink from 'src/components/Link/ExternalLink';
import StatusIcon from '@edc/react-ui/StatusIcon';

// External Functions
// =============================================================================
import {
  closeBanner,
  checkNextBannerOpen,
  getNextBannerMessage,
  getNextBannerSeverity,
  getNextBannerReferenceLink
} from 'src/ducks/banner';
import { useActions } from 'src/lib/hooks';
import t from 'src/i18n';

//  Flow Types
// =============================================================================
import { State as ReduxState } from 'src/ducks';

const useStyles = makeStyles(() => ({
  message: {
    display: 'flex'
  },
  root: {
    position: 'relative',
    top: 0,
    width: '100%'
  }
}));

const Banner = () => {
  const message = useSelector((state: ReduxState) => getNextBannerMessage(state.banner));
  const severity = useSelector((state: ReduxState) => getNextBannerSeverity(state.banner));
  const referenceLink = useSelector((state: ReduxState) => getNextBannerReferenceLink(state.banner));
  const open = useSelector((state: ReduxState) => checkNextBannerOpen(state.banner));

  const { closeBanner: hideBanner } = useActions({ closeBanner });

  const classes = useStyles();

  return (
    <Snackbar
      id="comp-Banner"
      key={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: classes.root }}
      open={open}>
      <SnackbarContent
        message={
          <Box display="flex" justifyContent="center">
            <StatusIcon severity={severity} />
            <Typography variant="body2">{message}</Typography>
          </Box>
        }
        action={
          <>
            {referenceLink && (
              <ExternalLink href={referenceLink}>
                <Button color="inherit" size="small">
                  {t('button.learnMore')}
                </Button>
              </ExternalLink>
            )}
            <Button color="inherit" size="small" onClick={hideBanner}>
              {t('button.dismiss')}
            </Button>
          </>
        }
        classes={{ message: classes.message, root: classes.root }}
      />
    </Snackbar>
  );
};

export default Banner;
