export const CHARACTER_RESTRICTION = Object.freeze({
  ALLOWED: 'ALLOWED',
  REQUIRED: 'REQUIRED',
  NOT_ALLOWED: 'NOT_ALLOWED',
  NOT_SET: 'NOT_SET'
});

export const SIGNATURE_ALGORITHM = Object.freeze({
  RSA_SHA1: 'RSA SHA1',
  RSA_SHA224: 'RSA SHA224',
  RSA_SHA256: 'RSA SHA256',
  RSA_SHA384: 'RSA SHA384',
  RSA_SHA512: 'RSA SHA512',
  RSA_RECOMMENDED: 'RSA RECOMMENDED',
  RSA_RECOMMENDED_NO_SHA224: 'RSA RECOMMENDED NO SHA224',
  RSAPSS_SHA1: 'RSAPSS SHA1',
  RSAPSS_SHA224: 'RSAPSS SHA224',
  RSAPSS_SHA256: 'RSAPSS SHA256',
  RSAPSS_SHA384: 'RSAPSS SHA384',
  RSAPSS_SHA512: 'RSAPSS SHA512',
  RSAPSS_RECOMMENDED: 'RSAPSS RECOMMENDED',
  RSAPSS_RECOMMENDED_NO_SHA224: 'RSAPSS RECOMMENDED NO SHA224'
});

export const isEmpty = (value: unknown): boolean => {
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return value === undefined || value === null || value === '';
};

export const isNumber = (value: unknown): boolean => {
  return (
    !isEmpty(value) && Number.isInteger(Number(value)) && String(value).indexOf('.') === -1 && !Array.isArray(value)
  );
};

export const parseNumber = (value: unknown): number | string => {
  if (!isNumber(value)) {
    return '';
  }

  return Number(value);
};

export const arrayReducer =
  (field: string, value: string | null) => (accumulator: Array<unknown>, a: { [index: string]: unknown }) => {
    if (a[field] !== value) {
      accumulator.push(a[field]);
    }
    return accumulator;
  };

export function getRootAccount(hostname: string = ''): string {
  const defaultRoot = 'entrust.us.trustedauth.com';

  // Returns ["https://test", "us", "dev", "trustedauthdev", "com"]
  const split = hostname.split('.');

  // eg. "localhost".split('.') === ['localhost']
  // Don't redirect.
  if (split.length === 1) {
    return defaultRoot;
  }

  // eg. 192.168.0.1
  if (split.every(field => isNumber(field))) {
    return defaultRoot;
  }

  if (hostname === 'notify.entrust.com') {
    return defaultRoot;
  }

  // Remove the account from the hostname
  // split = ["us", "dev", "trustedauthdev", "com"]
  split.shift();

  // Add the root account
  // split = ["entrust", "us", "dev", "trustedauthdev", "com"]
  split.unshift('entrust');

  return split.join('.');
}

export function redirectToRootAccount() {
  const hostname = window.location.hostname; // test.us.dev.trustedauthdev.com
  const protocol = window.location.protocol; // https:

  // This will only happen if someone is doing local UI development
  if (hostname === 'localhost') {
    return;
  }

  const rootAccount = getRootAccount(hostname);

  // Redirect to entrust.us.dev.trustedauthdev.com
  window.location.href = `${protocol}//${rootAccount}`;
}

/**
 * Utility sort functions for sorting object based on a field.
 * - sortBy: sorts alphabetically string fields
 * - sortByNumber: sort numbers in ascending order
 */
const collater = new Intl.Collator();
export const sortBy = (field: string) => (a: { [index: string]: string }, b: { [index: string]: string }) =>
  collater.compare(a[field], b[field]);

export const sortByNumber = (field: string) => (a: { [index: string]: number }, b: { [index: string]: number }) =>
  a[field] - b[field];

export const base64ToBinary = (base64: string): Uint8Array => {
  const binary = atob(base64);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return view;
};

export const humanFileSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${Number((size / Math.pow(1024, i)).toFixed(2)) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const getTenantURL = (hostname: string, isSpIdp: boolean): string => {
  const { protocol, port } = window.location;

  const url = `${protocol}//${hostname}`;

  // For tenant management enabled tenants, the below action parameter is added to the url.
  // A url with an action would look like: https://entrust.es.dev.trustedauthdev.com/#/?action=sp
  const action = '#/?action=sp';

  if (port) {
    return isSpIdp ? `${url}:${port}${action}` : `${url}:${port}`;
  }

  return isSpIdp ? url + action : url;
};

export const getDataURL = (image: string): string => {
  if (!image.includes('data:image')) {
    return `data:image;base64,${image}`;
  }

  return image;
};
