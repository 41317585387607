//      
import t, { ts } from 'src/i18n';

                                                                      
                                                                                           

                 
                             
  

/*
 * Given an API error code, return the
 * associated error message
 */
export const lookupAPIError = (apiError          ) => {
  // for errors returned from the issuance API, we don't do any of the special processing defined below for regular
  // Entrust IDaaS errors.  The error messages are also defined in a separate element in the error message file
  if (apiError?.response?.code) {
    //$FlowFixMe
    if (apiError.response.code === 'FLEET_MANAGEMENT_NOT_OPTED_IN') {
      return null;
    }
    if (apiError.response.code === 'ISSUANCE_CREDENTIAL_RESERVED_KEYWORD_ERROR') {
      return ts(`restIssuanceApiErrors.${apiError.response.code}`, 'none', apiError?.response?.message);
    }
    return t(`restIssuanceApiErrors.${apiError.response.code}`);
  }

  //$FlowFixMe
  const key         =
    apiError?.response?.errorCode || (apiError?.status === 429 ? 'too_many_requests' : 'general_error');

  // A bit of a hack; if we are attempting to COMPLETE an authentication for a PUSH (meaning
  // we're checking if the PUSH has been acknowledged) and the user cancels/concerns or changes
  // authenticator level, we may get a 404 'no_transaction' back. We don't want to display this,
  // as it's a misleading eror. We just eat it. To keep proper errorHandler behaviour, we need to
  // consume the error here though, as opposed to in authenticationflow.js
  if (key === 'no_transaction') {
    return null;
  }

  if (key === 'password_change_required') {
    return null;
  }

  // The session cookie for the login session is gone.  The caller will send us back to the login page to start again
  // We don't want to display an error.
  if (key === 'invalid_web_session') {
    return null;
  }

  // if directory sync info is not found, let caller handle the exception
  if (key === 'directory_sync_info_not_found') {
    return null;
  }

  // if password unlock is not supported, caller will redirect to reset password page
  if (key === 'passwordunlock_not_supported') {
    return null;
  }

  // if the get unlock password api times out, caller will redirect to reset password page
  if (key === 'passwordunlock_timeout') {
    return null;
  }

  // Do not display the error snackbar for an SP user logged in at tenant site
  if (key === 'renew_sp_user') {
    return null;
  }

  // Some REST API Errors accept the "errorMessage" or "parameters" part of the exception in the string
  switch (key) {
    case 'email_template_body_syntax_error':
    case 'smartcredential_variable_value_not_unique':
      //$FlowFixMe
      return ts(`restApiErrors.${key}`, 'none', apiError?.response?.errorMessage);
    default:
      return t(`restApiErrors.${key}`);
  }
};

const createError = (code        ) => {
  let constructor = function () {
    this.name = code;
    this.message = code;
    this.stack = new Error().stack;
  };

  constructor.prototype = Object.create(Error.prototype);
  constructor.prototype.constructor = constructor;
  constructor.prototype.name = code;
  return constructor;
};

export const EXCEPTION_NOT_LOGGED_IN = createError('not_logged_in');
export const EXCEPTION_EXPIRED_SESSION = createError('expired_session');

// The standard behaviour for an authentication error on the second factor page is to
// stay on the current screen, and allow the user to try again
// (for example, for invalid_user_response).
// However, for the following login related error codes, authentication will
// not be able to complete, so should be handled differently (e.g. return
// the user back to the login screen).
// The names used here map to error codes defined in the ExternalErrorCodes class
export const API_ERRORS_LOGIN_CANNOT_COMPLETE = {
  grid_challenge_expired: true,
  no_otp: true,
  user_locked: true,
  user_invalid_state: true,
  user_not_found: true
};
