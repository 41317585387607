/**
 * React Router Selectors
 *
 */

export const getIsErrorRoute = location => location.pathname.startsWith('/error');
export const getIsLogoutRoute = location => location.pathname.startsWith('/logout');
export const getIsOidcRoute = location => location.pathname.startsWith('/oidc');
export const getIsRootRoute = location => location.pathname === '/';
export const getIsSamlRoute = location => location.pathname.startsWith('/saml');
export const getIsSignUpRoute = location => location.pathname === '/signup';
export const getIsSignUpSuccessRoute = location => location.pathname.startsWith('/signup/thank-you');
export const getIsPasswordResetRoute = location => location.pathname.startsWith('/reset');
export const getIsIdpCompleteRoute = location => location.pathname.startsWith('/idp/complete');

export const getIsPortalRoute = location =>
  !(
    getIsErrorRoute(location) ||
    getIsOidcRoute(location) ||
    getIsRootRoute(location) ||
    getIsSamlRoute(location) ||
    getIsSignUpRoute(location) ||
    getIsSignUpSuccessRoute(location) ||
    getIsPasswordResetRoute(location) ||
    getIsLogoutRoute(location) ||
    getIsIdpCompleteRoute(location)
  );
