//      
import config from 'src/../config';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import apiAdmin from 'src/api/swagger/api-web-administration.swagger';
import { getJWTToken } from 'src/api/management-api';
import errorHandler from 'src/api/errorHandler';

                                                                         
                                                                                               

export function getSelfRoles()                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfRolesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getRoles()                           {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .listSiteRolesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getRolesListForSP()                           {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .listSPRolesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getRole(id        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .getSiteRoleUsingGET({
      parameters: {
        id,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteRole(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .removeSiteRoleUsingDELETE({
      parameters: {
        id,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createRole(role      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSiteRoleUsingPOST({
      parameters: {
        role,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyRole(role      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSiteRoleUsingPUT({
      parameters: {
        role,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getPermissions()                             {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .listPermissionsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserRoleById(userid        , roleid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .getUserSiteRoleAssociationUsingGET({
      parameters: {
        userid,
        roleid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getUserRoles(userid        )                       {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .getUserRolesUsingGET({
      parameters: {
        userid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyUserRoles(userid        , roleid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .modifyUserSiteRoleAssociationUsingPUT({
      parameters: {
        userid,
        roleid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserRoles(userid        , roleid        )                {
  const jwtToken = getJWTToken();

  return apiAdmin.roles
    .deleteUserSiteRoleAssociationUsingDELETE({
      parameters: {
        userid,
        roleid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function modifyUserSPRoles(userid        , roleid        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .modifyUserSPRoleAssociationUsingPUT({
      parameters: {
        userid,
        roleid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteUserSPRoles(userid        , roleid        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteUserSPRoleAssociationUsingDELETE({
      parameters: {
        userid,
        roleid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}
