//      
import apiAuth from 'src/api/swagger/api-web-authentication.swagger';
import apiInternal from 'src/api/swagger/api-web-internal.swagger';
import config from 'src/../config';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';
import { getTokenAttribute } from 'src/lib/jwt';

// Flow Types
             
                        
                
                 
                               
                                
                   
                
                        
                       
            
                 
       
                
                       
                              
                              
                                   
                                         
             
           
              
               
                  
                                     
                              
        
                   
                         
              
               
       
                          
                           
                      
               
                    
                       
                              
                                 
                                     
               
                   
                  
                                                      
                                                                                            

/*
 * The getSelfTokens() REST API call
 */
export function getSelfTokens()                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .listSelfTokensUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The startActivateSelfToken() REST API call
 */
export function startActivateSelfToken(tokenid        , activateParms               )                          {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .startActivateSelfTokenUsingPOST({
      parameters: {
        tokenid,
        activateParms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The completeSelfActivateEST() REST API call
 */
export function completeSelfActivateEST(tokenid        , parms                       )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .completeActivateSelfTokenUsingPUT({
      parameters: {
        tokenid,
        parms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The deleteSelfToken() REST API call
 */
export function deleteSelfToken(tokenid        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfTokenUsingDELETE({
      parameters: {
        tokenid,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The setSelfTokenLabel() REST API call
 */
export const setSelfTokenLabel = (tokenid        , label        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .modifySelfTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms: { label }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

/*
 * The unlockSelfToken() REST API call
 */
export function unlockSelfToken(tokenid        , unlockChallenge             )                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .unlockSelfTokenUsingPUT({
      parameters: {
        tokenid,
        parms: {
          unlockChallenge
        },
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function assignSelfToken(serialNumber        , response        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .assignSelfTokenUsingPUT({
      parameters: {
        assignParms: {
          serialNumber,
          response
        },
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The changeSelfTokenState() REST API call
 */
export function changeSelfTokenState(tokenid        , state                  )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .changeSelfTokenStateUsingPUT({
      parameters: {
        tokenid,
        parms: {
          state
        },
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The syncSelfToken() REST API call
 */
export function syncSelfToken(tokenid        , response            )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .resetSelfTokenUsingPUT({
      parameters: {
        tokenid,
        parms: {
          response
        },
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The getSelfUser() REST API call
 */
export function getSelfUser()                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfUserUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const sendSelfPasswordExpiryNotification = ()                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .sendSelfPasswordExpiryNotificationUsingPUT({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getSelfUserPasswordSettings()                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetUserPasswordSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The getSelfUserPassword() REST API call
 */
export function getSelfUserPassword()                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetUserPasswordUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The selfSetUserPassword() REST API call
 */
export function selfSetUserPassword(userPassword                   )                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfSetUserPasswordUsingPUT({
      parameters: {
        Authorization: jwtToken,
        userPassword
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The selfGetUserPassthroughUsingGET() REST API call
 */
export function selfGetUserPassthrough()                                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetUserPassthroughUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfGetUserMachineSettings()                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfUserMachineSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfCreateMachineAuthenticator(
  machineAuthenticatorRegistration                                  
)                                                  {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfCreateMachineAuthenticatorUsingPOST({
      parameters: {
        Authorization: jwtToken,
        machineAuthenticatorRegistration
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfGetMachineAuthenticators()                                           {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfListMachineAuthenticatorsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfDeleteMachineAuthenticator(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfDeleteMachineAuthenticatorUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The createSelfToken() REST API call
 */
export function createSelfToken(type        , parms                  )                 {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSelfTokenUsingPOST({
      parameters: {
        type,
        parms,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

/*
 * The UpdateSelfUser REST API PUT call
 */
export function updateSelfUser(user      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfUserUsingPUT({
      parameters: {
        user,
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfSmartCredential(id        )                           {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfSmartCredentialUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function changeSelfSmartCredentialState(id        , state                       )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .changeSelfSmartCredentialStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms: {
          state
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSelfSmartCredential(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfSmartCredentialUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Create Smart Credential Definition Variable
// =============================================================================
export const getSelfSmartCredentialDefinitions = ()                                              => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfSmartCredentialDefinitionsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const createSelfSmartCredential = (toCreate                      )                           => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSelfSmartCredentialUsingPOST({
      parameters: {
        Authorization: jwtToken,
        toCreate
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function activateSelfSmartCredential(
  id        ,
  parms                              
)                                         {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .activateSelfSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unassignSelfSmartCredential(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .unassignSelfSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function unblockSelfSmartCredential(
  id        ,
  parms                             
)                                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .unblockSelfSmartCredentialUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// Add correct flow types.
export function assignSelfGridBySerial(
  serialNumber        ,
  response        ,
  emailAttributeName        
)                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .assignSelfGridUsingPUT({
      parameters: {
        Authorization: jwtToken,
        assignParms: {
          serialNumber,
          response,
          emailParms: { emailAttributeName }
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const createSelfGridCardAuthenticator = (emailAttributeName        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSelfGridUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms: { emailParms: { emailAttributeName } }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function challengeSelfGridBySerial(gridSerial                             )                                {
  const jwtToken = getJWTToken();

  const authToken = getTokenAttribute();

  return apiInternal.internal
    .gridSelfChallengeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userChallengeParms: {
          authToken,
          gridSerial
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfGridCard(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfGridUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getSelfGridExport = (gridid        )                      => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfSingleGridExportUsingGET({
      parameters: {
        Authorization: jwtToken,
        gridid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const sendSelfGridEmail = (gridid        , emailAttributeName        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deliverSelfAssignedGridByEmailUsingPOST({
      parameters: {
        Authorization: jwtToken,
        gridid,
        emailParms: {
          emailAttributeName
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function deleteSelfGridCard(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfGridUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function changeSelfGridCardState(id        , state                      )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .changeSelfGridStateUsingPUT({
      parameters: {
        Authorization: jwtToken,
        id,
        parms: {
          state
        }
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfKbaQuestions()                               {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetKbaQuestionsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function addSelfKba(kba                             )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfAddKnowledgeBasedAuthenticatorUsingPOST({
      parameters: {
        Authorization: jwtToken,
        kba
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSelfKba(kba                             )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfUpdateKnowledgeBasedAuthenticatorUsingPUT({
      parameters: {
        Authorization: jwtToken,
        kba
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSelfKba()                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfDeleteKnowledgeBasedAuthenticatorUsingDELETE({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfKba()                                       {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetKnowledgeBasedAuthenticatorUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

// FIDO methods
export const startFIDORegister = ()                                 => {
  const jwtToken = getJWTToken();

  return apiAuth.authenticationselfadmin
    .startFIDORegisterUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

// FIDO methods
export const completeFIDORegister = (response                      )                     => {
  const jwtToken = getJWTToken();

  return apiAuth.authenticationselfadmin
    .completeFIDORegisterUsingPOST({
      parameters: {
        Authorization: jwtToken,
        response
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const deleteSelfFIDOToken = (fidoid        )                => {
  const jwtToken = getJWTToken();

  return apiAuth.authenticationselfadmin
    .deleteSelfFIDOTokenUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        fidoid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const updateSelfFIDOToken = (fidoid        , parms                )                => {
  const jwtToken = getJWTToken();

  return apiAuth.authenticationselfadmin
    .updateSelfFIDOTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        fidoid,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function selfValidateUserPassword(parms                             )                                          {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .validateSelfUserPasswordUsingPOST({
      parameters: {
        Authorization: jwtToken,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfUserRegistration()                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfUserRegistrationUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function setSelfUserRegistrationComplete()                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .setSelfUserRegistrationCompleteUsingPUT({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfUserVerification()                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfUserVerificationUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function completeSelfGoogleTokenRegistration(
  tokenid        ,
  parms                        
)                            {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .completeRegistrationSelfTokenUsingPUT({
      parameters: {
        Authorization: jwtToken,
        tokenid,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfGetCachedActiveSyncDevices()                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfCachedActiveSyncDevicesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfGetActiveSyncDevices()                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfActiveSyncDevicesUsingPOST({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfUpdateActiveSynDevice(parms          )                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfActiveSyncDevicesUsingPUT({
      parameters: {
        Authorization: jwtToken,
        parms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfRemoveActiveSynDevice(deviceid        )                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .removeSelfActiveSyncDevicesUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        deviceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfSettings()                        {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfSettingsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfDesktopClient()                                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfDesktopClientsUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSelfDesktopClientById(id        )                         {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .getSelfDesktopClientByIdUsingGET({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function selfDeleteDesktopClient(id        )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfDesktopClientUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        id
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSelfSystemAttributeChallenge(
  userAttributeValueParms                         
)                                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfSystemContactAttributeChallengeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSelfSystemAttributeComplete(userAttributeValueParms                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfSystemContactAttributeCompleteUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSelfOptionalAttributeChallenge(
  userAttributeValueParms                         
)                                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfContactOptionalAttributeChallengeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSelfOptionalAttributeComplete(userAttributeValueParms                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .updateSelfContactOptionalAttributeCompleteUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createSelfExtraAttributeChallenge(
  userAttributeValueParms                         
)                                              {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSelfContactExtraAttributeChallengeUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createSelfExtraAttributeComplete(userAttributeValueParms                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .createSelfContactExtraAttributeCompleteUsingPOST({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSelfOptionalAttribute(userAttributeValueParms                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfOptionalAttributeUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deleteSelfExtraAttribute(userAttributeValueParms                         )                {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .deleteSelfExtraAttributeUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        userAttributeValueParms
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const selfGetFaceAuthenticators = ()                                    => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetFacesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const selfRegisterFaceAuthenticator = (registrationFlow         )                         => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfStartRegisterFaceUsingPOST({
      parameters: {
        Authorization: jwtToken,
        registrationFlow
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const selfDeleteFaceAuthenticator = (faceid        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfDeleteFaceUsingDELETE({
      parameters: {
        Authorization: jwtToken,
        faceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const selfRenewFaceAuthenticator = (faceid        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfStartRenewFaceUsingPUT({
      parameters: {
        Authorization: jwtToken,
        faceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const selfGetFaceAuthenticator = (faceid        )                             => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfGetFaceUsingGET({
      parameters: {
        Authorization: jwtToken,
        faceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export const selfSendFaceActivationEmail = (faceid        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.internal
    .selfSendFaceActivationEmailUsingPUT({
      parameters: {
        Authorization: jwtToken,
        faceid
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
