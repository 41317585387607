//      
import { combineReducers } from 'redux';

import activeEntitlements, { initialState as activeEntitlementInitialState } from 'src/ducks/activeEntitlements';
import authentication, { initialState as authenticationInitialState } from 'src/ducks/authentication';
import banner, { initialState as bannerInitialState } from 'src/ducks/banner';
import bundleFeatures, { initialState as bundleFeaturesState } from 'src/ducks/bundleFeatures';
import tenantEntitlement, { initialState as tenantEntitlementInitialState } from 'src/ducks/tenantEntitlements';
import errors, { initialState as errorsInitialState } from 'src/ducks/errors';
import messages, { initialState as messagesInitialState } from 'src/ducks/messages';
import permissions, { initialState as permissionsInitialState } from 'src/ducks/permissions';
import settings, { initialState as settingsInitialState } from 'src/ducks/settings';
import themes, { initialState as subscriberInfoInitialState, getIsRootSpAccount } from 'src/ducks/theme';
import userProperties, { initialState as userInfoInitialState } from 'src/ducks/userInfo';
import onboarding, { initialState as onboardingInitialState } from 'src/ducks/onboarding';

import { getLocale } from 'src/ducks/locale';

                                                                           
                                                                    
                                                    
                                                                    
                                                                     
                                                   
                                                       
                                                             
                                                        
                                                  
                                                        
                                                                                      

                     
                                             
                                      
                      
                                      
                                      
                     
                         
                               
                          
                     
                                
                             
  

                                                                         
                                   
                                        

const appReducer = combineReducers({
  activeEntitlements,
  authentication,
  banner,
  bundleFeatures,
  tenantEntitlement,
  errors,
  messages,
  permissions,
  settings,
  themes,
  userProperties,
  onboarding
});

// Global Reducer
// =============================================================================
const LOGOUT = 'LOGOUT';

export const logout = () => {
  return { type: LOGOUT, payload: new Promise(resolve => resolve()) };
};

export default (state       , action        ) => {
  // Clear Redux store on logout
  if (action.type === LOGOUT) {
    const { authentication, banner, themes, messages, onboarding } = state;
    state = Object.assign({}, { authentication }, { banner }, { themes }, { messages }, { onboarding });
  }

  return appReducer(state, action);
};

// Initial Redux State
// =============================================================================
export const initialDucksState = Object.freeze({
  activeEntitlements: activeEntitlementInitialState,
  authentication: authenticationInitialState,
  banner: bannerInitialState,
  bundleFeatures: bundleFeaturesState,
  tenantEntitlement: tenantEntitlementInitialState,
  errors: errorsInitialState,
  messages: messagesInitialState,
  permissions: permissionsInitialState,
  settings: settingsInitialState,
  themes: subscriberInfoInitialState,
  userProperties: userInfoInitialState,
  onboarding: onboardingInitialState
});

// Global Redux Selectors
// =============================================================================

export const isOffline = (state       )          => {
  const { themes } = state;

  return themes.failed;
};

export const accountIsLoading = (state       )          => {
  return state.themes.lastUpdated === null;
};

export const getShowMessageOfTheDay = (state       )          => {
  const locale = getLocale(state);

  return !getIsRootSpAccount(state.themes) && state.themes.subscriber.motd[locale];
};

export const getIsUserLoading = (state       )          => {
  const { userProperties, permissions } = state;

  const userIsLoading =
    userProperties.lastUpdated === null || (userProperties.isLoading && userProperties.lastUpdated === null);

  const permissionsLoading =
    permissions.lastUpdated === null || (permissions.isLoading && permissions.lastUpdated === null);

  return userIsLoading || permissionsLoading;
};
