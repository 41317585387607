//      
import { useSelector } from 'react-redux';

// Entrust Components
// =============================================================================
import FinalBasicDialog from 'src/components/FinalBasicDialog';
import LegalTerms from './LegalTerms';

// Material-UI Components
// =============================================================================
import DialogContentText from '@material-ui/core/DialogContentText';

// Helpers
// ===========================================================================
import { ROLE_TYPE_SITE_ADMINISTRATOR } from 'src/lib/constants';
import { upsertTheme } from 'src/ducks/theme';
import { userHasRole } from 'src/ducks/permissions';
import { useActions } from 'src/lib/hooks';
import t from 'src/i18n';

const LegalTermsDialog = () => {
  const isAuthenticationAccount = useSelector(state => state.themes.subscriber.authenticationAccount);
  const isIssuanceAccount = useSelector(state => state.themes.subscriber.issuanceAccount);
  const isSiteAdmin = useSelector(state => userHasRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR));
  const subscriber = useSelector(state => state.themes.subscriber);
  const isDirectTenant = useSelector(state => state.settings.bundles.directTenant);

  const { upsertTheme: upsert } = useActions({ upsertTheme });
  const { legalAcknowledged } = subscriber;

  const onClose = async () => {
    subscriber.legalAcknowledged = true;
    await upsert(subscriber);
  };

  // If the user is not a site admin then don't display the dialog.
  // Only site admins can acknowledge the legal terms.
  // If this is an issuance account, wait for fetch settings to complete.
  // If direct tenant is true then don't display the dialog.
  if (
    !isSiteAdmin ||
    legalAcknowledged ||
    (isIssuanceAccount && (isDirectTenant === undefined || isDirectTenant === true))
  ) {
    return null;
  }

  return (
    <FinalBasicDialog
      id="sub-tos-ok"
      onClose={onClose}
      title={t('legal.termsOfUse')}
      primaryOnly
      primaryButtonLabel={t('button.ok')}>
      <DialogContentText component="div">
        <LegalTerms isAuthenticationAccount={isAuthenticationAccount} name="legalAcknowledged" />
      </DialogContentText>
    </FinalBasicDialog>
  );
};

export default LegalTermsDialog;
