import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  logo: {
    maxHeight: theme.spacing(3),
    padding: 4
  }
}));

/**
 * The Entrust logo displayed in the footer
 */
const FooterLogo = () => {
  const classes = useStyles();
  return <img alt="Entrust" src="/Entrust-Logo.png" className={classes.logo} />;
};

export default FooterLogo;
