//      
import api from 'src/api/swagger/api-web-issuance.swagger';
import apiInternal from 'src/api/swagger/api-web-issuanceinternal.swagger';
import config from 'src/../config';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

                                                                                                                     

export function getPrintersCount()                                  {
  const jwtToken = getJWTToken();

  return api.printers
    .printersSummary({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function readPrinter(printerId        )                   {
  const jwtToken = getJWTToken();

  return api.printers
    .readPrinter({
      parameters: {
        Authorization: jwtToken,
        printerId: printerId
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function createPrinter(body         )                              {
  const jwtToken = getJWTToken();

  return api.printers
    .createPrinter({
      parameters: {
        Authorization: jwtToken,
        body
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function readPrinters(withStatus         )                          {
  const jwtToken = getJWTToken();

  return api.printers
    .readPrinters({
      parameters: {
        Authorization: jwtToken,
        withStatus
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getPrinters()                          {
  const jwtToken = getJWTToken();

  return api.printers
    .getPrinters({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function deletePrinter(printerId        )                {
  const jwtToken = getJWTToken();

  return api.printers
    .deletePrinter({
      parameters: {
        Authorization: jwtToken,
        printerId: printerId
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function replacePrinter(printerId        , printer         )                {
  const jwtToken = getJWTToken();

  return api.printers
    .replacePrinter({
      parameters: {
        Authorization: jwtToken,
        printerId,
        printer
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function registerPrinterAlexa(action        , printerId        )                  {
  const jwtToken = getJWTToken();

  return apiInternal.alexa
    .registerPrinterAlexa({
      parameters: {
        Authorization: jwtToken,
        printerId,
        action
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function readPrintersAlexa()                  {
  const jwtToken = getJWTToken();

  return apiInternal.alexa
    .readPrintersAlexa({
      parameters: {
        Authorization: jwtToken,
        withStatus: true
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getRibbonBundle = (ribbonId        )                => {
  const jwtToken = getJWTToken();

  return apiInternal.printersearch
    .getRibbonBundle({
      parameters: {
        Authorization: jwtToken,
        ribbonId: ribbonId
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
