//      

import api from 'src/api/swagger/api-web-internal.swagger';
import config from 'src/../config';
import errorHandler from 'src/api/errorHandler';
import { getJWTToken } from 'src/api/management-api';

             
              
                            
                 
                                                   

export function getSubscriber()                           {
  const jwtToken = getJWTToken();

  return api.internal
    .getSubscriberUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function updateSubscriber(subscriberModel                 )                           {
  const jwtToken = getJWTToken();

  return api.internal
    .updateSubscriberUsingPUT({
      parameters: {
        Authorization: jwtToken,
        subscriberModel
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function resetAccountTheme()                           {
  const jwtToken = getJWTToken();

  return api.internal
    .resetThemeUsingPUT({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getSubscriberPublic = ()                           => {
  return api.internal
    .getSubscriberPublicLoginUsingGET({
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};

export function getSanitizedHTML(html                  )                       {
  const jwtToken = getJWTToken();

  return api.internal
    .getSanitizedHTMLUsingPOST({
      parameters: {
        Authorization: jwtToken,
        html
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export function getSubscriberEntitlementBundle()                                            {
  const jwtToken = getJWTToken();

  return api.internal
    .getSubscriberEntitlementBundledFeaturesUsingGET({
      parameters: {
        Authorization: jwtToken
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
}

export const getEntitlementBundles = (type        )                                            => {
  const jwtToken = getJWTToken();

  return api.internal
    .getEntitlementBundlesUsingGET({
      parameters: {
        Authorization: jwtToken,
        type
      },
      baseUrl: config.apiWebManagementUrl
    })
    .catch(errorHandler);
};
