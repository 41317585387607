//      
import { useRef, useEffect, useMemo, useCallback } from 'react';

import { bindActionCreators,                              } from 'redux';
import { FIDO_SUPPORTED, base64Decode, base64Encode } from 'src/lib/fido';
import { useDispatch } from 'react-redux';
                                                                                                   
import debounce from 'lodash.debounce';

/**
 * React Hook for setInterval.
 *
 * Pass null as the delay to pause the interval
 *
 * Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback          , delay               ) => {
  const savedCallback = useRef          ();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
};

export const useTimeout = (callback          , delay               ) => {
  const savedCallback = useRef          ();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
    return undefined;
  }, [delay]);
};

                                                    

/**
 * React Hook (ish) for performing Web Authentication
 *
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Web_Authentication_API/Attestation_and_Assertion
 *
 * @param fdoChallenge - The FIDO Challenge from the server
 *
 * @returns A method to authenticate the FIDO challenge and an Abort Controller to
 *  stop the authenticate method.
 */
export const useWebAuthn = (fidoChallenge               )                                             => {
  const abortController = FIDO_SUPPORTED ? new AbortController() : null;
  const authenticate                   = async () => {
    if (!FIDO_SUPPORTED) {
      // The caller should have checked this before calling authenticate.
      throw new Error('FIDO not supported by this browser.');
    }
    const publicKey = {
      challenge: base64Decode(fidoChallenge.challenge),
      allowCredentials: fidoChallenge.allowCredentials.map(rk => {
        return { type: 'public-key', id: base64Decode(rk) };
      }),
      timeout: fidoChallenge.timeout * 1000
    };

    //$FlowFixMe
    const response             = await navigator.credentials.get({ publicKey });
    const fidoResponse = {
      credentialId: response.id,
      authenticatorData: base64Encode(response.response.authenticatorData),
      signature: base64Encode(response.response.signature),
      clientDataJSON: base64Encode(response.response.clientDataJSON)
    };

    return fidoResponse;
  };

  return [authenticate, abortController];
};

/**
 * useAction hook for binding the Redux Dispatch to actions.
 *
 * Source: https://react-redux.js.org/api/hooks#recipe-useactions
 */
export function useActions                            (actions   )    {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, [dispatch]);
}

export const usePrevious = (value     ) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

/**
 * useDidMountEffect hook to run side effect after the initial render.
 */
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

                                                     
                                                 
                 
                                
 

export function useDebounce                            (
  callback   ,
  delay        ,
  options         
)                   {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedFunction = useCallback(
    debounce(
      (...args) => {
        callbackRef.current(...args);
      },
      delay,
      options
    ),
    [delay, options]
  );

  useEffect(() => {
    return () => {
      debouncedFunction.cancel();
    };
  }, [debouncedFunction]);

  return debouncedFunction;
}
