//      
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Entrust Components
// =============================================================================
import AccountProvider from './Providers/AccountProvider';
import ErrorBoundary from 'src/components/App/ErrorBoundary';
import Footer from 'src/components/Layout/Footer';
import Header from 'src/components/Layout/Header';
import IdleDialog from './IdleDialog';
import LayoutWrapper from 'src/components/Layout/LayoutWrapper';
import LegalTermsDialog from 'src/components/LegalTermsDialog';
import Routes from 'src/routes/routes';
import ScrollBar from 'src/components/Layout/ScrollBar';
import StyleHelmet from 'src/components/App/StyleHelmet';

// Material-UI Components
// =============================================================================
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles'; // v1.x
import Box from '@material-ui/core/Box';

// Helper Functions
// ===========================================================================
import t, { i18n } from 'src/i18n';

// External Functions
// ===========================================================================
import { getPrinters } from 'src/api/printers';

// Redux / Router Functions
// ===========================================================================
import { getLocale } from 'src/ducks/locale';
import { changeRenderingLocale, getTheme, getPalette, setAccountLockStatusPolling } from 'src/ducks/theme';
import { getIsAdmin } from 'src/ducks/permissions';
import { getIsLoggedIn } from 'src/ducks/authentication';
import { useActions } from 'src/lib/hooks';
import { addMessage } from 'src/ducks/messages';
import { fetchSettings } from 'src/ducks/settings';

// Styles / Constants / Other
// ===========================================================================
import 'react-table/react-table.css';
import './entskin.css';
import { DIRECT_TENANT_ACCOUNT_STATUS_DELAY } from 'src/lib/constants';

require('src/lib/validationRules');

// Flow Types
// ===========================================================================
                                                     

const App = () => {
  const location = useLocation();

  const isAdmin = useSelector((state            ) => getIsAdmin(state.permissions));
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const isIssuanceAccount = useSelector((state            ) => state.themes.subscriber.issuanceAccount);
  const isLoggedIn = useSelector((state            ) => getIsLoggedIn(state.authentication));
  const locale = useSelector((state            ) => getLocale(state, location));
  const palette = useSelector((state            ) => getPalette(state.themes));
  const smartLoginEnabled = useSelector((state            ) => state.themes.subscriber.smartLoginEnabled);
  const theme = useSelector((state            ) => getTheme(state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector((state            ) => state.settings.bundles.directTenantLocked);
  const isLockStatusPolling = useSelector((state            ) => state.themes.isLockStatusPolling);

  const accountLockRef = React.useRef(null);
  const actions = useActions({ addMessage, setAccountLockStatusPolling, fetchSettings, changeRenderingLocale });

  React.useEffect(() => {
    i18n.on('languageChange', actions.changeRenderingLocale);
    return () => {
      i18n.off('languageChange', actions.changeRenderingLocale);
    };
  }, []);

  React.useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  /**
   * Fetch lock status of direct tenant every 30 seconds. isLockStatusPolling will be false by default.
   * Tenant must be locked and isLockStatusPolling must be set before polling the accounts status can begin.
   * For direct tenant issuance accounts only.
   */
  React.useEffect(() => {
    if (isIssuanceAccount && isDirectTenant && isDirectTenantLocked && isLockStatusPolling) {
      accountLockRef.current = setInterval(async () => {
        // retrieve tenants printers and filter the ones that are IDLE and IIDaaS Ribbon ready
        const printers = await getPrinters();
        const iidaasReadyPrinters = printers.filter(printer => printer.status == 'IDLE' && printer.ptpReady == true);

        // only need a single printer to be ready to unlock account
        if (iidaasReadyPrinters.length) {
          await actions.setAccountLockStatusPolling(false);
          await actions.fetchSettings(); // get updated account lock status
          await actions.addMessage(t('DirectTenant.onboarding.success'));
          clearInterval(accountLockRef.current);
        }
      }, DIRECT_TENANT_ACCOUNT_STATUS_DELAY);
    }

    return () => clearInterval(accountLockRef.current);
  }, [isLockStatusPolling, isDirectTenant]);

  return (
    <AccountProvider>
      <MuiThemeProvider theme={theme}>
        <V0MuiThemeProvider muiTheme={palette}>
          <LayoutWrapper>
            <StyleHelmet />

            <Header />

            <ScrollBar>
              <Box display="flex" flexDirection="column" flex="1 0 auto" margin={1} justifyContent="center">
                <ErrorBoundary>
                  <Routes
                    isAuthenticationAccount={isAuthenticationAccount}
                    isIssuanceAccount={isIssuanceAccount}
                    smartLoginEnabled={smartLoginEnabled}
                  />
                </ErrorBoundary>

                {isAdmin && <LegalTermsDialog />}

                {isLoggedIn && <IdleDialog />}
              </Box>

              <Footer />
            </ScrollBar>
          </LayoutWrapper>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    </AccountProvider>
  );
};

export default App;
