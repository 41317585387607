//      
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

// Entrust Components
// =============================================================================
import ArrowDropLeft from '@edc/react-ui/Icons/DropArrowLeft';
import ExternalLink from 'src/components/Link/ExternalLink';

// Material-UI Components
// =============================================================================
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Redux / Utility functions
// =============================================================================
import t from 'src/i18n';
import Badge from '@material-ui/core/Badge';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DOC_LINKS,
  ROLE_TYPE_ACCOUNT_MANAGER,
  ROLE_TYPE_SITE_ADMINISTRATOR,
  SITE_ISSUANCE_ADMIN_ROLE_ID,
  SUPER_ADMINISTRATOR_ROLE_ID
} from 'src/lib/constants';
import FinalBasicDialog from 'src/components/FinalBasicDialog';
import { getFullName } from 'src/ducks/userInfo';
import { getLocale } from 'src/ducks/locale';
import { getIsAdmin, userHasRole } from 'src/ducks/permissions';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Typography from '@material-ui/core/Typography';
import { updateSelfUser } from 'src/api/self';

const styles = {
  menuButtonStyle: {
    fontSize: 'initial',
    width: 'auto'
  },
  menuButtonIconsStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  notificationIconStyle: {
    display: '20px',
    alignItems: 'left'
  },
  notificationStyle: {
    fontSize: 'initial',
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  }
};

const getHelpMenuItems = (
  isAdmin,
  isAuthenticationAccount,
  isDirectTenant,
  isIssuanceAccount,
  isSiteAdmin,
  isSiteManager,
  locale,
  url
) => {
  const menuItems = [];

  // The user guide is only supported in the following locales
  const userGuideLocale = ['en', 'de', 'fr', 'es', 'ja'].includes(locale) ? locale : 'en';

  // Help Videos
  // IIDaaS Ribbon enabled tenants can see the Support and Resources section
  isIssuanceAccount &&
    isDirectTenant &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-supportVideos"
        key="app-parts-header-supportVideos"
        href={'#/serviceprovider/supportandresources'}>
        <MenuItem primaryText={t('appBar.help.supportAndResources')} />
      </ExternalLink>
    );

  // User Guide
  // Anyone can see the user guide, no role checking is needed.
  isAuthenticationAccount &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-userGuide"
        key="app-parts-header-userGuide"
        href={url == null ? DOC_LINKS.USER_HELP_URL(userGuideLocale) : url}>
        <MenuItem primaryText={t('menu.userGuide')} />
      </ExternalLink>
    );

  // Admin Guide
  // Only Site Administrators can see the Admin Guide. Users with Sp Role cannot see the Admin Guide.
  isSiteAdmin &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-adminGuide"
        key="app-parts-header-adminGuide"
        href={isAuthenticationAccount ? DOC_LINKS.ADMIN_HELP_URL : DOC_LINKS.ISSUANCE_HELP_URL}>
        <MenuItem
          primaryText={isAuthenticationAccount ? t('appBar.help.adminGuide') : t('appBar.help.adminGuideIssuance')}
        />
      </ExternalLink>
    );

  // Service Provider Help
  // Only Account Managers (users with SP role) can see the SP Guide.
  isSiteManager &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-spGuide"
        key="app-parts-header-spGuide"
        href={isAuthenticationAccount ? DOC_LINKS.SP_HELP_URL : DOC_LINKS.ISSUANCE_SP_HELP_URL}>
        <MenuItem primaryText={t('appBar.help.spGuide')} />
      </ExternalLink>
    );

  // Integration Guide
  isSiteAdmin &&
    isAuthenticationAccount &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-integrationGuide"
        key="app-parts-header-integrationGuide"
        href={DOC_LINKS.TIGS_HELP_URL}>
        <MenuItem primaryText={t('appBar.help.integrationGuide')} />
      </ExternalLink>
    );

  // Release Notes
  // Both Site Administrators and Account Managers can see the release notes.
  isAdmin &&
    menuItems.push(
      <ExternalLink
        id="app-parts-header-whatsNew"
        key="app-parts-header-whatsNew"
        href={
          isAuthenticationAccount ? DOC_LINKS.RELEASE_NOTES_URL_AUTHENTICATION : DOC_LINKS.RELEASE_NOTES_URL_ISSUANCE
        }>
        <MenuItem primaryText={t('menu.releaseNotes')} />
      </ExternalLink>
    );

  return menuItems;
};

const getWhitepaperMenuItems = (isAdmin, isIssuanceAccount, isAuthenticationAccount) => {
  const menuItems = [];

  if (isAdmin) {
    menuItems.push(
      <ExternalLink
        id="app-parts-header-acsPlatformSecurity"
        key="app-parts-header-acsPlatformSecurity"
        href="/documentation/whitepapers/Entrust-Identity-Security-Whitepaper.pdf">
        <MenuItem primaryText={t('menu.identityPlatform')} />
      </ExternalLink>
    );
  }

  if (isIssuanceAccount && !isAuthenticationAccount) {
    menuItems.push(
      <ExternalLink
        id="app-parts-header-iidaasSecurity"
        key="app-parts-header-iidasSecurity"
        href="/documentation/whitepapers/Entrust-IIDaaS-Security-Whitepaper.pdf">
        <MenuItem primaryText={t('menu.iidaasPlatform')} />
      </ExternalLink>
    );
  }

  return menuItems;
};

const getDeveloperMenuItems = (isAdmin, isAuthenticationAccount, isIssuanceAccount) => {
  const menuItems = [];

  if (isAdmin && isAuthenticationAccount) {
    menuItems.push(
      <ExternalLink
        id="app-parts-header-adminApiDocs"
        key="app-parts-header-adminApiDocs"
        href={DOC_LINKS.ADMINDOC_URL}>
        <MenuItem primaryText={t('menu.adminDocs')} />
      </ExternalLink>
    );
  }

  if (isAdmin && isAuthenticationAccount) {
    menuItems.push(
      <ExternalLink id="app-parts-header-authApiDocs" key="app-parts-header-authApiDocs" href={DOC_LINKS.AUTHDOC_URL}>
        <MenuItem primaryText={t('menu.authDocs')} />
      </ExternalLink>
    );
  }

  if (isAdmin && isIssuanceAccount) {
    menuItems.push(
      <ExternalLink
        id="app-parts-header-issuanceApiDocs"
        key="app-parts-header-issuanceApiDocs"
        href={DOC_LINKS.ISSUANCEDOC_URL}>
        <MenuItem primaryText={t('menu.issuanceDocs')} />
      </ExternalLink>
    );
  }

  menuItems.push(
    <ExternalLink id="app-parts-header-portal" key="app-parts-header-portal" href={DOC_LINKS.PORTAL_URL}>
      <MenuItem primaryText={t('menu.developerPortal')} />
    </ExternalLink>
  );

  return menuItems;
};

// Nested Menu not supported in Material UI V1
// https://github.com/mui-org/material-ui/issues/11723
const FixedAppBarMenu = () => {
  const fullName = useSelector(state => getFullName(state.userProperties));
  const isAdmin = useSelector(state => getIsAdmin(state.permissions));
  const isAuthenticationAccount = useSelector(state => state.themes.subscriber.authenticationAccount);
  const isDirectTenant = useSelector(state => state.settings.bundles.directTenant);
  const isIssuanceAccount = useSelector(state => state.themes.subscriber.issuanceAccount);
  const isSiteManager = useSelector(state => userHasRole(state.permissions, ROLE_TYPE_ACCOUNT_MANAGER));
  const locale = useSelector(state => getLocale(state));
  const isSiteAdmin = useSelector(state => userHasRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR));
  const userGuide = useSelector(state => state.settings.self.userGuideUrl);
  const userData = useSelector(state => state.userProperties.user);
  const userRole = useSelector(state => state.permissions.roles);

  const helpMenuItems = getHelpMenuItems(
    isAdmin,
    isAuthenticationAccount,
    isDirectTenant,
    isIssuanceAccount,
    isSiteAdmin,
    isSiteManager,
    locale,
    userGuide
  );
  const whitepaperMenuItems = getWhitepaperMenuItems(isAdmin, isIssuanceAccount, isAuthenticationAccount);
  const developerMenuItems = getDeveloperMenuItems(isAdmin, isAuthenticationAccount, isIssuanceAccount);
  const history = useHistory();

  const logout = () => history.push('/logout');

  const myprofile = () => history.push('/myprofile');
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);

  const onCancelDelete = () => {
    setShowNotificationDialog(false);
  };

  const onReleaseNote = async () => {
    try {
      userData.showNotification = false;
      await updateSelfUser(userData);
      setShowNotificationDialog(false);
    } catch (error) {
      setShowNotificationDialog(false);
    }
  };

  let isAdminRole = false;

  Object.keys(userRole).map(key => {
    if (userRole[key].id === SUPER_ADMINISTRATOR_ROLE_ID || userRole[key].id === SITE_ISSUANCE_ADMIN_ROLE_ID) {
      isAdminRole = true;
    }
  });

  const showNotification = userData.showNotification;

  return (
    <>
      {showNotification && isAdminRole && isIssuanceAccount && !isAuthenticationAccount && (
        <Badge badgeContent={1} color="secondary">
          <NotificationsActiveIcon
            id="active-release-notes-notification"
            htmlColor="white"
            style={styles.notificationStyle}
            iconStyle={styles.notificationIconStyle}
            onClick={() => {
              setShowNotificationDialog(!showNotificationDialog);
            }}
          />
        </Badge>
      )}
      {!showNotification && isAdminRole && isIssuanceAccount && !isAuthenticationAccount && (
        <NotificationsIcon
          id="release-notes-notification"
          htmlColor="white"
          style={styles.notificationStyle}
          iconStyle={styles.notificationIconStyle}
          onClick={() => {
            setShowNotificationDialog(!showNotificationDialog);
          }}
        />
      )}
      {showNotificationDialog && (
        <FinalBasicDialog
          id="notifications-dialog"
          apiCall={onReleaseNote}
          onClose={onCancelDelete}
          primaryButtonLabel={t('breadCrumbs.ignore')}
          title={t('breadCrumbs.notification')}>
          <DialogContentText component="div">
            <Typography>
              {t('breadCrumbs.view_the')}
              <ExternalLink
                id="release-note"
                color="blue"
                variant="subtitle1"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={onReleaseNote}
                href={DOC_LINKS.RELEASE_NOTES_URL_ISSUANCE}>
                {t('menu.releaseNotes')}
              </ExternalLink>
              {t('breadCrumbs.show_release_notes_notification_message')}
            </Typography>
          </DialogContentText>
        </FinalBasicDialog>
      )}

      <IconMenu
        id="user-name"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        targetOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconButtonElement={
          <IconButton style={styles.menuButtonStyle} iconStyle={styles.menuButtonIconsStyle}>
            <div>
              <div style={{ color: 'white' }} id="app-parts-header-fullName">
                {fullName}
              </div>
              <MoreVertIcon id="app-parts-header-userNameIcon" htmlColor="white" />
            </div>
          </IconButton>
        }>
        {/* My Profile */}
        <MenuItem id="app-parts-header-nyprofile" primaryText={t('UserProfileMenu.myprofile')} onClick={myprofile} />

        {/* LOGOUT */}
        <MenuItem id="app-parts-header-logout" primaryText={t('menu.logout')} onClick={logout} />

        {/* Help */}
        <MenuItem
          id="app-parts-header-help"
          primaryText={t('menu.help')}
          leftIcon={<ArrowDropLeft id="app-parts-header-helpArrowIcon" />}
          menuItems={helpMenuItems}
        />

        {/* White Papers */}
        {isAdmin && (
          <MenuItem
            id="app-parts-header-whitepapers"
            primaryText={t('menu.whitePapers')}
            leftIcon={<ArrowDropLeft id="app-parts-header-whitepaperArrowIcon" />}
            menuItems={whitepaperMenuItems}
          />
        )}

        {/* Developers */}
        {isAdmin && (
          <MenuItem
            id="app-parts-header-developer"
            primaryText={t('menu.developer')}
            leftIcon={<ArrowDropLeft id="app-parts-header-whitepaperArrowIcon" />}
            menuItems={developerMenuItems}
          />
        )}
      </IconMenu>
    </>
  );
};

export default FixedAppBarMenu;
