export const compareDatesFast = (date1: Date, date2: Date) => {
  if (!date1 && !date2) {
    return 0;
  }
  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }

  if (date1 === date2) {
    return 0;
  } else if (date1 < date2) {
    return -1;
  } else {
    return 1;
  }
};
