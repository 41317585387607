//      

// Helper Functions
// =============================================================================
import { setUnauthenticatedJWT, isLoggedIn, getTokenAttribute } from 'src/lib/jwt';
import { getChallenge } from 'src/api/authentication';

import { setNonces } from 'src/lib/machine';

// Authenticator types supported
export const AUTHENTICATOR_FIDO = 'FIDO';
export const AUTHENTICATOR_GRID = 'GRID';
export const AUTHENTICATOR_KBA = 'KBA';
export const AUTHENTICATOR_OTP = 'OTP';
export const AUTHENTICATOR_PASSWORD = 'PASSWORD';
export const AUTHENTICATOR_PASSWORD_AND_SECONDFACTOR = 'PASSWORD_AND_SECONDFACTOR';
export const AUTHENTICATOR_SMARTCREDENTIALPUSH = 'SMARTCREDENTIALPUSH';
export const AUTHENTICATOR_TEMP_ACCESS_CODE = 'TEMP_ACCESS_CODE';
export const AUTHENTICATOR_TOKEN = 'TOKEN';
export const AUTHENTICATOR_TOKENPUSH = 'TOKENPUSH';
export const AUTHENTICATOR_USER_CERTIFICATE = 'USER_CERTIFICATE';
export const AUTHENTICATOR_FACE = 'FACE';

// Flow Types
             
                        
                       
                      
               
                                                         

                                                                                    

                                  
                                  
                         
                                
                                   
                                            
                                       
                                       
                                             
                                          
  

/*
 * Given a list of authenticators available to a user, return the preferred authenticator.
 */
export function getPreferredAuthenticator(authenticators                )          {
  return (Array.isArray(authenticators) && authenticators.length > 0 && authenticators[0]) || null;
}

// Authenticator, secondFactorAuthenticator, otpDefaultDelivery & availableOTPDelivery can be null.
// These parameters are passed in from AuthenticationFlow.
export const processChallenge = async (
  authToken        ,
  userId        ,
  summary        ,
  applicationId        ,
  requestDetail               ,
  authenticator         ,
  authenticators               ,
  secondFactorAuthenticator                   ,
  secondFactorAuthenticators               ,
  machineAuthenticator                      ,
  locale         = null,
  otpDeliveryInfo
)                                    => {
  // If we don't have a specific authenticator then pick one based on priority order: the authenticators list
  // is in the resource rule defined order. For resource rules prior to 3.6, the first authenticator is the
  // one selected in the resource rule. Then, the rest are sorted in increasing order (strongest first). For
  // instance, if RR is KBA, and user has KBA, OTP, and TOKEN, the authenticators is: [KBA, TOKEN, OTP]. For 3.6
  // RR, the order will be the one defined in the resource rule.
  const preferredAuthenticator = authenticator || getPreferredAuthenticator(authenticators);

  if (preferredAuthenticator === null) {
    return null;
  }

  const { otpDefaultDelivery, otpDeliveryAttribute } = otpDeliveryInfo || {};

  try {
    const challenge = await getChallenge(preferredAuthenticator, {
      applicationId,
      requestDetail,
      authToken: authToken || getTokenAttribute(),
      machineAuthenticator,
      otpDeliveryType: otpDefaultDelivery,
      otpDeliveryAttribute,
      secondFactorAuthenticator,
      ...(isAuthenticator(AUTHENTICATOR_TOKENPUSH, preferredAuthenticator, secondFactorAuthenticator) && {
        tokenPushMutualChallengeEnabled: true
      }),
      ...((isAuthenticator(AUTHENTICATOR_FACE, preferredAuthenticator, secondFactorAuthenticator) ||
        isAuthenticator(AUTHENTICATOR_TOKENPUSH, preferredAuthenticator, secondFactorAuthenticator)) && {
        pushMutualChallengeEnabled: true
      }),
      summary,
      userId,
      locale
    });

    setNonces(challenge.machineAuthenticator);

    // This sets the user ID which is needed to see on the authenticator challenge page as
    // getChallenge returns ID as null despite it being in the payload
    setUnauthenticatedJWT({ ...challenge, userId });

    return {
      authenticator: preferredAuthenticator,
      authenticators,
      secondFactorAuthenticators,
      otpDefaultDelivery,
      authenticationComplete: challenge.authenticationCompleted,
      machineAuthenticator: challenge.machineAuthenticator,
      userMachineSettings: challenge.userMachineSettings,
      challenge
    };
  } catch (error) {
    return null;
  }
};

/*
 *  Return the authorization token which is used
 *  for all the REST API calls.  Return null if
 *  there is no JWT or it has expired.
 */
export const getJWTToken = ()             => {
  return isLoggedIn() ? getTokenAttribute() : null;
};

export const isAuthenticator = (
  desiredAuthenticator        ,
  authenticator        ,
  secondFactorAuthenticator               
) => {
  return (
    authenticator === desiredAuthenticator ||
    (authenticator === AUTHENTICATOR_PASSWORD_AND_SECONDFACTOR && secondFactorAuthenticator === desiredAuthenticator) ||
    false
  );
};
