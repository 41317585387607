/**
 * @overview
 * <p>
 * The <code>machineSecret</code> JavaScript namespace allows one to calculate
 * a device fingerprint based on attribute values associated with the web
 * browser in use. The resulting device fingerprint is a JSON object comprised
 * of device attribute names and their associated values. This JSON object
 * should be passed to a server-side web app that communicates with the
 * {@link http://www.entrust.com/products/entrust-identityguard/|Entrust Identity Enterprise}
 * Authentication API to implement machine authentication, a part of the
 * overall risk-based authentication platform supported by this product.
 * </p>
 * <p>
 * In addition to a device fingerprint that will undergo risk analysis
 * when passed to Entrust Identity Enterprise, it is also possible to create
 * a device ID, a single hex number that is a SHA256 hash of the
 * constituent parts of a device fingerprint.
 * </p>
 * <p>
 * Finally, <code>machineSecret</code> also allows one to store machine
 * and sequence nonce values, calculated by Entrust Identity Enterprise, across one or
 * more web-based storage types. The currently supported storage types
 * are cookies and HTML 5 local storage. Flash storage is not supported anymore.
 * </p>
 * <p>
 * When writing a nonce value, an attempt is made to store it across all
 * requested storage types. When reading the nonce value back, it is
 * guaranteed that the latest available value is always returned, and
 * if a prescribed storage type is missing a value, or that value is
 * out of date, the latest value will be re-established. This is to
 * ensure that users don't accidentally delete the machine and/or
 * sequence nonces that allow them to avoid second factor authentication,
 * by accidentally removing, for example, all their cookies.
 * </p>
 * @copyright 2023 {@link https://www.entrust.com|Entrust Corporation}
 * @version 2.1.0
 * @license See the following <a href="../../License/license.txt">license</a>
 */

import UAParser from 'ua-parser-js';
import CryptoJS from 'crypto-js/crypto-js';

/* eslint-disable */
(function (window) {
  'use strict';
  // Simply used for logging identification
  var LIBRARY_NAME = 'machineSecret';
  // This will be substituted at build time: DO NOT TOUCH!
  var LIBRARY_VERSION = '2.0.0';
  // 3rd party user agent parser
  var userAgent = new UAParser();

  // Device attributes we are going to collect
  var attributes = {
    browserName: {
      cacheable: true,
      get: function () {
        return getUserAgentInfo(userAgent.getBrowser(), 'name');
      }
    },
    browserVersion: {
      cacheable: true,
      get: function () {
        return getUserAgentInfo(userAgent.getBrowser(), 'version');
      }
    },
    osName: {
      cacheable: true,
      get: function () {
        return getUserAgentInfo(userAgent.getOS(), 'name');
      }
    },
    osVersion: {
      cacheable: true,
      get: function () {
        return getUserAgentInfo(userAgent.getOS(), 'version');
      }
    },
    plugins: {
      cacheable: true,
      get: function () {
        return getPlugins();
      }
    },
    platform: {
      cacheable: true,
      get: function () {
        return navigator.platform;
      }
    },
    appVersion: {
      cacheable: true,
      get: function () {
        return navigator.appVersion;
      }
    },
    cpu: {
      cacheable: true,
      get: function () {
        return getCPU();
      }
    },
    cssVendorPrefix: {
      cacheable: true,
      get: function () {
        return getCSSVendorPrefix();
      }
    },
    cookiesEnabled: {
      cacheable: true,
      get: function () {
        return navigator.cookieEnabled;
      }
    },
    javaEnabled: {
      cacheable: true,
      get: function () {
        return navigator.javaEnabled();
      }
    },
    flashEnabled: {
      cacheable: true,
      get: function () {
        return isFlashEnabled();
      }
    },
    flashVersion: {
      cacheable: true,
      get: function () {
        return getFlashVersion();
      }
    },
    language: {
      cacheable: true,
      get: function () {
        return navigator.language;
      }
    },
    doNotTrack: {
      cacheable: false,
      get: function () {
        return doNotTrack();
      }
    },
    timezoneOffset: {
      cacheable: false,
      get: function () {
        return -new Date().getTimezoneOffset();
      }
    },
    width: {
      cacheable: true,
      get: function () {
        return screen.width;
      }
    },
    height: {
      cacheable: true,
      get: function () {
        return screen.height;
      }
    },
    availWidth: {
      cacheable: true,
      get: function () {
        return screen.availWidth;
      }
    },
    availHeight: {
      cacheable: true,
      get: function () {
        return screen.availHeight;
      }
    },
    colorDepth: {
      cacheable: true,
      get: function () {
        return screen.colorDepth;
      }
    },
    localStorage: {
      cacheable: true,
      get: function () {
        return hasLocalStorage();
      }
    },
    sessionStorage: {
      cacheable: true,
      get: function () {
        return hasSessionStorage();
      }
    },
    indexedDB: {
      cacheable: true,
      get: function () {
        return !!window.indexedDB;
      }
    },
    fonts: {
      cacheable: true,
      get: function () {
        return getFonts();
      }
    },
    canvas: {
      cacheable: true,
      get: function () {
        return getCanvas();
      }
    },
    webGL: {
      cacheable: true,
      get: function () {
        return getWebGL();
      }
    },
    ioBlackbox: {
      cacheable: false,
      get: function () {
        return getIovationBlackbox();
      }
    }
  };
  // Used to cache values for attributes that support it.
  var attributesCache = {};
  // We will create a new object each time we calculate the fingerprint
  var fingerprint = null;
  // Collectable attributes that require asynchronous callbacks.
  var asynchronousAttributes = {};
  // Ordered list of post-processors to be called once all "getting" is done
  // but prior to conversion to JSON.
  var postProcessors = [];
  // By default we aren't collecting reasons why attribute evaluation failed
  var errorAttributeName = null;
  // By default, we aren't excluding any device attributes
  var attributeExclusions = null;
  // By default, we aren't collecting iovation ReputationManager 360 data
  var iovationBlackboxElementId = null;

  // Storage types we are supporting
  var storageTypes = {
    cookie: {
      EPOCH: 'Thu, 01-Jan-1970 00:00:01 GMT',
      OPTIONS: ['expires', 'path', 'domain'], // plus secure
      enabled: undefined,
      isEnabled: function () {
        if (this.enabled === undefined) {
          if (navigator.cookieEnabled) {
            // Make sure we can actually write and read back a cookie
            var key = '__EntrustDatacard__';
            var value = new Date().toUTCString();
            this.set(key, value);
            this.enabled = this.remove(key) === value;
          } else {
            this.enabled = false;
          }
          debug('Cookie storage is' + (!this.enabled ? ' NOT' : '') + ' enabled.');
        }
        return this.enabled;
      },
      set: function (key, value) {
        var result = [];
        var defaultExpires = function () {
          // Default expiration is in one year
          var nextYear = new Date();
          nextYear.setFullYear(nextYear.getFullYear() + 1);
          result.push('expires=' + nextYear.toUTCString());
        };
        result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        if (storageTypeOptions) {
          for (var i = 0, l = this.OPTIONS.length; i < l; i++) {
            var option = storageTypeOptions[this.OPTIONS[i]];
            if (option) {
              result.push(this.OPTIONS[i] + '=' + option);
            } else if (i == 0) {
              // No expiration provided; use the default
              defaultExpires();
            }
          }
          // There is also the secure option to check
          if (!!storageTypeOptions.secure) result.push('secure');
        } else {
          // No expiration provided; use the default
          defaultExpires();
        }
        var cookie = result.join('; ');
        document.cookie = cookie;
        debug('Set cookie as follows: ' + cookie);
        // Return the created cookie just because we always return the
        // value from a set
        return cookie;
      },
      get: function (key) {
        key = encodeURIComponent(key);
        var cookies = document.cookie,
          offset = cookies.indexOf(key + '=');
        // Check to see if the cookie exists
        if (offset < 0) return null;
        // Now we're looking for the offset of the value
        offset = offset + key.length + 1;
        var end = cookies.indexOf(';', offset);
        if (end < 0) {
          end = cookies.length;
        }
        var value = decodeURIComponent(cookies.substring(offset, end));
        debug('Existing value for cookie ' + key + ' is: ' + value);
        return value;
      },
      remove: function (key) {
        var value = this.get(key);
        if (value) {
          debug('Request to delete cookie ' + key);
          // This is a bit of a hack
          var origOptions = storageTypeOptions;
          storageTypeOptions = { expires: this.EPOCH };
          this.set(key, '');
          // Return to the original options which could be null
          storageTypeOptions = origOptions;
        }
        return value;
      }
    },
    localStorage: {
      enabled: undefined,
      isEnabled: function () {
        if (this.enabled === undefined) {
          try {
            if (window.localStorage) {
              // Make sure we can actually write and read back
              // a value
              var key = '__EntrustDatacard__';
              var value = new Date().toUTCString();
              this.set(key, value);
              this.enabled = this.remove(key) === value;
            } else {
              this.enabled = false;
            }
          } catch (e) {
            // In case trying to access local storage throws an
            // exception (very unlikely)
            this.enabled = false;
          }
          debug('HTML5 Local Storage is' + (!this.enabled ? ' NOT' : '') + ' enabled.');
        }
        return this.enabled;
      },
      set: function (key, value) {
        window.localStorage.setItem(key, value);
        debug('Setting local storage key ' + key + ' to value: ' + value);
        return value;
      },
      get: function (key) {
        var value = window.localStorage.getItem(key);
        debug('Existing value for local storage key ' + key + ' is: ' + value);
        return value;
      },
      remove: function (key) {
        var value = this.get(key);
        if (value) {
          window.localStorage.removeItem(key);
          debug('Removed local storage entry with key ' + key + ' whose previous value was: ' + value);
        }
        return value;
      }
    },
    // Flash storage is no longer supported. The code is adjusted
    // accordingly.
    flash: {
      initialized: false,
      callback: undefined,
      timeoutid: undefined,
      flash: undefined,
      enabled: undefined,
      init: function (callback, wait) {
        // Flash will be disabled always as
        // it is no longer supported
        if (!this.initialized) {
          this.initialized = true;
          this.enabled = false;
          // We won't be using Flash
          callback(false);
        }
      },
      isEnabled: function () {
        // If this.enabled is undefined, then Flash was never
        // properly initialized via the init function which requires
        // a callback. Thus, we'll just disable Flash.
        if (this.enabled === undefined) {
          debug('Flash is no longer supported!');
          this.enabled = false;
        }
        return this.enabled;
      },
      set: function (key, value) {
        debug('Flash is no longer supported!');
        // Indication that this didn't work
        return undefined;
      },
      get: function (key) {
        debug('Flash is no longer supported!');
        return undefined;
      },
      remove: function (key) {
        debug('Flash is no longer supported!');
        return undefined;
      }
    }
  };
  // By default, we are not debugging
  var debugOn = false;
  // By default, Flash storage is excluded as it is not supported.
  var storageTypeExclusions = 'flash';

  //By default, there are no storage type options.
  var storageTypeOptions = null;
  // Default names for the machine and sequence nonces
  var machineNonceName = 'machineNonce',
    sequenceNonceName = 'sequenceNonce';
  // Private methods for getting device attribute values
  function isFlashEnabled() {
    // Flash is no longer supported.
    return false;
  }
  function getFlashVersion() {
    // Flash is no longer supported.
    return '0.0.0';
  }
  function getPlugins() {
    // We aren't going to bother trying to use ActiveX to enumerate
    // through a list of potential plugins for IE < 11 though we
    // certainly could
    var result = [];
    var plugins = navigator.plugins;
    if (plugins) {
      for (var i = 0, l = plugins.length; i < l; i++) {
        var plugin = plugins.item(i);
        result.push(plugin.name + ';' + plugin.filename + ';' + plugin.description);
      }
    }
    // Consistent order for device ID calculation
    return result.sort();
  }
  function getCPU() {
    // Two different variations depending on browser
    var cpu = navigator.oscpu;
    if (cpu === undefined) cpu = navigator.cpuClass;
    return cpu;
  }
  function getCSSVendorPrefix() {
    // http://davidwalsh.name/vendor-prefix
    // (we settle on Opera if none is found)
    // IE 8 (and below) doesn't support getComputedStyle
    if (!window.getComputedStyle) {
      if (document.documentElement.currentStyle) return 'ms';
      else return 'o';
    }
    var styles = window.getComputedStyle(document.documentElement, '');
    return (Array.prototype.slice
      .call(styles)
      .join('')
      .match(/-(moz|webkit|ms|khtml)-/) ||
      (styles.OLink === '' && ['', 'o']))[1];
  }
  function hasLocalStorage() {
    try {
      return !!window.localStorage;
    } catch (e) {
      // SecurityError referencing it means that it exists
      return true;
    }
  }
  function hasSessionStorage() {
    try {
      return !!window.sessionStorage;
    } catch (e) {
      // SecurityError referencing it means that it exists
      return true;
    }
  }
  function getUserAgentInfo(attrs, name) {
    var result = attrs[name];
    // Work around some issues in the UAParser library we are using
    if (typeof result !== 'string') result = 'unknown';
    return result;
  }
  function doNotTrack() {
    return navigator.doNotTrack ? navigator.doNotTrack : 'unknown';
  }
  function getFonts() {
    // A font will be compared against all the three default fonts.
    // and if it doesn't match all 3, then that font is not available.
    var baseFonts = ['monospace', 'sans-serif', 'serif'];
    // We use m or w because these two characters take up the maximum width.
    // And we use a lli so that the same matching fonts can get separated
    var testString = 'mmmmmmmmmmlli';
    // We test using 72px font size, though we may use any size.
    // I guess the larger the better.
    var testSize = '72px';
    var h = document.getElementsByTagName('body')[0];
    // This probably means that this code is being run before the
    // document has loaded
    if (!h) return 'undefined';
    // Create a SPAN in the document to get the width of the text we use
    // to test with.
    var s = document.createElement('span');
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    var defaultWidth = {},
      defaultHeight = {};
    for (var index in baseFonts) {
      // Get the default width for the three base fonts
      s.style.fontFamily = baseFonts[index];
      h.appendChild(s);
      // width for the default font
      defaultWidth[baseFonts[index]] = s.offsetWidth;
      // height for the default font
      defaultHeight[baseFonts[index]] = s.offsetHeight;
      h.removeChild(s);
    }
    var detect = function (font) {
      var detected = false;
      for (var index in baseFonts) {
        // name of the font along with the base font for fallback.
        s.style.fontFamily = font + ',' + baseFonts[index];
        h.appendChild(s);
        var matched =
          s.offsetWidth !== defaultWidth[baseFonts[index]] || s.offsetHeight !== defaultHeight[baseFonts[index]];
        h.removeChild(s);
        detected = detected || matched;
      }
      return detected;
    };
    var fontList = [
      'Abadi MT Condensed Light',
      'Academy Engraved LET',
      'ADOBE CASLON PRO',
      'Adobe Garamond',
      'ADOBE GARAMOND PRO',
      'Agency FB',
      'Aharoni',
      'Albertus Extra Bold',
      'Albertus Medium',
      'Algerian',
      'Amazone BT',
      'American Typewriter',
      'American Typewriter Condensed',
      'AmerType Md BT',
      'Andale Mono',
      'Andalus',
      'Angsana New',
      'AngsanaUPC',
      'Antique Olive',
      'Aparajita',
      'Apple Chancery',
      'Apple Color Emoji',
      'Apple SD Gothic Neo',
      'Arabic Typesetting',
      'ARCHER',
      'Arial',
      'Arial Black',
      'Arial Hebrew',
      'Arial MT',
      'Arial Narrow',
      'Arial Rounded MT Bold',
      'Arial Unicode MS',
      'ARNO PRO',
      'Arrus BT',
      'Aurora Cn BT',
      'AvantGarde Bk BT',
      'AvantGarde Md BT',
      'AVENIR',
      'Ayuthaya',
      'Bandy',
      'Bangla Sangam MN',
      'Bank Gothic',
      'BankGothic Md BT',
      'Baskerville',
      'Baskerville Old Face',
      'Batang',
      'BatangChe',
      'Bauer Bodoni',
      'Bauhaus 93',
      'Bazooka',
      'Bell MT',
      'Bembo',
      'Benguiat Bk BT',
      'Berlin Sans FB',
      'Berlin Sans FB Demi',
      'Bernard MT Condensed',
      'BernhardFashion BT',
      'BernhardMod BT',
      'Big Caslon',
      'BinnerD',
      'Bitstream Vera Sans Mono',
      'Blackadder ITC',
      'BlairMdITC TT',
      'Bodoni 72',
      'Bodoni 72 Oldstyle',
      'Bodoni 72 Smallcaps',
      'Bodoni MT',
      'Bodoni MT Black',
      'Bodoni MT Condensed',
      'Bodoni MT Poster Compressed',
      'Book Antiqua',
      'Bookman Old Style',
      'Bookshelf Symbol 7',
      'Boulder',
      'Bradley Hand',
      'Bradley Hand ITC',
      'Bremen Bd BT',
      'Britannic Bold',
      'Broadway',
      'Browallia New',
      'BrowalliaUPC',
      'Brush Script MT',
      'Calibri',
      'Californian FB',
      'Calisto MT',
      'Calligrapher',
      'Cambria',
      'Cambria Math',
      'Candara',
      'CaslonOpnface BT',
      'Castellar',
      'Centaur',
      'Century',
      'Century Gothic',
      'Century Schoolbook',
      'Cezanne',
      'CG Omega',
      'CG Times',
      'Chalkboard',
      'Chalkboard SE',
      'Chalkduster',
      'Charlesworth',
      'Charter Bd BT',
      'Charter BT',
      'Chaucer',
      'ChelthmITC Bk BT',
      'Chiller',
      'Clarendon',
      'Clarendon Condensed',
      'CloisterBlack BT',
      'Cochin',
      'Colonna MT',
      'Comic Sans',
      'Comic Sans MS',
      'Consolas',
      'Constantia',
      'Cooper Black',
      'Copperplate',
      'Copperplate Gothic',
      'Copperplate Gothic Bold',
      'Copperplate Gothic Light',
      'CopperplGoth Bd BT',
      'Corbel',
      'Cordia New',
      'CordiaUPC',
      'Cornerstone',
      'Coronet',
      'Courier',
      'Courier New',
      'Cuckoo',
      'Curlz MT',
      'DaunPenh',
      'Dauphin',
      'David',
      'DB LCD Temp',
      'DELICIOUS',
      'Denmark',
      'Devanagari Sangam MN',
      'DFKai-SB',
      'Didot',
      'DilleniaUPC',
      'DIN',
      'DokChampa',
      'Dotum',
      'DotumChe',
      'Ebrima',
      'Edwardian Script ITC',
      'Elephant',
      'English 111 Vivace BT',
      'Engravers MT',
      'EngraversGothic BT',
      'Eras Bold ITC',
      'Eras Demi ITC',
      'Eras Light ITC',
      'Eras Medium ITC',
      'Estrangelo Edessa',
      'EucrosiaUPC',
      'Euphemia',
      'Euphemia UCAS',
      'EUROSTILE',
      'Exotc350 Bd BT',
      'FangSong',
      'Felix Titling',
      'Fixedsys',
      'FONTIN',
      'Footlight MT Light',
      'Forte',
      'Franklin Gothic',
      'Franklin Gothic Book',
      'Franklin Gothic Demi',
      'Franklin Gothic Demi Cond',
      'Franklin Gothic Heavy',
      'Franklin Gothic Medium',
      'Franklin Gothic Medium Cond',
      'FrankRuehl',
      'Fransiscan',
      'Freefrm721 Blk BT',
      'FreesiaUPC',
      'Freestyle Script',
      'French Script MT',
      'FrnkGothITC Bk BT',
      'Fruitger',
      'FRUTIGER',
      'Futura',
      'Futura Bk BT',
      'Futura Lt BT',
      'Futura Md BT',
      'Futura ZBlk BT',
      'FuturaBlack BT',
      'Gabriola',
      'Galliard BT',
      'Garamond',
      'Gautami',
      'Geeza Pro',
      'Geneva',
      'Geometr231 BT',
      'Geometr231 Hv BT',
      'Geometr231 Lt BT',
      'Georgia',
      'GeoSlab 703 Lt BT',
      'GeoSlab 703 XBd BT',
      'Gigi',
      'Gill Sans',
      'Gill Sans MT',
      'Gill Sans MT Condensed',
      'Gill Sans MT Ext Condensed Bold',
      'Gill Sans Ultra Bold',
      'Gill Sans Ultra Bold Condensed',
      'Gisha',
      'Gloucester MT Extra Condensed',
      'GOTHAM',
      'GOTHAM BOLD',
      'Goudy Old Style',
      'Goudy Stout',
      'GoudyHandtooled BT',
      'GoudyOLSt BT',
      'Gujarati Sangam MN',
      'Gulim',
      'GulimChe',
      'Gungsuh',
      'GungsuhChe',
      'Gurmukhi MN',
      'Haettenschweiler',
      'Harlow Solid Italic',
      'Harrington',
      'Heather',
      'Heiti SC',
      'Heiti TC',
      'HELV',
      'Helvetica',
      'Helvetica Neue',
      'Herald',
      'High Tower Text',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Mincho ProN',
      'Hoefler Text',
      'Humanst 521 Cn BT',
      'Humanst521 BT',
      'Humanst521 Lt BT',
      'Impact',
      'Imprint MT Shadow',
      'Incised901 Bd BT',
      'Incised901 BT',
      'Incised901 Lt BT',
      'INCONSOLATA',
      'Informal Roman',
      'Informal011 BT',
      'INTERSTATE',
      'IrisUPC',
      'Iskoola Pota',
      'JasmineUPC',
      'Jazz LET',
      'Jenson',
      'Jester',
      'Jokerman',
      'Juice ITC',
      'Kabel Bk BT',
      'Kabel Ult BT',
      'Kailasa',
      'KaiTi',
      'Kalinga',
      'Kannada Sangam MN',
      'Kartika',
      'Kaufmann Bd BT',
      'Kaufmann BT',
      'Khmer UI',
      'KodchiangUPC',
      'Kokila',
      'Korinna BT',
      'Kristen ITC',
      'Krungthep',
      'Kunstler Script',
      'Lao UI',
      'Latha',
      'Leelawadee',
      'Letter Gothic',
      'Levenim MT',
      'LilyUPC',
      'Lithograph',
      'Lithograph Light',
      'Long Island',
      'Lucida Bright',
      'Lucida Calligraphy',
      'Lucida Console',
      'Lucida Fax',
      'LUCIDA GRANDE',
      'Lucida Handwriting',
      'Lucida Sans',
      'Lucida Sans Typewriter',
      'Lucida Sans Unicode',
      'Lydian BT',
      'Magneto',
      'Maiandra GD',
      'Malayalam Sangam MN',
      'Malgun Gothic',
      'Mangal',
      'Marigold',
      'Marion',
      'Marker Felt',
      'Market',
      'Marlett',
      'Matisse ITC',
      'Matura MT Script Capitals',
      'Meiryo',
      'Meiryo UI',
      'Microsoft Himalaya',
      'Microsoft JhengHei',
      'Microsoft New Tai Lue',
      'Microsoft PhagsPa',
      'Microsoft Sans Serif',
      'Microsoft Tai Le',
      'Microsoft Uighur',
      'Microsoft YaHei',
      'Microsoft Yi Baiti',
      'MingLiU',
      'MingLiU_HKSCS',
      'MingLiU_HKSCS-ExtB',
      'MingLiU-ExtB',
      'Minion',
      'Minion Pro',
      'Miriam',
      'Miriam Fixed',
      'Mistral',
      'Modern',
      'Modern No. 20',
      'Mona Lisa Solid ITC TT',
      'Monaco',
      'Mongolian Baiti',
      'MONO',
      'Monotype Corsiva',
      'MoolBoran',
      'Mrs Eaves',
      'MS Gothic',
      'MS LineDraw',
      'MS Mincho',
      'MS Outlook',
      'MS PGothic',
      'MS PMincho',
      'MS Reference Sans Serif',
      'MS Reference Specialty',
      'MS Sans Serif',
      'MS Serif',
      'MS UI Gothic',
      'MT Extra',
      'MUSEO',
      'MV Boli',
      'MYRIAD',
      'MYRIAD PRO',
      'Nadeem',
      'Narkisim',
      'NEVIS',
      'News Gothic',
      'News GothicMT',
      'NewsGoth BT',
      'Niagara Engraved',
      'Niagara Solid',
      'Noteworthy',
      'NSimSun',
      'Nyala',
      'OCR A Extended',
      'Old Century',
      'Old English Text MT',
      'Onyx',
      'Onyx BT',
      'OPTIMA',
      'Oriya Sangam MN',
      'OSAKA',
      'OzHandicraft BT',
      'Palace Script MT',
      'Palatino',
      'Palatino Linotype',
      'Papyrus',
      'Parchment',
      'Party LET',
      'Pegasus',
      'Perpetua',
      'Perpetua Titling MT',
      'PetitaBold',
      'Pickwick',
      'Plantagenet Cherokee',
      'Playbill',
      'PMingLiU',
      'PMingLiU-ExtB',
      'Poor Richard',
      'Poster',
      'PosterBodoni BT',
      'PRINCETOWN LET',
      'Pristina',
      'PTBarnum BT',
      'Pythagoras',
      'Raavi',
      'Rage Italic',
      'Ravie',
      'Ribbon131 Bd BT',
      'Rockwell',
      'Rockwell Condensed',
      'Rockwell Extra Bold',
      'Rod',
      'Roman',
      'Sakkal Majalla',
      'Santa Fe LET',
      'Savoye LET',
      'Sceptre',
      'Script',
      'Script MT Bold',
      'SCRIPTINA',
      'Segoe Print',
      'Segoe Script',
      'Segoe UI',
      'Segoe UI Light',
      'Segoe UI Semibold',
      'Segoe UI Symbol',
      'Serifa',
      'Serifa BT',
      'Serifa Th BT',
      'ShelleyVolante BT',
      'Sherwood',
      'Shonar Bangla',
      'Showcard Gothic',
      'Shruti',
      'Signboard',
      'SILKSCREEN',
      'SimHei',
      'Simplified Arabic',
      'Simplified Arabic Fixed',
      'SimSun',
      'SimSun-ExtB',
      'Sinhala Sangam MN',
      'Sketch Rockwell',
      'Skia',
      'Small Fonts',
      'Snap ITC',
      'Snell Roundhand',
      'Socket',
      'Souvenir Lt BT',
      'Staccato222 BT',
      'Steamer',
      'Stencil',
      'Storybook',
      'Styllo',
      'Subway',
      'Swis721 BlkEx BT',
      'Swiss911 XCm BT',
      'Sylfaen',
      'Symbol',
      'Synchro LET',
      'System',
      'Tahoma',
      'Tamil Sangam MN',
      'Technical',
      'Teletype',
      'Telugu Sangam MN',
      'Tempus Sans ITC',
      'Terminal',
      'Thonburi',
      'Times',
      'Times New Roman',
      'Times New Roman PS',
      'Traditional Arabic',
      'Trajan',
      'TRAJAN PRO',
      'Trebuchet MS',
      'Tristan',
      'Tubular',
      'Tunga',
      'Tw Cen MT',
      'Tw Cen MT Condensed',
      'Tw Cen MT Condensed Extra Bold',
      'TypoUpright BT',
      'Unicorn',
      'Univers',
      'Univers CE 55 Medium',
      'Univers Condensed',
      'Utsaah',
      'Vagabond',
      'Vani',
      'Verdana',
      'Vijaya',
      'Viner Hand ITC',
      'VisualUI',
      'Vivaldi',
      'Vladimir Script',
      'Vrinda',
      'Webdings',
      'Westminster',
      'WHITNEY',
      'Wide Latin',
      'Wingdings',
      'Wingdings 2',
      'Wingdings 3',
      'ZapfEllipt BT',
      'ZapfHumnst BT',
      'ZapfHumnst Dm BT',
      'Zapfino',
      'Zurich BlkEx BT',
      'Zurich Ex BT',
      'ZWAdobeF'
    ];
    var result = [];
    for (var i = 0, l = fontList.length; i < l; i++) {
      if (detect(fontList[i])) result.push(/*fontList[i]*/ i);
    }
    return result;
  }
  function getCanvas() {
    // https://www.browserleaks.com/canvas#how-does-it-work
    // Very simple now, need to make it more complex (geo shapes etc)
    var canvas = document.createElement('canvas');
    if (!!(canvas.getContext && canvas.getContext('2d'))) {
      canvas.width = 1600;
      canvas.height = 100;
      var ctx = canvas.getContext('2d');
      // text with lowercase/uppercase/punctuation symbols
      var txt = 'http://www.entrustdatacard.com/This Is The <Canvas> FP 1.0';
      ctx.textBaseline = 'top';
      // ios8 specific font
      ctx.font = "72px 'DamascusLight'";
      ctx.fillStyle = '#f60';
      ctx.fillRect(2, 0, 1000, 70);
      ctx.fillStyle = '#069';
      ctx.fillText(txt, 2, 0);
      // android specific font
      ctx.font = "72px 'Roboto Condensed'";
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
      ctx.fillText(txt, 4, 2);
      ctx.strokeStyle = 'rgba(202, 104, 0, 0.9)';
      // osx specific font
      ctx.font = "72px 'Menlo'";
      ctx.strokeText(txt, 8, 4);
      // To create a signature from the canvas, we must export the pixels
      // from the application's memory using the toDataURL() method,
      // which will return the base64-encoded string of the binary
      // image file.
      return hash(canvas.toDataURL());
    }
    return 'undefined';
  }
  function getWebGL() {
    var getWebglCanvas = function () {
      var canvas = document.createElement('canvas');
      var gl = null;
      try {
        gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      } catch (e) {}
      if (!gl) gl = null;
      return gl;
    };
    var fa2s = function (fa) {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      return '(' + fa[0] + ' x ' + fa[1] + ')';
    };
    var maxAnisotropy = function (gl) {
      var anisotropy,
        ext =
          gl.getExtension('EXT_texture_filter_anisotropic') ||
          gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic') ||
          gl.getExtension('MOZ_EXT_texture_filter_anisotropic');
      return ext
        ? ((anisotropy = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT)),
          0 === anisotropy && (anisotropy = 2),
          anisotropy)
        : null;
    };
    // WebGL fingerprinting is a combination of techniques, found in
    // MaxMind antifraud script & Augur fingerprinting.
    // First it draws a gradient object with shaders and converts the image
    // to the Base64 string, the first item in our results array.
    // Then it enumerates all WebGL extensions & capabilities and appends
    // them to the result array we are building, resulting in something
    // that is potentially very unique on each device.
    // Since iOS supports webgl starting from version 8.1 and 8.1 runs on
    // several graphics chips, the results may be different across ios
    // devices, but we need to verify it.
    var result = [];
    var gl = getWebglCanvas();
    if (gl) {
      var vShaderTemplate =
        'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
      var fShaderTemplate =
        'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';
      var vertexPosBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);
      var vertices = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0]);
      gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
      vertexPosBuffer.itemSize = 3;
      vertexPosBuffer.numItems = 3;
      var program = gl.createProgram(),
        vshader = gl.createShader(gl.VERTEX_SHADER);
      gl.shaderSource(vshader, vShaderTemplate);
      gl.compileShader(vshader);
      var fshader = gl.createShader(gl.FRAGMENT_SHADER);
      gl.shaderSource(fshader, fShaderTemplate);
      gl.compileShader(fshader);
      gl.attachShader(program, vshader);
      gl.attachShader(program, fshader);
      gl.linkProgram(program);
      gl.useProgram(program);
      program.vertexPosAttrib = gl.getAttribLocation(program, 'attrVertex');
      program.offsetUniform = gl.getUniformLocation(program, 'uniformOffset');
      gl.enableVertexAttribArray(program.vertexPosArray);
      gl.vertexAttribPointer(program.vertexPosAttrib, vertexPosBuffer.itemSize, gl.FLOAT, !1, 0, 0);
      gl.uniform2f(program.offsetUniform, 1, 1);
      gl.drawArrays(gl.TRIANGLE_STRIP, 0, vertexPosBuffer.numItems);
      if (gl.canvas != null) result.push(hash(gl.canvas.toDataURL()));
      // extensions
      result.push(gl.getSupportedExtensions().join(';'));
      // webgl aliased line width range
      result.push(fa2s(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE)));
      // webgl aliased point size range
      result.push(fa2s(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE)));
      // webgl alpha bits
      result.push(gl.getParameter(gl.ALPHA_BITS));
      // webgl antialiasing
      result.push(gl.getContextAttributes().antialias);
      // webgl blue bits
      result.push(gl.getParameter(gl.BLUE_BITS));
      // webgl depth bits
      result.push(gl.getParameter(gl.DEPTH_BITS));
      // webgl green bits
      result.push(gl.getParameter(gl.GREEN_BITS));
      // webgl max anisotropy
      result.push(maxAnisotropy(gl));
      // webgl max combined texture image units
      result.push(gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS));
      // webgl max cube map texture size
      result.push(gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE));
      // webgl max fragment uniform vectors
      result.push(gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS));
      // webgl max render buffer size
      result.push(gl.getParameter(gl.MAX_RENDERBUFFER_SIZE));
      // webgl max texture image units
      result.push(gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS));
      // webgl max texture size
      result.push(gl.getParameter(gl.MAX_TEXTURE_SIZE));
      // webgl max varying vectors
      result.push(gl.getParameter(gl.MAX_VARYING_VECTORS));
      // webgl max vertex attribs
      result.push(gl.getParameter(gl.MAX_VERTEX_ATTRIBS));
      // webgl max vertex texture image units
      result.push(gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS));
      // webgl max vertex uniform vectors
      result.push(gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS));
      // webgl max viewport dims
      result.push(fa2s(gl.getParameter(gl.MAX_VIEWPORT_DIMS)));
      // webgl red bits
      result.push(gl.getParameter(gl.RED_BITS));
      // webgl renderer
      result.push(gl.getParameter(gl.RENDERER));
      // webgl shading language version
      result.push(gl.getParameter(gl.SHADING_LANGUAGE_VERSION));
      // webgl stencil bits
      result.push(gl.getParameter(gl.STENCIL_BITS));
      // webgl vendor
      result.push(gl.getParameter(gl.VENDOR));
      // webgl version
      result.push(gl.getParameter(gl.VERSION));
    }
    return result;
  }
  /**
   * Collect the iovation blackbox data, optionally store it in a form field
   * and return it. If setIovationBlackboxElementId() was called and a valid
   * form element ID was provided, the blackbox data will be stored there.
   * @returns {string} The iovation blackbox data, or null if iovation is not
   *     enabled or if the data could not be collected.
   * @public
   */
  function getIovationBlackbox() {
    if (typeof ioGetBlackbox != 'function') {
      debug('iovation snare javascript not loaded');
      return null;
    }
    var bb = ioGetBlackbox();
    if (!bb.finished) {
      error('iovation blackbox collection not ready');
      return null;
    }
    if (iovationBlackboxElementId != null && document.getElementById(iovationBlackboxElementId) != null) {
      document.getElementById(iovationBlackboxElementId).value = bb.blackbox;
    }
    return bb.blackbox;
  }
  // Helper method to see if an array contains a value
  // We could use Array.indexOf >= 0 but not all browsers have this in
  // their JS implementation
  function contains(array, value) {
    if (!array) return false;
    var i = array.length;
    while (i--) {
      if (array[i] === value) return true;
    }
    return false;
  }

  // Helper method that calls the SHA256 hashing code in use
  function hash(message) {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  }

  // Helper method for displaying a debug message
  function debug(message) {
    if (debugOn) console.log('DEBUG(' + LIBRARY_NAME + '): ' + message);
  }
  // Helper method for displaying an error message
  function error(message) {
    message = 'ERROR(' + LIBRARY_NAME + '): ' + message;
    if (window.console && window.console.log) {
      console.log(message);
    } else {
      alert(message);
    }
  }
  // Helper method for setting a nonce value
  function setNonce(name, value) {
    var ts = new Date().toUTCString();
    for (var storageType in storageTypes) {
      if (!contains(storageTypeExclusions, storageType) && storageTypes[storageType].isEnabled()) {
        storageTypes[storageType].set(
          name,
          JSON.stringify({
            timestamp: ts,
            nonce: value
          })
        );
      }
    }
  }
  // Helper method for getting a nonce value and making sure that the latest
  // is replicated back to other storage types where needed
  function getNonce(name) {
    var nonceObjects = [];
    var notFound = [];
    // We need to check all supported storage types and make sure
    // that we get the latest value from all of them
    for (var storageType in storageTypes) {
      if (!contains(storageTypeExclusions, storageType) && storageTypes[storageType].isEnabled()) {
        var nonceString = storageTypes[storageType].get(name);
        if (nonceString) {
          var nonceObject = JSON.parse(nonceString);
          nonceObject.storageType = storageType;
          nonceObjects.push(nonceObject);
          debug('For nonce ' + name + ', fetched nonce value ' + nonceString + ' from storage type ' + storageType);
        } else {
          notFound.push(storageType);
        }
      }
    }
    if (nonceObjects.length === 0) {
      debug('Could not find a nonce value for ' + name);
      return undefined;
    }
    var latest = 0;
    if (nonceObjects.length > 1) {
      var ts = Date.parse(nonceObjects[latest].timestamp);
      // We need to make sure that all values have the same time stamp,
      // and if not, that we sanitize everything so that the same value
      // (i.e., the latest value) is stored everywhere.
      var i = 1,
        refresh = false;
      do {
        var ts2 = Date.parse(nonceObjects[i].timestamp);
        if (ts2 !== ts) {
          debug('Mismatch between nonce timestamps!');
          refresh = true;
          if (ts2 > ts) {
            debug(
              'Timestamp for nonce value in storage type ' +
                nonceObjects[i].storageType +
                ' is newer than for storage type ' +
                nonceObjects[latest].storageType
            );
            // We found a date that's newer than our previous newest
            latest = i;
            ts = ts2;
          }
        }
      } while (++i < nonceObjects.length);
      if (refresh) {
        // We have a timestamp inconsistency
        debug('Fixing nonce timestamp inconsistencies.');
        var timestamp = nonceObjects[latest].timestamp;
        var nonce = nonceObjects[latest].nonce;
        nonceString = JSON.stringify({
          timestamp: timestamp,
          nonce: nonce
        });
        for (i = 0; i < nonceObjects.length; i++) {
          if (nonceObjects[i].timestamp !== timestamp) {
            storageType = nonceObjects[i].storageType;
            debug(
              'Storage type ' +
                storageType +
                ' is being updated with the latest timestamp and ' +
                'nonce values: ' +
                nonceString
            );
            storageTypes[storageType].set(name, nonceString);
          }
        }
      }
    }
    // We also need to worry about whether one or more storage locations
    // where the nonce values are expected to be found is missing a value.
    // If so, the latest value should be replicated.
    for (i = 0; i < notFound.length; i++) {
      // We need to add it (back?)
      nonceString = JSON.stringify({
        timestamp: nonceObjects[latest].timestamp,
        nonce: nonceObjects[latest].nonce
      });
      storageType = notFound[i];
      storageTypes[storageType].set(name, nonceString);
      debug('Restoring nonce ' + name + ' with value ' + nonceString + ' to storage type ' + storageType);
    }
    return nonceObjects[latest].nonce;
  }
  // Helper method for removing a "nonce" value
  function removeNonce(name) {
    for (var storageType in storageTypes) {
      if (!contains(storageTypeExclusions, storageType) && storageTypes[storageType].isEnabled()) {
        storageTypes[storageType].remove(name);
      }
    }
  }
  // Our internal "constructor" with our public methods, and for Flash
  // communication, what should be considered private methods like
  // the functions above.
  function define() {
    // Indication that we are using a device fingerprint
    var DEVICE_FP_IN_USE = '__DeviceFPInUse__';
    /**
     * Allow a device fingerprint and a device id to be calculated
     * based on a prescribed set of device attributes.  Also, allow
     * machine and sequence nonces to be stored across multiple
     * web-based storage mechanisms.
     * @example
     * <caption>Exclude a device attribute and a storage type</caption>
     * machineSecret.setAttributeExclusions('availWidth').
     * setStorageTypeExclusions('cookie');
     * @example
     * <caption>Change the name of the machine and sequence nonces</caption>
     * machineSecret.setMachineNonceName('com.company.machnonce').
     * setSequenceNonceName('com.company.seqnonce');
     * @example
     * <caption>Enable Debugging</caption>
     * machineSecret.setDebugOn(true);
     * @example
     * <caption>Set a form variable to have the device fingerprint value</caption>
     * document.forms["myForm"]["deviceFp"].value =
     * machineSecret.getDeviceFingerprint();
     * @example
     * <caption> Set form variables based on the current machine and sequence nonces</caption>
     * document.forms["myForm"]["machNonce"].value =
     * machineSecret.fetchMachineNonce();
     * document.forms["myForm"]["seqNonce"].value =
     * machineSecret.fetchSequenceNonce();
     * @example
     * <caption>Store the machine and sequence nonce values based on form variables</caption>
     * machineSecret.storeMachineNonce(
     * document.forms["myForm"]["machNonce"].value);
     * machineSecret.storeSequenceNonce(
     * document.forms["myForm"]["seqNonce"].value);
     * @example
     * <caption>Add cookie related options for nonce storage</caption>
     * machineSecret.setStorageTypeOptions(
     *   { path : '/foo/bar', secure : true}
     * );
     * @example
     * <caption>Add a new device attribute</caption>
     * machineSecret.addDeviceAttribute('userAgent',
     * function() {return navigator.userAgent;});
     * @namespace
     */
    var machineSecret = {};
    var asyncWaitList = [];
    var asyncTimeoutObject = null;
    var asyncCallback = null;
    /**
     * Exclude one or more attributes from the device fingerprint
     * and device id calculations. If you attempt to exclude more
     * device attributes than are supported, attempt to exclude
     * a device attribute that does not exist, or pass in a value that
     * is neither a String nor a String array, then the request will be
     * ignored and an error registered.
     * @public
     * @param {(string|string[])} exclude - The device attributes to
     *                                      exclude.
     * @returns {Object} The machineSecret object for function chaining
     *                   purposes.
     */
    machineSecret.setAttributeExclusions = function (exclude) {
      var attrs = Object.keys(attributes);
      if (exclude instanceof Array) {
        // We can't exclude more device attributes than there are
        // defined or attribute types don't exist
        if (exclude.length >= attrs.length) {
          error(
            'Ignoring attempt to set device attribute ' +
              'exclusions as the value provided would have excluded' +
              'all attributes.'
          );
        } else {
          var success = true;
          for (var i = 0; i < exclude.length; i++) {
            if (!contains(attrs, exclude[i])) {
              success = false;
              break;
            }
          }
          if (success) {
            attributeExclusions = exclude;
          } else {
            error(
              'Ignoring attempt to set device attribute ' +
                "exclusions as an unknown attribute '" +
                exclusion +
                "' was found."
            );
          }
        }
      } else if (typeof exclude === 'string') {
        if (contains(attrs, exclude)) {
          attributeExclusions = [exclude];
        } else {
          error(
            'Ignoring attempt to set device attribute ' +
              "exclusions as an unknown attribute '" +
              exclude +
              "' was provided."
          );
        }
      } else {
        error(
          'Ignoring attempt to set device attribute exclusions ' +
            'as the value provided was neither an Array nor a String.'
        );
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Exclude one or more storage types when asked to store or fetch a
     * machine and/or sequence nonce. If you attempt to exclude more
     * storage types than are supported, attempt to exclude a storage
     * type that does not exist, or pass in a value that is neither
     * a String nor a String Array, then the request will be ignored
     * and an error registered. Currently supported storage
     * types are as follows: "cookie" and "localStorage". Flash storage
     * is no longer supported. The JS functions that involve flash have
     * been adjusted accordingly.
     * @public
     * @param {(string|string[])} exclude - The storage types to exclude.
     *                                      Pass null if you want to cancel
     *                                      any previous exclusions.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setStorageTypeExclusions = function (exclude) {
      if (exclude === null) {
        storageTypeExclusions = 'flash'; //The default excluded storage type
        // For chaining
        return machineSecret;
      }

      var types = Object.keys(storageTypes);
      if (exclude instanceof Array) {
        //Adding the default excluded storage type (i.e. 'flash') to the
        //exclusion list. This is required incase if you are excluding 2 types
        //with neither of them being Flash
        //(e.g. machineSecret.setStorageTypeExclusions(['cookie', localStorage']')),
        //then it has to ignore the exclusions. Otherwise it will excluded all
        //storage types.
        if (!contains(exclude, storageTypeExclusions)) {
          exclude.push(storageTypeExclusions);
        }

        // We can't exclude more storage types than there are defined
        // or storage types that don't exist
        if (exclude.length >= types.length) {
          error(
            'Ignoring attempt to set storage type exclusions ' +
              'as the value provided would have excluded all ' +
              'storage types.'
          );
        } else {
          var success = true;
          for (var i = 0; i < exclude.length; i++) {
            if (!contains(types, exclude[i])) {
              success = false;
              break;
            }
          }
          if (success) {
            storageTypeExclusions = exclude;
          } else {
            error(
              'Ignoring attempt to set storage type ' +
                "exclusions as an unknown storage type '" +
                exclusion +
                "' was found."
            );
          }
        }
      } else if (typeof exclude === 'string') {
        if (contains(types, exclude)) {
          storageTypeExclusions = [exclude];
          if (exclude !== 'flash') {
            storageTypeExclusions.push('flash');
          }
        } else {
          error(
            'Ignoring attempt to set storage type exclusions ' +
              "as an unknown storage type '" +
              exclude +
              "' was provided."
          );
        }
      } else {
        error(
          'Ignoring attempt to set storage type exclusions as ' +
            'the value provided was neither an Array nor a String.'
        );
      }

      // For chaining
      return machineSecret;
    };
    /**
     * Add a post-processing function to be called once all of the
     * fingerprint data has been successfully/unsuccessfully collected.
     * Post-processors are called in the order they are inserted.
     * @public
     * @param {function} postProcessor - The post-processing
     *                   function to be called.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.addPostProcessor = function (postProcessor) {
      if (typeof postProcessor !== 'function') {
        error('Post-processor specified is not a function');
      } else {
        debug('Adding post-processor');
        postProcessors.push(postProcessor);
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Remove all post-processors from the list.
     * @public
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.removeAllPostProcessors = function () {
      debug('Removing all post-processors');
      postProcessors = [];
      // For chaining
      return machineSecret;
    };
    /**
     * A callback type for the asynchronous return of the device fingerprint
     * once it has been calculated.
     *
     * @callback asyncCallback
     * @param {string} deviceFingerprint - The string version of the JSON object
     *                 that represents a browser's device fingerprint.
     */
    /**
     * This function initiates the generation of the string version of the
     * JSON object that represents the web browser's device fingerprint.
     * This value should be passed to the Entrust Identity Enterprise
     * Authentication API to enable machine authentication.
     * <p>
     * The function has two calling signatures:
     * </p>
     * <ul>
     * <li>direct: if no arguments are passed, the function returns the
     *             JSON string directly.
     * </li>
     * <li>callback: if the optional <code>callback</code> function is
     *               passed, then this function will return <code>true</code> and
     *               the JSON string will be passed to the callback function
     *               once it has been fully calculated, or the wait period has expired.
     * </li>
     * </ul>
     * @public
     * @param {asyncCallback} [callback] - The callback function to be called once
     *                   all of the attributes and asynchronous attributes
     *                   have either been calculated, or the "wait" time is
     *                   exceeded.
     * @param {number} [wait=4000] - The number of milliseconds to wait for
     *                   all attributes to be calculated before giving up
     *                   and returning what we have.
     * @returns {string|boolean} The string version of the JSON object that
     *                   represents a browser's device fingerprint in the
     *                   direct call model, or <code>true</code> in the callback
     *                   model if all is well and <code>false</code> if a non-function
     *                   was passed in as the callback.
     */
    machineSecret.getDeviceFingerprint = function (callback, wait) {
      if (callback && typeof callback !== 'function') {
        error('When callback is provided, it must be a function.');
        return false;
      }
      fingerprint = {};
      fingerprint.platform = 'web';
      fingerprint.version = LIBRARY_VERSION;
      fingerprint.attributes = {};
      if (!!errorAttributeName) {
        fingerprint[errorAttributeName] = {};
      }
      for (var attribute in attributes) {
        fingerprint.attributes[attribute] = getAttributeValue(attribute);
      }
      // Clear the wait-list
      asyncCallback = callback;
      asyncWaitList = [];
      for (var attribute in asynchronousAttributes) {
        if (!contains(attributeExclusions, attribute)) {
          if (typeof callback === 'undefined') {
            if (!!errorAttributeName) {
              fingerprint[errorAttributeName][attribute] = 'requires a callback function';
            }
          } else {
            asyncWaitList.push(attribute);
            // TODO: Should we be passing the name of attribute into
            // the function that is supposed to be collecting the value for
            // that attribute?  Is that the method signature for the callback?
            asynchronousAttributes[attribute](attribute);
          }
        }
      }
      // If a device fingerprint was asked for, it is assumed it is being used
      setNonce(DEVICE_FP_IN_USE, true);
      if (typeof callback === 'undefined') {
        // Return the device fingerprint as a JSON string
        executePostProcessors();
        return debugOn ? JSON.stringify(fingerprint, null, 4) : JSON.stringify(fingerprint);
      } else {
        if (typeof wait === 'undefined' || typeof wait !== 'number') {
          wait = 4000;
        }
        if (!machineSecret.asynchronousAttributesCheckDone()) {
          // Start a timeout task
          asyncTimeoutObject = setTimeout(asynchronousAttributesGetterTimeout, wait);
        }
        // Say everything is fine.
        return true;
      }
    };
    /**
     * Gete the value for the named attribute from either the cache or live. If the
     * attribute value is live, and the attribute is cacheable, then update the
     * attribute cache with the value
     * @param (string) attributeName - Name of the attribute to get
     * @private
     */
    function getAttributeValue(attributeName) {
      var value;
      if (attributeName in attributesCache) {
        value = attributesCache[attributeName];
      } else {
        value = attributes[attributeName].get();
        if (attributes[attributeName].cacheable) {
          attributesCache[attributeName] = value;
        }
      }
      return value;
    }
    /**
     * This is a private function is called when a fingerprint get with a
     * callback exceeds the user-defined wait period.
     * @private
     */
    function asynchronousAttributesGetterTimeout() {
      while (asyncWaitList.length) {
        var attribute = asyncWaitList.pop();
        debug('timed out waiting for [attribute=' + attribute + ']');
        if (!!errorAttributeName) {
          fingerprint[errorAttributeName][attribute] = 'timed out waiting for response';
        }
      }
      executePostProcessors();
      asyncCallback(debugOn ? JSON.stringify(fingerprint, null, 4) : JSON.stringify(fingerprint));
    }
    /**
     * Iterates through the list of post-processors and executes them,
     * passing the <code>fingerprint</code> which the post-processor may
     * choose to read/modify.
     * @private
     */
    function executePostProcessors() {
      for (var i = 0; i < postProcessors.length; i++) {
        debug('executing post-processor #' + (i + 1));
        postProcessors[i](fingerprint);
      }
    }
    /**
     * Searches for the given attribute in the <code>asyncWaitList</code>
     * and removes it if it's found.
     * @param {string} attribute - The name of the attribute to remove
     * @private
     */
    function removeAttributeFromWaitList(attribute) {
      // remove the attribute from the wait-list
      for (var i = 0; i < asyncWaitList.length; i++) {
        if (asyncWaitList[i] === attribute) {
          asyncWaitList.splice(i, 1);
          break;
        }
      }
    }
    /**
     * This function is called by asynchronous attribute getters to set the
     * value for the attribute.
     * <p>
     * The function removes the attribute from <code>asyncWaitList</code>
     * and if this is the last attribute to be waited on, it also calls
     * the user-defined callback function and sends it the fingerprint
     * data.
     * </p>
     * @public
     * @param {string} attribute - The name of the attribute
     * @param {string} value - The attribute value
     */
    machineSecret.asynchronousAttributeSet = function (attribute, value) {
      debug('setting asynchronous attribute [attribute=' + attribute + '] [value=' + value + ']');
      fingerprint.attributes[attribute] = value;
      removeAttributeFromWaitList(attribute);
      machineSecret.asynchronousAttributesCheckDone();
    };
    /**
     * This function is called by asynchronous attribute getters to set the
     * failure message for an attribute that could not be gotten.
     * <p>
     * The function removes the attribute from <code>asyncWaitList</code>
     * and if this is the last attribute to be waited on, it also calls
     * the user-defined callback function and sends it the fingerprint
     * data.
     * </p>
     * @public
     * @param {string} attribute - The name of the attribute
     * @param {string} message - The failure message
     */
    machineSecret.asynchronousAttributeFail = function (attribute, message) {
      debug('failed asynchronous attribute [attribute=' + attribute + '] [message=' + message + ']');
      if (!!errorAttributeName) {
        fingerprint[errorAttributeName][attribute] = message;
      }
      removeAttributeFromWaitList(attribute);
      machineSecret.asynchronousAttributesCheckDone();
    };
    /**
     * This function checks to see if any asynchronous attributes are
     * pending responses and if they are not, it calls the user-defined
     * callback function with the fingerprint data.
     * @private
     * @returns {boolean} <code>true</code> if all asynchronous attributes
     *                    have reported and <code>false</code> otherwise
     */
    machineSecret.asynchronousAttributesCheckDone = function () {
      /* If the wait-list is now empty we stop the running timer and call the callback with
       * the fingerprint results JSON object.
       * Otherwise this is just one less attribute and we keep waiting for what remains.
       */
      if (!asyncWaitList.length) {
        if (!!asyncTimeoutObject) {
          clearTimeout(asyncTimeoutObject);
          asyncTimeoutObject = null;
        }
        executePostProcessors();
        asyncCallback(debugOn ? JSON.stringify(fingerprint, null, 4) : JSON.stringify(fingerprint));
        return true;
      } else {
        return false;
      }
    };
    /**
     * When machine and sequence nonces are not being used for machine authentication,
     * it is impossible to tell whether the Remember Me checkbox associated with
     * a login page should be checked or not.  If a device fingerprint has been
     * requested in a past authentication attempt, this will be remembered and returned
     * here.
     * @public
     * @returns {boolean} Whether a device fingerprint has been used for machine
     *                    authentication.
     */
    machineSecret.isDeviceFingerprintInUse = function () {
      return !!getNonce(DEVICE_FP_IN_USE);
    };
    /**
     * Return a hexadecimal string value that represents a device Id.
     * @public
     * @returns {string} The device Id which is a hexademical number
     *                   resulting from a SHA256 hash of the same
     *                   attributes used to calculate
     *                   the device fingerprint.
     */
    machineSecret.getDeviceId = function () {
      var attrs = [];
      for (var attribute in attributes) {
        if (!contains(attributeExclusions, attribute)) {
          attrs.push(attribute);
        }
      }
      attrs.sort();
      var result = '';
      for (var i = 0, l = attrs.length; i < l; i++) {
        result += attrs[i] + '=' + getAttributeValue(attrs[i]) + '\n';
      }
      debug('Device Fingerprint String For Hashing:\n' + result);
      // Hash the fingerprint
      return hash(result);
    };
    /**
     * Set the name to be used for the machine nonce.  If no name
     * is provided or the name is the same as the sequence nonce name,
     * an error will be registered. The default name for the machine
     * nonce is "machineNonce".
     * @public
     * @param {string} name - The name to be used for the machine nonce.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setMachineNonceName = function (name) {
      if (name && name !== sequenceNonceName) {
        machineNonceName = name;
      } else {
        error(
          'Ignoring attempt to set the machine nonce name since ' +
            'no value was provided, or it is the same as the ' +
            'sequence nonce name.'
        );
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Set the name to be used for the sequence nonce.  If no name
     * is provided or the name is the same as the machine nonce name,
     * an error will be registered. The default name for the sequence
     * nonce is "sequenceNonce".
     * @public
     * @param {string} name - The name to be used for the sequence nonce.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setSequenceNonceName = function (name) {
      if (name && name !== machineNonceName) {
        sequenceNonceName = name;
      } else {
        error(
          'Ignoring attempt to set the sequence nonce name since ' +
            'no value was provided, or it is the same as the machine ' +
            'nonce name.'
        );
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Set various options to be used for storing sequence and/or
     * machine nonce values
     * @public
     * @param {Object} options - Custom storage options
     * @param {string} options.expires - When the cookie associated with a
     *                                   nonce should expire. Default is
     *                                   one year expiration.
     * @param {string} options.path -  The path to associate with a cookie
     *                                 nonce. Default is no path.
     * @param {string} options.domain - The domain to associate with a
     *                                 cookie nonce. Default is no domain.
     * @param {boolean} options.secure - Whether the cookie associated with
     *                                  a nonce requires a secure (HTTPS)
     *                                  connection. Default is false.
     * @param {string} options.swfUrl - This parameter has been deprecated
     *									and will be ignored if provided since
     *									Flash is no longer supported)
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setStorageTypeOptions = function (options) {
      if (options && typeof options === 'object') {
        storageTypeOptions = options;
      } else {
        error('Ignoring attempt to set storage types options ' + 'as the value provided was not an Object.');
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Set the name of the fingerprint attribute that will be used to
     * collect attribute calculation failure reasons.
     * @public
     * @param {string} name - Name of the attribute for storing calculation
     *                   failure reasons.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setErrorAttributeName = function (name) {
      if (name && typeof name === 'string') {
        if (name in attributes || name in asynchronousAttributes) {
          error('The parameter you provided shares a name with a fingerprint attribute.');
        } else {
          errorAttributeName = name;
        }
      } else {
        error('The parameter you provided to setErrorAttributeName was not a string.');
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Set the ID of the form element that will be used to hold the iovation
     * blackbox data that is collected.
     * @param id the form element id
     * @public
     */
    machineSecret.setIovationBlackboxElementId = function (id) {
      iovationBlackboxElementId = id;
      // For chaining
      return machineSecret;
    };
    /**
     * Enable or disable debug mode.  In debug mode, messages are written
     * to the window console log associated with the web browser, assuming
     * the browser has such a log.
     * @public
     * @param {boolean} value - Whether debug mode is on (true) or off
     *                          (false)
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.setDebugOn = function (value) {
      if (typeof value === 'boolean') {
        if (window.console && window.console.log) {
          debugOn = value;
        } else {
          debugOn = false;
          if (value) {
            error('Ignoring attempt to turn on debugging ' + 'as there is no console available');
          }
        }
      } else {
        error('Ignoring attempt to change debug status as the value ' + 'provided was not a Boolean.');
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Store the provided value as a machine nonce.
     * This will silently fail if no storage types
     * can be used, meaning that on next authentication,
     * no machine nonce will be available for use.
     * @public
     * @param {string} value - The machine nonce value
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.storeMachineNonce = function (value) {
      setNonce(machineNonceName, value);
      // For chaining
      return machineSecret;
    };
    /**
     * Store the provided value as a sequence nonce.
     * This will silently fail if no storage types
     * can be used, meaning that on next authentication,
     * no sequence nonce will be available for use.
     * @public
     * @param {string} value - The sequence nonce value
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes.
     */
    machineSecret.storeSequenceNonce = function (value) {
      setNonce(sequenceNonceName, value);
      // For chaining
      return machineSecret;
    };
    /**
     * Fetch the value associated with the machine nonce.
     * If no machine nonce is currently available (not
     * previously stored or it was deleted from all
     * requested storage types) then the result is undefined.
     * @public
     * @export
     * @returns {string} The latest machine nonce or undefined
     *                   if a sequence nonce does not exist.
     */
    machineSecret.fetchMachineNonce = function () {
      return getNonce(machineNonceName);
    };
    /**
     * Fetch the value associated with the sequence nonce.
     * If no sequence nonce is currently available (not
     * previously stored or it was deleted from all
     * requested storage types) then the result is undefined.
     * @public
     * @returns {string} The latest sequence nonce or undefined
     *                   if a sequence nonce does not exist.
     */
    machineSecret.fetchSequenceNonce = function () {
      return getNonce(sequenceNonceName);
    };
    /**
     * Remove all aspects of machine secret information associated with the user.
     * This would be done if the user decides not to "Remember Me" anymore.
     * @public
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.doNotRemember = function () {
      // Remove machine nonce, if any
      removeNonce(machineNonceName);
      // Remove sequence nonce, if any
      removeNonce(sequenceNonceName);
      // Remove indication of device fingerprint use, if any.
      removeNonce(DEVICE_FP_IN_USE);
      // For chaining
      return machineSecret;
    };
    /**
     * A callback type for Flash initialization
     *
     * @callback flashInitializedCallback
     * @param {boolean} success - true if Flash successfully initialized, false otherwise.
     */
    /**
     * @deprecated This function has been deprecated as Flash is no longer supported.
     *              If invoked it will act as if Flash plugin-in is not installed/enabled.
     * @public
     * @param {flashInitializedCallback} callback - The function that will be invoked once
     *                                              Flash has initialized or it is
     *                                              determined that it can't initialize, or the
     *                                              request to initialize times out.
     * @param {number} [wait=10000] - The maximum amount of time, in milliseconds,
     *                                 to wait for Flash to initialize.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.initializeFlash = function (callback, wait) {
      debug('Flash is no longer supported.');
      if (typeof callback !== 'function') {
        error('The first argument to initializeFlash must be a callback function.');
      } else {
        if (wait && typeof wait !== 'number') {
          error('The optional second argument to initializeFlash must be a wait time in milliseconds.');
        } else {
          storageTypes.flash.init(callback, wait);
        }
      }
      // For chaining
      return machineSecret;
    };
    /**
     * A client can extend the fingerprint collection process by directly
     * calling functions such as:
     * <ul>
     *   <li>addDeviceAttribute</li>
     *   <li>addDeviceAsynchronousAttribute</li>
     *   <li>setErrorAttributeName</li>
     *   <li>addPostProcessor</li>
     * </ul>
     * The client may also package all these calls, and other functions, into
     * another package and assign a separate function to call them. That
     * function is refered to as an extension-function and is the argument
     * passed to this function.
     * @public
     * @param {function} func - The initialization function for to extend
     *                   the functionality.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.addExtensions = function (func) {
      if (typeof func === 'function') {
        func(machineSecret);
      } else {
        error(
          'Ignoring attempt to add extend the functionality ' +
            'because the initializer function provided is ' +
            'not a function.'
        );
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Add a new device attribute to contribute to the calculation of
     * the overall device fingerprint / device id.
     * @public
     * @param {string} name - The name to be used for the attribute. The
     *                        name cannot reference an already existing
     *                        attribute name or include space or
     *                        slash (/) characters.
     * @param {function} func - The function that will be used to
     *                          calculate the value of the device attribute
     * @param (boolean) cacheable - Once the value has been calculated it will
     *                              be cached on subsequent "gets" if cahceable
     *                              is set to "true". The value is optional
     *                              and defaults to "false" if not provided.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.addDeviceAttribute = function (name, func, cacheable) {
      // Not allowed to overwrite an existing attribute or provide a
      // non-function evaluator
      if (attributes[name] === undefined && /[\/ ]/.exec(name) === null && typeof func === 'function') {
        attributes[name] = {
          cacheable: cacheable == true,
          get: func
        };
      } else {
        error(
          'Ignoring attempt to add a new device attribute as its ' +
            'name is already in use, contains illegal characters, ' +
            'and/or the evaluator provided is not a function.'
        );
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Change the "cacheable" flag for the attribute.
     * @public
     * @param {string} attribute - The name of the attribute.
     * @param (boolean) cacheable - "true" means the attribute can be cached
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.setCacheable = function (name, cacheable) {
      if (typeof name == 'undefined') {
        error('isCacheable requires the name of the attribute.');
        return false;
      } else if (name in attributes) {
        attributes[name].cacheable = cacheable == true;
        if (!cacheable && name in attributesCache) {
          delete attributesCache[name];
        }
      } else {
        error('Ignoring attempt to set cacheability of attribute ' + "which isn't being managed: " + name);
      }
      // For chaining
      return machineSecret;
    };
    /**
     * Gets the "cacheable" state of the attribute.
     * @public
     * @param {string} attribute - The name of the attribute.
     * @returns {boolean} "true" if the attribute is cacheable
     */
    machineSecret.isCacheable = function (name) {
      if (typeof name == 'undefined') {
        error('isCacheable requires the name of the attribute.');
        return false;
      } else if (name in attributes) {
        return attributes[name].cacheable;
      } else {
        error('Ignoring attempt to get the cacheability of attribute ' + "which isn't being managed: " + name);
        return false;
      }
    };
    /**
     * Clears the attribute value cache to force "gets" of attribute values
     * to be gotten from the system rather than the cache.
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.clearCache = function () {
      attributesCache = {};
      // For chaining
      return machineSecret;
    };
    /**
     * Add a new device-asynchronous attribute to contribute to the
     * calculation of the overall device fingerprint / device id.
     * @public
     * @param {string} name - The name to be used for the attribute. The
     *                        name cannot reference an already existing
     *                        attribute name or include space or
     *                        slash (/) characters.
     * @param {function} func - The function that will be used to
     *                          calculate the value of the device attribute
     * @returns {Object} The machineSecret Object for function chaining
     *                   purposes
     */
    machineSecret.addDeviceAsynchronousAttribute = function (name, func) {
      // Not allowed to overwrite an existing attribute or provide a
      // non-function evaluator
      if (asynchronousAttributes[name] === undefined && /[\/ ]/.exec(name) === null && typeof func === 'function') {
        asynchronousAttributes[name] = func;
      } else {
        error(
          'Ignoring attempt to add a new device asynchronous ' +
            'attribute as its name is already in use, contains ' +
            'illegal characters, and/or the evaluator provided is ' +
            'not a function.'
        );
      }
      // For chaining
      return machineSecret;
    };
    return machineSecret;
  }
  // In case our browser does not support Object.keys
  if (!Object.keys) {
    Object.keys = function (obj) {
      var keys = [];
      for (var k in obj) {
        if (obj.hasOwnProperty(k)) keys.push(k);
      }
      return keys;
    };
  }
  if (typeof machineSecret === 'undefined') window.machineSecret = define();
})(window);
